var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loginContent" }, [
    _c("div", { staticClass: "loginImage" }, [
      _c(
        "div",
        { staticClass: "loginBox" },
        [
          _c("p", { staticClass: "loginTitle" }, [
            _vm._v("欢迎使用东克跨境平台系统")
          ]),
          _c(
            "el-card",
            { staticClass: "boxCard" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c("el-tab-pane", { attrs: { label: "注册", name: "1" } }, [
                    _c(
                      "div",
                      { staticClass: "regForm" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "registeredForm",
                            staticClass: "ruleForm",
                            attrs: {
                              model: _vm.registeredForm,
                              rules: _vm.loginRules,
                              "status-icon": ""
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "phone" } },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      type: "username",
                                      "auto-complete": "“new-password”",
                                      size: "small",
                                      clearable: "",
                                      placeholder: "11位手机号码"
                                    },
                                    model: {
                                      value: _vm.registeredForm.phone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.registeredForm,
                                          "phone",
                                          $$v
                                        )
                                      },
                                      expression: "registeredForm.phone"
                                    }
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("+86")
                                    ])
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "password" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "password",
                                    "auto-complete": "new-password",
                                    clearable: "",
                                    size: "small",
                                    placeholder: "输入密码"
                                  },
                                  model: {
                                    value: _vm.registeredForm.password,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.registeredForm,
                                        "password",
                                        $$v
                                      )
                                    },
                                    expression: "registeredForm.password"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "repassword" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "password",
                                    size: "small",
                                    clearable: "",
                                    placeholder: "二次输入密码"
                                  },
                                  model: {
                                    value: _vm.registeredForm.repassword,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.registeredForm,
                                        "repassword",
                                        $$v
                                      )
                                    },
                                    expression: "registeredForm.repassword"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "company_name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "small",
                                    clearable: "",
                                    placeholder: "公司名称"
                                  },
                                  model: {
                                    value: _vm.registeredForm.company_name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.registeredForm,
                                        "company_name",
                                        $$v
                                      )
                                    },
                                    expression: "registeredForm.company_name"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { prop: "username" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    size: "small",
                                    clearable: "",
                                    placeholder: "你的姓名"
                                  },
                                  model: {
                                    value: _vm.registeredForm.username,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.registeredForm,
                                        "username",
                                        $$v
                                      )
                                    },
                                    expression: "registeredForm.username"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "mt5",
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary"
                                    },
                                    on: { click: _vm.handleLogin }
                                  },
                                  [_vm._v("注册完成")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "prompt_b c_blue cursor_p",
                      on: { click: _vm.loginRouter }
                    },
                    [_vm._v("已有账号登录")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "record" }, [
      _c("p", { staticClass: "recordTitle" }, [
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
          [_vm._v("粤ICP备2020104876号-1")]
        ),
        _c("span", { staticStyle: { "margin-left": "10px" } }, [
          _vm._v("©2020深圳东克科技有限公司")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }