import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var stockmanageRouter = [{
  path: '/stockmanage',
  component: Layout,
  alwaysShow: true,
  name: 'stockmanage',
  redirect: 'noRedirect',
  meta: {
    title: '库存管理',
    icon: 'el-icon-user-solid'
  },
  // hidden: true,
  children: [{
    path: 'addstock/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/stockmanage/addstock/index'));
      });
    },
    name: 'addstock',
    meta: {
      title: '库存管理',
      keepAlive: true
    }
  }, {
    path: 'detailedstock/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/stockmanage/detailedstock/index'));
      });
    },
    name: 'detailedstock',
    meta: {
      title: '库存明细',
      keepAlive: true
    }
  }, {
    path: 'addWarehouse/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/stockmanage/addWarehouse/index'));
      });
    },
    name: 'addWarehouse',
    meta: {
      title: '仓库管理',
      keepAlive: true
    }
  }]
}];
export default stockmanageRouter;