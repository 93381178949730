var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "reply-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "1200px",
        title: _vm.title,
        "append-to-body": true,
        "modal-append-to-body": true,
        "before-close": _vm.reset
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "order-cell" },
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRule,
                "label-width": "140px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "售后信息", name: "first" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 8,
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: _vm.formData.reply_info,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "reply_info", $$v)
                          },
                          expression: "formData.reply_info"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "图片回复", name: "second" } },
                    [
                      _c(
                        "div",
                        [
                          _c("shop-window", {
                            ref: "shopWindow",
                            attrs: {
                              "img-list": _vm.formData.reply_img,
                              prop: "reply_img"
                            },
                            on: {
                              update: _vm.updateImgList,
                              delete: _vm.deleteImg
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", margin: "10px" },
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.SubmitLoading
                  },
                  on: { click: _vm.submitReply }
                },
                [_vm._v(" 确定 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }