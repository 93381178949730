import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var adminRouter = [{
  path: '/admin',
  component: Layout,
  alwaysShow: true,
  name: 'admin',
  redirect: 'noRedirect',
  meta: {
    title: '权限管理',
    icon: 'el-icon-menu'
  },
  children: [{
    path: 'menu/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/admin/menu/index'));
      });
    },
    name: 'menus',
    meta: {
      title: '菜单管理',
      keepAlive: true
    }
  }, {
    path: 'charactar/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/admin/charactar/index'));
      });
    },
    name: 'charactar',
    meta: {
      title: '角色管理',
      keepAlive: true
    }
  }]
}];
export default adminRouter;