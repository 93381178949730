import request from '@/utils/request';
import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 消息中心列表

export function baseSystemGetList(data) {
  return request({
    url: '/base/system/get-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 消息中心对应标题字段

export function baseSystemGetMap(data) {
  return request({
    url: '/base/system/get-map',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 消息中心未读

export function baseSystemRead(data) {
  return request({
    url: '/base/system/read',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}