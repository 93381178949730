var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "edit-pictures-dialog",
      attrs: { visible: _vm.dialogVisible, title: _vm.title, width: "50%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "upload-photos" }, [
        _c(
          "ul",
          { staticClass: "el-upload-list el-upload-list--picture-card" },
          [
            _vm._l(_vm.imgList, function(file, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "el-upload-list__item",
                  class: [_vm.checkedImg == file.url ? "active" : ""],
                  attrs: { tabindex: index },
                  on: {
                    click: function($event) {
                      return _vm.selectImg(file)
                    }
                  }
                },
                [
                  _c("el-image", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: file.url }
                  }),
                  _c("div", { staticClass: "bg selected" }),
                  _vm.checkedImg == file.url
                    ? _c(
                        "div",
                        { staticClass: "icon" },
                        [
                          _c("svg-icon", { attrs: { "icon-class": "checked" } })
                        ],
                        1
                      )
                    : _vm._e(),
                  file.showProgress
                    ? _c(
                        "div",
                        { staticClass: "upload-progress" },
                        [
                          _c("el-progress", {
                            attrs: { type: "circle", percentage: file.percent }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            _c(
              "div",
              { staticClass: "uploading" },
              [
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    staticClass: "upload-photos",
                    attrs: {
                      accept: "image/png, image/jpeg",
                      drag: "",
                      multiple: "",
                      action: "",
                      "show-file-list": false,
                      "http-request": _vm.upload,
                      "before-upload": _vm.handleBeforeUpload,
                      "on-change": _vm.handleChange
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _c("em", [_vm._v("点击上传")]),
                      _c("p", [_vm._v("或将文件拖到此处")])
                    ])
                  ]
                )
              ],
              1
            )
          ],
          2
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }