import _toConsumableArray from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import Router from 'vue-router';
import { parseMap } from '@/utils';
Vue.use(Router);
/* Layout */

import Layout from '@/layout';
import accountRouter from './modules/account';
import informationRouter from './modules/information';
import commerceRouter from './modules/commerce';
import commodityRouter from './modules/commodity';
import adminRouter from './modules/admin';
import systemRouter from './modules/system';
import afterRouter from './modules/after';
import billRouter from './modules/bill';
import personalRouter from './modules/personal';
import stockmanageRouter from './modules/stockmanage';
import logisticsrulesRouter from './modules/logisticsrules';
import messageCenterRouter from './modules/message';
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/registered',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/registered'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/dashboard/index'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'el-icon-s-home',
      affix: true
    }
  }]
}];
export var asyncRoutes = [].concat(_toConsumableArray(informationRouter), _toConsumableArray(commodityRouter), _toConsumableArray(commerceRouter), _toConsumableArray(accountRouter), _toConsumableArray(adminRouter), _toConsumableArray(systemRouter), _toConsumableArray(logisticsrulesRouter), _toConsumableArray(afterRouter), _toConsumableArray(billRouter), _toConsumableArray(personalRouter), _toConsumableArray(stockmanageRouter), _toConsumableArray(messageCenterRouter));
export var staticMap = [{
  path: '*',
  name: '404',
  redirect: '/404',
  hidden: true
}]; // 生成routesMap做映射

export var routesMap = parseMap(asyncRoutes);

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;