var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quoted-detail-box" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            model: _vm.formData,
            rules: _vm.formDataRules,
            "label-width": "160px",
            "label-position": "top"
          }
        },
        [
          _c("el-card", { staticClass: "m20 mt0" }, [
            _c(
              "div",
              { staticClass: "card-innerBox" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "Title", prop: "title" } },
                  [_c("span", [_vm._v(_vm._s(_vm.formData.product_title))])]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Source URL", prop: "url" } },
                  [
                    _vm.platform_index_id
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: {
                              href:
                                "https://aliexpress.com/item/" +
                                _vm.platform_index_id +
                                ".html",
                              type: "primary",
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                "https://aliexpress.com/item/" +
                                  _vm.platform_index_id +
                                  ".html"
                              )
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "el-card",
            { staticClass: "m20 mt0" },
            [
              _c("h5", { staticClass: "mt20 ml40" }, [_vm._v("Media")]),
              _c("el-divider"),
              _c(
                "div",
                { staticClass: "card-innerBox" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    _vm._l(_vm.imageList, function(img, key) {
                      return _c("el-image", {
                        key: key,
                        staticStyle: {
                          height: "120px",
                          width: "150px",
                          "margin-right": "20px"
                        },
                        attrs: { src: img, "preview-src-list": [img] }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "m20 mt0" },
            [
              _c("div", { staticClass: "btn-box" }, [
                _c("h5", { staticClass: "mt20 ml40" }, [_vm._v("Detailed")]),
                _c(
                  "div",
                  { staticClass: "mt10 mr10" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.logisticsSettings }
                      },
                      [_vm._v("物流设置")]
                    )
                  ],
                  1
                )
              ]),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.formData.sub_data,
                    "highlight-current-row": "",
                    fit: "",
                    border: "",
                    "header-cell-style": {
                      background: "#F3F5F9",
                      color: "#262B3EFF"
                    }
                  }
                },
                [
                  _vm._l(_vm.labelListOne, function(item) {
                    return _c("el-table-column", {
                      key: item.label,
                      attrs: {
                        label: item.label,
                        prop: item.value,
                        width: item.width,
                        "min-width": item.width ? item.width : "150",
                        fixed: "left"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                item.type === undefined
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row[item.value]))
                                    ])
                                  : _vm._e(),
                                item.type === "img"
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-image",
                                          {
                                            staticClass: "sku_image",
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px"
                                            },
                                            attrs: {
                                              src: scope.row.img,
                                              fit: "cover"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "image-slot",
                                                attrs: { slot: "error" },
                                                slot: "error"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-picture-outline",
                                                  staticStyle: {
                                                    "font-size": "30px"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _vm._l(_vm.optionList, function(item) {
                    return _c("el-table-column", {
                      key: item.label,
                      attrs: {
                        label: item.label,
                        prop: item.value,
                        width: item.width,
                        "min-width": item.width ? item.width : "150",
                        fixed: "left"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                item.type === undefined
                                  ? _c("span", [
                                      _vm._v(_vm._s(scope.row[item.value]))
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _vm._l(_vm.labelListTwo, function(item) {
                    return _c("el-table-column", {
                      key: item.label,
                      attrs: {
                        label: item.label,
                        prop: item.value,
                        width: item.width,
                        "min-width": item.width ? item.width : "150",
                        fixed: "left"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                item.type === "input"
                                  ? _c(
                                      "span",
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "90%" },
                                          attrs: {
                                            size: "small",
                                            placeholder: "0",
                                            disabled:
                                              scope.row[item.value] === null,
                                            maxlength: "200"
                                          },
                                          on: {
                                            blur: function($event) {
                                              return _vm.handleToSubmit(
                                                scope.row
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row[item.value],
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                item.value,
                                                $$v
                                              )
                                            },
                                            expression: "scope.row[item.value]"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.type === "change"
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "el-input",
                                          {
                                            staticStyle: { width: "90%" },
                                            attrs: {
                                              size: "small",
                                              placeholder: "0",
                                              disabled:
                                                scope.row[item.value] === null,
                                              maxlength: "200"
                                            },
                                            on: {
                                              blur: function($event) {
                                                return _vm.handleCalculateTotal(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row[item.value],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  item.value,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row[item.value]"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "line-height": "32px"
                                                },
                                                attrs: { slot: "prefix" },
                                                slot: "prefix"
                                              },
                                              [_vm._v("$")]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _vm._l(_vm.countryLabelList, function(item2, key) {
                    return _c("el-table-column", {
                      key: item2.label,
                      attrs: {
                        label: item2.label,
                        prop: item2.value,
                        width: item2.width
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function(scope) {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(scope.column.label) +
                                      "( " +
                                      _vm._s(_vm.dayList[key]) +
                                      " day)"
                                  )
                                ])
                              ]
                            }
                          },
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                item2.type === "dynamic"
                                  ? _c("span", [
                                      _c(
                                        "span",
                                        { staticClass: "change-style" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "left" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [_vm._v("Details")]
                                              ),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    size: "small",
                                                    placeholder: "0",
                                                    disabled:
                                                      scope.row[item2.value]
                                                        .logistics_fee ===
                                                        null &&
                                                      !scope.row.disabled,
                                                    maxlength: "200"
                                                  },
                                                  on: {
                                                    blur: function($event) {
                                                      return _vm.handleCalculateFeeTotal(
                                                        scope.row,
                                                        scope.row[item2.value]
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row[item2.value]
                                                        .logistics_fee,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row[item2.value],
                                                        "logistics_fee",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[item2.value].logistics_fee"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "line-height": "32px"
                                                      },
                                                      attrs: { slot: "prefix" },
                                                      slot: "prefix"
                                                    },
                                                    [_vm._v("$")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "right" },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "title" },
                                                [_vm._v("Total")]
                                              ),
                                              _c(
                                                "el-input",
                                                {
                                                  staticStyle: { width: "90%" },
                                                  attrs: {
                                                    size: "small",
                                                    placeholder: "0",
                                                    maxlength: "200",
                                                    disabled: ""
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row[item2.value]
                                                        .total,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        scope.row[item2.value],
                                                        "total",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row[item2.value].total"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "line-height": "32px"
                                                      },
                                                      attrs: { slot: "prefix" },
                                                      slot: "prefix"
                                                    },
                                                    [_vm._v("$")]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Operation", fixed: "right", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleClick(
                                      scope.row,
                                      scope.$index
                                    )
                                  }
                                }
                              },
                              [_vm._v("选择商品")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-card", { staticClass: "foot-box" }, [
        _c(
          "div",
          { staticClass: "foot-btn" },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleCancel } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.MakeAQuotation }
              },
              [_vm._v("进行报价")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "throttle",
                    rawName: "v-throttle",
                    value: [_vm.handleSaveDraft],
                    expression: "[handleSaveDraft]"
                  }
                ],
                attrs: { type: "primary", size: "small" }
              },
              [_vm._v("存草稿")]
            )
          ],
          1
        )
      ]),
      _c("rules-config", {
        attrs: {
          visible: _vm.dialogVisible,
          "quoted-id": _vm.quotedId.toString(),
          "channel-rules": _vm.channel_rules
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          },
          close: function($event) {
            return _vm.init(_vm.$route.query.id)
          },
          dayList: _vm.handleDayList
        }
      }),
      _c("select-commodity-dialog", {
        attrs: {
          visible: _vm.selectCommodity,
          serial: _vm.serialNumber,
          map_sku_id: _vm.map_sku_id
        },
        on: {
          "update:visible": function($event) {
            _vm.selectCommodity = $event
          },
          close: _vm.receiveData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }