var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "单点权限设置",
            "before-close": _vm.close,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("el-tree", {
            ref: "tree",
            staticClass: "authority",
            attrs: {
              data: _vm.menuTree,
              "show-checkbox": "",
              "node-key": "menu_id",
              "default-expanded-keys": _vm.openNode,
              "highlight-current": "",
              "render-content": _vm.renderContent,
              props: _vm.defaultProps
            }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: {
                    click: function($event) {
                      return _vm.close(1)
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }