import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array-buffer.constructor.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/es.array.slice.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var XLSX = require('xlsx');

export default {
  name: 'ProgExportImport',
  data: function data() {
    return {
      fullscreenLoading: false,
      // 加载中
      outFile: '',
      // 导出文件el对象
      errorDialog: false,
      // 错误信息弹窗
      errorMsg: '',
      // 错误信息内容
      // excelTitle: {}, // excel标题
      excelData: [] // excel处理数据

    };
  },
  props: {
    setExportData: {
      type: Function,
      default: function _default() {
        console.warn('未传递获取excel导出数据方法');
      }
    }
  },
  mounted: function mounted() {
    this.outFile = document.getElementById('downlink');
  },
  methods: {
    downloadFile: function downloadFile() {
      // 导出文件点击事件
      var exportData = this.setExportData(); // this.excelTitle = exportData.excelTitle

      this.excelData = exportData.excelData;
      var data = [{}, {}];

      for (var k in this.excelData[0]) {
        // 设置第1行为数据库字段行
        if (this.excelData[0].hasOwnProperty(k)) {
          data[0][k] = k; // data[1][k] = this.excelTitle[k] // 中文标题
        }
      }

      data = data.concat(this.excelData);
      this.downloadExl(data, exportData.excelName || '导出文件');
    },
    downloadExl: function downloadExl(json, downName, type) {
      var _this = this;

      // 导出到excel
      var keyMap = []; // 获取键

      for (var k in json[0]) {
        if (json[0].hasOwnProperty(k)) {
          keyMap.push(k);
        }
      }

      var tmpdata = []; // 用来保存转换好的json

      json.map(function (v, i) {
        return keyMap.map(function (k, j) {
          return Object.assign({}, {
            v: v[k] || '',
            position: (j > 25 ? _this.getCharCol(j) : String.fromCharCode(65 + j)) + (i + 1)
          });
        });
      }).reduce(function (prev, next) {
        return prev.concat(next);
      }).forEach(function (v) {
        tmpdata[v.position] = {
          v: v.v
        };
      });
      var outputPos = Object.keys(tmpdata); // 设置区域,比如表格从A1到D10

      var tmpWB = {
        SheetNames: ['sheet'],
        // 保存的表标题
        Sheets: {
          'sheet': Object.assign({}, tmpdata, // 内容
          {
            '!ref': outputPos[0] + ':' + outputPos[outputPos.length - 1] // 设置填充区域

          })
        }
      };
      var tmpDown = new Blob([this.s2ab(XLSX.write(tmpWB, {
        bookType: type || 'xlsx',
        bookSST: false,
        type: 'binary'
      } // 这里的数据是用来定义导出的格式类型
      ))], {
        type: ''
      }); // 创建二进制对象写入转换好的字节流

      var href = URL.createObjectURL(tmpDown); // 创建对象超链接

      this.outFile.download = downName + '.xlsx'; // 下载名称

      this.outFile.href = href; // 绑定a标签

      this.outFile.click(); // 模拟点击实现下载

      setTimeout(function () {
        // 延时释放
        URL.revokeObjectURL(tmpDown); // 用URL.revokeObjectURL()来释放这个object URL
      }, 100);
    },
    s2ab: function s2ab(s) {
      // 字符串转字符流
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);

      for (var i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
      }

      return buf;
    },
    getCharCol: function getCharCol(n) {
      // 将指定的自然数转换为26进制表示。映射关系：[0-25] -> [A-Z]。
      var s = '';
      var m = 0;

      while (n > 0) {
        m = n % 26 + 1;
        s = String.fromCharCode(m + 64) + s;
        n = (n - m) / 26;
      }

      return s;
    },
    fixdata: function fixdata(data) {
      // 文件流转BinaryString
      var o = '';
      var l = 0;
      var w = 10240;

      for (; l < data.byteLength / w; ++l) {
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
      }

      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    }
  }
};