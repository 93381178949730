import { Loading } from 'element-ui';
export var baseload = {
  data: function data() {
    return {
      loadingInstance: Object.create(null)
    };
  },
  methods: {
    mixinloading: function mixinloading(val) {
      this.loadingInstance = Loading.service(val);
    },
    mixinclose: function mixinclose() {
      var _this = this;

      if (this.loadingInstance != null) {
        this.$nextTick(function () {
          // 以服务的方式调用的 Loading 需要异步关闭
          _this.loadingInstance.close();
        });
      }
    }
  }
};