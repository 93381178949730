var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$parent.logisticsTitle,
            visible: _vm.visible,
            width: "600px",
            "before-close": _vm.logisticsAdd,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "logisticsForm",
              attrs: {
                model: _vm.logisticsForm,
                size: "small",
                "label-width": "150px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户代码:", prop: "client_code" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "客户代码" },
                    model: {
                      value: _vm.logisticsForm.client_code,
                      callback: function($$v) {
                        _vm.$set(_vm.logisticsForm, "client_code", $$v)
                      },
                      expression: "logisticsForm.client_code"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密钥:", prop: "secret_key" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "密钥" },
                    model: {
                      value: _vm.logisticsForm.secret_key,
                      callback: function($$v) {
                        _vm.$set(_vm.logisticsForm, "secret_key", $$v)
                      },
                      expression: "logisticsForm.secret_key"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.logisticsAdd } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "throttle",
                      rawName: "v-throttle",
                      value: [
                        function() {
                          return _vm.logisticsAdd(1)
                        }
                      ],
                      expression: "[() => logisticsAdd(1)]"
                    }
                  ],
                  attrs: { size: "small", type: "primary" }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }