import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";

/**
 * Array to String
 * @param {String} string
 */
export function ArrToString(arr) {
  if (!arr) return '';

  if (Array.isArray(arr) && arr.length > 0) {
    return arr.toString();
  }

  return '';
}