//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'index',
  data: function data() {
    return {
      messageTitle: '',
      orderList: [{
        title: ''
      }]
    };
  },
  created: function created() {
    var messageTitle = this.$route.params.id;

    if (messageTitle === 'order') {
      this.messageTitle = '订单通知';
    }

    if (messageTitle === 'system') {
      this.messageTitle = '系统通知';
    }

    if (messageTitle === 'after') {
      this.messageTitle = '售后消息';
    }

    if (messageTitle === 'bill') {
      this.messageTitle = '账单消息';
    }

    if (messageTitle === 'quoted') {
      this.messageTitle = '报价消息';
    }

    if (messageTitle === 'product') {
      this.messageTitle = '商品变更';
    }
  },
  methods: {
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.back();
    }
  }
};