import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 对账列表

export function settlementList(data) {
  return request({
    url: '/settlement/settlement/index',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 查询结算信息

export function settlementById(data) {
  return request({
    url: '/settlement/settlement/settlement-by-id',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 设置结算状态

export function setSettlementStatus(data) {
  return request({
    url: '/settlement/settlement/set-settlement-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 结算列表

export function settlementBillList(data) {
  return request({
    url: '/settlement/settlement/settlement-bill-list',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 设置供应商结算金额

export function changeSupplierAmount(data) {
  return request({
    url: '/settlement/settlement/change-supplier-amount',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 导出结算列表

export function exportSettlement(data) {
  return request({
    url: '/settlement/settlement/export-settlement',
    method: 'post',
    data: data,
    responseType: 'blob',
    baseURL: baseURL
  });
}