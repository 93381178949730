import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getOrderId } from '@/api/commerce';
export default {
  name: 'generate-dialog',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ' '
    },
    formData: {// type: [Array,Object],
      // default: () => {}
    },
    orderData: {},
    myRoute: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      labelForm: [{
        label: '商品编码:',
        value: 'goods_name'
      }, {
        label: 'sku名称:',
        value: 'sku_name'
      }, {
        label: 'sku数量:',
        value: 'sku_num'
      }, {
        label: 'sku新数量:',
        value: 'sku_num_new'
      }, {
        label: '订单号:',
        value: 'thirdParty_order_on'
      }],
      newGoods: [],
      // 新的拆分商品数组
      oldGoodsInfo: [],
      // 原订单商品信息
      selectedGoods: [],
      // 选择拆分的商品
      splitOrderForm: '' // 初始化数据

    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  watch: {
    formData: {
      handler: function handler(data) {
        this.newGoods = [];
        this.selectedGoods = JSON.parse(JSON.stringify(data));

        if (this.selectedGoods.length === this.splitOrderForm.goods_info.length) {
          this.oldGoodsInfo = JSON.parse(JSON.stringify(this.selectedGoods));
        } else {
          this.oldGoodsInfo = JSON.parse(JSON.stringify(this.splitOrderForm.goods_info));
        }

        this.newGoods.push(JSON.parse(JSON.stringify(this.oldGoodsInfo)));
        this.insert();
      }
    },
    orderData: {
      handler: function handler(data) {
        this.splitOrderForm = JSON.parse(JSON.stringify(data));
      }
    }
  },
  methods: {
    // 添加新订单
    addOrder: function addOrder() {
      var goods = this.newGoods[0];
      var skuSum = 0;

      for (var i = 0, len = goods.length; i < len; i++) {
        skuSum += parseInt(goods[i].sku_num);
      } // console.log(skuSum)


      if (skuSum <= 1) {
        this.$message({
          type: 'error',
          message: '无法拆分！'
        });
      } else {
        this.insert();
      }
    },
    // 插入一条订单
    insert: function insert() {
      var tmp = JSON.parse(JSON.stringify(this.selectedGoods));

      for (var i = 0; i < tmp.length; i++) {
        tmp[i].sku_num = '0';
      }

      this.newGoods.push(tmp);
    },
    orderSave: function orderSave() {
      var _this = this;

      if (this.newGoods.length === 1) {
        this.$message({
          type: 'error',
          message: '请拆分订单'
        });
        return;
      } // 判断拆分订单是否存在商品全为0


      for (var i = 0; i < this.newGoods.length; i++) {
        var goodsSkuNum = 0;

        for (var j = 0; j < this.newGoods[i].length; j++) {
          if (this.newGoods[i][j].sku_num.length === 0) {
            this.$message({
              type: 'error',
              message: '请输入正确拆分数量'
            });
            return;
          }

          goodsSkuNum += parseInt(this.newGoods[i][j].sku_num);
        }

        if (goodsSkuNum === 0) {
          this.$message({
            type: 'error',
            message: '拆分订单商品SKU数量不能全为0'
          });
          return;
        }
      }

      var data = [];
      var arr = [];

      for (var _i = 0; _i < this.newGoods.length; _i++) {
        var orderForm = JSON.parse(JSON.stringify(this.splitOrderForm));

        this.newGoods[_i].map(function (it, inx) {
          if (it.sku_num === 0) {
            arr.push(it);
          }
        });

        for (var _j = 0; _j < this.newGoods[_i].length; _j++) {
          if (this.newGoods[_i][_j].sku_num === 0) {
            this.newGoods[_i].splice(_j, arr.length);

            break;
          }
        }

        orderForm.goods_info = this.newGoods[_i];
        data.push(orderForm);
      }

      getOrderId({
        num: this.newGoods.length
      }).then(function (res) {
        if (res.code === 200) {
          for (var _i2 = 0; _i2 < data.length; _i2++) {
            data[_i2].order_no = res.data[_i2];
          }

          localStorage.setItem('splitOrders', JSON.stringify(data));
          _this.$parent.dialogVisible = false;

          _this.$store.dispatch('tagsView/delView', _this.myRoute);

          _this.$router.push({
            name: 'split-orderprocess',
            query: {
              service_name: _this.$route.query.service_name,
              order_no: _this.$route.query.order_no
            }
          });
        }
      });
    },
    // 实时更改商品sku数量
    update: function update(val, orderIndex, goodIndex) {
      var id = this.newGoods[orderIndex][goodIndex].id; // 待修改商品的总数量

      var skuSum = 0;
      var index = 0;

      for (var i = 0; i < this.oldGoodsInfo.length; i++) {
        if (this.oldGoodsInfo[i].id === id) {
          skuSum = parseInt(this.oldGoodsInfo[i].sku_num);
          index = i;
        }
      } // 更新订单1的sku数量


      var currentNum = val.length ? parseInt(val) : 0;
      var goodNumExcept = 0;

      for (var _i3 = 1; _i3 < this.newGoods.length; _i3++) {
        if (_i3 === orderIndex) continue;

        for (var j = 0; j < this.newGoods[_i3].length; j++) {
          if (this.newGoods[_i3][j].id === id) {
            goodNumExcept += parseInt(this.newGoods[_i3][j].sku_num);
          }
        }
      } // 最大可改写值


      var maxGoodNum = skuSum - goodNumExcept;

      if (currentNum >= 0 && currentNum <= maxGoodNum) {
        this.newGoods[0][index].sku_num = maxGoodNum - currentNum;
      } else {
        this.$message({
          type: 'error',
          message: '请输入正确SKU数量'
        }); // 输入错误恢复

        var oldSkuNum = parseInt(this.newGoods[0][index].sku_num);

        for (var _i4 = 1; _i4 < this.newGoods.length; _i4++) {
          if (_i4 === orderIndex) continue;

          for (var _j2 = 0; _j2 < this.newGoods[_i4].length; _j2++) {
            if (this.newGoods[_i4][_j2].id === id) {
              oldSkuNum += parseInt(this.newGoods[_i4][_j2].sku_num);
            }
          }
        }

        this.newGoods[orderIndex][goodIndex].sku_num = skuSum - oldSkuNum;
      }
    },
    reset: function reset() {
      this.newGoods = [];
      this.newGoods.push(JSON.parse(JSON.stringify(this.oldGoodsInfo)));
      this.insert();
    }
  }
};