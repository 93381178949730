var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "company" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              attrs: {
                inline: "",
                model: _vm.formInline,
                "label-width": "120px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称:", prop: "company_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-200",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "公司名称"
                    },
                    model: {
                      value: _vm.formInline.company_name,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "company_name", $$v)
                      },
                      expression: "formInline.company_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司状态:", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formInline.status,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "status", $$v)
                        },
                        expression: "formInline.status"
                      }
                    },
                    _vm._l(_vm.selectList.typeList, function(item, key) {
                      return _c("el-option", {
                        key: key + 1,
                        attrs: { label: item, value: String(key + 1) }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册时间:", prop: "create_start" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.create_start,
                      callback: function($$v) {
                        _vm.create_start = $$v
                      },
                      expression: "create_start"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v("筛 选")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.resetClick }
                    },
                    [_vm._v("重 置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.addClick }
                    },
                    [_vm._v("新增公司")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                loading: _vm.loading,
                data: _vm.tableData,
                "highlight-current-row": "",
                fit: "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            _vm._l(_vm.labelList, function(item, idx) {
              return _c("el-table-column", {
                key: idx,
                attrs: {
                  label: item.label,
                  prop: item.value,
                  width: item.width
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row[item.value]))]),
                          item.type == "operation"
                            ? _c(
                                "span",
                                [
                                  scope.row.status == "已激活"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.operation(
                                                scope.row.id,
                                                scope.row.company_name,
                                                "disable"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("停 用")]
                                      )
                                    : _vm._e(),
                                  scope.row.status == "未激活"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.operation(
                                                scope.row.id,
                                                scope.row.company_name,
                                                "enable"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("激 活")]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-link",
                                    {
                                      staticStyle: { display: "block" },
                                      attrs: {
                                        type: "primary",
                                        underline: false
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.adminAdd(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("分配管理员")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.Inquire
            }
          })
        ],
        1
      ),
      _c("company-add", {
        attrs: { visible: _vm.companyVisible },
        on: { companyAdd: _vm.closeCompany }
      }),
      _c("admin-add", {
        attrs: { visible: _vm.adminVisible },
        on: { adminAdd: _vm.closeAdmin }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }