//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { servicerSave, servicerById, servicerEdit, shippingrulerist, getuser } from "@/api/information";
export default {
  name: "service-add",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      shippingrulerForm: {},
      getuserform: {},
      serviceForm: {
        service_name: "",
        mobile: "",
        country: "",
        email: "",
        reference: "",
        activation: "",
        discount_rate: "",
        balance: "0",
        id: '',
        rule_id: ''
      },
      rules: {
        service_name: [{
          required: true,
          message: "请填写客户名称",
          trigger: "blur"
        }],
        mobile: [{
          required: true,
          message: "请填写电话号码",
          trigger: "blur"
        } // { pattern:/^(((\\+\\d{2}-)?0\\d{2,3}-\\d{7,8})|((\\+\\d{2}-)?(\\d{2,3}-)?([1][3,4,5,7,8][0-9]\\d{8})))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
        ],
        // country: [{ required: true, message: "请填写国家", trigger: "blur" }],
        email: [{
          required: true,
          message: "请填写邮箱",
          trigger: "blur"
        }, {
          pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
          message: "请输入正确的邮箱",
          trigger: "blur"
        }],
        rule_id: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }],
        // activation: [
        //   { required: true, message: "请选择活跃度", trigger: "change" },
        // ],
        discount_rate: [{
          pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
          message: "请输入正确格式,可保留两位小数"
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      getuser({
        id: this.$parent.serviceId
      }).then(function (res) {
        if (res.code === 200) {
          _this.getuserform = res.data;
        }
      });
      shippingrulerist({
        id: this.$parent.serviceId
      }).then(function (res) {
        if (res.code === 200) {
          _this.shippingrulerForm = res.data.data.data;
        }
      });

      if (val) {
        if (this.$parent.serviceTitle === "修改客户") {
          servicerById({
            id: this.$parent.serviceId
          }).then(function (res) {
            if (res.code === 200) {
              _this.serviceForm = res.data;
            }
          });
        }
      } else {
        this.$refs.serviceForm.resetFields();
      }
    }
  },
  methods: {
    serviceAdd: function serviceAdd(type) {
      var _this2 = this;

      if (type === 1) {
        this.$refs.serviceForm.validate(function (valid) {
          if (valid) {
            if (_this2.$parent.serviceTitle === "新增客户") {
              servicerSave(_this2.serviceForm).then(function (res) {
                if (res.code === 200) {
                  _this2.$emit("serviceAdd");

                  _this2.$message.success(res.message);
                }
              });
            } else {
              servicerEdit(_this2.serviceForm).then(function (res) {
                if (res.code === 200) {
                  _this2.$emit("serviceAdd");

                  _this2.$message.success(res.message);
                }
              });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$emit("serviceAdd", 1);
      }
    }
  }
};