var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logistics" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c("packageSearch", {
            attrs: { "filter-list": _vm.filterList },
            on: {
              handleFilter: _vm.handleFilter,
              resetFilter: _vm.resetFilter,
              handleSecFilter: _vm.handleSecFilter
            }
          })
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                fit: "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            _vm._l(_vm.labelList, function(item, idx) {
              return _c("el-table-column", {
                key: idx,
                attrs: {
                  label: item.label,
                  prop: item.value,
                  width: item.width
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          item.type === undefined
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row[item.value]))
                              ])
                            : _vm._e(),
                          item.type === "channel"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        width: "250",
                                        trigger: "hover"
                                      }
                                    },
                                    [
                                      _c(
                                        "ul",
                                        _vm._l(scope.row.channel, function(
                                          val,
                                          index
                                        ) {
                                          return _c("li", { key: index }, [
                                            _vm._v(_vm._s(val.channel_name))
                                          ])
                                        }),
                                        0
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            size: "mini",
                                            type: "primary",
                                            icon: "el-icon-view"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("查看")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.type === "logistics_name"
                            ? _c("span", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "c_blue cursor_p",
                                    on: {
                                      click: function($event) {
                                        return _vm.toLink(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.logistics_name) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          item.type === "operation"
                            ? _c(
                                "span",
                                [
                                  scope.row.cooperate_status === "1"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.logisticsClick(
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("修改物流信息")]
                                      )
                                    : _vm._e(),
                                  scope.row.cooperate_status === "1"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.shipping(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("配置发货渠道")]
                                      )
                                    : _vm._e(),
                                  scope.row.cooperate_status === "1"
                                    ? _c(
                                        "el-link",
                                        {
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.Updateprice(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("更新价格表")]
                                      )
                                    : _vm._e(),
                                  scope.row.cooperate_status === "1"
                                    ? _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "throttle",
                                              rawName: "v-throttle",
                                              value: [
                                                function() {
                                                  return _vm.operation(
                                                    scope.row,
                                                    "2"
                                                  )
                                                }
                                              ],
                                              expression:
                                                "[() => operation(scope.row,'2')]"
                                            }
                                          ],
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("停止合作")]
                                      )
                                    : _vm._e(),
                                  scope.row.cooperate_status === "2"
                                    ? _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "throttle",
                                              rawName: "v-throttle",
                                              value: [
                                                function() {
                                                  return _vm.operation(
                                                    scope.row,
                                                    "1"
                                                  )
                                                }
                                              ],
                                              expression:
                                                "[() => operation(scope.row,'1')]"
                                            }
                                          ],
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("启用")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.Inquire
            }
          })
        ],
        1
      ),
      _c("logistics-add", {
        attrs: { visible: _vm.logisticsVisible },
        on: { logisticsAdd: _vm.closelogistics }
      }),
      _c("dialog-import", {
        attrs: {
          updateid: _vm.UpdateId,
          visible: _vm.importVisible,
          "import-props": _vm.importProps,
          "import-return": false,
          title: _vm.Updatetitle
        },
        on: { close: _vm.closeImport }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }