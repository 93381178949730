import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var message = [{
  path: '/message',
  component: Layout,
  alwaysShow: true,
  name: 'message',
  redirect: 'noRedirect',
  meta: {
    title: '消息中心',
    icon: 'el-icon-message-solid'
  },
  hidden: true,
  children: [{
    path: 'message/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message-center/index'));
      });
    },
    name: 'message-center',
    meta: {
      title: '消息中心',
      keepAlive: true
    }
  }, {
    path: 'messageList/index/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message-center/message-list/index'));
      });
    },
    name: 'message-list',
    hidden: true,
    meta: {
      title: '消息列表'
    }
  }, {
    path: 'messageDetails/index/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/message-center/message-details/index'));
      });
    },
    name: 'message-details',
    hidden: true,
    meta: {
      title: '消息详情'
    }
  }]
}];
export default message;