import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { ruleChannelTitle } from '@/api/information';
import { logisticsChannelInfo } from '@/api/information'; // import pxChannel from './component/pxChannel'
// import yunExpressChannel from './component/yunExpressChannel'

export default {
  name: 'logistics-channel',
  components: {// pxChannel: () => import('./component/pxChannel'),
    // yunExpressChannel: () => import('./component/yunExpressChannel')
  },
  data: function data() {
    return {
      channelName: '1',
      cid: '1',
      activeName: '1',
      platform: [],
      labelList: [{
        label: '国家',
        value: 'country'
      }, {
        label: '参考时效',
        value: 'aging_time',
        type: 'aging_time'
      }, {
        label: '疫情期间参考时效',
        value: 'special_time',
        type: 'special_time'
      }, {
        label: '重量',
        value: 'weight_info'
      }, {
        label: '运费RMB/KG',
        value: 'shipping_cost'
      }, {
        label: '挂号费RMB/票',
        value: 'registered_fee'
      }],
      countryList: [{
        label: '澳门',
        value: 'attach2'
      }, {
        label: '新加波/马来西亚',
        value: 'attach3'
      }, {
        label: '日本',
        value: 'attach4'
      }, {
        label: '澳大利亚/新西兰',
        value: 'attach5'
      }, {
        label: '柬埔寨/印度尼西亚/印度尼西亚/老挝/越南',
        value: 'attach6'
      }, {
        label: '孟加拉/不丹',
        value: 'attach7'
      }, {
        label: '印度/斯里兰卡',
        value: 'attach8'
      }, {
        label: '巴基斯坦',
        value: 'attach9'
      }, {
        label: '加拿大',
        value: 'attach10'
      }, {
        label: '墨西哥',
        value: 'attach11'
      }, {
        label: '比利时/法国/德国（暂停）/意大利',
        value: 'attach12'
      }, {
        label: '奥地利/丹麦/芬兰',
        value: 'attach13'
      }, {
        label: '斯洛伐克/斯洛文尼亚',
        value: 'attach14'
      }, {
        label: '捷克/匈牙利/波兰/罗马尼亚',
        value: 'attach15'
      }, {
        label: '安道尔',
        value: 'attach16'
      }, {
        label: '俄罗斯/塞尔维亚/乌克兰',
        value: 'attach17'
      }, {
        label: '科威特/卡塔尔',
        value: 'attach18'
      }, {
        label: '土耳其',
        value: 'attach19'
      }, {
        label: '以色列/黎巴嫩',
        value: 'attach20'
      }, {
        label: '埃及',
        value: 'attach21'
      }, {
        label: '肯尼亚/利比亚/南非',
        value: 'attach22'
      }, {
        label: '科摩罗/刚果共和国',
        value: 'attach23'
      }, {
        label: '安哥拉/刚果民主共和国',
        value: 'attach24'
      }, {
        label: '尼日尔/塞拉里昂',
        value: 'attach25'
      }, {
        label: '巴拿马/委内瑞拉',
        value: 'attach26'
      }, {
        label: '智利/哥斯达黎加',
        value: 'attach27'
      }, {
        label: '巴西/牙买加',
        value: 'attach28'
      }, {
        label: '阿根廷/哥伦比亚',
        value: 'attach29'
      }, {
        label: '美国',
        value: 'attach30'
      }],
      tableData: [],
      zdDataA: [],
      zdDataB: [],
      loading: false
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    console.log(from);

    if (from.name === 'channel-conifg') {
      next(function (vm) {
        console.log(vm.$route.params.platform_id);
        console.log(vm.$route.params.cid);
        vm.channelName = vm.$route.params.platform_id;
        vm.activeName = vm.$route.params.cid;
      });
    }

    next();
  },
  created: function created() {
    var _this = this;

    ruleChannelTitle().then(function (res) {
      _this.platform = res.data;

      _this.handleClick(_this.channelName);
    });
  },
  methods: {
    channelClick: function channelClick(tab) {
      if (tab.name === '1') {
        this.activeName = '1';
        this.handleClick(tab.name);
      } else if (tab.name === '2') {
        this.activeName = '6';
        this.handleClick(tab.name);
      } else {
        this.activeName = '18';
        this.handleClick(tab.name);
      }
    },
    handleClick: function handleClick(name) {
      var _this2 = this;

      this.loading = true;
      this.tableData = [];
      setTimeout(function () {
        logisticsChannelInfo({
          cid: _this2.activeName,
          logistics_platform_id: name
        }).then(function (res) {
          if (res.code === 200) {
            _this2.tableData = res.data;
            _this2.loading = false;
          }
        });
      }, 500);
    },
    flitterData: function flitterData() {
      var _this3 = this;

      var spanOneArr = [];
      var spanTwoArr = [];
      var spanThreeArr = [];
      var concatOne = 0;
      var concatTwo = 0;
      var concatThree = 0;
      this.tableData.forEach(function (item, index) {
        if (index === 0) {
          spanOneArr.push(1);
          spanTwoArr.push(1);
          spanThreeArr.push(1);
        } else {
          if (item.country === _this3.tableData[index - 1].country) {
            // 第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }

          if (item.aging_time === _this3.tableData[index - 1].aging_time && item.country === _this3.tableData[index - 1].country) {
            // 第二列需合并相同内容的判断条件
            spanTwoArr[concatTwo] += 1;
            spanTwoArr.push(0);
          } else {
            spanTwoArr.push(1);
            concatTwo = index;
          }

          if (item.special_time === _this3.tableData[index - 1].special_time && item.country === _this3.tableData[index - 1].country) {
            // 第二列需合并相同内容的判断条件
            spanThreeArr[concatThree] += 1;
            spanThreeArr.push(0);
          } else {
            spanThreeArr.push(1);
            concatThree = index;
          }
        }
      });
      return {
        one: spanOneArr,
        two: spanTwoArr,
        three: spanThreeArr
      };
    },
    // 合并列
    objectSpanMethods: function objectSpanMethods(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0) {
        var _row = this.flitterData(this.list).one[rowIndex];

        var _col = _row > 0 ? 1 : 0;

        return {
          rowspan: _row,
          colspan: _col
        };
      }

      if (columnIndex === 1) {
        var _row2 = this.flitterData(this.list).two[rowIndex];

        var _col2 = _row2 > 0 ? 1 : 0;

        return {
          rowspan: _row2,
          colspan: _col2
        };
      }

      if (columnIndex === 2) {
        var _row3 = this.flitterData(this.list).three[rowIndex];

        var _col3 = _row3 > 0 ? 1 : 0;

        return {
          rowspan: _row3,
          colspan: _col3
        };
      }
    },
    // 表头换行
    renderheader: function renderheader(h, _ref2) {
      var column = _ref2.column,
          $index = _ref2.$index;
      return h('span', {}, [h('span', {}, column.label.split('/')[0]), h('br'), h('span', {}, column.label.split('/')[1])]);
    }
  }
};