import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.values.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getDashboardStatistics } from '@/api/admin';
import * as echarts from 'echarts';
export default {
  name: 'dashboard',
  filters: {
    ellipsis: function ellipsis(value) {
      if (!value) {
        return "";
      } else if (value.length > 90) {
        return value.slice(0, 90) + "...";
      } else {
        return value;
      }
    }
  },
  data: function data() {
    return {
      dashboardForm: {
        id: '',
        //主键
        all_orders: '',
        //总订单
        today_orders: '',
        //今日订单
        today_turnover: '',
        //今日成交额
        all_wait_for_receiving: '',
        //总的待收货
        all_wait_for_send: '',
        //总的待发货
        all_delivered: '',
        //总的已收货
        sales_trend_data: [],
        //销量趋势数据
        exception_product: [],
        //异常产品
        after_info: [] //售后信息

      },
      option: {
        xAxis: {
          name: '（小时）',
          type: 'category',
          data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
        },
        yAxis: {
          name: '金额',
          type: 'value',
          data: []
        },
        series: [{
          data: [],
          type: 'line',
          smooth: true,
          itemStyle: {
            color: '#9597fe'
          }
        }]
      },
      pending: {
        add: {
          total: 0,
          afterOrder: [],
          status: 1
        },
        processing: {
          total: 0,
          afterOrder: [],
          status: 2
        }
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    var myChart = echarts.init(document.getElementById("myChart"));
    getDashboardStatistics().then(function (res) {
      if (res.code === 200) {
        _this.dashboardForm = res.data;
        _this.pending = _this.dashboardForm.after_info;

        _this.$set(_this.option.series[0], 'data', Object.values(_this.dashboardForm.sales_trend_data));

        myChart.setOption(_this.option);
      }
    });
  },
  methods: {}
};