var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "修改订单信息",
        visible: _vm.visible,
        width: "60%",
        "before-close": _vm.orderEdit,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tabloading,
              expression: "tabloading"
            }
          ],
          staticClass: "loading-layer",
          staticStyle: { "padding-top": "15px" }
        },
        [
          _c(
            "el-form",
            {
              ref: "orderForm",
              attrs: {
                model: _vm.orderForm,
                size: "small",
                "label-width": "130px",
                rules: _vm.rules,
                inline: ""
              }
            },
            [
              _c("el-divider", [_vm._v("快递信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "规则:", prop: "rule_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-250",
                      attrs: {
                        placeholder: "请选择规则",
                        clearable: "",
                        size: "small"
                      },
                      on: { change: _vm.rulesClick },
                      model: {
                        value: _vm.orderForm.rule_info.rule_id,
                        callback: function($$v) {
                          _vm.$set(_vm.orderForm.rule_info, "rule_id", $$v)
                        },
                        expression: "orderForm.rule_info.rule_id"
                      }
                    },
                    _vm._l(_vm.rulesList, function(rule, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: rule.rule_name, value: rule.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发货渠道:", prop: "channel_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-250",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.orderForm.rule_info.channel_id,
                        callback: function($$v) {
                          _vm.$set(_vm.orderForm.rule_info, "channel_id", $$v)
                        },
                        expression: "orderForm.rule_info.channel_id"
                      }
                    },
                    _vm._l(_vm.channelList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: item.channel_name,
                          value: item.channel_id
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "美元汇率:", prop: "usd_exchange" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      readonly: "",
                      size: "small",
                      placeholder: "美元汇率"
                    },
                    model: {
                      value: _vm.orderForm.rule_info.usd_exchange,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm.rule_info, "usd_exchange", $$v)
                      },
                      expression: "orderForm.rule_info.usd_exchange"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "利润率:", prop: "rate" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      readonly: "",
                      size: "small",
                      placeholder: "利润率"
                    },
                    model: {
                      value: _vm.orderForm.rule_info.rate,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm.rule_info, "rate", $$v)
                      },
                      expression: "orderForm.rule_info.rate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "挂号费:", prop: "registration_fee" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      readonly: "",
                      size: "small",
                      placeholder: "挂号费"
                    },
                    model: {
                      value: _vm.orderForm.rule_info.registration_fee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.orderForm.rule_info,
                          "registration_fee",
                          $$v
                        )
                      },
                      expression: "orderForm.rule_info.registration_fee"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人姓:", prop: "first_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件人姓"
                    },
                    model: {
                      value: _vm.orderForm.first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "first_name", $$v)
                      },
                      expression: "orderForm.first_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人名:", prop: "last_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件人名"
                    },
                    model: {
                      value: _vm.orderForm.last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "last_name", $$v)
                      },
                      expression: "orderForm.last_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话:", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "联系电话"
                    },
                    model: {
                      value: _vm.orderForm.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "mobile", $$v)
                      },
                      expression: "orderForm.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件地址:", prop: "address1" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件地址"
                    },
                    model: {
                      value: _vm.orderForm.address1,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "address1", $$v)
                      },
                      expression: "orderForm.address1"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件国家:", prop: "country" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件国家"
                    },
                    model: {
                      value: _vm.orderForm.country,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "country", $$v)
                      },
                      expression: "orderForm.country"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人所在城市:", prop: "city" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件人所在城市"
                    },
                    model: {
                      value: _vm.orderForm.city,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "city", $$v)
                      },
                      expression: "orderForm.city"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人所在省/州:", prop: "province" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件人所在省/州"
                    },
                    model: {
                      value: _vm.orderForm.province,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "province", $$v)
                      },
                      expression: "orderForm.province"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人邮编:", prop: "zip" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件人邮编"
                    },
                    model: {
                      value: _vm.orderForm.zip,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "zip", $$v)
                      },
                      expression: "orderForm.zip"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人邮箱:", prop: "email" } },
                [
                  _c("el-input", {
                    staticClass: "w-250",
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: "收件人邮箱"
                    },
                    model: {
                      value: _vm.orderForm.email,
                      callback: function($$v) {
                        _vm.$set(_vm.orderForm, "email", $$v)
                      },
                      expression: "orderForm.email"
                    }
                  })
                ],
                1
              ),
              _c("el-divider", [_vm._v("商品信息")]),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.orderForm.goods_info,
                    border: "",
                    "header-cell-style": {
                      background: "#e9f1ff",
                      color: "#606266"
                    }
                  }
                },
                _vm._l(_vm.labelShop, function(item, key) {
                  return _c("el-table-column", {
                    key: key,
                    attrs: { label: item.label, prop: item.value },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              item.type === undefined
                                ? _c(
                                    "span",
                                    {
                                      domProps: {
                                        innerHTML: _vm._s(scope.row[item.value])
                                      }
                                    },
                                    [_vm._v(_vm._s(scope.row[item.value]))]
                                  )
                                : _vm._e(),
                              item.type === "order_goods"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "c_blue cursor_p",
                                      on: {
                                        click: function($event) {
                                          return _vm.order_goods(
                                            scope.row.goods_id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(scope.row.goods_id))]
                                  )
                                : _vm._e(),
                              item.type === "input"
                                ? _c("el-input", {
                                    attrs: {
                                      clearable: "",
                                      placeholder: item.label,
                                      size: "small"
                                    },
                                    model: {
                                      value: scope.row[item.value],
                                      callback: function($$v) {
                                        _vm.$set(scope.row, item.value, $$v)
                                      },
                                      expression: "scope.row[item.value]"
                                    }
                                  })
                                : _vm._e(),
                              item.type === "name"
                                ? _c("span", [
                                    scope.row.goods_name
                                      ? _c("span", [
                                          _vm._v(_vm._s(scope.row.goods_name))
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.third_sku_name)
                                          )
                                        ])
                                  ])
                                : _vm._e(),
                              item.type === "sku"
                                ? _c("span", [
                                    scope.row.sku
                                      ? _c("span", [
                                          _vm._v(_vm._s(scope.row.sku))
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(scope.row.third_sku))
                                        ])
                                  ])
                                : _vm._e(),
                              item.type === "operating"
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.selectProduct(
                                                scope.$index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("选择")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.orderEdit } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "throttle",
                  rawName: "v-throttle",
                  value: [
                    function() {
                      return _vm.orderEdit(1)
                    }
                  ],
                  expression: "[() => orderEdit(1)]"
                }
              ],
              attrs: { size: "small", type: "primary", loading: _vm.loading }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("product", {
        attrs: { visible: _vm.selectCommodity },
        on: {
          "update:visible": function($event) {
            _vm.selectCommodity = $event
          },
          close: _vm.receiveData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }