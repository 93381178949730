import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addMenu from './components/addMenu';
import Pagination from '@/components/Pagination'; // import menuTree from '../../../components/menuTree'

import { menuGetList, commonGetAll, menuGetOption, menuUpdateStatus } from '@/api/admin';
export default {
  name: 'menus',
  components: {
    addMenu: addMenu,
    Pagination: Pagination
  },
  data: function data() {
    return {
      formData: {
        back_column_id: '',
        name: '',
        type: '',
        method: '',
        status: ''
      },
      selectList: {
        method: [],
        status: [],
        type: []
      },
      tableData: [],
      tableDataLoading: false,
      multipleSelection: [],
      dialogVisible: false,
      // 弹窗状态
      openMenuType: '',
      // 弹窗操作状态
      openMenuId: '',
      // 当前行ID
      treeDeploy: {
        // 菜单树绑定值
        status: 'status',
        label: 'name',
        children: 'next_menu',
        nodeKey: 'menu_id',
        isSelect: true,
        isCheck: false,
        isTag: true
      },
      treeList: [],
      // 菜单数组
      listQuery: {
        // 分页
        page: 1,
        limit: 10,
        total: 0
      }
    };
  },
  computed: {},
  created: function created() {
    this.getList();
    this.getData(1);
  },
  methods: {
    // 菜单更改回调
    menuChange: function menuChange(e) {
      this.formData.back_column_id = e;
      this.getData(1);
    },
    // 获取页面数据
    getList: function getList() {
      var _this = this;

      // 返回下拉选项
      menuGetOption().then(function (res) {
        _this.selectList = _objectSpread({}, res.data);
      }); // 返回全部菜单

      commonGetAll({
        id: [1]
      }).then(function (res) {
        for (var key in res.data) {
          _this.treeList.push(res.data[key][0]);
        }
      });
    },
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.getData(1);
    },
    // 获取表格数据
    getData: function getData() {
      var _this2 = this;

      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      if (type == 1) this.listQuery.page = 1;
      this.formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      this.formData.iDisplayLength = this.listQuery.limit;
      this.tableDataLoading = true;
      menuGetList(this.formData).then(function (res) {
        if (res.code == 200) {
          setTimeout(function () {
            _this2.tableData = res.data;
            _this2.tableDataLoading = false;
            _this2.listQuery.total = Number(res.total);
          }, 300);
        }
      });
    },
    // 表格选择框更改
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    openMenu: function openMenu(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.openMenuType = type;
      type == 'mdf' ? this.openMenuId = item.row.id : this.openMenuId = '';
      this.dialogVisible = true;
    },
    // 关闭菜单操作弹窗
    closeMenu: function closeMenu(type) {
      if (type == 1) {
        this.treeList = [];
        this.getData();
        this.getList(); // setTimeout(() => {
        //   this.$set(this.$refs.menuTree,'openNode',[this.openMenuId])
        // }, 500);
      }

      this.dialogVisible = false;
    },
    // 修改菜单状态
    updateStatus: function updateStatus(type) {
      var _this3 = this;

      if (this.multipleSelection.length == 0) return this.$message({
        type: 'warning',
        message: '请先选择菜单!'
      });
      var title = '';

      switch (type) {
        case 1:
          title = '显示';
          break;

        case 0:
          title = '不显示';
          break;

        case 3:
          title = '删除';
          if (this.multipleSelection.length != 1) return this.$message({
            type: 'warning',
            message: '一次只能删除一个菜单!'
          });
          break;
      }

      this.$confirm('您确定' + title + this.multipleSelection.length + '个菜单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params = {
          id: _this3.multipleSelection.map(function (item) {
            return item.id;
          }).join(','),
          status: type
        };
        menuUpdateStatus(params).then(function (res) {
          _this3.getData();

          if (res.code == 200) {
            _this3.$message({
              type: 'success',
              message: '操作成功!'
            });
          }
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 重置
    resetClick: function resetClick() {
      this.formData = this.$options.data().formData;
      this.getData(1);
    }
  }
};