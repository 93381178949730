var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity-filter" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "formInline",
              attrs: {
                inline: true,
                model: _vm.formInline,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称:", prop: "customer_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        multiple: "",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formInline.customer_id,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "customer_id", $$v)
                        },
                        expression: "formInline.customer_id"
                      }
                    },
                    _vm._l(_vm.serviceList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: item.service_name,
                          value: String(item.id)
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "状态"
                      },
                      model: {
                        value: _vm.formInline.status,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "status", $$v)
                        },
                        expression: "formInline.status"
                      }
                    },
                    _vm._l(_vm.statusList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间:" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.create_at,
                      callback: function($$v) {
                        _vm.create_at = $$v
                      },
                      expression: "create_at"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "更新时间:" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.update_at,
                      callback: function($$v) {
                        _vm.update_at = $$v
                      },
                      expression: "update_at"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v("筛 选")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.resetClick }
                    },
                    [_vm._v("重 置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            _vm._l(_vm.labelList, function(item, idx) {
              return _c("el-table-column", {
                key: idx,
                attrs: {
                  label: item.label,
                  prop: item.value,
                  width: item.width
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          item.type === undefined
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row[item.value]))
                              ])
                            : _vm._e(),
                          item.type === "status"
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.statusList[scope.row.status]))
                              ])
                            : _vm._e(),
                          item.type === "image"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        width: 300,
                                        "open-delay": 300,
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-image",
                                        {
                                          staticClass: "product-img",
                                          attrs: {
                                            slot: "reference",
                                            src: scope.row.img,
                                            alt: "加载失败..."
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "image-slot",
                                              attrs: { slot: "error" },
                                              slot: "error"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "error-icon el-icon-picture-outline"
                                              })
                                            ]
                                          )
                                        ]
                                      ),
                                      _c("el-image", {
                                        staticStyle: {
                                          height: "300px",
                                          width: "300px"
                                        },
                                        attrs: { src: scope.row.img }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.type === "operation"
                            ? _c(
                                "span",
                                [
                                  scope.row.status === 2
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "warning" },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleUpdate(
                                                scope.row.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("报价")]
                                      )
                                    : _vm._e(),
                                  scope.row.status !== 2
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            disabled: scope.row.status === 4
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleUpdate(
                                                scope.row.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("更新")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.Inquire
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }