import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 获取基础状态

export function orderStatus(data) {
  return request({
    url: '/order/order/get-order-status',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单列表

export function orderDataList(data) {
  return request({
    url: '/order/order/index',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单详情

export function orderInfoById(data) {
  return request({
    url: '/order/order/order-info-by-id',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单补发

export function orderReissue(data) {
  return request({
    url: '/order/order/order-reissue',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单退款

export function orderRefund(data) {
  return request({
    url: '/order/order/order-refund',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单修改

export function orderEditOrder(data) {
  return request({
    url: '/order/order/edit-order',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取物流列表

export function orderLogistics(data) {
  return request({
    url: '/order/order-logistics/index',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流信息

export function orderLogisticsWay(data) {
  return request({
    url: '/order/order-logistics/way-bill-info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单导出

export function orderExport(data) {
  return request({
    url: '/order/order/export',
    method: 'get',
    params: data,
    responseType: 'blob',
    baseURL: baseURL
  });
} // 运单导出

export function orderLogisticsExport(data) {
  return request({
    url: '/order/order-logistics/export',
    method: 'get',
    params: data,
    responseType: 'blob',
    baseURL: baseURL
  });
} // 获取新订单id

export function getOrderId(num) {
  return request({
    url: '/order/order/create-uuid',
    method: 'get',
    params: num,
    baseURL: baseURL
  });
} // 拆分订单，生成预览

export function orderSplit(data) {
  return request({
    url: '/order/order/separate-order',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 确认拆分订单

export function orderSplitChecked(data) {
  return request({
    url: '/order/order/separate-order-confirm',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取合并订单

export function getMergedOrder(data) {
  return request({
    url: '/order/order/order-merge-info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 合并订单预览

export function previewMergedorder(data) {
  return request({
    url: '/order/order/combine-order',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 合并订单确认

export function checkMergedorder(data) {
  return request({
    url: '/order/order/combine-order-confirm',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 复原订单

export function recoverOrder(data) {
  return request({
    url: '/order/order/order-recover',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 运单更新

export function uploadTrackingOnline(data) {
  return request({
    url: '/order/order-logistics/upload-tracking-online',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 已送达

export function trackingArrived(data) {
  return request({
    url: '/order/order-logistics/update-logistics-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 收费

export function ordercostcharge(data) {
  return request({
    url: '/quoted/quoted/order-cost-charge',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}