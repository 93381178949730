import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { companyEheckAudits, baseCommonEdit } from '@/api/account';
export default {
  name: 'review-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      accountForm: {
        provider_id: [],
        audi_id: ''
      },
      show: {},
      rules: {
        audi_id: [{
          required: true,
          message: '请赋予权限',
          trigger: 'change'
        }],
        provider_id: [{
          required: true,
          message: '请选择客户',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      if (val) {
        this.show = this.$parent.dataList;

        if (this.$parent.reviewTitle == '修改用户账号') {
          baseCommonEdit({
            id: this.$parent.dataList.id
          }).then(function (res) {
            if (res.code == 200) {
              _this.accountForm.audi_id = res.data.role[0].role_id;
              var array = [];
              res.data.service.map(function (item) {
                array.push(item.provider_id);
              });
              _this.accountForm.provider_id = array;
            }
          });
        }
      } else {
        this.$refs.accountForm.resetFields();
      }
    }
  },
  methods: {
    reviewAdd: function reviewAdd(type) {
      var _this2 = this;

      if (type == 1) {
        this.$refs.accountForm.validate(function (valid) {
          if (valid) {
            _this2.accountForm.id = _this2.$parent.dataList.id;
            companyEheckAudits(_this2.accountForm).then(function (res) {
              if (res.code == 200) {
                _this2.$message.success(res.message);

                _this2.$emit('reviewAdd');
              }
            });
          }
        });
      } else {
        this.$emit('reviewAdd', 1);
      }
    }
  }
};