//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getChannel } from '@/api/commodity';
export default {
  name: 'shop-setting',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '配置店铺'
    },
    flag: {
      type: String,
      default: 'view'
    },
    formData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    storeId: {
      type: String,
      default: ''
    },
    poolList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      shopForm: {
        third_good_id: '',
        third_sku_id: '',
        pool_id: '',
        good_id: '',
        sku_id: '',
        sale_price: '',
        sku_weight: '',
        logistics_id: '',
        chann_id: '',
        profit_margin: '',
        service_fee: '',
        store_id: '',
        providers_id: ''
      },
      shopRules: {
        third_good_id: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }],
        third_sku_id: [{
          required: true,
          message: '不能为空',
          trigger: 'blur'
        }] // pool_id: [{ required: true, message: '不能为空', trigger: 'blur' }]
        // sku_weight: [{ required: true, message: '不能为空', trigger: 'blur' }],
        // logistics_id: [{ required: true, message: '不能为空', trigger: 'change' }],
        // chann_id: [{ required: true, message: '不能为空', trigger: 'change' }],
        // profit_margin: [{ required: true, message: '不能为空', trigger: 'blur' }],
        // service_fee: [{ required: true, message: '不能为空', trigger: 'blur' }]

      },
      channelList: [],
      hostList: [],
      channelDisable: true,
      isIndeterminate: true,
      checkAll: []
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    },
    providers: function providers() {
      return this.$store.getters.options.providers;
    },
    custody: function custody() {
      return this.$store.getters.options.custody;
    }
  },
  watch: {
    formData: {
      handler: function handler(data) {
        this.shopForm = data;
      }
    },
    'shopForm.logistics_id': {
      handler: function handler(val) {
        if (val) {
          this.channelDisable = true;
          this.initChannel(val);
        }
      }
    }
  },
  created: function created() {},
  methods: {
    close: function close() {
      if (this.flag === 'add') {
        this.$emit('close', false);
      }

      this.channelList = [];
      this.dialogVisible = false;
    },
    save: function save(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.shopForm.store_id = _this.storeId;

          _this.$emit('close', _this.shopForm);

          _this.channelList = [];
          _this.dialogVisible = false;
        } else {
          console.log('error');
        }
      });
    },
    initChannel: function initChannel(id) {
      var _this2 = this;

      getChannel({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          _this2.channelDisable = false;
          _this2.shopForm.chann_id = '';
          _this2.channelList = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleCheckedChange: function handleCheckedChange(val) {
      this.$emit('add-pool', val);
    },
    handleRemoveTag: function handleRemoveTag(val) {
      this.$emit('update-pool', val);
    }
  }
};