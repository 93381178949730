var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-list-box" }, [
    _c(
      "div",
      { staticClass: "list-title" },
      [
        _c("el-button", {
          attrs: { size: "small", icon: "el-icon-back" },
          on: { click: _vm.prev }
        }),
        _c("span", { staticClass: "message-title" }, [
          _vm._v(_vm._s(_vm.messageTitle))
        ])
      ],
      1
    ),
    _c("div", { staticClass: "list-main" }, [
      _vm.messageTitle === "订单通知"
        ? _c(
            "div",
            { staticClass: "list-container" },
            [
              _c("div", { staticClass: "article-box" }, [
                _c("h4", [_vm._v("退款订单")]),
                _c(
                  "p",
                  [
                    _c("el-button", { attrs: { type: "text" } }, [
                      _vm._v("查看订单")
                    ])
                  ],
                  1
                )
              ]),
              _c("el-divider")
            ],
            1
          )
        : _vm._e(),
      _vm.messageTitle === "系统通知"
        ? _c("div", { staticClass: "list-container" }, [_vm._v("系统通知")])
        : _vm._e(),
      _vm.messageTitle === "售后消息"
        ? _c("div", { staticClass: "list-container" }, [_vm._v("售后消息")])
        : _vm._e(),
      _vm.messageTitle === "账单消息"
        ? _c("div", { staticClass: "list-container" }, [_vm._v("账单消息")])
        : _vm._e(),
      _vm.messageTitle === "报价消息"
        ? _c("div", { staticClass: "list-container" }, [_vm._v("报价消息")])
        : _vm._e(),
      _vm.messageTitle === "商品变更"
        ? _c("div", { staticClass: "list-container" }, [_vm._v("商品变更")])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }