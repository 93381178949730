var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "generateBill" }, [
    _c("div", { staticClass: "box-card" }, [
      _c(
        "div",
        { staticClass: "create-btn p20" },
        [
          _c("el-button", {
            attrs: { size: "small", icon: "el-icon-back" },
            on: { click: _vm.prev }
          }),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.previewBill }
            },
            [_vm._v("Preview bill")]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "box-card create-box ml20 mr20 mb20" }, [
      _c("div", { staticClass: "form-data-box" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-form",
              {
                ref: "generateForm",
                attrs: {
                  model: _vm.generateForm,
                  "label-width": "100px",
                  inline: "",
                  rules: _vm.generateFormRules,
                  "label-position": "top"
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "upload-logo" },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-photos",
                        attrs: {
                          accept: "image/png, image/jpeg",
                          action: "",
                          "show-file-list": false,
                          "http-request": _vm.upload,
                          "before-upload": _vm.handleBeforeUpload,
                          "on-change": _vm.handleChange
                        }
                      },
                      [
                        _vm.showImg
                          ? _c("div", { staticClass: "el-upload__text" }, [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _c("em", [_vm._v("Add Your Logo")])
                            ])
                          : _c("div", [
                              _c("img", {
                                staticStyle: {
                                  height: "200px",
                                  width: "250px"
                                },
                                attrs: { src: _vm.generateForm.enterprise_logo }
                              })
                            ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "enterprise_info" } },
                  [
                    _c("el-input", {
                      staticClass: "mv-calc",
                      attrs: {
                        type: "textarea",
                        size: "small",
                        rows: 6,
                        placeholder: "Who is this invoice from？（required）"
                      },
                      model: {
                        value: _vm.generateForm.enterprise_info,
                        callback: function($$v) {
                          _vm.$set(_vm.generateForm, "enterprise_info", $$v)
                        },
                        expression: "generateForm.enterprise_info"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Bill To", prop: "bill_to" } },
                          [
                            _c("el-input", {
                              staticClass: "bill-calc",
                              attrs: {
                                type: "textarea",
                                size: "small",
                                rows: 6,
                                placeholder:
                                  "Who is this invoice to？（required）"
                              },
                              model: {
                                value: _vm.generateForm.bill_to,
                                callback: function($$v) {
                                  _vm.$set(_vm.generateForm, "bill_to", $$v)
                                },
                                expression: "generateForm.bill_to"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "Ship To", prop: "ship_to" } },
                          [
                            _c("el-input", {
                              staticClass: "bill-calc",
                              attrs: {
                                type: "textarea",
                                size: "small",
                                rows: 6,
                                placeholder: "（optional）"
                              },
                              model: {
                                value: _vm.generateForm.ship_to,
                                callback: function($$v) {
                                  _vm.$set(_vm.generateForm, "ship_to", $$v)
                                },
                                expression: "generateForm.ship_to"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-form",
              {
                ref: "generateRightForm",
                attrs: {
                  model: _vm.generateRightForm,
                  "label-width": "100px",
                  inline: "",
                  rules: _vm.generateFormRightRules,
                  "label-position": "right"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: " ", prop: "bill_info" } },
                  [
                    _c("el-input", {
                      staticClass: "big right_calc",
                      attrs: { type: "text", size: "medium" },
                      model: {
                        value: _vm.generateRightForm.bill_info,
                        callback: function($$v) {
                          _vm.$set(_vm.generateRightForm, "bill_info", $$v)
                        },
                        expression: "generateRightForm.bill_info"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "#", prop: "bill_name" } },
                  [
                    _c("el-input", {
                      staticClass: "right_calc",
                      attrs: {
                        type: "text",
                        size: "small",
                        placeholder: "Please input...(required)"
                      },
                      model: {
                        value: _vm.generateRightForm.bill_name,
                        callback: function($$v) {
                          _vm.$set(_vm.generateRightForm, "bill_name", $$v)
                        },
                        expression: "generateRightForm.bill_name"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Date", prop: "date" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "right_calc",
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        size: "small",
                        clearable: false
                      },
                      model: {
                        value: _vm.generateRightForm.date,
                        callback: function($$v) {
                          _vm.$set(_vm.generateRightForm, "date", $$v)
                        },
                        expression: "generateRightForm.date"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "Due Date", prop: "due_date" } },
                  [
                    _c("el-date-picker", {
                      staticClass: "right_calc",
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        size: "small",
                        clearable: false
                      },
                      model: {
                        value: _vm.generateRightForm.due_date,
                        callback: function($$v) {
                          _vm.$set(_vm.generateRightForm, "due_date", $$v)
                        },
                        expression: "generateRightForm.due_date"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "box-card create-box ml20 mr20 mb20" },
      [
        _vm.modifyShow
          ? _c(
              "el-table",
              {
                staticClass: "material-table",
                attrs: { data: _vm.order_list }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "ltem", label: "ltem", "min-width": "320" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                placeholder: "Please enter content",
                                maxlength: "200"
                              },
                              model: {
                                value: scope.row.ltem,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "ltem", $$v)
                                },
                                expression: "scope.row.ltem"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1327945919
                  )
                }),
                _c("el-table-column", {
                  attrs: { prop: "quantity", label: "Quantity", width: "260" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "rate", label: "Rate", width: "260" }
                }),
                _c("el-table-column", {
                  attrs: { prop: "amount", label: "Amount", width: "260" }
                })
              ],
              1
            )
          : _vm._e(),
        _c(
          "el-button",
          {
            staticClass: "mt20",
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.modify }
          },
          [_vm._v("Modify")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box-card create-box ml20 mr20 mb20" },
      [
        _c(
          "el-form",
          {
            attrs: {
              model: _vm.notesForm,
              "label-width": "120px",
              inline: "",
              "label-position": "left",
              rules: _vm.notesFormRules
            }
          },
          [
            _c("div", { staticClass: "notes-form" }, [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Notes", prop: "notes" } },
                    [
                      _c("el-input", {
                        staticClass: "w-730",
                        attrs: {
                          type: "textarea",
                          size: "small",
                          rows: 6,
                          placeholder:
                            "Notes——any relevant information not alreaday covered"
                        },
                        model: {
                          value: _vm.notesForm.notes,
                          callback: function($$v) {
                            _vm.$set(_vm.notesForm, "notes", $$v)
                          },
                          expression: "notesForm.notes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Terms", prop: "terms" } },
                    [
                      _c("el-input", {
                        staticClass: "w-730",
                        attrs: {
                          type: "textarea",
                          size: "small",
                          rows: 6,
                          placeholder:
                            "Terms and conditions-late fees, payment methods, delivery schedule"
                        },
                        model: {
                          value: _vm.notesForm.terms,
                          callback: function($$v) {
                            _vm.$set(_vm.notesForm, "terms", $$v)
                          },
                          expression: "notesForm.terms"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "product-details" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Subtotal", prop: "subtotal" } },
                    [
                      _c("span", [
                        _vm._v("US$" + _vm._s(_vm.notesForm.subtotal))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Discount(%)", prop: "discount" } },
                    [
                      _c("el-input", {
                        staticClass: "w-200",
                        attrs: { type: "text", size: "small" },
                        on: {
                          focus: function($event) {
                            _vm.notesForm.discount = ""
                          }
                        },
                        model: {
                          value: _vm.notesForm.discount,
                          callback: function($$v) {
                            _vm.$set(_vm.notesForm, "discount", $$v)
                          },
                          expression: "notesForm.discount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tax(%)", prop: "tax" } },
                    [
                      _c("el-input", {
                        staticClass: "w-200",
                        attrs: { type: "text", size: "small" },
                        on: {
                          focus: function($event) {
                            _vm.notesForm.tax = ""
                          }
                        },
                        model: {
                          value: _vm.notesForm.tax,
                          callback: function($$v) {
                            _vm.$set(_vm.notesForm, "tax", $$v)
                          },
                          expression: "notesForm.tax"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Total", prop: "total" } },
                    [_c("span", [_vm._v("US$" + _vm._s(_vm.notesForm.total))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Amount Paid($)", prop: "amount_paid" } },
                    [
                      _c("el-input", {
                        staticClass: "w-200",
                        attrs: { type: "text", size: "small" },
                        on: {
                          focus: function($event) {
                            _vm.notesForm.amount_paid = ""
                          }
                        },
                        model: {
                          value: _vm.notesForm.amount_paid,
                          callback: function($$v) {
                            _vm.$set(_vm.notesForm, "amount_paid", $$v)
                          },
                          expression: "notesForm.amount_paid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Balance Due", prop: "balance_due" } },
                    [
                      _c("span", [
                        _vm._v("US$" + _vm._s(_vm.notesForm.balance_due))
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }