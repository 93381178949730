//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderSplitChecked } from '@/api/commerce';
import { ruleOption } from '@/api/information';
export default {
  name: 'order-checked',
  data: function data() {
    return {
      labelBasis: [{
        label: '订单号:',
        value: 'order_no'
      }, {
        label: '第三方订单号:',
        value: 'thirdParty_order_on'
      }, {
        label: '订单名称:',
        value: 'order_name'
      }, {
        label: '客户:',
        value: 'service_name'
      }, {
        label: '店铺:',
        value: 'store_url'
      }, {
        label: '订单状态:',
        value: 'order_status_name'
      }, {
        label: '物流状态:',
        value: 'logistics_status_name'
      }, {
        label: '订单创建时间:',
        value: 'create_time'
      }, {
        label: '订单更新时间:',
        value: 'update_time'
      }],
      labelExpress: [{
        label: '收件人姓:',
        value: 'first_name'
      }, {
        label: '收件人名:',
        value: 'last_name'
      }, // {label:'收件人姓名:',value:'consignee'},
      {
        label: '收件国家:',
        value: 'country'
      }, {
        label: '收件人所在城市:',
        value: 'city'
      }, {
        label: '收件人所在省/州:',
        value: 'province'
      }, {
        label: '收件邮编:',
        value: 'zip'
      }, {
        label: '收件人地址:',
        value: 'address1'
      }, {
        label: '联系电话:',
        value: 'mobile'
      }, {
        label: '收件人邮箱:',
        value: 'email'
      }],
      labelRules: [{
        label: '挂号费',
        value: 'registration_fee'
      }, {
        label: '规则',
        value: 'rule_id'
      }, {
        label: '发货渠道',
        value: 'channel_name'
      }, {
        label: '美元汇率',
        value: 'usd_exchange'
      }, {
        label: '利润率',
        value: 'rate'
      }],
      labelCommodity: [{
        label: '客户订单总金额:',
        value: 'service_order_all_money'
      }, {
        label: '客户订单商品金额:',
        value: 'service_order_goods_money'
      }, {
        label: '客户订单物流费用:',
        value: 'service_order_logistics_money'
      }, {
        label: '客户订单服务费用:',
        value: 'service_order_money'
      }, {
        label: '币种:',
        value: 'currency'
      }, {
        label: '结算折扣率:',
        value: 'rate'
      }],
      labelShop: [{
        label: '商品编码',
        value: 'goods_id',
        type: 'order_goods'
      }, {
        label: '中文商品名称',
        value: 'goods_name'
      }, {
        label: '英文商品名称',
        value: 'english_title'
      }, {
        label: 'SKU编码',
        value: 'sku'
      }, {
        label: 'SKU名称',
        value: 'sku_name'
      }, {
        label: 'SKU数量',
        value: 'sku_num'
      }, {
        label: '售卖金额',
        value: 'sale_money'
      }, // {label:'物流费用:',value:'logistics_cost'},
      {
        label: '服务费用',
        value: 'service_fee'
      }, {
        label: '状态',
        value: 'goods_type',
        type: 'goods_type'
      }],
      orderForm: [],
      rulesList: []
    };
  },
  computed: {
    rules: function rules() {
      var obj = {};

      for (var i in this.rulesList) {
        obj[this.rulesList[i].id] = this.rulesList[i].rule_name;
      }

      return obj;
    }
  },
  created: function created() {
    this.getRules();
    this.orderForm = JSON.parse(localStorage.getItem('checkedOrders'));
  },
  activated: function activated() {
    this.getRules();
    this.orderForm = JSON.parse(localStorage.getItem('checkedOrders'));
  },
  methods: {
    getRules: function getRules() {
      var _this = this;

      ruleOption().then(function (res) {
        _this.rulesList = res.data;
      });
    },
    updateTags: function updateTags() {
      var views = ['split-detail', 'split-orderprocess', 'split-order-checked'];
      this.$store.dispatch('tagsView/clearSelectedTags', views);
    },
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    // 提交订单
    submitOrder: function submitOrder() {
      var _this2 = this;

      var data = this.orderForm;
      orderSplitChecked(data).then(function (result) {
        if (result.code === 200) {
          _this2.updateTags();

          _this2.$store.dispatch('tagsView/delView', _this2.$route);

          _this2.$router.push({
            name: 'order-system',
            params: {
              thirdParty_order_on: _this2.orderForm[0].thirdParty_order_on
            }
          });
        }
      }).catch(function (err) {
        console.log(err);

        _this2.$message({
          type: 'error',
          message: '提交订单错误'
        });
      });
    }
  }
};