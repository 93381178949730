import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = '';
export function scheduleDataList(data) {
  return request({
    url: '/order/schedule/index',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function exportDataList(data) {
  return request({
    url: '/system/admin/export-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 库存管理

export function getstockList(data) {
  return request({
    url: '/quoted/quoted/inventory-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function getwarehoust_list(data) {
  return request({
    url: '/quoted/quoted/warehoust-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function addWarehouse(data) {
  return request({
    url: '/quoted/quoted/opt-warehouse',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}
export function getopt_inventory(data) {
  return request({
    url: 'quoted/quoted/opt-inventory',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}
export function addopt_inventory(data) {
  return request({
    url: '/quoted/quoted/opt-inventory',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}
export function taskstock(data) {
  return request({
    url: '/quoted/quoted/store-rules-edit',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}
export function getdetaillist(data) {
  return request({
    url: '/quoted/quoted/inventory-detail-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // export function settlementBillList(data) {
//   return request({
//     url: '/settlement/settlement/settlement-bill-list',
//     method: 'post',
//     data: data,
//     baseURL
//   })
// }
//物流规则

export function shippingrulerist(data) {
  return request({
    url: '/quoted/quoted/shipping-rule-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function shippingruleadd(data) {
  return request({
    url: '/quoted/quoted/shipping-rule-add',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}
export function shippingrulesdetail(data) {
  return request({
    url: '/quoted/quoted/shipping-rules-detail',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 店铺规则配置

export function storerules(data) {
  return request({
    url: '/quoted/quoted/store-rules',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function getuser(data) {
  return request({
    url: '/servicer/servicer/get-user',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function gusinesslist(data) {
  return request({
    url: '/quoted/quoted/business-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function fundslist(data) {
  return request({
    url: '/quoted/quoted/funds-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 订单导出

export function orderExport(data) {
  return request({
    url: '/order/order/export',
    method: 'get',
    params: data,
    responseType: 'blob',
    baseURL: baseURL
  });
}