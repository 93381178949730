import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { scheduleDataList } from '@/api/system';
export default {
  // name:"runTaskList",
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    }
  },
  data: function data() {
    return {
      tableColumn: [{
        label: 'id',
        value: 'id'
      }, {
        label: '上传数量',
        value: 'number',
        width: '150'
      }, {
        label: '上传进度',
        value: '',
        type: 'progress'
      }, {
        label: '状态',
        value: 'status'
      }, {
        label: '结果',
        value: '',
        type: 'result'
      }, {
        label: '创建时间',
        value: 'create_time'
      }, {
        label: '创建人',
        value: 'create_user'
      }, {
        label: '更新时间',
        value: 'update_time'
      }],
      tableData: [],
      tableDataLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      timeId: '',
      dialogVisible: false,
      resultData: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.Inquire();
    this.timeId = setInterval(function () {
      setTimeout(_this.Inquire(), 0);
    }, 5000);
  },
  destroyed: function destroyed() {
    window.clearTimeout(this.timeId);
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      var params = {
        iDisplayLength: this.listQuery.limit,
        iDisplayStart: (this.listQuery.page - 1) * this.listQuery.limit
      };
      scheduleDataList(params).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            item.status = item.status === '1' ? '进行中' : '已完成';
          });
          _this2.tableDataLoading = false;
          _this2.listQuery.total = parseInt(res.total);
          _this2.tableData = res.data;
        }
      });
    },
    viewResult: function viewResult(row) {
      this.dialogVisible = true;
      this.resultData = row.content;
    }
  }
};