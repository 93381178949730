import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getOrganizationalStructure, permissionUid, permissionUidSave } from '@/api/admin';
export default {
  name: 'data-authority',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      saveLoading: false,
      dialogTitle: '',
      treeList: [],
      list: [],
      defaultProps: {
        children: 'son',
        label: 'name'
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val, oldVal) {
      var _this = this;

      // 打开  关闭
      if (val) {
        permissionUid({
          id: this.id
        }).then(function (res) {
          var checkedKeys = res.data.role.split(',');

          var list = _this.list.filter(function (item) {
            return checkedKeys.includes(item.user_id);
          });

          _this.$refs.tree.setCheckedNodes(list);
        });
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      // getOrganizationalStructure().then(res =>{
      //   this.treeList = res.data
      //   fn(this.treeList,this.list)
      // })
      function fn(treeList, data) {
        treeList.forEach(function (item) {
          data.push(item);

          if (item.son != undefined) {
            fn(item.son, data);
          }
        });
      }
    },
    close: function close(type) {
      var _this2 = this;

      if (type != 1) return this.$emit('close');
      var uid = this.$refs.tree.getCheckedNodes().map(function (item) {
        return item.user_id;
      }).join(',');
      this.saveLoading = true;
      permissionUidSave({
        id: this.id,
        uid: uid
      }).then(function (res) {
        _this2.$emit('close');

        _this2.saveLoading = false;

        _this2.$message({
          type: 'success',
          message: res.message
        });
      });
    }
  }
};