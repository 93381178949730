import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 上传图片接口

export function uploadImage(data, cb) {
  return request({
    baseURL: baseURL,
    url: '/image_upload',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    },
    onUploadProgress: function onUploadProgress(progressEvent) {
      return cb(progressEvent);
    }
  });
}
export function goodsImport(data) {
  return request({
    url: '/goods/goods/import-goods',
    method: 'get',
    params: data,
    responseType: 'blob',
    baseURL: baseURL
  });
}
export function goodsExport(data) {
  return request({
    url: '/goods/goods/export',
    method: 'get',
    params: data,
    responseType: 'blob',
    baseURL: baseURL
  });
} // 商品列表

export function goodsList(data) {
  return request({
    url: '/goods/goods/goods-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 商品状态改变

export function goodsOpertation(data) {
  return request({
    url: '/goods/goods/opertation',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 商品编辑回显

export function goodsEdit(data) {
  return request({
    url: '/goods/goods/good-edit',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 商品新增/修改

export function goodsSave(data) {
  return request({
    url: '/goods/goods/goods-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 生成SKU编码

export function generateSku(data) {
  return request({
    url: '/goods/goods/generate-sku',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // SKU设置保存

export function skuInfoSave(data) {
  return request({
    url: '/goods/goods/sku-info-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取所有sku

export function getAllSku(data) {
  return request({
    url: '/goods/goods/get-sku',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取所有设置列表

export function goodsOptions(data) {
  return request({
    url: '/goods/goods/options',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 根据物流商获取渠道

export function getChannel(data) {
  return request({
    url: '/goods/goods/get-channel',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 根据客户获取店铺

export function getStore(data) {
  return request({
    url: '/goods/goods/get-store',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 关联客户/供应商

export function relatedSave(data) {
  return request({
    url: '/goods/goods/related-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取客户/供应商

export function relatedEdit(data) {
  return request({
    url: '/goods/goods/related-edit',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取所有用户列表

export function allUserList(data) {
  return request({
    url: '/goods/goods/user-list',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 商品搜索

export function goodsSearch(data) {
  return request({
    url: '/goods/goods/goods-search',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 商品sku列表

export function productSku(data) {
  return request({
    url: '/goods/goods/sub-product',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取渠道

export function getRuleDetail(data) {
  return request({
    url: '/order/order/get-rule-detail',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 商品关联发送通知

export function setGoodsSkuMap(data) {
  return request({
    url: '/goods/goods/goods-sku-map',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 报价列表

export function quotedList(data) {
  return request({
    url: '/quoted/quoted/index',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 报价详情

export function quotedEdit(data) {
  return request({
    url: '/quoted/quoted/get-edit',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 报价详情选择物流规则

export function quotedRules(data) {
  return request({
    url: '/quoted/quoted/rules',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 国家配置详情

export function getChanCountry(data) {
  return request({
    url: '/logistics/logistics/get-chan-country',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 保存草稿

export function quotedDraft(data) {
  return request({
    url: '/quoted/quoted/draft',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取公共属性（状态下拉框）

export function quotedOption(data) {
  return request({
    url: '/quoted/quoted/option',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 选择商品确认

export function quotedMap(data) {
  return request({
    url: '/quoted/quoted/map',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 进行报价

export function quotedSend(data) {
  return request({
    url: '/quoted/quoted/send',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 报价详情商品属性修改保存

export function quotedChangePrice(data) {
  return request({
    url: '/quoted/quoted/channge-price',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}