var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$parent.storeTitle,
            visible: _vm.visible,
            width: "700px",
            "before-close": _vm.storeAdd,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "storeForm",
              attrs: {
                model: _vm.storeForm,
                size: "small",
                "label-width": "170px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台:", prop: "store_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: {
                      size: "small",
                      disabled: "",
                      placeholder: "shopify"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺代号:", prop: "store_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: {
                      size: "small",
                      placeholder: "卖家自定义,以做区分"
                    },
                    model: {
                      value: _vm.storeForm.store_name,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "store_name", $$v)
                      },
                      expression: "storeForm.store_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台账号:", prop: "platform_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "平台账号" },
                    model: {
                      value: _vm.storeForm.platform_name,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "platform_name", $$v)
                      },
                      expression: "storeForm.platform_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺域名:", prop: "store_url" } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "w-350",
                      attrs: { size: "small", placeholder: "店铺域名" },
                      model: {
                        value: _vm.storeForm.store_url,
                        callback: function($$v) {
                          _vm.$set(_vm.storeForm, "store_url", $$v)
                        },
                        expression: "storeForm.store_url"
                      }
                    },
                    [
                      _c("template", { slot: "append" }, [
                        _vm._v(".myshopify.com")
                      ])
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "api_key:", prop: "api_key" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "api_key" },
                    model: {
                      value: _vm.storeForm.api_key,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "api_key", $$v)
                      },
                      expression: "storeForm.api_key"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "password:", prop: "password" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "password" },
                    model: {
                      value: _vm.storeForm.password,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "password", $$v)
                      },
                      expression: "storeForm.password"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "shared_secret:", prop: "shared_secret" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "shared_secret" },
                    model: {
                      value: _vm.storeForm.shared_secret,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "shared_secret", $$v)
                      },
                      expression: "storeForm.shared_secret"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "api_version:", prop: "api_version" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "年-月" },
                    model: {
                      value: _vm.storeForm.api_version,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "api_version", $$v)
                      },
                      expression: "storeForm.api_version"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "location_id:", prop: "location_id" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "location_id" },
                    model: {
                      value: _vm.storeForm.location_id,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "location_id", $$v)
                      },
                      expression: "storeForm.location_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "负责人:", prop: "charge_people" } },
                [
                  _c("el-input", {
                    staticClass: "w-350",
                    attrs: { size: "small", placeholder: "负责人" },
                    model: {
                      value: _vm.storeForm.charge_people,
                      callback: function($$v) {
                        _vm.$set(_vm.storeForm, "charge_people", $$v)
                      },
                      expression: "storeForm.charge_people"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.storeAdd } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.storeAdd(1)
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }