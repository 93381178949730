import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { uploadImage } from '@/api/commodity';
export default {
  name: 'upload-print',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data: function data() {
    return {
      perUploadList: [],
      uploadList: [],
      savePicLoading: false
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    // 超出文件钩子
    handleExceed: function handleExceed(files, fileList) {
      this.$message.error("\u5F53\u524D\u9650\u5236\u9009\u62E9".concat(this.limit, "\u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    // 关闭弹窗
    handleClose: function handleClose(type) {
      if (type === 1) {
        this.$emit('close', 1);
      }
    },
    // 本地上传  数据更改
    handleChange: function handleChange(file, fileList) {
      // console.log(file, fileList)
      this.perUploadList = fileList;
    },
    // 本地上传  数据删除
    handleRemove: function handleRemove(file, fileList) {
      // console.log(file, fileList)
      this.perUploadList = fileList;
    },
    // 判断上传图片大小
    handleBeforeUpload: function handleBeforeUpload(file) {
      var isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error('上传的图片大小不能超过 2MB!');
      }

      return isLt2M;
    },
    // 异步上传图片
    upload: function upload(fileObj) {
      var _this = this;

      var formData = new FormData();
      formData.append('file', fileObj.raw);
      return new Promise(function (resolve, reject) {
        uploadImage(formData).then(function (res) {
          resolve(res.data);
        }).catch(function () {
          _this.savePicLoading = false;
        });
      });
    },
    submit: function submit() {
      var _this2 = this;

      // this.$refs.myUpload.submit()
      var req = [];
      this.savePicLoading = true;
      this.perUploadList.forEach(function (item) {
        req.push(_this2.upload(item));
      });
      Promise.all(req).then(function (result) {
        var list = result.map(function (item) {
          return item['data-show-file'];
        });

        _this2.$emit('close', list);

        _this2.$message.success('上传' + list.length + '条成功！ ' + (_this2.picUpLoadList.length - list.length) + '条失败！');

        _this2.$refs['myUpload'].clearFiles();

        _this2.perUploadList = [];
      }).finally(function () {
        _this2.savePicLoading = false;
      });
    }
  }
};