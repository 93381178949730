//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { roleSave } from '@/api/admin';
export default {
  name: 'mdf-charactar',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      dialogTitle: '',
      formData: {
        role_name: '',
        is_open: ''
      },
      isOpenOptions: [],
      rules: {
        role_name: [{
          required: true,
          message: '角色名称不能为空!',
          trigger: 'blur'
        }],
        is_open: [{
          required: true,
          message: '请选择状态!',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val, oldVal) {
      var _this = this;

      // 打开  关闭
      if (val) {
        // 修改  新增
        if (this.type == 'mdf') {
          this.dialogTitle = '修改角色';
          this.$nextTick(function () {
            _this.formData = JSON.parse(JSON.stringify(_this.data));

            if (_this.formData.is_open == '启用') {
              _this.formData.is_open = '1';
            } else {
              _this.formData.is_open = '0';
            }
          });
        } else {
          this.dialogTitle = '新增角色';
        }
      } else {
        this.formData = {};
        this.$refs.formData.resetFields();
      }
    },
    '$parent.isOpenOptions': function $parentIsOpenOptions(value, oldValue) {
      this.isOpenOptions = value;
    }
  },
  mounted: function mounted() {},
  methods: {
    close: function close(type) {
      var _this2 = this;

      if (type == 1) {
        this.$refs.formData.validate(function (valid) {
          if (valid) {
            _this2.saveLoading = true;
            roleSave(_this2.formData).then(function (res) {
              _this2.$emit('close', 1);

              _this2.saveLoading = false;
            }).catch(function (err) {});
          } else {
            return false;
          }
        });
      } else {
        this.$emit('close');
      }
    }
  }
};