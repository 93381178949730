import request from '@/utils/request';
import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 用户信息

export function getUserInfo(data) {
  return request({
    url: '/user/dx-user/info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 保存用户信息

export function setUserInfo(data) {
  return request({
    url: '/user/dx-user/set-info',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 修改密码

export function setUserPassword(data) {
  return request({
    url: '/user/dx-user/set-password',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 保存账单信息管理

export function setUserEnterprise(data) {
  return request({
    url: '/user/dx-user/save-enterprise',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 删除账单信息

export function deleteUserEnterprise(data) {
  return request({
    url: '/user/dx-user/delete-enterprise',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}