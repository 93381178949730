var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "generate-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "1200px",
        title: _vm.title,
        "append-to-body": true,
        "modal-append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        closed: function($event) {
          return _vm.reset()
        }
      }
    },
    [
      _c(
        "el-card",
        { staticClass: "box-card mt5" },
        _vm._l(_vm.newGoods, function(info, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "clearfix step-jump inputDeep",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("span", [_vm._v("订单" + _vm._s(index + 1))]),
              _c(
                "div",
                _vm._l(info, function(item, idx) {
                  return _c(
                    "el-form",
                    {
                      key: idx,
                      attrs: {
                        size: "small",
                        inline: "",
                        "label-width": "150px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品编码：", prop: "goods_id" } },
                        [_c("div", [_vm._v(_vm._s(item.goods_id))])]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "sku名称：", prop: "sku_name" } },
                        [_c("div", [_vm._v(_vm._s(item.sku_name))])]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "sku编码：", prop: "sku" } },
                        [_c("div", [_vm._v(_vm._s(item.sku))])]
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "sku数量：", prop: "sku_num" } },
                        [
                          index === 0
                            ? _c("el-input", {
                                staticClass: "w-40",
                                attrs: {
                                  type: "number",
                                  disabled: true,
                                  size: "small"
                                },
                                model: {
                                  value: item.sku_num,
                                  callback: function($$v) {
                                    _vm.$set(item, "sku_num", $$v)
                                  },
                                  expression: "item.sku_num"
                                }
                              })
                            : _c("el-input", {
                                staticClass: "w-40",
                                attrs: { type: "number", size: "small" },
                                on: {
                                  input: function($event) {
                                    return _vm.update(item.sku_num, index, idx)
                                  }
                                },
                                model: {
                                  value: item.sku_num,
                                  callback: function($$v) {
                                    _vm.$set(item, "sku_num", $$v)
                                  },
                                  expression: "item.sku_num"
                                }
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _c("div", { staticClass: "bottom2" })
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", size: "medium", plain: "" },
              on: { click: _vm.addOrder }
            },
            [_vm._v("添加新订单")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "throttle",
                  rawName: "v-throttle",
                  value: [_vm.orderSave],
                  expression: "[orderSave]"
                }
              ],
              staticClass: "btn1",
              attrs: { type: "primary", size: "medium", plain: "" }
            },
            [_vm._v("确 认")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }