import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.reduce.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderBillCreate, orderBillCancel, getOrderBillDetail, orderBillFinish } from '@/api/bill';
export default {
  name: 'bill-detail',
  data: function data() {
    return {
      billStatusList: [{
        value: '1',
        label: '未结算'
      }, {
        value: '2',
        label: '已结算'
      }],
      billDetailForm: {
        bill_name: '',
        bill_info: '',
        bill_status: '',
        enterprise_logo: '',
        enterprise_info: '',
        date: '',
        due_date: '',
        refund_price: '0',
        amount_paid: '0',
        balance_due: '0',
        enterprise_name: '',
        business_address: '',
        contact_number: '',
        mailbox: '',
        bill_to: '',
        ship_to: '',
        order_list: []
      },
      tagTitle: '',
      choose: false
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    // 返回
    prev: function prev() {
      if (this.tagTitle !== 'preview') {
        this.$store.dispatch('tagsView/delView', this.$route);
      }

      this.$router.go(-1);
    },
    // 确定账单
    previewBill: function previewBill() {
      var _this = this;

      this.$confirm('Confirm to send the current bill to the customer: ' + this.billDetailForm.bill_to + ' For confirmation,please click: confirm', '', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(function () {
        var formData = JSON.parse(JSON.stringify(_this.billDetailForm));
        orderBillCreate(formData).then(function (res) {
          if (res.code === 200) {
            _this.$message.success('订单创建完成！'); // 点击完成账单撤回自己


            _this.$store.dispatch('bill/closeTags', {
              data: _this.$route,
              flag: true
            });

            _this.$router.push({
              name: 'bill-index'
            });
          } else {
            _this.$message.error('创建账单失败!');
          }
        });
      });
    },
    // 撤回账单
    withdrawBill: function withdrawBill() {
      var _this2 = this;

      if (this.billDetailForm.bill_status === '1') {
        this.$confirm('Are you sure you want to withdraw the current bill? After withdraw, the account information cannot be found in the system. if necessary, it needs to be regenerated!', 'Operation tips', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          orderBillCancel({
            bill_id: _this2.billDetailForm.id
          }).then(function (res) {
            if (res.code === 200) {
              // 点击撤回账单，关闭自己
              _this2.$store.dispatch('bill/closeTags', {
                data: _this2.$route,
                flag: true
              });

              _this2.$router.push({
                name: 'bill-index'
              });
            }
          });
        });
      } else {
        this.$message.error('当前状态下账单不能撤回！');
      }
    },
    // 查看
    iViews: function iViews() {
      var bill_id = '';

      if (this.tagTitle === 'detail') {
        bill_id = this.billDetailForm.id;
      }

      this.$router.push({
        name: 'preview-bill',
        query: {
          bill_id: bill_id
        }
      });
    },
    // 初始化
    handleGetOrderBillDetail: function handleGetOrderBillDetail() {
      var _this3 = this;

      var id = this.billDetailForm.id;
      getOrderBillDetail({
        bill_id: id
      }).then(function (res) {
        if (res.code === 200) {
          _this3.billDetailForm = res.data;

          _this3.$set(_this3.billDetailForm, 'order_list', res.data.billLtem);

          _this3.billDetailForm.refund_price = _this3.billDetailForm.refunded;
        }
      });
    },
    // 计算数组之和
    handleReduce: function handleReduce(total, num) {
      return total + num;
    },
    // 初始化
    init: function init() {
      this.billDetailForm = this.$route.query.orderData;
      this.tagTitle = this.$route.params.id;

      if (this.tagTitle === 'detail') {
        this.handleGetOrderBillDetail();
      } else {
        // 预览页面时，关闭上一页
        this.$store.dispatch('bill/closeTags', {
          data: this.$route,
          flag: false
        });
        var arrNew = [];
        this.billDetailForm.order_list.forEach(function (value, index, array) {
          if (value.ltem.substr(value.ltem.length - 2, 2) === '退款') {
            arrNew.push(value.amount);
          }
        });

        if (arrNew.length > 0) {
          this.billDetailForm.refund_price = arrNew.reduce(this.handleReduce);
        } else {
          this.billDetailForm.refund_price = 0;
        }
      }
    },
    handleChange: function handleChange() {
      this.choose = true;
    },
    changeBill: function changeBill() {
      var _this4 = this;

      var ids = [];
      ids.push(this.billDetailForm.id);
      orderBillFinish({
        id: ids
      }).then(function (res) {
        if (res.code === 200) {
          _this4.$message.success('账单结算成功！'); // 点击结算账单，关闭自己


          _this4.$store.dispatch('bill/closeTags', {
            data: _this4.$route,
            flag: true
          });

          _this4.$router.push({
            name: 'bill-index'
          });
        } else {
          _this4.$message.error(res.message);
        }
      });
    }
  }
};