import _createForOfIteratorHelper from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.values.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderDataList, orderReissue, orderRefund, orderExport, orderStatus, getOrderId, ordercostcharge } from "@/api/commerce";
import { blobUpload } from "@/utils/blob";
import { getSession } from "@/utils/session";
export default {
  name: "order-system",
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/Pagination"));
      });
    },
    DialogImport: function DialogImport() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/dialogImport"));
      });
    },
    orderEdit: function orderEdit() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./component/orderEdit"));
      });
    } // packageSearch: () => import('@/components/packageSearch/index')

  },

  /*  computed: {
    // 展开过滤列表
    filterList() {
      return [
        { label: '订单号', inputType: 'input', name: 'order_no' },
        { label: '第三方订单号', inputType: 'input', name: 'thirdParty_order_on' },
        { label: '订单状态', inputType: 'select', name: 'order_status', children: this.orderStatusList, multiple: true },
        { label: '物流状态', inputType: 'select', name: 'logistics_status', children: this.logisticsStatusList, multiple: true },
        { label: '客户', inputType: 'select', name: 'service_id', children: this.serviceList, multiple: true },
        { label: '店铺', inputType: 'select', name: 'store_url', children: this.stores, multiple: true },
        { label: '收件人姓名', inputType: 'input', name: 'consignee' },
        { label: '订单导入人员', inputType: 'input', name: 'import_people' },
        { label: '订单名称', inputType: 'input', name: 'order_name' },
        { label: '订单类型', inputType: 'select', name: 'order_type', children: this.orderTypes },
        { label: '创建时间', inputType: 'datetimerange', name: ['create_start_time','create_end_time']},
        { label: '更新时间', inputType: 'datetimerange', name: ['update_start_time','update_end_time']}
      ]
    },
    abnormalFilterList() {
      return [
        { label: '订单号', inputType: 'input', name: 'order_no' },
        { label: '第三方订单号', inputType: 'input', name: 'thirdParty_order_on' },
        { label: '物流状态', inputType: 'select', name: 'logistics_status', children: this.logisticsStatusList, multiple: true },
        { label: '客户', inputType: 'select', name: 'service_id', children: this.serviceList, multiple: true },
        { label: '店铺', inputType: 'select', name: 'store_url', children: this.stores, multiple: true },
        { label: '收件人姓名', inputType: 'input', name: 'consignee' },
        { label: '订单导入人员', inputType: 'input', name: 'import_people' },
        { label: '订单名称', inputType: 'input', name: 'order_name' },
        { label: '订单类型', inputType: 'select', name: 'order_type', children: this.orderTypes },
        { label: '创建时间', inputType: 'datetimerange', name: ['create_start_time','create_end_time']},
        { label: '更新时间', inputType: 'datetimerange', name: ['update_start_time','update_end_time']}
      ]
    }
  },*/
  data: function data() {
    return {
      formpandian: {
        mark: "",
        charge: ""
      },
      dialogpandian: false,
      formtui: {
        mark: "",
        return_cost: ""
      },
      dialogtui: false,
      rules: {},
      loading: false,
      downloadLoading: false,
      orderVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      platform_id: "01",
      labelList: [{
        label: "订单号",
        value: "order_no",
        type: "order"
      }, {
        label: "第三方订单号",
        value: "thirdParty_order_on"
      }, {
        label: "订单名称",
        value: "order_name",
        width: "100"
      }, {
        label: "客户",
        value: "service_name",
        width: "60"
      }, {
        label: "店铺",
        value: "store_url"
      }, {
        label: "订单状态",
        value: "order_status_name",
        width: "100"
      }, {
        label: "订单商品及数量",
        value: "goods_sku"
      }, {
        label: "物流信息",
        type: "logistics"
      }, {
        label: "收件信息",
        type: "receive"
      }, {
        label: "订单导入员",
        value: "import_people",
        width: "100"
      }, {
        label: "创建时间",
        value: "create_time"
      }, {
        label: "更新时间",
        value: "update_time"
      }, {
        label: "订单支付时间",
        value: "order_update_time"
      }, {
        label: "店铺状态",
        type: "store_type",
        value: "store_type"
      }, {
        label: "操作",
        type: "operation",
        width: "80"
      }, {
        label: "异常原因",
        value: "abnormal_reason",
        type: "popover",
        width: "80"
      }],
      importVisible: false,
      importProps: {
        index: process.env.VUE_APP_DOWNLOAD_URL + "/importOrder.xlsx",
        url: "/order/order/upload-order",
        name: "importOrder.xlsx"
      },
      updateTitle: "批量导入订单",
      store_type: {
        1: "正常",
        2: "暂不处理"
      },
      formInline: {
        order_status: [],
        logistics_status: [],
        service_id: [],
        thirdParty_order_on: "",
        consignee: "",
        import_people: "",
        order_name: "",
        store_url: "",
        order_no: "",
        order_type: "",
        store_type: ""
      },
      statusloading: false,

      /* orderStatusList: [],
      logisticsStatusList: [],
      serviceList: [],
      channelList: [],
      stores: [],
      orderTypes: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '2',
          label: '拆单'
        },
        {
          value: '3',
          label: '合单'
        }
      ],*/
      selectList: {
        orderStautsList: [],
        logisticsStatusList: [],
        serviceList: [],
        channelList: [],
        stores: [],
        orderTypes: ["全部", "拆单", "合单"]
      },
      update_start: [],
      order_play_time: [],
      create_start: [],
      selectTabData: [],
      // 单选多选数据存入
      tableData: [],
      orderEditId: "",
      abnormal_count: "",
      thirdParty_pay_no: "",
      jumpRoute: "",
      plat_form: "",
      platforms: [],
      platform: "1",
      tabOneActive: "01",
      tabTwoActive: "02",
      formInlineOneOne: {
        order_status: [],
        logistics_status: [],
        service_id: [],
        thirdParty_order_on: "",
        consignee: "",
        import_people: "",
        order_name: "",
        store_url: "",
        order_no: "",
        order_type: ""
      },
      formInlineOneTwo: {
        order_status: [],
        logistics_status: [],
        service_id: [],
        thirdParty_order_on: "",
        consignee: "",
        import_people: "",
        order_name: "",
        store_url: "",
        order_no: "",
        order_type: ""
      },
      formInlineTwoOne: {
        order_status: [],
        logistics_status: [],
        service_id: [],
        thirdParty_order_on: "",
        consignee: "",
        import_people: "",
        order_name: "",
        store_url: "",
        order_no: "",
        order_type: ""
      },
      formInlineTwoTwo: {
        order_status: [],
        logistics_status: [],
        service_id: [],
        thirdParty_order_on: "",
        consignee: "",
        import_people: "",
        order_name: "",
        store_url: "",
        order_no: "",
        order_type: ""
      }
    };
  },
  watch: {
    $route: function $route() {
      if (this.$route.params.thirdParty_order_on !== undefined) {
        this.searchAfterSplitted(this.$route.params.thirdParty_order_on);
      }

      if (this.$route.params.order_no !== undefined) {
        this.searchAfterMerged(this.$route.params.order_no);
      }

      if (this.$route.params.orderList !== undefined) {
        this.searchAfterRecovered(this.$route.params.orderList);
      }
    },
    create_start: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.create_start_time = this.create_start[0];
          this.formInline.create_end_time = this.create_start[1];
        } else {
          this.formInline.create_start_time = "";
          this.formInline.create_end_time = "";
        }
      },
      immediate: true
    },
    update_start: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.update_start_time = this.update_start[0];
          this.formInline.update_end_time = this.update_start[1];
        } else {
          this.formInline.update_start_time = "";
          this.formInline.update_end_time = "";
        }
      },
      immediate: true
    },
    order_play_time: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.start_order_update_time = this.order_play_time[0];
          this.formInline.end_order_update_time = this.order_play_time[1];
        } else {
          this.formInline.start_order_update_time = "";
          this.formInline.end_order_update_time = "";
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // 展开过滤取值
    this.selectList.serviceList = getSession("serviceList");
    this.selectList.channelList = getSession("channelList");
    this.platforms = getSession("platform");
  },
  mounted: function mounted() {
    this.Inquire();
    this.init();
  },
  methods: {
    handlePlatformClick: function handlePlatformClick(tab) {
      if (tab.paneName === "1") {
        this.platform_id = this.tabOneActive;
        this.handleClick();
      } else {
        this.platform_id = this.tabTwoActive;
        this.handleClick();
      }
    },
    searchAfterSplitted: function searchAfterSplitted(val) {
      // 重置查询参数
      this.resetClick();
      this.formInline.thirdParty_order_on = val;
      this.search(); // 展开过滤需要

      /*  this.resetFilter()
      //重置页数
      this.listQuery.page = 1
      this.listQuery.limit = 10
      let formData = [{
        binding: true,
        filterName: "filterItem1",
        inputType: "input",
        label: "第三方订单号",
        multiple: false,
        name: "thirdParty_order_on",
        value: val
      }]
      this.handleFilter(formData)*/
    },
    searchAfterMerged: function searchAfterMerged(val) {
      // 合单后根据订单号搜索
      // 重置查询参数
      this.resetClick();
      this.formInline.order_no = val;
      this.search(); // 展开过滤需要

      /* this.resetFilter()
      //重置页数
      this.listQuery.page = 1
      this.listQuery.limit = 10
      let formData = [{
        binding: true,
        filterName: "filterItem0",
        inputType: "input",
        label: "订单号",
        multiple: false,
        name: "order_no",
        value: val
      }]
      this.handleFilter(formData)*/
    },
    searchAfterRecovered: function searchAfterRecovered(val) {
      this.resetClick();
      this.formInline.thirdParty_order_on = val.join("-");
      this.search(); // 展开过滤需要

      /* this.resetFilter()
      //重置页数
      this.listQuery.page = 1
      this.listQuery.limit = 10
      let formData = [{
        binding: true,
        filterName: "filterItem1",
        inputType: "input",
        label: "第三方订单号",
        multiple: false,
        name: "thirdParty_order_on",
        value: val.join('-')
      }]
      this.handleFilter(formData)*/
    },
    init: function init() {
      var _this = this;

      this.statusloading = true;
      orderStatus({
        plat_form: this.platform
      }).then(function (res) {
        if (res.code === 200) {
          _this.selectList.orderStautsList = res.data.orderStatus;
          _this.selectList.logisticsStatusList = res.data.logisticsStatus;
          _this.selectList.stores = res.data.stores;
          _this.statusloading = false;
        }
      }); // 展开过滤

      /* orderStatus().then(res => {
        if (res.code === 200) {
          res.data.stores.map(item => {
            let o = {}
            o.value = item
            o.label = item
            this.stores.push(o)
          })
          for (let i in res.data.orderStatus) {
            let o  = {}
            o.value = i
            o.label = res.data.orderStatus[i]
            this.orderStatusList.push(o)
          }
          for( let i in res.data.logisticsStatus) {
            let o = {}
            o.value = i
            o.label = res.data.logisticsStatus[i]
            this.logisticsStatusList.push(o)
          }
          this.statusloading = false
        }
      })*/
    },
    handleClick: function handleClick() {
      if (this.platform === "1") {
        this.init();

        switch (this.platform_id) {
          case "01":
            this.formInline = this.formInlineOneOne;
            break;

          case "11":
            // this.formInline = this.formInlineOneTwo
            this.formInline = this.formInlineOneOne;
            break;
        }
      } else {
        this.init();

        switch (this.platform_id) {
          case "02":
            this.formInline = this.formInlineTwoOne;
            break;

          case "12":
            // this.formInline = this.formInlineTwoTwo
            this.formInline = this.formInlineTwoOne;
            break;
        }
      }

      this.Inquire();

      if (this.platform_id.slice(1, 2) === "1") {
        this.tabOneActive = this.platform_id;
      } else {
        this.tabTwoActive = this.platform_id;
      }
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;

      if (this.platform_id === "12" || this.platform_id === "11") {
        formData.order_status = "5";
      } else {
        formData.order_status = this.formInline.order_status.join(",");
      }

      formData.logistics_status = this.formInline.logistics_status.join(",");
      formData.service_id = this.formInline.service_id.join(",");
      formData.thirdParty_order_on = this.formInline.thirdParty_order_on.replace(/\s+/g, "-");
      formData.order_no = this.formInline.order_no.replace(/\s+/g, "-");
      formData.order_name = this.formInline.order_name.replace(/\s+/g, ",");
      formData.plat_form = this.platform;
      orderDataList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            res.data.data.map(function (item) {
              if (item.goods_sku) {
                item.goods_sku = item.goods_sku.replace(/,/gi, "<br>");
              }
            });
            _this2.tableData = res.data.data;
            _this2.abnormal_count = res.data.abnormal_count;
            _this2.listQuery.total = parseInt(res.total);
            _this2.loading = false;
          }, 300);
        }
      });
    },
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();

      switch (this.platform_id) {
        case "01":
          this.formInlineOneOne = this.formInline;
          break;

        case "11":
          this.formInlineOneOne = this.formInline; // this.formInlineOneTwo = this.formInline

          break;

        case "02":
          this.formInlineTwoOne = this.formInline;
          break;

        case "12":
          this.formInlineTwoOne = this.formInline;
        // this.formInlineTwoTwo = this.formInline
      }
    },
    // 重置
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.update_start = [];
      this.create_start = [];
      this.search();

      switch (this.platform_id) {
        case "01":
          this.formInlineOneOne = this.formInline;
          break;

        case "11":
          this.formInlineOneOne = this.formInline; // this.formInlineOneTwo = this.formInline

          break;

        case "02":
          this.formInlineTwoOne = this.formInline;
          break;

        case "12":
          this.formInlineTwoOne = this.formInline;
        // this.formInlineTwoTwo = this.formInline
      }
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectTabData = val;
    },
    // 合并
    handleCombine: function handleCombine() {
      var _this3 = this;

      var store_url = this.selectTabData[0].store_url;

      if (this.selectTabData.length < 2) {
        this.$message.error("请选择至少两条数据!");
        return false;
      }

      for (var i = 0; i < this.selectTabData.length; i++) {
        if (store_url !== this.selectTabData[i].store_url) {
          this.$message.error("请选择相同的店铺进行合并!");
          return false;
        }

        if (this.selectTabData[i].order_status_name !== "完成支付" || this.selectTabData[i].logistics_no !== "") {
          this.$message.error("订单不允许合并!");
          return false;
        }

        if (this.selectTabData[i].order_type === "2" || this.selectTabData[i].order_type === "3") {
          this.$message.error("拆分或合并的订单不能再进行合并!");
          return false;
        }
      }

      var mergeIds = [];

      var _iterator = _createForOfIteratorHelper(this.selectTabData),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var v = _step.value;
          mergeIds.push(v.customer_orders_sublist_id);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      getOrderId({
        num: 1
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$router.push({
            name: "merge-detail",
            query: {
              ids: JSON.stringify(mergeIds),
              order_no: res.data[0],
              service_name: _this3.selectTabData[0].service_name
            }
          });
        }
      });
    },
    // 拆分
    handleSplit: function handleSplit() {
      if (this.selectTabData.length !== 1) {
        this.$message.error("请选择至少一条数据!");
        return false;
      }

      if (this.selectTabData[0].order_type === "2" || this.selectTabData[0].order_type === "3") {
        this.$message.error("拆分或合并的订单不能再进行拆分!");
        return false;
      }

      if ((this.selectTabData[0].order_status_name === "完成支付" || this.selectTabData[0].order_status_name === "异常") && this.selectTabData[0].logistics_no === "") {
        this.$router.push({
          name: "split-detail",
          query: {
            service_name: this.selectTabData[0].service_name,
            order_no: this.selectTabData[0].order_no,
            id: JSON.parse(this.selectTabData[0].customer_orders_sublist_id),
            plat_form: this.platform
          }
        });
      } else {
        this.$message.error("订单不符合拆分条件，请检查!");
      }
    },
    // 打开上传弹窗
    openImport: function openImport() {
      this.importVisible = true;
    },
    // 关闭上传弹窗
    closeImport: function closeImport(type) {
      this.importVisible = false;

      if (type === 1) {
        this.Inquire();
      }
    },
    // 修改
    orderEdit: function orderEdit(id) {
      this.orderVisible = true;
      this.orderEditId = id;
    },
    closeOederEdit: function closeOederEdit(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.orderVisible = false;
    },
    tuimoney: function tuimoney(i) {
      this.formtui.id = i.id;
      this.formtui.platform = i.platform;
      this.formtui.service_name = i.service_name;
      this.formtui.customer_id = i.customer_id;
      this.formtui.order_no = i.order_no;
      this.formtui.thirdParty_order_on = i.thirdParty_order_on;
      this.formtui.order_name = i.order_name;
      this.formtui.store_url = i.store_url;
      this.dialogtui = true;
    },
    tuiok: function tuiok() {
      var _this4 = this;

      var typeTitle;
      var typeHead;
      typeTitle = "确认对订单id:" + this.formtui.order_no + ",所属客户为" + this.formtui.service_name + "的订单,进行退款操作,确认操作后会同步运单状态置为取消,且结算单也置为退款";
      typeHead = "退款";
      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        orderRefund(_this4.formtui).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.message);

            _this4.dialogtui = false;

            _this4.Inquire();
          }
        });
      }).catch(function () {
        _this4.$message({
          type: "info",
          message: "已取消设置"
        });
      });
    },
    payok: function payok() {
      var _this5 = this;

      ordercostcharge(this.formpandian).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.message);
        }
      });
    },
    // 收费
    paymoney: function paymoney(i) {
      console.log('skdjsdk', i);
      this.formpandian.customer_id = i.customer_id;
      this.formpandian.order_no = i.order_no;
      this.formpandian.thirdParty_order_on = i.thirdParty_order_on;
      this.formpandian.order_name = i.order_name;
      this.formpandian.store_url = i.store_url;
      this.dialogpandian = true;
    },
    // 操作弹窗
    operation: function operation(item, type) {
      var _this6 = this;

      var typeTitle;
      var typeHead;

      if (type === "refund") {
        typeTitle = "确认对订单id:" + item.order_no + ",所属客户为" + item.service_name + "的订单,进行退款操作,确认操作后会同步运单状态置为取消,且结算单也置为退款";
        typeHead = "退款";
      } else {
        typeTitle = "确认补发订单id:" + item.order_no + ",所属客户为" + item.service_name + "的订单,进行退款操作,确认操作后会重新生成一条运单,如历史生成运单状态会置为已取消";
        typeHead = "补发";
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        if (type === "refund") {
          orderRefund({
            id: item.id,
            plat_form: _this6.platform
          }).then(function (res) {
            if (res.code === 200) {
              _this6.$message.success(res.message);

              _this6.Inquire();
            }
          });
        } else {
          orderReissue({
            id: item.id,
            plat_form: _this6.platform
          }).then(function (res) {
            if (res.code === 200) {
              _this6.$message.success(res.message);

              _this6.Inquire();
            }
          });
        }
      }).catch(function () {
        _this6.$message({
          type: "info",
          message: "已取消设置"
        });
      });
    },
    // 下单详情
    orderClick: function orderClick(item) {
      this.$router.push({
        name: "orderd-details",
        query: {
          order_no: item.order_no,
          service_name: item.service_name,
          id: item.customer_orders_sublist_id,
          plat_form: this.platform
        },
        params: {
          id: item.customer_orders_sublist_id
        }
      });
    },
    // 导出搜索结果
    handleDownload: function handleDownload() {
      var _this7 = this;

      var params = JSON.parse(JSON.stringify(this.formInline));

      if (this.platform_id === "12" || this.platform_id === "11") {
        params.order_status = "5";
      } else {
        params.order_status = this.formInline.order_status.join(",");
      }

      params.logistics_status = this.formInline.logistics_status.join(",");
      params.service_id = this.formInline.service_id.join(",");
      params.thirdParty_order_on = this.formInline.thirdParty_order_on.replace(/\s+/g, "-");
      params.order_no = this.formInline.order_no.replace(/\s+/g, "-");
      params.order_name = this.formInline.order_name.replace(/\s+/g, ",");
      var arr = Object.values(params).filter(function (item) {
        return item;
      });
      if (arr.length === 0) return this.$message({
        type: "warning",
        message: "请选择搜索条件进行导出!"
      });
      this.downloadLoading = true;
      params.plat_form = this.platform;
      orderExport(params).then(function (res) {
        blobUpload(res, "orderSystem.xlsx").then(function (res) {
          console.log(res);
          _this7.downloadLoading = false;
        }).catch(function (err) {
          console.log(err);
          _this7.downloadLoading = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this7.downloadLoading = false;
      });
    }
    /* // 展开搜索
    handleFilter(val) {
      console.log('val',val)
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
      switch (this.platform_id) {
        case '01':
          this.formInlineOneOne = this.formInline
          break
        case '11':
          this.formInlineOneTwo = this.formInline
          break
        case '02':
          this.formInlineTwoOne = this.formInline
          break
        case '12':
          this.formInlineTwoTwo = this.formInline
      }
    },
    // 重置
    resetFilter() {
      this.formInline = this.$options.data().formInline
      this.Inquire()
      switch (this.platform_id) {
        case '01':
          this.formInlineOneOne = this.formInline
          break
        case '11':
          this.formInlineOneTwo = this.formInline
          break
        case '02':
          this.formInlineTwoOne = this.formInline
          break
        case '12':
          this.formInlineTwoTwo = this.formInline
      }
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
      switch (this.platform_id) {
        case '01':
          this.formInlineOneOne = this.formInline
          break
        case '11':
          this.formInlineOneTwo = this.formInline
          break
        case '02':
          this.formInlineTwoOne = this.formInline
          break
        case '12':
          this.formInlineTwoTwo = this.formInline
      }
    }*/

  }
};