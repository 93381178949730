var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-card",
        { staticClass: "nav" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, inline: true, "label-width": "100px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.customer,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "customer", $$v)
                      },
                      expression: "form.customer"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺链接" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.store_url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "store_url", $$v)
                      },
                      expression: "form.store_url"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.order_no,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "order_no", $$v)
                      },
                      expression: "form.order_no"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单名称：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.order_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "order_name", $$v)
                      },
                      expression: "form.order_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间:", prop: "create_start" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.create_start,
                      callback: function($$v) {
                        _vm.create_start = $$v
                      },
                      expression: "create_start"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.look } },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.reset } },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "流水单号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.type == 1
                          ? _c("div", [_vm._v("Order Cost")])
                          : _vm._e(),
                        scope.row.type == 2
                          ? _c("div", [_vm._v("Recharge")])
                          : _vm._e(),
                        scope.row.type == 3
                          ? _c("div", [_vm._v("Refund/Partial Refund")])
                          : _vm._e(),
                        scope.row.type == 4
                          ? _c("div", [_vm._v("Additional Charges")])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "order_no", label: "订单号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "order_name", label: "订单名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customer", label: "客户" }
              }),
              _c("el-table-column", {
                attrs: { prop: "store_url", label: "店铺URL" }
              }),
              _c("el-table-column", {
                attrs: { prop: "previous_balance", label: "扣费前余额" }
              }),
              _c("el-table-column", {
                attrs: { prop: "detail", label: "收入/支出" }
              }),
              _c("el-table-column", {
                attrs: { prop: "current_balance", label: "当前余额" }
              }),
              _c("el-table-column", {
                attrs: { prop: "currency", label: "币种" }
              }),
              _c("el-table-column", {
                attrs: { prop: "create_time", label: "创建时间" }
              }),
              _c("el-table-column", { attrs: { prop: "mark", label: "备注" } })
            ],
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.count,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getstockList
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "客户充值",
            visible: _vm.dialogpandian,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogpandian = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formpandian } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "充值金额：", "label-width": "200px" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formpandian.current_qty,
                      callback: function($$v) {
                        _vm.$set(_vm.formpandian, "current_qty", $$v)
                      },
                      expression: "formpandian.current_qty"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "收款渠道: ",
                    "label-width": "200px",
                    prop: "warehouse_id"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.warehouse_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "warehouse_id", $$v)
                        },
                        expression: "form.warehouse_id"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.warehoustlist, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注：", "label-width": "200px" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formpandian.current_qty,
                      callback: function($$v) {
                        _vm.$set(_vm.formpandian, "current_qty", $$v)
                      },
                      expression: "formpandian.current_qty"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogpandian = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editok("pandian")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑规则信息",
            visible: _vm.dialogruku,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogruku = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formruku } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "方案规则:",
                    "label-width": "200px",
                    prop: "program"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-300",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formruku.program,
                        callback: function($$v) {
                          _vm.$set(_vm.formruku, "program", $$v)
                        },
                        expression: "formruku.program"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Standard", value: "Standard" }
                      }),
                      _c("el-option", {
                        attrs: { label: "Express", value: "Express" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流方案: ",
                    "label-width": "200px",
                    prop: "rule_id"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-300",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formruku.rule_id,
                        callback: function($$v) {
                          _vm.$set(_vm.formruku, "rule_id", $$v)
                        },
                        expression: "formruku.rule_id"
                      }
                    },
                    _vm._l(_vm.shippingrulerForm, function(item, key) {
                      return _c("el-option", {
                        key: key + 1,
                        attrs: { label: item.rule_name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户经理", "label-width": "200px" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-300",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formruku.account_manager,
                        callback: function($$v) {
                          _vm.$set(_vm.formruku, "account_manager", $$v)
                        },
                        expression: "formruku.account_manager"
                      }
                    },
                    _vm._l(_vm.getuserform, function(item, key) {
                      return _c("el-option", {
                        key: key + 1,
                        attrs: { label: item.username, value: item.username }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogruku = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editok("ruku")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "出库", visible: _vm.dialogchuku, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogchuku = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formchuku } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "输入手动出库的数量", "label-width": "200px" }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formchuku.current_qty,
                      callback: function($$v) {
                        _vm.$set(_vm.formchuku, "current_qty", $$v)
                      },
                      expression: "formchuku.current_qty"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": "200px" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formchuku.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.formchuku, "mark", $$v)
                      },
                      expression: "formchuku.mark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogchuku = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editok("chuku")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑库存", visible: _vm.dialogedit, width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogedit = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: true,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "sku别名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.product_title,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "product_title", $$v)
                          },
                          expression: "form.product_title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SKU编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.sku,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sku", $$v)
                          },
                          expression: "form.sku"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "div",
                { staticStyle: { "font-size": "17px", "font-weight": "bold" } },
                [_vm._v("已关联的商品：")]
              ),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    toggleRowSelection: "",
                    data: _vm.optinventorydata,
                    "header-cell-style": {
                      background: "#e9f1ff",
                      color: "#606266"
                    }
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "sku_code", label: "商品SKU", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sku_name", label: "SKU别名", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ven_product_name", label: "关联商品名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户商品图片", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              staticStyle: { height: "100px" },
                              attrs: {
                                src: scope.row.img_url,
                                alt: "",
                                srcset: ""
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "store_url", label: "店铺", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "是否绑定", prop: "", width: "65" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "是否绑定", type: "selection", width: "35" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "addflag" },
            [
              _c(
                "el-form",
                {
                  ref: "addform",
                  attrs: { model: _vm.addform, rules: _vm.rules }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Inventory_ID",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.addform.inventory_id,
                          callback: function($$v) {
                            _vm.$set(_vm.addform, "inventory_id", $$v)
                          },
                          expression: "addform.inventory_id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "current_qty",
                        label: "库存数量",
                        "label-width": _vm.formLabelWidth
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.addform.current_qty,
                          callback: function($$v) {
                            _vm.$set(_vm.addform, "current_qty", $$v)
                          },
                          expression: "addform.current_qty"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "消耗优先级",
                        "label-width": _vm.formLabelWidth,
                        prop: "level"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择消耗优先级" },
                          model: {
                            value: _vm.addform.level,
                            callback: function($$v) {
                              _vm.$set(_vm.addform, "level", $$v)
                            },
                            expression: "addform.level"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "1", value: "1" }
                          }),
                          _c("el-option", {
                            attrs: { label: "2", value: "2" }
                          }),
                          _c("el-option", {
                            attrs: { label: "3", value: "3" }
                          }),
                          _c("el-option", {
                            attrs: { label: "4", value: "4" }
                          }),
                          _c("el-option", { attrs: { label: "5", value: "5" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择仓库",
                        "label-width": _vm.formLabelWidth,
                        prop: "warehouse_id"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择仓库" },
                          model: {
                            value: _vm.addform.warehouse_id,
                            callback: function($$v) {
                              _vm.$set(_vm.addform, "warehouse_id", $$v)
                            },
                            expression: "addform.warehouse_id"
                          }
                        },
                        _vm._l(_vm.warehoustlist, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogedit = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editstock("addform")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加库存",
            visible: _vm.dialogFormVisible,
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: true,
                    "label-width": "80px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "sku别名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.product_title,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "product_title", $$v)
                          },
                          expression: "form.product_title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "SKU编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.form.sku,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sku", $$v)
                          },
                          expression: "form.sku"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "div",
                { staticStyle: { "font-size": "17px", "font-weight": "bold" } },
                [_vm._v("已关联的商品")]
              ),
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    toggleRowSelection: "",
                    data: _vm.optinventorydata,
                    "header-cell-style": {
                      background: "#e9f1ff",
                      color: "#606266"
                    }
                  },
                  on: { "selection-change": _vm.handleSelectionChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "sku_code", label: "商品SKU", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sku_name", label: "SKU别名", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "product_title", label: "关联商品名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "客户商品图片", width: "180" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              staticStyle: { height: "100px" },
                              attrs: {
                                src: scope.row.img_url,
                                alt: "",
                                srcset: ""
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customer_name",
                      label: "店铺",
                      width: "180"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "是否绑定", prop: "", width: "65" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "是否绑定", type: "selection", width: "35" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.optinventorydata.length > 0
            ? _c(
                "div",
                { staticClass: "addflag" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "addform",
                      attrs: { model: _vm.addform, rules: _vm.rules }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Inventory_ID",
                            "label-width": _vm.formLabelWidth
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { autocomplete: "off", disabled: "" },
                            model: {
                              value: _vm.addform.inventory_id,
                              callback: function($$v) {
                                _vm.$set(_vm.addform, "inventory_id", $$v)
                              },
                              expression: "addform.inventory_id"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "current_qty",
                            label: "库存数量",
                            "label-width": _vm.formLabelWidth
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100px" },
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.addform.current_qty,
                              callback: function($$v) {
                                _vm.$set(_vm.addform, "current_qty", $$v)
                              },
                              expression: "addform.current_qty"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "消耗优先级",
                            "label-width": _vm.formLabelWidth,
                            prop: "level"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择消耗优先级" },
                              model: {
                                value: _vm.addform.level,
                                callback: function($$v) {
                                  _vm.$set(_vm.addform, "level", $$v)
                                },
                                expression: "addform.level"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "1", value: "1" }
                              }),
                              _c("el-option", {
                                attrs: { label: "2", value: "2" }
                              }),
                              _c("el-option", {
                                attrs: { label: "3", value: "3" }
                              }),
                              _c("el-option", {
                                attrs: { label: "4", value: "4" }
                              }),
                              _c("el-option", {
                                attrs: { label: "5", value: "5" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "选择仓库",
                            "label-width": _vm.formLabelWidth,
                            prop: "warehouse_id"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择仓库" },
                              model: {
                                value: _vm.addform.warehouse_id,
                                callback: function($$v) {
                                  _vm.$set(_vm.addform, "warehouse_id", $$v)
                                },
                                expression: "addform.warehouse_id"
                              }
                            },
                            _vm._l(_vm.warehoustlist, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addstock("addform")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }