import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderInfoById, orderEditOrder } from '@/api/commerce';
import { getRuleDetail } from '@/api/commodity';
import { ruleOption } from '@/api/information';
import product from './product';
export default {
  name: 'order-edit',
  components: {
    product: product
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    plat_form: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      orderForm: {
        channel_name: '',
        goods_info: [],
        rule_info: {}
      },
      labelShop: [{
        label: '商品编码',
        value: 'goods_id',
        type: 'order_goods'
      }, {
        label: '商品名称',
        value: 'goods_name',
        type: 'name'
      }, {
        label: 'SKU编码',
        value: 'sku',
        type: 'sku'
      }, {
        label: 'SKU名称',
        value: 'sku_name'
      }, {
        label: '数量',
        value: 'sku_num'
      }, {
        label: '更新商品编码',
        value: 'new_goods_id',
        type: 'input'
      }, {
        label: '更新SKU编码',
        value: 'new_sku',
        type: 'input'
      }, {
        label: '更新SKU数量',
        value: 'new_sku_num',
        type: 'input'
      }, {
        label: '操作',
        type: 'operating'
      }],
      platform_id: '0',
      rules: {},
      tabList: [],
      rulesList: [],
      logisticsList: [],
      channelList: [],
      loading: false,
      tabloading: false,
      selectCommodity: false,
      formIdx: ''
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      if (val) {
        this.tabloading = true;
        orderInfoById({
          orders_sublist_id: this.$parent.orderEditId,
          plat_form: this.plat_form
        }).then(function (res) {
          if (res.code === 200) {
            _this.orderForm = res.data;

            if (res.data.rule_info) {
              _this.getLogistics(res.data.rule_info.rule_id);
            }

            _this.tabloading = false;
          }
        }).finally(function () {
          _this.tabloading = false;
        });
        ruleOption().then(function (res) {
          console.log(res.data);
          _this.rulesList = res.data;
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$refs.orderForm.resetFields();
      }
    }
  },
  methods: {
    rulesClick: function rulesClick(val) {
      console.log(val);
      this.getLogistics(val);
    },
    selectProduct: function selectProduct(idx) {
      this.selectCommodity = true;
      this.formIdx = idx;
    },
    receiveData: function receiveData(data) {
      console.log(data);
      this.$set(this.orderForm.goods_info[this.formIdx], 'new_goods_id', data.good_id);
      this.$set(this.orderForm.goods_info[this.formIdx], 'new_sku', data.sku); // this.linkForm = data
    },
    // 保存
    orderEdit: function orderEdit(type) {
      var _this2 = this;

      if (type === 1) {
        this.$refs.orderForm.validate(function (valid) {
          if (valid) {
            _this2.loading = true;
            delete _this2.orderForm.token;
            _this2.orderForm.plat_form = _this2.plat_form;
            orderEditOrder(_this2.orderForm).then(function (res) {
              if (res.code === 200) {
                _this2.$message.success(res.message);

                _this2.$emit('orderEdit');
              }
            }).finally(function () {
              _this2.loading = false;
            });
            console.log(_this2.orderForm);
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('orderEdit', 1);
      }
    },
    // 获取发货渠道
    getLogistics: function getLogistics(val) {
      var _this3 = this;

      if (val) {
        getRuleDetail({
          id: val
        }).then(function (res) {
          console.log(res.data);

          if (res.code === 200) {
            _this3.channelList = res.data;
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    handleClick: function handleClick() {},
    // 商品参数跳转
    order_goods: function order_goods(goods_id) {
      this.$emit('orderEdit');
      this.$router.push({
        name: 'commodity-detail',
        params: {
          id: goods_id
        },
        query: {
          type: 'view'
        }
      });
    }
  }
};