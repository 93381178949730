var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderSystem" },
    [
      _c(
        "el-card",
        { ref: "searchForm", staticClass: "searchForm box-card" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabList, function(item, key) {
              return _c("el-tab-pane", {
                key: key,
                attrs: { label: item, name: String(key) }
              })
            }),
            1
          ),
          _c(
            "el-form",
            {
              ref: "formInline",
              staticClass: "formInline",
              attrs: {
                size: "small",
                inline: true,
                model: _vm.formInline,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品编码:", prop: "id" } },
                [
                  _c("el-input", {
                    staticClass: "w-200",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "请输入商品编码"
                    },
                    model: {
                      value: _vm.formInline.id,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "id", $$v)
                      },
                      expression: "formInline.id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SKU编码:", prop: "sku_id" } },
                [
                  _c("el-input", {
                    staticClass: "w-200",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "请输入SKU编码"
                    },
                    model: {
                      value: _vm.formInline.sku_id,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "sku_id", $$v)
                      },
                      expression: "formInline.sku_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SKU别名:", prop: "sku_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-200",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "请输入SKU别名"
                    },
                    model: {
                      value: _vm.formInline.sku_name,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "sku_name", $$v)
                      },
                      expression: "formInline.sku_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品中文名称:", prop: "chinese_title" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "100",
                      clearable: "",
                      placeholder: "请输入商品中文名称"
                    },
                    model: {
                      value: _vm.formInline.chinese_title,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "chinese_title", $$v)
                      },
                      expression: "formInline.chinese_title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品英文名称:", prop: "english_title" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "100",
                      clearable: "",
                      placeholder: "请输入商品英文名称"
                    },
                    model: {
                      value: _vm.formInline.english_title,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "english_title", $$v)
                      },
                      expression: "formInline.english_title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "采购链接:", prop: "product_pur_url" } },
                [
                  _c("el-input", {
                    staticClass: "w-200",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "请输入商品采购链接"
                    },
                    model: {
                      value: _vm.formInline.product_pur_url,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "product_pur_url", $$v)
                      },
                      expression: "formInline.product_pur_url"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品属性:", prop: "product_attribute" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择商品属性"
                      },
                      model: {
                        value: _vm.formInline.product_attribute,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "product_attribute", $$v)
                        },
                        expression: "formInline.product_attribute"
                      }
                    },
                    _vm._l(_vm.selectList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: String(key) }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.activeName === "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "创建人:", prop: "create_user" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-200",
                          attrs: {
                            size: "small",
                            clearable: "",
                            filterable: "",
                            "default-first-option": "",
                            placeholder: "请选择创建人"
                          },
                          model: {
                            value: _vm.formInline.create_user,
                            callback: function($$v) {
                              _vm.$set(_vm.formInline, "create_user", $$v)
                            },
                            expression: "formInline.create_user"
                          }
                        },
                        _vm._l(_vm.userList, function(item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item, value: key }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeName === "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "更新人:", prop: "update_user" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-200",
                          attrs: {
                            size: "small",
                            clearable: "",
                            filterable: "",
                            "default-first-option": "",
                            placeholder: "请选择更新人"
                          },
                          model: {
                            value: _vm.formInline.update_user,
                            callback: function($$v) {
                              _vm.$set(_vm.formInline, "update_user", $$v)
                            },
                            expression: "formInline.update_user"
                          }
                        },
                        _vm._l(_vm.userList, function(item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item.username, value: item.user_id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeName === "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "创建时间:" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          size: "small",
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.create_at,
                          callback: function($$v) {
                            _vm.create_at = $$v
                          },
                          expression: "create_at"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "更新时间:" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      "value-format": "yyyy-MM-dd",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.update_at,
                      callback: function($$v) {
                        _vm.update_at = $$v
                      },
                      expression: "update_at"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v("筛 选")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.resetClick }
                    },
                    [_vm._v("重 置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn-group" },
            [
              _vm.activeName === "0"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-plus"
                      },
                      on: { click: _vm.addGoods }
                    },
                    [_vm._v("新增商品")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-upload2"
                  },
                  on: { click: _vm.openImport }
                },
                [_vm._v("批量导入商品")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    loading: _vm.downloadLoading,
                    size: "small",
                    icon: "el-icon-download"
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v("导出搜索结果")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-delete"
                  },
                  on: { click: _vm.goodsRemove }
                },
                [_vm._v("删 除")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { ref: "tableInfo", staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", align: "center", fixed: "" }
              }),
              _vm._l(_vm.labelList, function(item, idx) {
                return [
                  item.show === undefined || item.show.includes(_vm.activeName)
                    ? _c("el-table-column", {
                        key: idx,
                        attrs: {
                          fixed: item.fixed,
                          width: item.width,
                          label: item.label,
                          prop: item.value
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  item.type === undefined
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row[item.value]))
                                      ])
                                    : _vm._e(),
                                  item.type === "id"
                                    ? _c("span", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "c_blue cursor_p",
                                            on: {
                                              click: function($event) {
                                                return _vm.toLink(scope.row)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(scope.row[item.value]) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  item.type === "image"
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.img_url !== null &&
                                          scope.row.img_url.length > 0
                                            ? [
                                                _c(
                                                  "el-popover",
                                                  {
                                                    attrs: {
                                                      placement: "top",
                                                      width: 300,
                                                      "open-delay": 300,
                                                      trigger: "hover"
                                                    }
                                                  },
                                                  [
                                                    _c("el-image", {
                                                      staticClass:
                                                        "product-img",
                                                      attrs: {
                                                        slot: "reference",
                                                        src: scope.row.img_url,
                                                        alt: "加载失败..."
                                                      },
                                                      slot: "reference"
                                                    }),
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        height: "300px",
                                                        width: "300px"
                                                      },
                                                      attrs: {
                                                        src: scope.row.img_url
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            : [_vm._v("无")]
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  item.type === "link"
                                    ? _c(
                                        "span",
                                        [
                                          scope.row.product_pur_url
                                            ? [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "islink text-ellipsis-two",
                                                    attrs: {
                                                      href:
                                                        scope.row
                                                          .product_pur_url,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row
                                                          .product_pur_url
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            : [_c("span", [_vm._v("无")])]
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  item.type === "sku_info"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "text-align": "left" }
                                        },
                                        [
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("采购价:")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sku_info.length > 0 &&
                                                  scope.row.sku_info[0]
                                                    .pur_price
                                              )
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("采购库存:")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sku_info.length > 0 &&
                                                  scope.row.sku_info[0]
                                                    .pur_inventory
                                              )
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("attr:")]
                                            ),
                                            _vm._v(" "),
                                            scope.row.sku_info.length > 0 &&
                                            scope.row.sku_info[0].attr &&
                                            scope.row.sku_info[0].attr == 1
                                              ? _c("span", [_vm._v("liquid")])
                                              : _vm._e(),
                                            scope.row.sku_info.length > 0 &&
                                            scope.row.sku_info[0].attr &&
                                            scope.row.sku_info[0].attr == 2
                                              ? _c("span", [_vm._v("normal")])
                                              : _vm._e(),
                                            scope.row.sku_info.length > 0 &&
                                            scope.row.sku_info[0].attr &&
                                            scope.row.sku_info[0].attr == 3
                                              ? _c("span", [_vm._v("special")])
                                              : _vm._e(),
                                            scope.row.sku_info.length > 0 &&
                                            scope.row.sku_info[0].attr &&
                                            scope.row.sku_info[0].attr == 4
                                              ? _c("span", [_vm._v("powder")])
                                              : _vm._e()
                                          ]),
                                          _c("br")
                                        ]
                                      )
                                    : _vm._e(),
                                  item.type === "sku_sell"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "text-align": "left" }
                                        },
                                        [
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("售卖价:")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sku_info.length > 0 &&
                                                  scope.row.sku_info[0]
                                                    .sale_price
                                              )
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("售卖库存:")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sku_info.length > 0 &&
                                                  scope.row.sku_info[0]
                                                    .sale_inventory
                                              )
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("物流利润率:")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sku_info.length > 0 &&
                                                  scope.row.sku_info[0]
                                                    .profit_margin
                                              )
                                            )
                                          ]),
                                          _c("br"),
                                          _c("span", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("服务费:")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                scope.row.sku_info.length > 0 &&
                                                  scope.row.sku_info[0]
                                                    .service_fee
                                              )
                                            )
                                          ]),
                                          _c("br")
                                        ]
                                      )
                                    : _vm._e(),
                                  item.type === "operation"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: { display: "block" },
                                              attrs: {
                                                type: "primary",
                                                underline: false
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.actions(
                                                    scope.row.id,
                                                    "associate"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("关联客户&供应商")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.Inquire
            }
          })
        ],
        1
      ),
      _c("service-provider", {
        attrs: {
          visible: _vm.serviceDialog,
          title: _vm.serviceTitle,
          flag: _vm.serviceFlag,
          "form-data": _vm.serviceData
        },
        on: {
          "update:visible": function($event) {
            _vm.serviceDialog = $event
          },
          close: _vm.initData
        }
      }),
      _c("dialog-import", {
        attrs: { visible: _vm.importVisible, "import-props": _vm.importProps },
        on: { close: _vm.closeImport }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }