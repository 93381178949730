import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { supplierSave, supplierById, supplierEdit } from '@/api/information';
export default {
  name: 'supplier-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      supplierForm: {
        supplier_name: '',
        mobile: '',
        address: '',
        duty_name: '',
        cooperate_time: '',
        category: '',
        wechat_num: ''
      },
      rules: {
        supplier_name: [{
          required: true,
          message: '请填写公司名称',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请填写电话号码',
          trigger: 'blur'
        } // { pattern:/^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
        ],
        address: [{
          required: true,
          message: '请填写地址信息',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      if (val) {
        if (this.$parent.supplierTitle === '修改供应商') {
          supplierById({
            id: this.$parent.supplierId
          }).then(function (res) {
            if (res.code === 200) {
              res.data.category = res.data.category.replace(/,/gi, '\n');
              _this.supplierForm = {
                supplier_name: res.data.supplier_name,
                mobile: res.data.mobile,
                address: res.data.address,
                duty_name: res.data.duty_name,
                cooperate_time: res.data.cooperate_time,
                category: res.data.category,
                wechat_num: res.data.wechat_num
              };
              console.log(_this.supplierForm);
            }
          });
        }
      } else {
        this.$refs.supplierForm.resetFields();
      }
    }
  },
  methods: {
    supplierAdd: function supplierAdd(type) {
      var _this2 = this;

      if (type === 1) {
        this.$refs.supplierForm.validate(function (valid) {
          if (valid) {
            var params = JSON.parse(JSON.stringify(_this2.supplierForm));
            params.category = _this2.supplierForm.category.replace(/\n/gi, ',');

            if (_this2.$parent.supplierTitle === '新增供应商') {
              supplierSave(params).then(function (res) {
                if (res.code === 200) {
                  _this2.$emit('supplierAdd');

                  _this2.$message.success(res.message);
                }
              });
            } else {
              params.id = _this2.$parent.supplierId;
              supplierEdit(params).then(function (res) {
                if (res.code === 200) {
                  _this2.$emit('supplierAdd');

                  _this2.$message.success(res.message);
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('supplierAdd', 1);
      }
    }
  }
};