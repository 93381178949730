import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { randomSrt } from '@/utils'
import { orderInfoById } from '@/api/commerce';
import { GenerateDialog } from './components';
export default {
  name: 'split-detail',
  components: {
    GenerateDialog: GenerateDialog
  },
  data: function data() {
    return {
      labelBasis: [{
        label: '订单号:',
        value: 'order_no'
      }, {
        label: '第三方订单号:',
        value: 'thirdParty_order_on'
      }, {
        label: '订单名称:',
        value: 'order_name'
      }, {
        label: '客户:',
        value: 'service_name'
      }, {
        label: '店铺:',
        value: 'store_url'
      }, {
        label: '订单状态:',
        value: 'order_status_name'
      }, {
        label: '物流状态:',
        value: 'logistics_status_name'
      }, {
        label: '订单售卖金额:',
        value: 'sale_money'
      }, {
        label: '订单创建时间:',
        value: 'create_time'
      }, {
        label: '订单更新时间:',
        value: 'update_time'
      }],
      labelExpress: [{
        label: '物流单号:',
        value: 'logistics_no'
      }, {
        label: '物流公司:',
        value: 'logistics_company'
      }, {
        label: '收件人姓:',
        value: 'first_name'
      }, {
        label: '收件人名:',
        value: 'last_name'
      }, {
        label: '收件国家:',
        value: 'country'
      }, {
        label: '收件人所在城市:',
        value: 'city'
      }, {
        label: '收件人所在省/州:',
        value: 'province'
      }, {
        label: '收件邮编:',
        value: 'zip'
      }, {
        label: '收件人地址:',
        value: 'address1'
      }, {
        label: '联系电话:',
        value: 'mobile'
      }, {
        label: '收件人邮箱:',
        value: 'email'
      }],
      labelCommodity: [{
        label: '客户订单总金额:',
        value: 'service_order_all_money'
      }, {
        label: '客户订单商品金额:',
        value: 'service_order_goods_money'
      }, {
        label: '客户订单物流费用:',
        value: 'service_order_logistics_money'
      }, {
        label: '客户订单服务费用:',
        value: 'service_order_money'
      }, {
        label: '币种:',
        value: 'currency'
      }, {
        label: '结算折扣率:',
        value: 'rate'
      }],
      labelShop: [{
        label: '商品编码',
        value: 'goods_id',
        type: 'order_goods'
      }, {
        label: '中文商品名称',
        value: 'goods_name'
      }, {
        label: '英文商品名称',
        value: 'english_title'
      }, {
        label: 'SKU编码',
        value: 'sku'
      }, {
        label: 'SKU名称',
        value: 'sku_name'
      }, {
        label: 'SKU数量',
        value: 'sku_num'
      }, {
        label: '售卖金额',
        value: 'sale_money'
      }, {
        label: '服务费用',
        value: 'service_fee'
      }, {
        label: '状态',
        value: 'goods_type',
        type: 'goods_type'
      }],
      splitRules: {},
      splitOrderForm: {
        goods_info: []
      },
      orderFlag: false,
      dialogVisible: false,
      formData: {},
      selectTabData: [],
      // 单选多选数据存入
      myRoute: {},
      plat_form: ''
    };
  },
  activated: function activated() {
    this.fetchData();
  },
  created: function created() {
    this.fetchData();
    this.myRoute = this.$route;
    this.plat_form = this.$route.query.plat_form;
  },
  methods: {
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    fetchData: function fetchData() {
      var _this = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var sId = this.$route.query.id; // console.log(sId)

      orderInfoById({
        orders_sublist_id: sId,
        plat_form: this.plat_form
      }).then(function (res) {
        if (res.code === 200) {
          _this.splitOrderForm = res.data;
          loading.close();
        }
      }).finally(function () {
        loading.close();
      });
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.selectTabData = val;
    },
    // 商品参数跳转
    order_goods: function order_goods(goods_id) {
      this.$router.push({
        name: 'commodity-detail',
        params: {
          id: goods_id
        },
        query: {
          type: 'view'
        }
      });
    },
    // . 下一步生成订单数据
    generateOrder: function generateOrder(orderId) {
      if (this.selectTabData.length < 1) {
        this.$message.error('请选择至少1条数据!');
        return false;
      }

      orderId ? this.orderFlag = true : this.orderFlag = false;
      this.formData = this.selectTabData;
      this.dialogVisible = true;
    }
  }
};