import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getOrderList } from '@/api/bill';
import exportImport from '@/components/exportImport';
export default {
  name: 'preview-bill',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    exportImport: exportImport
  },
  data: function data() {
    return {
      previewForm: {
        settlement_status: '',
        order_name: '',
        create_start_time: '',
        create_end_time: ''
      },
      createDate: [],
      previewFormStatusList: [{
        value: 1,
        label: '已结算'
      }, {
        value: 2,
        label: '已退款'
      }, {
        value: 3,
        label: '已处理'
      }, {
        value: 4,
        label: '已取消'
      }],
      // 列表数据
      tableData: [],
      orderData: {},
      // 列表表头
      labelList: [{
        label: '订单名称',
        value: 'order_name'
      }, {
        label: '店铺',
        value: 'store_url'
      }, {
        label: '订单商品及数量',
        type: 'goodsSku',
        value: 'goods_sku'
      }, {
        label: '产品价格',
        value: 'product_total_amount'
      }, {
        label: '物流费用',
        value: 'logistics_cost'
      }, {
        label: '服务费用',
        value: 'service_fee'
      }, {
        label: '总价',
        value: 'total_amount'
      }, {
        label: '状态',
        type: 'status',
        value: 'settlement_status',
        width: '100'
      }],
      loading: false,
      // 分页
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      pageSize: [10, 30, 50, 100]
    };
  },
  watch: {
    createDate: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.previewForm.create_start_time = this.createDate[0];
          this.previewForm.create_end_time = this.createDate[1];
        } else {
          this.previewForm.create_start_time = '';
          this.previewForm.create_end_time = '';
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.orderData.bill_id = this.$route.query.bill_id;
    this.Inquire();
  },
  methods: {
    // 返回
    prev: function prev() {
      // 返回时关闭自己
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    // 筛选
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 重置
    resetClick: function resetClick() {
      this.previewForm = this.$options.data().previewForm;
      this.create_start = [];
      this.update_start = [];
      this.Inquire();
    },
    // 搜索
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      this.orderData = _objectSpread(_objectSpread({}, this.orderData), this.previewForm);
      var formData = JSON.parse(JSON.stringify(this.orderData));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      getOrderList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total);
            _this.loading = false;
          }, 300);
        }
      }).catch(function (e) {
        console.log(e);
      });
    },
    // 导出
    setExportData: function setExportData() {
      return {
        // excelTitle: {},
        excelData: this.tableData
      };
    },
    getValueOfLabel: function getValueOfLabel(num, sum) {
      var arr = sum.filter(function (e) {
        return e.value === num;
      });

      if (arr.length > 0) {
        return arr[0].label;
      } else {
        return num;
      }
    },
    // 逗号换行
    handleWarp: function handleWarp(content) {
      content = content.replace(/,/g, '</br>');
      return content;
    }
  }
};