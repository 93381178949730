import request from '@/utils/request';
import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 账单列表

export function orderBillDataList(data) {
  return request({
    url: '/order/bill/index',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取客户下的店铺

export function getStoreByService(data) {
  return request({
    url: '/order/bill/get-store-by-service',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取店铺订单，且没有结算过账单和退单的

export function getStoreOrder(data) {
  return request({
    url: '/order/bill/get-store-order',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 生成账单订单列表

export function orderBillGenerate(data) {
  return request({
    url: '/order/bill/generate',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 客户列表

export function orderServicerList(data) {
  return request({
    url: '/order/order/get-servicer-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取账单订单列表

export function getOrderList(data) {
  return request({
    url: '/order/bill/get-order-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 创建账单

export function orderBillCreate(data) {
  return request({
    url: '/order/bill/create',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 完成账单

export function orderBillFinish(data) {
  return request({
    url: '/order/bill/finish',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 撤回账单

export function orderBillCancel(data) {
  return request({
    url: '/order/bill/cancel',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 查看账单详情

export function getOrderBillDetail(data) {
  return request({
    url: '/order/bill/detail',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}