import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import { goodsOptions } from '@/api/commodity';
import { getGoodsDeposite } from '@/api/information';
import { LinkCommodityDialog } from './components';
import { orderStatus } from '@/api/commerce';
import { getSession } from '@/utils/session';
export default {
  name: 'hosting',
  components: {
    Pagination: Pagination,
    LinkCommodityDialog: LinkCommodityDialog // packageSearch: () => import('@/components/packageSearch/index')

  },
  filters: {
    ArrToStr: function ArrToStr(value) {
      if (!value) return '';

      if (Array.isArray(value)) {
        return value[0].toString();
      }

      return value.toString();
    }
  },

  /* computed: {
    // 展开过滤列表
    filterList() {
      return [
        { label: '客户名称', inputType: 'select', name: 'customer_name', children: this.serviceList },
        { label: '商品名称', inputType: 'input', name: 'product_title' },
        { label: '托管状态', inputType: 'select', name: 'manage_status', children: this.manageList },
        { label: '店铺链接', inputType: 'select', name: 'store_url', children: this.storeList }
      ]
    }
  },*/
  data: function data() {
    return {
      rules: {},
      loading: false,
      downloadLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      activeName: '0',
      formInline: {
        customer_name: '',
        product_title: '',
        manage_status: '',
        store_url: ''
      },
      create_at: [],
      update_at: [],
      labelList: [{
        label: '客户名称',
        value: 'customer_name',
        show: '0,1'
      }, {
        label: '商品图片',
        value: 'img_url',
        type: 'image',
        width: '150',
        show: '0,1'
      }, {
        label: '商品名称',
        value: 'product_title',
        align: 'left',
        width: '400',
        show: '0,1'
      }, {
        label: '店铺链接',
        value: 'shopify_url',
        show: '0,1'
      }, {
        label: '托管状态',
        value: 'manage_status',
        type: 'hosting_status',
        show: '0'
      }, {
        label: '关联商品',
        value: 'chinese_title',
        show: '0'
      }, {
        label: '关联商品编码',
        value: 'good_id',
        show: '0'
      }, {
        label: '关联商品SKU',
        value: 'map_sku',
        show: '0'
      }, {
        label: 'SKU图片',
        value: 'sku_url',
        type: 'link_sku_img',
        show: '0'
      }, {
        label: '操作',
        type: 'operation'
      }],
      commodityDialog: false,
      offerAPriceDialog: false,
      selectList: [],
      tableData: [],
      // tabList: ['公海列表', '私海列表'],
      tabList: ['托管池'],
      userList: [],
      dialogVisible: false,
      formData: {},
      hostFlag: false,
      serviceList: [],
      storeList: [],
      manageList: [{
        value: '1',
        label: '正常'
      }, {
        value: '2',
        label: '取消托管'
      }, {
        value: '3',
        label: '未匹配'
      }, {
        value: '4',
        label: '未匹配-有订单'
      }],
      platform: ''
    };
  },
  created: function created() {
    if (this.$route.query.status === 'dashboard') {
      this.$set(this.formInline, 'manage_status', '4');
      this.search();
      this.initData();
    } else {
      this.Inquire();
      this.initData();
    }
  },
  methods: {
    initData: function initData() {
      var _this = this;

      goodsOptions().then(function (res) {
        if (res.status === 200) {
          _this.$store.commit('goods/SET_OPTIONS', res.data);

          _this.selectList = res.data.product_attribute;
        }
      }).catch(function (err) {
        console.log(err);
      });
      this.serviceList = getSession('serviceList');
      orderStatus({
        plat_form: this.platform
      }).then(function (res) {
        if (res.code === 200) {
          _this.storeList = res.data.stores; // 展开过滤转换

          /* res.data.stores.map(item => {
            let o = {}
            o.value = item
            o.label = item
            this.storeList.push(o)
          })*/
        }
      });
    },
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 重置
    resetClick: function resetClick() {
      this.create_at = [];
      this.update_at = [];
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;

      var formData = _objectSpread({}, this.formInline);

      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      getGoodsDeposite(formData).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data;
          _this2.listQuery.total = +res.total;
          _this2.loading = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    actions: function actions(row) {
      // if (row.manage_status == '取消托管'){
      //   this.$message.error('取消托管的商品无法关联')
      //   return false
      // }
      console.log(row);
      row.sku_id ? this.hostFlag = true : this.hostFlag = false;
      this.formData = {
        sku_name: row.chinese_title,
        sku: row.map_sku,
        sku_id: row.sku_id,
        good_id: row.good_id,
        url: row.sku_url,
        sale_price: row.sale_price,
        service_fee: row.service_fee,
        old_sale_price: row.old_sale_price,
        old_service_fee: row.old_service_fee,
        sku_weight: row.sku_weight,
        product_attribute: row.product_attribute,
        customer_name: row.customer_name,
        store_url: row.shopify_url,
        s_id: row.s_id,
        id: row.id,
        product_id: row.product_id,
        shopify_sku_id: row.shopify_sku_id,
        customer_id: row.customer_id
      };
      this.dialogVisible = true;
    }
    /* // 展开搜索
    handleFilter(val) {
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
      })
      this.Inquire()
    },
    // 重置
    resetFilter() {
      this.formInline = this.$options.data().formInline
      this.Inquire()
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
      })
      this.Inquire()
    }*/

  }
};