var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar dark-bg" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("el-avatar", {
                    attrs: {
                      src:
                        _vm.avatar !== null
                          ? _vm.avatar
                          : "https://dx-tech-bucket.s3.amazonaws.com/20210713181701248c21f969b5f03d33d43e04f8f136e7682",
                      size: "small"
                    }
                  }),
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(_vm._s(_vm.username))
                  ]),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.personalSettings($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("个人设置")
                      ])
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("i", {
            staticClass: "el-icon-message-solid little-bell",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "message-center" })
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }