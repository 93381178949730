//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { companyEdit, companyAdmin } from '@/api/account';
export default {
  name: 'admin-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      formData: {
        admin_id: []
      },
      rules: {},
      userOptions: []
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      if (val) {
        companyEdit({
          id: this.$parent.adminList.id
        }).then(function (res) {
          if (res.code == 200) {
            _this.userOptions = res.data;
          }
        });
        this.formData.admin_id = this.$parent.adminList.admin_id;
      } else {
        this.$refs.formData.resetFields();
      }
    }
  },
  methods: {
    adminAdd: function adminAdd(type) {
      var _this2 = this;

      if (type == 1) {
        this.saveLoading = true;
        this.formData.id = this.$parent.adminList.id;
        companyAdmin(this.formData).then(function (res) {
          _this2.$emit('adminAdd');

          _this2.saveLoading = false;
        }).catch(function (err) {});
      } else {
        this.$emit('adminAdd', 1);
      }
    }
  }
};