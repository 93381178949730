import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { menuGetUpdate, menuStore } from '@/api/admin';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    treeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    selectList: {
      type: Object,
      default: function _default() {}
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      formData: {
        name: '',
        // 名称
        p_id: '',
        // 父级ID
        module_name: '',
        // 模块
        controller_name: '',
        // 控制器
        action_name: '',
        // 动作
        type: '',
        // 菜单类型
        method: '',
        // 请求方式
        sort: '',
        // 排序
        icon: '',
        // 图标
        status: '' // 是否显示

      },
      columnTrees: [],
      rules: {
        name: [{
          required: true,
          message: '请输入菜单名称',
          trigger: 'blur'
        }],
        p_id: [{
          required: true,
          message: '请选择菜单父级',
          trigger: 'change'
        }] // module_name:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // controller_name:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // action_name:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // type:[
        //   { required: true, message: '请选择菜单类型', trigger: 'change' },
        // ],
        // method:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // sort:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // icon:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],
        // status:[
        //   { required: true, message: '请输入活动名称', trigger: 'blur' },
        // ],

      },
      iconList: ['404', 'bug', 'clipboard', 'component', 'dashboard', 'documentation', 'drag', 'edit', 'education', 'email', 'example', 'excel', 'exit-fullscreen', 'form', 'fullscreen', 'guide', 'icon', 'international', 'link', 'list', 'lock', 'message', 'money', 'nested', 'password', 'pdf', 'people', 'peoples', 'search', 'shopping', 'size', 'skill', 'star', 'tab', 'table', 'theme', 'tree-table', 'tree', 'user', 'wechat', 'zip']
    };
  },
  computed: {},
  watch: {
    dialogVisible: function dialogVisible(val, oldVal) {
      var _this = this;

      // 打开  关闭
      if (val) {
        if (this.type == 'mdf') {
          menuGetUpdate({
            id: this.id
          }).then(function (res) {
            _this.formData = res.data;
          });
        } else {
          this.formData.p_id = String(this.pid);
          this.formData.type = Number(Object.keys(this.selectList.type)[0]);
          this.formData.method = Object.keys(this.selectList.method)[0];
          this.formData.status = Number(Object.keys(this.selectList.status)[0]);
        }
      } else {
        this.$refs.formData.resetFields();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;

    setTimeout(function () {
      _this2.tiledTree();
    }, 3000);
  },
  methods: {
    iconClick: function iconClick(e) {
      this.formData.icon = e;
    },
    close: function close(type) {
      var _this3 = this;

      if (type != 1) return this.$emit('close');
      this.$refs.formData.validate(function (valid) {
        if (valid) {
          console.log(_this3.formData);
          menuStore(_this3.formData).then(function (res) {
            _this3.$emit('close', 1);

            _this3.tiledTree();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 平铺tree数据
    tiledTree: function tiledTree() {
      var data = [];
      fn(this.treeList);
      this.columnTrees = data;

      function fn(treeList) {
        treeList.forEach(function (item) {
          data.push(item);

          if (item.next_menu != undefined) {
            fn(item.next_menu);
          }
        });
      }
    }
  }
};