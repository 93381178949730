var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分配人员",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.close
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("div", [_vm._v(_vm._s(_vm.dialogTitle))]),
              _c("el-tree", {
                ref: "tree",
                staticClass: "authority",
                attrs: {
                  data: _vm.treeList,
                  "show-checkbox": "",
                  "render-after-expand": "",
                  accordion: "",
                  "node-key": "id",
                  "auto-expand-parent": false,
                  "highlight-current": "",
                  props: _vm.defaultProps
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: {
                    click: function($event) {
                      return _vm.close(1)
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }