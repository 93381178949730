var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("input", {
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          id: "imFile",
          accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        },
        on: {
          change: function($event) {
            return _vm.importFile(this)
          }
        }
      }),
      _c("a", { attrs: { id: "downlink" } }),
      _c(
        "span",
        { on: { click: _vm.downloadFile } },
        [
          _vm._t("export", [
            _c("el-button", { staticClass: "button" }, [_vm._v("导出")])
          ])
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", size: "tiny" },
          model: {
            value: _vm.errorDialog,
            callback: function($$v) {
              _vm.errorDialog = $$v
            },
            expression: "errorDialog"
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.errorMsg))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.errorDialog = false
                    }
                  }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }