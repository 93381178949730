import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { productSku } from '@/api/commodity';
import Pagination from '@/components/Pagination';
export default {
  name: 'select-commodity-dialog',
  components: {
    Pagination: Pagination
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择商品SKU'
    },
    flag: {
      type: String,
      default: 'view'
    },
    formData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      formInline: {
        sku: '',
        sku_name: ''
      },
      tableData: [],
      tableSelected: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      loading: false
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  watch: {
    flag: {
      handler: function handler(type) {
        if (type === 'edit') {
          this.isDisabled = false;
          this.showClose = true;
        } else {
          this.isDisabled = true;
          this.showClose = false;
        }
      },
      immediate: true
    },
    formData: {
      handler: function handler(data) {
        this.form = data;
      },
      deep: true
    }
  },
  mounted: function mounted() {// this.Inquire()
  },
  methods: {
    handleOpen: function handleOpen() {
      this.Inquire();
    },
    onSearch: function onSearch() {
      this.Inquire();
    },
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;

      var formData = _objectSpread({}, this.formInline);

      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      productSku(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this.loading = false;
            _this.tableData = res.data;
            _this.listQuery.total = +res.total.totalCount;
          }, 300);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    clickChange: function clickChange(item) {
      this.tableSelected = item;
    },
    dblclickChange: function dblclickChange(row, column, event) {
      var data = Object.assign({}, row);
      this.$emit('close', data);
      this.dialogVisible = false;
    },
    submit: function submit() {
      var data = Object.assign({}, this.tableSelected);
      this.$emit('close', data);
      this.dialogVisible = false;
    },
    handleClosed: function handleClosed() {
      // this.$refs['formInline'].resetFields()
      this.formInline = {
        sku: '',
        sku_name: ''
      };
      this.dialogVisible = false;
    }
  }
};