var state = {
  options: {}
};
var mutations = {
  SET_OPTIONS: function SET_OPTIONS(state, data) {
    state.options = data;
  }
};
var actions = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};