//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { login } from '@/api/user'
// import { validUsername } from '@/utils/validate'
export default {
  name: 'login',
  data: function data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入手机号码'))
    //   } else {
    //     callback()
    //   }
    // }
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else if (value.length < 6) {
        callback(new Error('密码不少于6位'));
      } else {
        callback();
      }
    };

    return {
      activeName: '1',
      LoginForm: {
        phone: '',
        password: '',
        id: 1
      },
      loginRules: {
        phone: [{
          required: true,
          message: '请输入11位手机号码',
          trigger: 'blur'
        }, {
          pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
          message: '请输入合法手机号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      loading: false
    };
  },
  methods: {
    handleLogin: function handleLogin() {
      var _this = this;

      this.$refs.LoginForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;

          _this.$store.dispatch('user/login', _this.LoginForm).then(function () {
            _this.$router.push({
              name: 'Dashboard'
            });

            _this.loading = false;

            _this.$message.success('登录成功');
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 注册用户
    registered: function registered() {
      this.$router.push({
        path: '/registered'
      });
    }
  }
};