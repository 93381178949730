var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card pt20" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.channelClick },
          model: {
            value: _vm.channelName,
            callback: function($$v) {
              _vm.channelName = $$v
            },
            expression: "channelName"
          }
        },
        _vm._l(_vm.platform, function(item, key) {
          return _c(
            "el-tab-pane",
            { key: key, attrs: { label: item.platform_title, name: item.id } },
            [
              _c(
                "el-tabs",
                {
                  on: {
                    "tab-click": function($event) {
                      return _vm.handleClick(_vm.channelName)
                    }
                  },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(item.platform_channel_title, function(tab, idxs) {
                  return _c("el-tab-pane", {
                    key: idxs,
                    attrs: { label: tab.channel_name, name: tab.cid }
                  })
                }),
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName !== "5",
                  expression: "activeName !== '5'"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "tableData",
              staticClass: "tableTd",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                fit: "",
                border: "",
                "header-cell-style": { background: "#5ba78e", color: "#fff" },
                "span-method": _vm.objectSpanMethods
              }
            },
            _vm._l(_vm.labelList, function(item, idx) {
              return _c("el-table-column", {
                key: idx,
                attrs: {
                  label: item.label,
                  prop: item.value,
                  width: item.width
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          item.type === undefined
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row[item.value]))
                              ])
                            : _vm._e(),
                          item.type === "aging_time" && scope.row.aging_time
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(scope.row.aging_time + "个工作日") +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          item.type === "special_time" && scope.row.special_time
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(scope.row.special_time + "个工作日") +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          !scope.row[item.value]
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("/")
                              ])
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeName === "5",
                  expression: "activeName === '5'"
                },
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "tableData",
              staticClass: "tableTd",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                stripe: "",
                "highlight-current-row": "",
                fit: "",
                border: "",
                "header-cell-style": { background: "#5ba78e", color: "#fff" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "attach", label: "分区", width: "130" }
              }),
              _vm._l(_vm.countryList, function(count, index) {
                return _c(
                  "el-table-column",
                  { key: index, attrs: { label: index + 1 + "区" } },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: count.value,
                        label: count.label,
                        "render-header": _vm.renderheader
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }