var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "billDetail" },
    [
      _c("div", { staticClass: "box-card" }, [
        _vm.tagTitle === "preview"
          ? _c(
              "div",
              { staticClass: "create-btn p20" },
              [
                _c("el-button", {
                  attrs: { size: "small", icon: "el-icon-back" },
                  on: { click: _vm.prev }
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "throttle",
                        rawName: "v-throttle",
                        value: [_vm.previewBill],
                        expression: "[previewBill]"
                      }
                    ],
                    attrs: { type: "primary", size: "small" }
                  },
                  [_vm._v("Preview bill")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.tagTitle !== "preview"
          ? _c(
              "div",
              { staticClass: "btn-box p20" },
              [
                _c(
                  "div",
                  [
                    _c("el-button", {
                      staticClass: "mr30",
                      attrs: { size: "small", icon: "el-icon-back" },
                      on: { click: _vm.prev }
                    }),
                    _c("span", { staticClass: "mr30" }, [
                      _vm._v(_vm._s("【" + _vm.billDetailForm.bill_name + "】"))
                    ]),
                    _c("span", { staticClass: "mr30" }, [
                      _vm._v(_vm._s(_vm.billDetailForm.bill_no))
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "w-200 sec",
                        attrs: {
                          size: "small",
                          clearable: "",
                          filterable: "",
                          "default-first-option": "",
                          disabled: _vm.billDetailForm.bill_status === "2",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.billDetailForm.bill_status,
                          callback: function($$v) {
                            _vm.$set(_vm.billDetailForm, "bill_status", $$v)
                          },
                          expression: "billDetailForm.bill_status"
                        }
                      },
                      _vm._l(_vm.billStatusList, function(item, key) {
                        return _c("el-option", {
                          key: key,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                !_vm.choose
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "throttle",
                            rawName: "v-throttle",
                            value: [_vm.withdrawBill],
                            expression: "[withdrawBill]"
                          }
                        ],
                        attrs: { type: "primary", size: "small" }
                      },
                      [_vm._v("撤回账单")]
                    )
                  : _vm._e(),
                _vm.choose
                  ? _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "throttle",
                            rawName: "v-throttle",
                            value: [_vm.changeBill],
                            expression: "[changeBill]"
                          }
                        ],
                        attrs: { type: "primary", size: "small" }
                      },
                      [_vm._v("完成账单")]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.billDetailForm,
            "label-width": "100px",
            inline: "",
            "label-position": "left"
          }
        },
        [
          _c("div", { staticClass: "box-card create-box ml20 mr20 mb20" }, [
            _c("div", { staticClass: "form-data-box" }, [
              _c(
                "div",
                { staticClass: "left" },
                [
                  _c(
                    "div",
                    { staticClass: "upload-logo" },
                    [
                      _c("el-image", {
                        staticStyle: { height: "200px", width: "250px" },
                        attrs: { src: _vm.billDetailForm.enterprise_logo }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "enterprise_info" } },
                    [
                      _c("div", { staticClass: "mv-calc" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.enterprise_info))
                      ])
                    ]
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Bill To", prop: "bill_to" } },
                            [
                              _c("div", { staticClass: "bill-calc" }, [
                                _vm._v(_vm._s(_vm.billDetailForm.bill_to))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ship To", prop: "ship_to" } },
                            [
                              _c("div", { staticClass: "bill-calc" }, [
                                _vm._v(_vm._s(_vm.billDetailForm.ship_to))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: " ", prop: "bill_info" } },
                    [
                      _c("span", { staticClass: "w-400 big" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.bill_info))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "#", prop: "bill_name" } },
                    [
                      _c("span", { staticClass: "w-400" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.bill_name))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Date", prop: "date" } },
                    [
                      _c("span", { staticClass: "w-400" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.date))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Due Date", prop: "due_date" } },
                    [
                      _c("span", { staticClass: "w-400" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.due_date))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Balance Due", prop: "balance_due" } },
                    [
                      _c("span", { staticClass: "w-400" }, [
                        _vm._v("US$" + _vm._s(_vm.billDetailForm.balance_due))
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "box-card create-box ml20 mr20 mb20" },
            [
              _c(
                "el-table",
                {
                  staticClass: "material-table",
                  attrs: { data: _vm.billDetailForm.order_list }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "ltem", label: "ltem", "min-width": "320" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "quantity", label: "Quantity", width: "260" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "rate", label: "Rate", width: "260" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "amount", label: "Amount", width: "260" }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "mt20",
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-view"
                  },
                  on: { click: _vm.iViews }
                },
                [_vm._v("Details")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "box-card create-box ml20 mr20 mb20" }, [
            _c("div", { staticClass: "notes-form" }, [
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Notes", prop: "notes" } },
                    [
                      _c("div", { staticClass: "notesTerms" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.notes))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Terms", prop: "terms" } },
                    [
                      _c("div", { staticClass: "notesTerms" }, [
                        _vm._v(_vm._s(_vm.billDetailForm.terms))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "product-details" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Subtotal", prop: "subtotal" } },
                    [
                      _c("span", [
                        _vm._v("US$" + _vm._s(_vm.billDetailForm.subtotal))
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Discount(%)", prop: "discount" } },
                    [_c("span", [_vm._v(_vm._s(_vm.billDetailForm.discount))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tax(%)", prop: "tax" } },
                    [_c("span", [_vm._v(_vm._s(_vm.billDetailForm.tax))])]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Total", prop: "total" } },
                    [
                      _c("span", [
                        _vm._v("US$" + _vm._s(_vm.billDetailForm.total))
                      ])
                    ]
                  ),
                  _vm.billDetailForm.amount_paid !== 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: "Amount Paid", prop: "amount_paid" }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "US$" + _vm._s(_vm.billDetailForm.amount_paid)
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm.billDetailForm.refund_price !== 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Refunded", prop: "refund_price" } },
                        [
                          _c("span", [
                            _vm._v(
                              "US$" + _vm._s(_vm.billDetailForm.refund_price)
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }