var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "commodity-dialog",
      attrs: {
        visible: _vm.dialogVisible,
        width: "600px",
        title: _vm.title,
        "append-to-body": true,
        "modal-append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClosed
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "linkForm",
          attrs: {
            model: _vm.linkForm,
            rules: _vm.rules,
            size: "small",
            "label-width": "85px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "商品SKU", prop: "sku_name" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-normal",
                  attrs: { disabled: "", placeholder: "选择商品SKU" },
                  model: {
                    value: _vm.linkForm.sku_name,
                    callback: function($$v) {
                      _vm.$set(_vm.linkForm, "sku_name", $$v)
                    },
                    expression: "linkForm.sku_name"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append", type: "primary", size: "small" },
                      on: { click: _vm.selectProduct },
                      slot: "append"
                    },
                    [_vm._v("选择")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "SKU图片" } },
            [
              _c(
                "el-image",
                { staticClass: "sku_image", attrs: { src: _vm.linkForm.url } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "image-slot",
                      attrs: { slot: "error" },
                      slot: "error"
                    },
                    [
                      _c("i", {
                        staticClass: "error-icon el-icon-picture-outline"
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "fixing-box",
              attrs: { label: "售卖价格", prop: "sale_price" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "售卖价格" },
                model: {
                  value: _vm.linkForm.sale_price,
                  callback: function($$v) {
                    _vm.$set(_vm.linkForm, "sale_price", $$v)
                  },
                  expression: "linkForm.sale_price"
                }
              }),
              _c("span", { staticClass: "before-fixing" }, [
                _vm._v(_vm._s(_vm.linkForm.old_sale_price))
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "重量(G)", prop: "sku_weight" } },
            [
              _c("el-input", {
                attrs: { placeholder: "重量" },
                model: {
                  value: _vm.linkForm.sku_weight,
                  callback: function($$v) {
                    _vm.$set(_vm.linkForm, "sku_weight", $$v)
                  },
                  expression: "linkForm.sku_weight"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "fixing-box",
              attrs: { label: "服务费", prop: "service_fee" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "服务费" },
                model: {
                  value: _vm.linkForm.service_fee,
                  callback: function($$v) {
                    _vm.$set(_vm.linkForm, "service_fee", $$v)
                  },
                  expression: "linkForm.service_fee"
                }
              }),
              _c("span", { staticClass: "before-fixing" }, [
                _vm._v(_vm._s(_vm.linkForm.old_service_fee))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.handleClosed()
                }
              }
            },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "throttle",
                  rawName: "v-throttle",
                  value: [
                    function() {
                      return _vm.submit("linkForm")
                    }
                  ],
                  expression: "[() => submit('linkForm')]"
                }
              ],
              attrs: {
                size: "mini",
                loading: _vm.submitLoading,
                type: "primary"
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("select-commodity-dialog", {
        attrs: { visible: _vm.selectCommodity },
        on: {
          "update:visible": function($event) {
            _vm.selectCommodity = $event
          },
          close: _vm.receiveData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }