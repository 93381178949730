import _createForOfIteratorHelper from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { afterSaleDataList, afterSaleStatus, afterSaleOpertation, afterSaleUpdateStatus, afterSaleRefund, afterSaleReissue, afterSaleType } from '@/api/after';
import { getSession } from '@/utils/session';
import { orderStatus } from '@/api/commerce';
export default {
  name: 'after-index',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    } // DialogImport: () => import('@/components/dialogImport'),
    // packageSearch: () => import('@/components/packageSearch/index')

  },

  /* computed: {
    // 展开过滤列表
    filterList() {
      return [
        { label: '订单号', inputType: 'input', name: 'order_no' },
        { label: '第三方订单号', inputType: 'input', name: 'third_order_no' },
        { label: '售后状态', inputType: 'select', name: 'status', children: this.selectList.status },
        { label: '售后操作', inputType: 'select', name: 'after_opertation', children: this.selectList.after_opertation },
        { label: '订单名称', inputType: 'input', name: 'order_name' }
      ]
    }
  },*/
  data: function data() {
    return {
      loading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      labelList: [{
        label: '订单号',
        value: 'order_no',
        type: 'order_no'
      }, {
        label: '第三方订单号',
        value: 'third_order_no'
      }, {
        label: '订单名称',
        value: 'order_name',
        width: '80'
      }, {
        label: '客户',
        value: 'customer_name'
      }, {
        label: '店铺',
        value: 'store_url'
      }, {
        label: '订单商品及数量',
        type: 'product_json'
      }, {
        label: '物流信息',
        type: 'shipping_json'
      }, {
        label: '收件信息',
        type: 'receive_json'
      }, {
        label: '售后类型',
        value: 'after_model',
        width: '80',
        type: 'after_model'
      }, {
        label: '订单创建时间',
        value: 'order_create'
      }, {
        label: '申请售后时间',
        value: 'after_create_time'
      }, {
        label: '售后更新时间',
        value: 'update_time'
      }, {
        label: '售后状态',
        type: 'status',
        width: '80'
      }, // { label: '售后操作', type: 'after_operation'  },
      {
        label: '操作',
        type: 'operation',
        width: '80'
      }],
      searchForm: {
        order_no: '',
        third_order_no: '',
        status: '',
        after_opertation: '',
        order_name: ''
      },
      statusloading: false,
      opertationloading: false,
      selectList: {
        status: [],
        after_opertation: [],
        serviceList: [],
        stores: [],
        afterModelList: []
      },
      selectTabData: [],
      // 单选多选数据存入
      tableData: [],
      orderCount: 0,
      pendingTotal: 0,
      processTotal: 0,
      finishTotal: 0,
      waitingTotal: 0,
      saleMode: [{
        value: '1',
        label: '补发'
      }, {
        value: '2',
        label: '退款'
      }, {
        value: '3',
        label: '退货/退款'
      }, {
        value: '4',
        label: '其他'
      }]
    };
  },
  mounted: function mounted() {
    this.selectList.serviceList = getSession('serviceList');

    if (this.$route.query.status) {
      this.searchForm.status = this.$route.query.status;
    }

    this.loading = true;
    this.statusloading = true;
    this.opertationloading = true;
    this.init();
    this.Inquire();
  },
  activated: function activated() {// this.Inquire()
    // this.statusloading = true
    // this.opertationloading = true
  },
  methods: {
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 重置
    resetClick: function resetClick() {
      this.searchForm = this.$options.data().searchForm;
      this.Inquire();
    },
    complete: function complete() {
      var _this = this;

      var ids = [];

      var _iterator = _createForOfIteratorHelper(this.selectTabData),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          ids.push(item.id);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      afterSaleUpdateStatus({
        id: ids
      }).then(function (res) {
        if (res.code === 200) {
          _this.$message.success(res.message);

          _this.Inquire();
        }
      }).catch(function (e) {
        console.log(e);
      });
    },
    init: function init() {
      var _this2 = this;

      // 获取售后订单状态所有选项
      afterSaleStatus().then(function (res) {
        if (res.code === 200) {
          for (var i in res.data) {
            var oneStatus = {
              'value': parseInt(i),
              'label': res.data[i]
            };

            _this2.selectList.status.push(oneStatus);
          }

          _this2.statusloading = false;
        }
      }); // 获取售后订单操作所有选项

      afterSaleOpertation().then(function (res) {
        if (res.code === 200) {
          for (var i in res.data) {
            var oneOpertation = {
              'value': parseInt(i),
              'label': res.data[i]
            };

            _this2.selectList.after_opertation.push(oneOpertation);
          }

          _this2.opertationloading = false;
        }
      });
      orderStatus().then(function (res) {
        if (res.code === 200) {
          _this2.selectList.stores = res.data.stores;
        }
      });
      afterSaleType().then(function (res) {
        if (res.code === 200) {
          _this2.selectList.afterModelList = res.data.model;
        }
      });
    },
    // 查询
    Inquire: function Inquire() {
      var _this3 = this;

      var formData = JSON.parse(JSON.stringify(this.searchForm));

      if (formData.status === '') {
        formData.status = 0;
      }

      if (formData.after_opertation === '') {
        formData.after_opertation = 0;
      } // console.log(formData)


      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      afterSaleDataList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this3.tableData = res.data;
            _this3.listQuery.total = +res.total.totalCount;
            _this3.orderCount = res.total.allTotal;
            _this3.finishTotal = res.total.finishTotal;
            _this3.pendingTotal = res.total.pendingTotal;
            _this3.processTotal = res.total.processTotal;
            _this3.waitingTotal = res.total.waitingTotal;
            _this3.loading = false;
          }, 300);
        }
      });
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.selectTabData = val;
    },
    // 操作弹窗
    operation: function operation(item, type) {
      var _this4 = this;

      var typeTitle;
      var typeHead;

      if (type === 'refund') {
        typeTitle = '确认对订单id:' + item.order_no + ',所属客户为' + item.customer_name + '的订单,进行退款操作,确认操作后会同步运单状态置为取消,且结算单也置为退款';
        typeHead = '退款';
      } else {
        typeTitle = '确认补发订单id:' + item.order_no + ',所属客户为' + item.customer_name + '的订单,进行退款操作,确认操作后会重新生成一条运单,如历史生成运单状态会置为已取消';
        typeHead = '补发';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (type === 'refund') {
          afterSaleRefund({
            third_order_no: item.third_order_no
          }).then(function (res) {
            if (res.code === 200) {
              _this4.$message.success(res.message);

              _this4.Inquire();
            }
          });
        } else {
          afterSaleReissue({
            third_order_no: item.third_order_no
          }).then(function (res) {
            if (res.code === 200) {
              _this4.$message.success(res.message);

              _this4.Inquire();
            }
          });
        }
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消设置'
        });
      });
    },
    // .  查看售后详情
    afterClick: function afterClick(row) {
      this.$router.push({
        name: 'after-sale-edit',
        query: {
          id: row.id,
          order_name: row.order_name,
          service_name: row.customer_name
        }
      });
    },

    /* // 展开搜索
    handleFilter(val) {
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.searchForm).find( e => e === item.name)
        if (item.name === filterItem) {
          this.searchForm[filterItem] = item.value
        }
      })
      this.Inquire()
    },
    // 重置
    resetFilter() {
      this.searchForm = this.$options.data().searchForm
      this.Inquire()
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.searchForm).find( e => e === item.name)
        if (item.name === filterItem) {
          this.searchForm[filterItem] = item.value
        }
      })
      this.Inquire()
    }*/
    getValueOfLabel: function getValueOfLabel(num, sum) {
      var obj = sum.find(function (it) {
        return it.value === num;
      });

      if (obj) {
        return obj.label;
      } else {
        return num;
      }
    },
    handleCount: function handleCount() {
      this.searchForm.status = '';
      this.search();
    },
    handlePending: function handlePending() {
      this.searchForm.status = 1;
      this.search();
    },
    handleProcess: function handleProcess() {
      this.searchForm.status = 2;
      this.search();
    },
    handleWaitForCompletion: function handleWaitForCompletion() {
      this.searchForm.status = 4;
      this.search();
    },
    handleFinish: function handleFinish() {
      this.searchForm.status = 3;
      this.search();
    }
  }
};