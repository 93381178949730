var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderdDetails" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "orderTitle" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-arrow-left" },
                  on: { click: _vm.prev }
                },
                [_vm._v("返回")]
              ),
              _c("span", { staticClass: "title" }, [
                _vm._v("[ " + _vm._s(this.$route.query.service_name) + " ]")
              ]),
              _c("span", { staticClass: "orderId" }, [
                _vm._v(_vm._s(this.$route.query.order_no))
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "throttle",
                      rawName: "v-throttle",
                      value: [_vm.submitOrder],
                      expression: "[submitOrder]"
                    }
                  ],
                  staticStyle: { float: "right" },
                  attrs: { type: "primary", size: "small" }
                },
                [_vm._v("预览订单")]
              )
            ],
            1
          ),
          _c(
            "el-form",
            { attrs: { inline: "", "label-width": "150px" } },
            [
              _c(
                "el-card",
                { staticClass: "box-card mt5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix step-jump",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("基本信息")])]
                  ),
                  _vm._l(_vm.labelBasis, function(val, idx) {
                    return _c(
                      "el-form-item",
                      {
                        key: idx,
                        attrs: { label: val.label, prop: val.value }
                      },
                      [
                        val.value === "order_no"
                          ? _c(
                              "div",
                              { staticClass: "w-300" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "10px" } },
                                  [_vm._v(_vm._s(_vm.newOrderNo))]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: { effect: "dark", placement: "top" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v("被合并的订单信息:")
                                        ]),
                                        _vm._l(
                                          _vm.orderForm[val.value],
                                          function(info, index) {
                                            return _c("div", { key: index }, [
                                              _c("div", [_vm._v(_vm._s(info))])
                                            ])
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "info",
                                          size: "mini",
                                          round: ""
                                        }
                                      },
                                      [_vm._v("显示更多")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "w-300" },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        (_vm.orderForm[val.value] || [])[0]
                                      )
                                    )
                                  ]
                                ),
                                val.value === "thirdParty_order_on" ||
                                val.value === "order_name"
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content"
                                          },
                                          [
                                            _c("div", [
                                              _vm._v("被合并的订单信息:")
                                            ]),
                                            _vm._l(
                                              _vm.orderForm[val.value],
                                              function(info, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _c("div", [
                                                      _vm._v(_vm._s(info))
                                                    ])
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "info",
                                              size: "mini",
                                              round: ""
                                            }
                                          },
                                          [_vm._v("显示更多")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "el-card",
                { staticClass: "box-card mt5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix step-jump",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("快递信息")])]
                  ),
                  _vm._l(_vm.labelExpress, function(val) {
                    return _c(
                      "el-form-item",
                      {
                        key: val.value,
                        attrs: { label: val.label, prop: val.value }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "allow-create": "",
                              "default-first-option": ""
                            },
                            model: {
                              value: _vm.expressInfo[val.value],
                              callback: function($$v) {
                                _vm.$set(_vm.expressInfo, val.value, $$v)
                              },
                              expression: "expressInfo[val.value]"
                            }
                          },
                          _vm._l(_vm.orderForm[val.value], function(
                            item,
                            index
                          ) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item, value: item }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "el-card",
                { staticClass: "box-card mt5" },
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.orderForm.goods_info,
                        border: "",
                        "header-cell-style": {
                          background: "#e9f1ff",
                          color: "#606266"
                        }
                      }
                    },
                    _vm._l(_vm.labelShop, function(item, key) {
                      return _c("el-table-column", {
                        key: key,
                        attrs: { label: item.label, prop: item.value },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " + _vm._s(scope.row[item.value]) + " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }