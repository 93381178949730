//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { servicerBalance } from "@/api/information";
export default {
  name: "recharge-add",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      rechargeForm: {
        money: "",
        mark: '',
        channe: ""
      },
      selectList: {},
      rules: {
        money: [{
          required: true,
          message: "请填写充值金额",
          trigger: "blur"
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {
        console.log(val);
      } else {
        this.$refs.rechargeForm.resetFields();
      }
    }
  },
  methods: {
    rechargeAdd: function rechargeAdd(type) {
      var _this = this;

      if (type === 1) {
        this.$refs.rechargeForm.validate(function (valid) {
          if (valid) {
            servicerBalance({
              id: _this.$parent.rechargeId,
              money: _this.rechargeForm.money,
              mark: _this.rechargeForm.mark,
              channe: _this.rechargeForm.channe
            }).then(function (res) {
              if (res.code === 200) {
                _this.$emit("rechargeAdd");

                _this.$message.success(res.message);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } else {
        this.$emit("rechargeAdd", 1);
      }
    }
  }
};