var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderSystem" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                inline: true,
                model: _vm.searchForm,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号:", prop: "order_no" } },
                [
                  _c("el-input", {
                    staticClass: "w-180",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "订单号"
                    },
                    model: {
                      value: _vm.searchForm.order_no,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "order_no", $$v)
                      },
                      expression: "searchForm.order_no"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单名称:", prop: "order_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-180",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "订单号"
                    },
                    model: {
                      value: _vm.searchForm.order_name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "order_name", $$v)
                      },
                      expression: "searchForm.order_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "第三方订单号:", prop: "thirdParty_order_on" }
                },
                [
                  _c("el-input", {
                    staticClass: "w-180",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "请输入第三方订单号"
                    },
                    model: {
                      value: _vm.searchForm.third_order_no,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "third_order_no", $$v)
                      },
                      expression: "searchForm.third_order_no"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售后类型:", prop: "after_model" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-180",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        loading: _vm.opertationloading,
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.searchForm.after_model,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "after_model", $$v)
                        },
                        expression: "searchForm.after_model"
                      }
                    },
                    _vm._l(_vm.selectList.afterModelList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售后状态:", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-180",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        loading: _vm.statusloading,
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.searchForm.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "status", $$v)
                        },
                        expression: "searchForm.status"
                      }
                    },
                    _vm._l(_vm.selectList.status, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户:", prop: "service_name" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-180",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        loading: _vm.opertationloading,
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.searchForm.customer_name,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "customer_name", $$v)
                        },
                        expression: "searchForm.customer_name"
                      }
                    },
                    _vm._l(_vm.selectList.serviceList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: item.service_name,
                          value: String(item.service_name)
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺:", prop: "store_url" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-180",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        loading: _vm.opertationloading,
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.searchForm.store_url,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "store_url", $$v)
                        },
                        expression: "searchForm.store_url"
                      }
                    },
                    _vm._l(_vm.selectList.stores, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: String(item) }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v("筛 选")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.resetClick }
                    },
                    [_vm._v("重 置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "throttle",
              rawName: "v-throttle",
              value: [_vm.complete],
              expression: "[complete]"
            }
          ],
          staticClass: "m10 ml30",
          attrs: { type: "primary", size: "small" }
        },
        [_vm._v(" 完成售后 "), _c("i", { staticClass: "el-icon-check" })]
      ),
      _c(
        "el-card",
        { ref: "tableInfo", staticClass: "box-card" },
        [
          _c("div", { staticClass: "top-bar" }, [
            _c("span", [
              _vm._v("总售后订单："),
              _c(
                "span",
                {
                  staticStyle: { margin: "10px", cursor: "pointer" },
                  on: { click: _vm.handleCount }
                },
                [_vm._v(_vm._s(_vm.orderCount))]
              )
            ]),
            _c("span", [
              _vm._v("未处理："),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "10px",
                    color: "#d24848",
                    cursor: "pointer"
                  },
                  on: { click: _vm.handlePending }
                },
                [_vm._v(_vm._s(_vm.pendingTotal))]
              )
            ]),
            _c("span", [
              _vm._v("处理中："),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "10px",
                    color: "#df9e3d",
                    cursor: "pointer"
                  },
                  on: { click: _vm.handleProcess }
                },
                [_vm._v(_vm._s(_vm.processTotal))]
              )
            ]),
            _c("span", [
              _vm._v("等待完成："),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "10px",
                    color: "#3383de",
                    cursor: "pointer"
                  },
                  on: { click: _vm.handleWaitForCompletion }
                },
                [_vm._v(_vm._s(_vm.waitingTotal))]
              )
            ]),
            _c("span", [
              _vm._v("已完成："),
              _c(
                "span",
                {
                  staticStyle: {
                    margin: "10px",
                    color: "#129a49",
                    cursor: "pointer"
                  },
                  on: { click: _vm.handleFinish }
                },
                [_vm._v(_vm._s(_vm.finishTotal))]
              )
            ])
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                fit: "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              },
              on: {
                select: _vm.handleSelectionChange,
                "select-all": _vm.handleSelectionChange
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm._l(_vm.labelList, function(item, idx) {
                return _c("el-table-column", {
                  key: idx,
                  attrs: {
                    width: item.width,
                    label: item.label,
                    prop: item.value
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            item.type === "order_no"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "c_blue cursor_p",
                                    on: {
                                      click: function($event) {
                                        return _vm.afterClick(scope.row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row[item.value]) + " "
                                    ),
                                    scope.row.is_read === 0
                                      ? _c("span", {
                                          staticClass: "order_no_dots_class"
                                        })
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            item.type === undefined
                              ? _c(
                                  "span",
                                  {
                                    domProps: {
                                      innerHTML: _vm._s(scope.row[item.value])
                                    }
                                  },
                                  [_vm._v(_vm._s(scope.row[item.value]))]
                                )
                              : _vm._e(),
                            item.type === "product_json"
                              ? _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _c(
                                      "el-tooltip",
                                      { attrs: { placement: "top" } },
                                      [
                                        _vm._l(scope.row.product_json, function(
                                          it,
                                          idx
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: idx,
                                              attrs: { slot: "content" },
                                              slot: "content"
                                            },
                                            [
                                              _c("p", [
                                                _c(
                                                  "label",
                                                  { staticClass: "mr5" },
                                                  [_vm._v("产品SKU: ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(_vm._s(it.sku_name))
                                                ]),
                                                _c(
                                                  "label",
                                                  { staticClass: "mr5" },
                                                  [_vm._v("；产品数量: ")]
                                                ),
                                                _c("span", [
                                                  _vm._v(_vm._s(it.sku_num))
                                                ])
                                              ])
                                            ]
                                          )
                                        }),
                                        _c("div", [
                                          _c("p", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("产品SKU: ")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.product_json[0]
                                                    .sku_name
                                                )
                                              )
                                            ])
                                          ]),
                                          _c("p", [
                                            _c(
                                              "label",
                                              { staticClass: "mr5" },
                                              [_vm._v("产品数量: ")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.product_json[0]
                                                    .sku_num
                                                )
                                              )
                                            ])
                                          ])
                                        ])
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.type === "receive_json"
                              ? _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("姓名:")
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          scope.row.receive_json.first_name +
                                            " " +
                                            scope.row.receive_json.last_name
                                        )
                                      )
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("联系电话:")
                                      ]),
                                      _vm._v(
                                        _vm._s(scope.row.receive_json.mobile)
                                      )
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("国家:")
                                      ]),
                                      _vm._v(
                                        _vm._s(scope.row.receive_json.country)
                                      )
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("地址:")
                                      ]),
                                      _vm._v(
                                        _vm._s(scope.row.receive_json.address)
                                      )
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("邮箱:")
                                      ]),
                                      _vm._v(
                                        _vm._s(scope.row.receive_json.email)
                                      )
                                    ]),
                                    _c("br")
                                  ]
                                )
                              : _vm._e(),
                            item.type === "shipping_json"
                              ? _c(
                                  "div",
                                  { staticStyle: { "text-align": "left" } },
                                  [
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("公司:")
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          scope.row.shipping_json
                                            ? scope.row.shipping_json
                                                .logistics_company
                                            : "--"
                                        )
                                      )
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("单号:")
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          scope.row.shipping_json
                                            ? scope.row.shipping_json
                                                .logistics_no
                                            : "--"
                                        )
                                      )
                                    ]),
                                    _c("br"),
                                    _c("span", [
                                      _c("label", { staticClass: "mr5" }, [
                                        _vm._v("状态:")
                                      ]),
                                      _vm._v(
                                        _vm._s(
                                          scope.row.shipping_json
                                            ? scope.row.shipping_json
                                                .logistics_status
                                            : "--"
                                        )
                                      )
                                    ]),
                                    _c("br")
                                  ]
                                )
                              : _vm._e(),
                            item.type === "operation"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "throttle",
                                            rawName: "v-throttle",
                                            value: [
                                              function() {
                                                return _vm.operation(
                                                  scope.row,
                                                  "refund"
                                                )
                                              }
                                            ],
                                            expression:
                                              "[() => operation(scope.row,'refund')]"
                                          }
                                        ],
                                        staticClass: "refund",
                                        class:
                                          scope.row.status !== "1"
                                            ? "default"
                                            : "",
                                        attrs: {
                                          disabled: scope.row.status !== "1",
                                          size: "small"
                                        }
                                      },
                                      [_vm._v("退款")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "throttle",
                                            rawName: "v-throttle",
                                            value: [
                                              function() {
                                                return _vm.operation(
                                                  scope.row,
                                                  "reissue"
                                                )
                                              }
                                            ],
                                            expression:
                                              "[() => operation(scope.row,'reissue')]"
                                          }
                                        ],
                                        staticClass: "reissue",
                                        class:
                                          scope.row.status !== "1"
                                            ? "default"
                                            : "",
                                        attrs: {
                                          disabled: scope.row.status !== "1",
                                          size: "small"
                                        }
                                      },
                                      [_vm._v("补发")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.type === "after_operation"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.after_operation) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            item.type === "after_model"
                              ? _c("div", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectList.afterModelList
                                          ? _vm.selectList.afterModelList[
                                              scope.row.after_model
                                            ]
                                          : scope.row.after_model
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            item.type === "status"
                              ? _c(
                                  "span",
                                  [
                                    _vm.selectList.status[scope.row.status - 1]
                                      .value === 1
                                      ? _c(
                                          "router-link",
                                          {
                                            staticStyle: { color: "#d24848" },
                                            attrs: {
                                              to: {
                                                name: "after-sale-edit",
                                                query: { id: scope.row.id }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectList.status[
                                                  scope.row.status - 1
                                                ].label
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectList.status[scope.row.status - 1]
                                      .value === 3
                                      ? _c(
                                          "router-link",
                                          {
                                            staticStyle: { color: "#129a49" },
                                            attrs: {
                                              to: {
                                                name: "after-sale-edit",
                                                query: { id: scope.row.id }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectList.status[
                                                  scope.row.status - 1
                                                ].label
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectList.status[scope.row.status - 1]
                                      .value === 2
                                      ? _c(
                                          "router-link",
                                          {
                                            staticStyle: { color: "#df9e3d" },
                                            attrs: {
                                              to: {
                                                name: "after-sale-edit",
                                                query: { id: scope.row.id }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectList.status[
                                                  scope.row.status - 1
                                                ].label
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.selectList.status[scope.row.status - 1]
                                      .value === 4
                                      ? _c(
                                          "router-link",
                                          {
                                            staticStyle: { color: "#3383de" },
                                            attrs: {
                                              to: {
                                                name: "after-sale-edit",
                                                query: { id: scope.row.id }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.selectList.status[
                                                  scope.row.status - 1
                                                ].label
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.type === "popover"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top",
                                          width: "300",
                                          "open-delay": 300,
                                          trigger: "hover"
                                        }
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "webkitBox1",
                                          attrs: { slot: "reference" },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scope.row.abnormal_reason
                                            )
                                          },
                                          slot: "reference"
                                        }),
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scope.row.abnormal_reason
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            ],
            2
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.Inquire
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }