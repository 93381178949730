//
//
//
//
//
//
//
//
//
import { getSession } from '@/utils/session';
export default {
  name: 'app',
  data: function data() {
    return {
      unreadCount: 9999,
      webSock: null,
      dragText: {
        type: 1,
        msg: ''
      },
      dragFlag: false
    };
  },
  created: function created() {// this.initWebSocket()
  },
  methods: {
    openNotice: function openNotice() {},
    // 初始化weosocket
    initWebSocket: function initWebSocket() {
      var wsuri = 'wss://s.dongketech.com/wss/notifation';
      this.webSock = new WebSocket(wsuri);
      this.webSock.onmessage = this.websocketonmessage;
      this.webSock.onopen = this.websocketonopen;
      this.webSock.onerror = this.websocketonerror;
      this.webSock.onclose = this.websocketclose;
    },
    websocketonmessage: function websocketonmessage(e) {
      // 数据接收
      var redata = JSON.parse(e.data);

      if (redata && redata.code !== -1) {
        if (redata.data.msg_json !== undefined) {
          this.dragText.type = redata.data.type;
          this.dragText.msg = redata.data.msg_json.msg;
          this.dragFlag = true;
        }
      } else {
        this.websocketclose();
      }
    },
    websocketonopen: function websocketonopen() {
      // 连接建立之后执行send方法发送数据
      var user = getSession('user');
      var actions = {
        server_id: user.company_id
      };
      this.websocketsend(JSON.stringify(actions));
    },
    websocketonerror: function websocketonerror() {
      // 连接建立失败重连
      this.initWebSocket();
    },
    websocketsend: function websocketsend(Data) {
      // 数据发送
      this.webSock.send(Data);
      console.log(Data);
    },
    websocketclose: function websocketclose(e) {
      // 关闭
      console.log('断开连接', e);
    },
    handleClickDragClose: function handleClickDragClose() {
      this.dragFlag = false;
    }
  }
};