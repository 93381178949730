import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderInfoById, recoverOrder } from '@/api/commerce';
export default {
  name: 'orderd-details',
  data: function data() {
    return {
      labelBasis: [{
        label: '订单号:',
        value: 'order_no'
      }, {
        label: '第三方订单号:',
        value: 'thirdParty_order_on'
      }, {
        label: '订单名称:',
        value: 'order_name'
      }, {
        label: '客户:',
        value: 'service_name'
      }, {
        label: '店铺:',
        value: 'store_url'
      }, {
        label: '订单状态:',
        value: 'order_status_name'
      }, {
        label: '物流状态:',
        value: 'logistics_status_name'
      }, // { label: '订单售卖金额:', value: 'sale_money' },
      {
        label: '订单创建时间:',
        value: 'create_time'
      }, {
        label: '订单更新时间:',
        value: 'update_time'
      }],
      labelExpress: [{
        label: '物流单号:',
        value: 'logistics_no'
      }, {
        label: '物流公司:',
        value: 'logistics_company'
      }, {
        label: '收件人姓:',
        value: 'first_name'
      }, {
        label: '收件人名:',
        value: 'last_name'
      }, // {label:'收件人姓名:',value:'consignee'},
      {
        label: '收件国家:',
        value: 'country'
      }, {
        label: '收件人所在城市:',
        value: 'city'
      }, {
        label: '收件人所在省/州:',
        value: 'province'
      }, {
        label: '收件邮编:',
        value: 'zip'
      }, {
        label: '收件人地址:',
        value: 'address1'
      }, {
        label: '联系电话:',
        value: 'mobile'
      }, {
        label: '收件人邮箱:',
        value: 'email'
      }],
      labelCommodity: [{
        label: '客户订单总金额:',
        value: 'service_order_all_money'
      }, {
        label: '客户订单商品金额:',
        value: 'service_order_goods_money'
      }, {
        label: '客户订单物流费用:',
        value: 'service_order_logistics_money'
      }, {
        label: '客户订单服务费用:',
        value: 'service_order_money'
      }, {
        label: '币种:',
        value: 'currency'
      } // { label: '结算折扣率:', value: 'rate' }
      ],
      labelShop: [{
        label: '商品编码',
        value: 'goods_id',
        type: 'order_goods'
      }, {
        label: '中文商品名称',
        value: 'goods_name'
      }, {
        label: '英文商品名称',
        value: 'english_title'
      }, {
        label: 'SKU编码',
        value: 'sku'
      }, {
        label: 'SKU名称',
        value: 'sku_name'
      }, {
        label: 'SKU数量',
        value: 'sku_num'
      }, {
        label: '售卖金额',
        value: 'sale_money'
      }, // {label:'物流费用:',value:'logistics_cost'},
      {
        label: '服务费用',
        value: 'service_fee'
      }, {
        label: '状态',
        value: 'goods_type',
        type: 'goods_type'
      }],
      tabOrderList: [],
      platform_order: '0',
      orderForm: {
        goods_info: []
      },
      plat_form: ''
    };
  },
  computed: {
    canRecover: function canRecover() {
      return this.orderForm.order_type === '2' || this.orderForm.order_type === '3';
    }
  },
  created: function created() {
    this.plat_form = this.$route.query.plat_form;
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      orderInfoById({
        orders_sublist_id: this.$route.query.id,
        plat_form: this.plat_form
      }).then(function (res) {
        if (res.code === 200) {
          _this.orderForm = res.data;
          console.log(_this.orderForm);
          loading.close();
        }
      }).finally(function () {
        loading.close();
      });
    },
    recoverOperation: function recoverOperation() {
      var _this2 = this;

      var typeTitle;
      var typeHead = '订单复原';
      var type = this.orderForm.order_type;

      if (type === '2') {
        typeTitle = '确认对当前订单进行复原吗？复原后对应拆分的相关订单将会一起恢复';
      } else if (type === '3') {
        typeTitle = '确认对当前订单进行复原吗？复原后对应合并的相关订单将会一起恢复';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.recover();
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消复原'
        });
      });
    },
    recover: function recover() {
      var _this3 = this;

      if (this.orderForm.logistics_no !== '') {
        this.$message.error('该订单无法复原');
        return false;
      }

      var data = {};
      data.orders_sublist_id = this.orderForm.customer_orders_sublist_id;
      data.service_id = this.orderForm.service_id;
      data.type = this.orderForm.order_type === '2' ? '1' : '2';
      recoverOrder(data).then(function (res) {
        if (res.code === 200) {
          _this3.$store.dispatch('tagsView/delView', _this3.$route);

          _this3.$router.push({
            name: 'order-system',
            params: {
              orderList: res.data
            }
          });

          _this3.$message({
            type: 'success',
            message: '复原成功'
          });
        }
      });
    },
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    // 商品参数跳转
    order_goods: function order_goods(goods_id) {
      this.$router.push({
        name: 'commodity-detail',
        params: {
          id: goods_id
        },
        query: {
          type: 'view'
        }
      });
    }
  }
};