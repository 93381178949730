var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-card",
        { staticClass: "nav" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, inline: true, "label-width": "100px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "sku编码" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.sku_code,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sku_code", $$v)
                      },
                      expression: "form.sku_code"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "sku别名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.sku_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sku_name", $$v)
                      },
                      expression: "form.sku_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Inventory_ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.inventory_id,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "inventory_id", $$v)
                      },
                      expression: "form.inventory_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "托管商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.ven_product_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "ven_product_name", $$v)
                      },
                      expression: "form.ven_product_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺链接" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.store_url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "store_url", $$v)
                      },
                      expression: "form.store_url"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.form.oper_user,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "oper_user", $$v)
                      },
                      expression: "form.oper_user"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创建时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "value-format": "timestamp",
                      "end-placeholder": "结束日期"
                    },
                    model: {
                      value: _vm.form.create_time,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "create_time", $$v)
                      },
                      expression: "form.create_time"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择仓库",
                    "label-width": _vm.formLabelWidth,
                    prop: "warehouse_id"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择仓库" },
                      model: {
                        value: _vm.form.warehouse_id,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "warehouse_id", $$v)
                        },
                        expression: "form.warehouse_id"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.warehoustlist, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "操作",
                    "label-width": _vm.formLabelWidth,
                    prop: "oper"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.oper,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "oper", $$v)
                        },
                        expression: "form.oper"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "入库", value: "入库" }
                      }),
                      _c("el-option", {
                        attrs: { label: "出库", value: "出库" }
                      }),
                      _c("el-option", {
                        attrs: { label: "盘点", value: "盘点" }
                      }),
                      _c("el-option", {
                        attrs: { label: "订单消耗", value: "订单消耗" }
                      }),
                      _c("el-option", {
                        attrs: { label: "订单取消", value: "订单取消" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.look } },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.reset } },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "inventory_id", label: "inventory_id" }
              }),
              _c("el-table-column", {
                attrs: { prop: "sku_code", label: "SKU编码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "sku_name", label: "SKU名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "ven_product_name", label: "托管商品名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "store_url", label: "店铺链接" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customer_name", label: "客户名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "warehouse_name", label: "存储仓库" }
              }),
              _c("el-table-column", { attrs: { prop: "qty", label: "数量" } }),
              _c("el-table-column", { attrs: { prop: "oper", label: "操作" } }),
              _c("el-table-column", {
                attrs: { prop: "c_qty", label: "当前库存" }
              }),
              _c("el-table-column", { attrs: { prop: "mark", label: "备注" } }),
              _c("el-table-column", {
                attrs: { prop: "oper_user", label: "操作员" }
              }),
              _c("el-table-column", {
                attrs: { prop: "G1_create_time", label: "创建日期" }
              })
            ],
            1
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.getdetaillist
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "库存盘点", visible: _vm.dialogpandian },
          on: {
            "update:visible": function($event) {
              _vm.dialogpandian = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formpandian } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请输入盘点后实际数量",
                    "label-width": "200px"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formpandian.current_qty,
                      callback: function($$v) {
                        _vm.$set(_vm.formpandian, "current_qty", $$v)
                      },
                      expression: "formpandian.current_qty"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogpandian = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editok("pandian")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "入库", visible: _vm.dialogruku },
          on: {
            "update:visible": function($event) {
              _vm.dialogruku = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formruku } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请输入手动入库的数量",
                    "label-width": "200px"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formruku.current_qty,
                      callback: function($$v) {
                        _vm.$set(_vm.formruku, "current_qty", $$v)
                      },
                      expression: "formruku.current_qty"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": "200px" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formruku.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.formruku, "mark", $$v)
                      },
                      expression: "formruku.mark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogruku = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editok("ruku")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "出库", visible: _vm.dialogchuku },
          on: {
            "update:visible": function($event) {
              _vm.dialogchuku = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.formchuku } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "输入手动入库的数量", "label-width": "200px" }
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formchuku.current_qty,
                      callback: function($$v) {
                        _vm.$set(_vm.formchuku, "current_qty", $$v)
                      },
                      expression: "formchuku.current_qty"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": "200px" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.formchuku.mark,
                      callback: function($$v) {
                        _vm.$set(_vm.formchuku, "mark", $$v)
                      },
                      expression: "formchuku.mark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogchuku = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.editok("chuku")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }