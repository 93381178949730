import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import io from 'socket.io-client';
import { afterSaleDetails, afterSaleType } from '@/api/after';
import { getSession } from '@/utils/session';
import { uploadImage } from '@/api/commodity';
import { getToken } from '@/utils/auth';
export default {
  name: 'after-sale-edit',
  components: {
    Tinymce: function Tinymce() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./tinymce'));
      });
    }
  },
  data: function data() {
    return {
      user_id: '',
      after_id: '',
      msg: '',
      loading: false,
      HSocket: false,
      isMessageRecord: true,
      typeList: {},
      afterSaleInfo: {
        customer_name: '',
        order_no: '',
        after_type: '',
        product_json: '',
        shipping_json: {
          logistics_status: ''
        },
        after_create_time: '',
        sku_name: '',
        id: '',
        third_order_no: '',
        reply: []
      },
      afterChat: {
        after_id: '',
        reply_info: '',
        reply_img: []
      },
      reply_img: [],
      afterModelList: [],
      afterPushStatus: {},
      socket: null,
      message: '正在建立链接，请稍后...',
      bInformation: {},
      status: 1,
      hRouter: {},
      // 视屏弹窗
      videoVisible: false,
      previewUrl: ''
    };
  },
  watch: {
    $route: function $route() {
      if (this.$route.name !== 'after-sale-edit') {
        this.socket.emit('leave-after', {
          after_id: this.after_id
        });
        this.$store.dispatch('tagsView/delView', this.hRouter);
      }
    }
  },
  updated: function updated() {
    this.$nextTick(function () {
      var scr = document.getElementById('h_message_record');

      if (scr !== null) {
        scr.scrollTop = scr.scrollHeight;
      }
    });
  },
  mounted: function mounted() {
    this.loading = true;
    this.getDetail();
    this.getType();
    this.user_id = getSession('user').id;
    this.initWebSocket();
    this.hRouter = this.$route;
    console.log(123456);
  },
  beforeDestroy: function beforeDestroy() {
    this.socket.close();
  },
  methods: {
    // 视频大图查看
    handlePreview: function handlePreview(file, type) {
      this.previewUrl = file;
      this.videoVisible = true;
    },
    getType: function getType() {
      var _this = this;

      afterSaleType().then(function (res) {
        if (res.code === 200) {
          _this.typeList = res.data.type;
          _this.afterModelList = res.data.model;
        }
      }); // afterSaleAfterModel().then(res => {
      //   if (res.code === 200) {
      //     this.afterModelList = res.data
      //   }
      // })
    },
    getDetail: function getDetail() {
      var _this2 = this;

      var data = parseInt(this.$route.query.id);
      afterSaleDetails({
        id: data
      }).then(function (res) {
        if (res.code === 200) {
          _this2.afterSaleInfo = res.data;

          _this2.$set(_this2.afterChat, 'after_id', res.data.after_id);

          _this2.after_id = res.data.after_id;
          _this2.status = res.data.status;
          _this2.loading = false;

          _this2.socket.emit('after-read', {
            after_id: _this2.after_id
          });
        }
      });
    },
    handleReply: function handleReply() {
      if (this.afterChat.reply_info === '' && this.afterChat.reply_img.length === 0) {
        this.$message.warning('Please enter the reply content or picture！');
      } else {
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        var d = date.getDate();
        var H = date.getHours();
        var M = date.getMinutes();
        var S = date.getSeconds();
        var FormatDate = y + '/' + m + '/' + d + ' ' + H + ':' + M + ':' + S;
        var uniqId = this.getUniqueId();
        var obj = {
          message_uniq_id: uniqId,
          is_push: 0,
          type: 2,
          after_id: this.after_id,
          reply_user: this.user_id,
          reply_user_image: this.bInformation.icon,
          reply_user_name: this.bInformation.username,
          reply_time: FormatDate,
          reply_info: this.afterChat.reply_info,
          reply_img: this.afterChat.reply_img
        };
        this.afterPushStatus[uniqId] = obj;
        this.$set(this.afterChat, 'message_uniq_id', uniqId);
        this.$set(this.afterChat, 'after_id', this.after_id);
        this.socket.emit('after-reply', this.afterChat); // 发送10秒后处理发送失败

        setTimeout(function () {
          if (!obj.is_push) {
            obj.is_push = 2;
          }
        }, 10000); // this.isMessageRecord = false
        // this.$nextTick(() => {
        //   this.isMessageRecord = true
        // })
        // 消息上屏

        this.afterSaleInfo.reply.push(obj);
        this.afterChat = this.$options.data().afterChat;
        this.reply_img = [];
      }
    },
    reReply: function reReply(data) {
      // 重新发出回复
      data.is_push = 0;
      this.socket.emit('after-reply', data); // 发送10秒后处理发送失败

      setTimeout(function () {
        if (!data.is_push) {
          data.is_push = 2;
        }
      }, 10000);
    },
    getUniqueId: function getUniqueId() {
      var random = Math.random().toString(16);
      random = random.split('.')[1];
      return new Date().getTime().toString(16) + random;
    },
    handleClickClose: function handleClickClose(index) {
      this.afterChat.reply_img.splice(index, 1);
    },
    Upload: function Upload(fileObj) {
      var _this3 = this;

      this.loading = true;

      if (this.afterChat.reply_img.length <= 4) {
        var file = {
          showProgress: true,
          url: '',
          percent: 0
        };
        var formData = new FormData();
        formData.append('file', fileObj.file);
        uploadImage(formData, function (progress) {
          file.percent = Math.round(progress.loaded / progress.total * 100);
        }).then(function (res) {
          if (res.code === 200) {
            var data = JSON.parse(JSON.stringify(res.data));
            file.url = data['data-service-file'];
            file.showProgress = false;
            var obj = {
              url: file.url,
              type: fileObj.file.type
            };

            _this3.reply_img.push(obj);

            _this3.$set(_this3.afterChat, 'reply_img', _this3.reply_img);

            _this3.showImg = false;
            _this3.loading = false;
          }
        }).catch(function (err) {
          console.log(err);
          _this3.loading = false;
        });
      } else {
        this.$message.warning('最多上传5张图片！');
        this.loading = false;
      }
    },
    handleBeforeUpload: function handleBeforeUpload(file, fileList) {// console.log('change', file)
    },
    handleChange: function handleChange(file) {
      var _this4 = this;

      var isSize = new Promise(function (resolve, reject) {
        var isLt2M = file.size / 1024 / 1024 < 2;
        isLt2M ? resolve() : reject(new Error('Error'));
      }).then(function () {
        return file;
      }, function () {
        _this4.$message.error('The uploaded image size exceeds 2M！');

        return Promise.reject(new Error('Error'));
      });
      return isSize;
    },
    initWebSocket: function initWebSocket() {
      var _this5 = this;

      // const url = 'wss://io.fbali.co/?type=2&user_id=' + this.user_id + '&token=' + getToken()
      var url = 'wss://' + process.env.VUE_APP_SOCKET_URLS + '/?type=2&user_id=' + this.user_id + '&token=' + getToken();
      console.log('ssss', process.env);
      this.socket = io.connect(url, {
        timeout: 60000,
        reconnectionDelayMax: 1000,
        reconnectionDelay: 500,
        httpCompress: false,
        wsEngine: 'wss',
        origins: '*',
        transports: ['websocket'],
        allowRequest: true,
        allowUpgraders: true
      });
      this.socket.on('connect', function (e) {
        _this5.socket.emit('join-after', {
          after_id: _this5.after_id
        });
      });
      this.socket.on('join-after', function (e) {
        if (e.code === 200) {
          if (_this5.user_id === parseInt(e.data.user_id)) {
            _this5.message = '';
            _this5.bInformation = e.data;
          }
        }
      });
      this.socket.on('after-reply', function (e) {
        if (e.code === 200) {
          _this5.afterSaleInfo.reply.push(e.data);

          _this5.socket.emit('after-read', {
            after_id: _this5.after_id,
            type: 2
          });

          _this5.isMessageRecord = false;

          _this5.$nextTick(function () {
            _this5.isMessageRecord = true;
          });
        }
      });
      this.socket.on('send-error', function (e) {
        if (e.code === 400) {
          _this5.socket.emit('join-after', {
            after_id: _this5.after_id
          });
        }
      });
      this.socket.on('disconnect', function () {
        console.log('连接断开，尝试重新链接');
        _this5.message = '正在建立链接，请稍后...';
      }); // 发送信息成功

      this.socket.on('send-success', function (e) {
        if (e.code === 200 && e.data && e.data.message_uniq_id) {
          _this5.afterPushStatus[e.data.message_uniq_id]['is_push'] = 1;
          delete _this5.afterPushStatus[e.data.message_uniq_id];
        }
      });
    },
    handleToBack: function handleToBack() {
      this.socket.emit('leave-after', {
        after_id: this.after_id
      });
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.back();
    },
    closeSelectedTag: function closeSelectedTag(tag) {
      console.log(tag);
    }
  }
};