import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var accountRouter = [{
  path: '/account',
  component: Layout,
  alwaysShow: true,
  name: 'account',
  redirect: 'noRedirect',
  meta: {
    title: '账户后台',
    icon: 'el-icon-s-custom'
  },
  children: [{
    path: 'company/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/company/index'));
      });
    },
    name: 'company',
    meta: {
      title: '公司管理',
      keepAlive: true
    }
  }, {
    path: 'account-set/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/accountSet/index'));
      });
    },
    name: 'account-set',
    meta: {
      title: '账号管理',
      keepAlive: true
    }
  }]
}];
export default accountRouter;