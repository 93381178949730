import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { storeConfigRule, ruleOption, ruleConfigSave, ruleConfigEdit, storeType } from '@/api/information';
export default {
  name: 'client-rules',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    packageSearch: function packageSearch() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/packageSearch/index'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '客户ID',
        value: 'customer_id'
      }, // { label: '优先级顺序', value: 'mobile' },
      {
        label: '客户名称',
        value: 'servide_name'
      }, {
        label: '店铺链接',
        value: 'store_url'
      }, {
        label: '店铺名称',
        value: 'store_name'
      }, {
        label: '规则名称',
        value: 'rule_name'
      }, // { label: '创建人', value: 'create_user' },
      // { label: '创建时间', value: 'create_time' },
      {
        label: '自定义状态',
        value: 'type'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      update_start: [],
      create_start: [],
      loading: false,
      dialogVisible: false,
      submitLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        servide_name: '',
        rule_name: '',
        store_name: ''
      },
      rules: {
        rule_id: [{
          required: true,
          message: '规则名称不能为空',
          trigger: 'bulr'
        }]
      },
      ruleForm: {
        id: '',
        rule_id: ''
      },
      ruleList: []
    };
  },
  computed: {
    // 展开过滤列表
    filterList: function filterList() {
      return [{
        label: '客户名称',
        inputType: 'input',
        name: 'servide_name'
      }, {
        label: '规则名称',
        inputType: 'input',
        name: 'rule_name'
      }, {
        label: '店铺名称',
        inputType: 'input',
        name: 'store_name'
      }];
    }
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      storeConfigRule(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total.totalCount);
            _this.loading = false;
          }, 300);
        }
      });
    },
    // 新增规则
    handleOpen: function handleOpen() {
      var _this2 = this;

      ruleOption().then(function (res) {
        console.log(res.data);

        if (res.code === 200) {
          _this2.ruleList = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 操作
    operation: function operation(type, item) {
      var _this3 = this;

      if (type === 1) {
        this.ruleForm.id = item.id;
        ruleConfigEdit({
          id: item.id
        }).then(function (res) {
          // console.log(res.data)
          if (res.code === 200) {
            _this3.ruleForm = res.data;
          }
        }).catch(function (err) {
          console.log(err);
        });
        this.dialogVisible = true;
      }
    },
    ruleStoreSave: function ruleStoreSave(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.submitLoading = true;
          ruleConfigSave(_this4.ruleForm).then(function (res) {
            console.log(res.data);

            if (res.code === 200) {
              _this4.ruleForm = _this4.$options.data().ruleForm;
              _this4.submitLoading = false;
              _this4.dialogVisible = false;

              _this4.Inquire();
            }
          }).catch(function (err) {
            console.log(err);
            _this4.submitLoading = false;
          });
        } else {
          console.log();
        }
      });
    },
    // 展开搜索
    handleFilter: function handleFilter(val) {
      var _this5 = this;

      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this5.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this5.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    },
    // 重置
    resetFilter: function resetFilter() {
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 展开后二次搜索
    handleSecFilter: function handleSecFilter(val) {
      var _this6 = this;

      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this6.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this6.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    },
    modifyStoreType: function modifyStoreType(row) {
      var _this7 = this;

      storeType({
        id: row.id,
        type: row.type
      }).then(function (res) {
        if (res.code === 200) {
          _this7.$message.success(res.message);
        }
      });
    }
  }
};