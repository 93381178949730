var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "after-chat-box"
    },
    [
      _c(
        "div",
        { staticClass: "afterSaleTitle" },
        [
          _c("el-button", {
            staticClass: "button",
            attrs: { size: "small", icon: "el-icon-back", circle: "" },
            on: { click: _vm.handleToBack }
          }),
          _c("span", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.afterSaleInfo.customer_name) + " ")
          ]),
          _c("span", { staticClass: "orderId" }, [
            _vm._v(" " + _vm._s(_vm.afterSaleInfo.order_no) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "after-sale-card" },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("售后信息")])
          ]),
          _c(
            "el-form",
            {
              staticClass: "infoForm",
              attrs: { inline: true, model: _vm.afterSaleInfo }
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 72 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "客户诉求:" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.typeList[_vm.afterSaleInfo.after_type])
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "售后类型:" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.afterModelList[_vm.afterSaleInfo.after_model]
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "物流状态:" } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.afterSaleInfo.product_json !== undefined &&
                                _vm.afterSaleInfo.shipping_json
                                ? _vm.afterSaleInfo.shipping_json
                                    .logistics_status
                                : ""
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "创建时间:" } }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.afterSaleInfo.after_create_time))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品SKU:" } },
                        _vm._l(_vm.afterSaleInfo.product_json, function(
                          item,
                          k
                        ) {
                          return _c("span", { key: k, staticClass: "in_txt" }, [
                            _vm._v(_vm._s(item.sku_name) + " ")
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "订单编号:" } }, [
                        _c("span", [_vm._v(_vm._s(_vm.afterSaleInfo.order_no))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "第三方订单号:" } },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.afterSaleInfo.third_order_no))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "订单名称:" } }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.afterSaleInfo.order_name))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-card", { staticClass: "after-sale-card" }, [
        _vm.isMessageRecord
          ? _c(
              "div",
              {
                staticClass: "message_record",
                attrs: { id: "h_message_record" }
              },
              _vm._l(_vm.afterSaleInfo.reply, function(item, idx) {
                return _c(
                  "div",
                  {
                    key: idx,
                    staticClass: "message-box",
                    class: [
                      item.type === 2 && _vm.user_id === item.reply_user
                        ? "right"
                        : "left"
                    ]
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _vm.user_id !== item.reply_user ||
                        parseInt(item.type) !== 2
                          ? _c("el-image", {
                              staticClass: "avatar",
                              attrs: {
                                src: item.reply_user_image,
                                "preview-src-list": [item.reply_user_image]
                              }
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "title-text" }, [
                          _c("p", { staticClass: "title-user-name" }, [
                            _vm._v(_vm._s(item.reply_user_name))
                          ]),
                          _c("p", { staticClass: "title-time" }, [
                            _vm._v(_vm._s(item.reply_time))
                          ])
                        ]),
                        parseInt(item.type) === 2 &&
                        _vm.user_id === item.reply_user
                          ? _c("el-image", {
                              staticClass: "avatar",
                              attrs: {
                                src: item.reply_user_image,
                                "preview-src-list": [item.reply_user_image]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("div", { staticClass: "contain" }, [
                      item.reply_info
                        ? _c("div", { staticClass: "mb10" }, [
                            parseInt(item.type) === 2 && item.message_uniq_id
                              ? _c("span", {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: !item.is_push,
                                      expression: "!item.is_push"
                                    }
                                  ],
                                  staticClass: "message_push_status",
                                  style: {
                                    color: "red",
                                    cursor:
                                      item.is_push === 2 ? "pointer" : "default"
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      item.is_push === 2
                                        ? "Message sending failed "
                                        : "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      item.is_push === 2 && _vm.reReply(item)
                                    }
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [
                                  item.type === 2 &&
                                  _vm.user_id === item.reply_user
                                    ? "reply-right"
                                    : "reply-left"
                                ]
                              },
                              [
                                _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(item.reply_info)
                                  }
                                })
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        _vm._l(item.reply_img, function(it, i) {
                          return _c("span", { key: i }, [
                            it.type
                              ? _c(
                                  "span",
                                  [
                                    it.type !== "video/mp4"
                                      ? _c("el-image", {
                                          staticClass: "image",
                                          attrs: {
                                            src: it.url,
                                            "preview-src-list": [it.url]
                                          }
                                        })
                                      : _vm._e(),
                                    it.type === "video/mp4"
                                      ? _c("video", {
                                          staticClass: "image",
                                          attrs: {
                                            preload: "metadata",
                                            src: it.url
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handlePreview(
                                                it.url,
                                                it.type
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  [
                                    _c("el-image", {
                                      staticClass: "image",
                                      attrs: {
                                        src: it,
                                        "preview-src-list": [it]
                                      }
                                    })
                                  ],
                                  1
                                )
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ]),
      _vm.status !== 3
        ? _c("el-card", { staticClass: "after-sale-card" }, [
            _c(
              "div",
              { staticClass: "chat_box" },
              [
                _c("tinymce", {
                  ref: "tinymces",
                  attrs: { menubar: "", height: 200 },
                  on: { reply: _vm.handleReply },
                  model: {
                    value: _vm.afterChat.reply_info,
                    callback: function($$v) {
                      _vm.$set(_vm.afterChat, "reply_info", $$v)
                    },
                    expression: "afterChat.reply_info"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "upload-box" },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-photos",
                        attrs: {
                          accept: "image/png, image/jpeg, video/mp4",
                          action: "",
                          "show-file-list": false,
                          "http-request": _vm.Upload,
                          "before-upload": _vm.handleBeforeUpload,
                          "on-change": _vm.handleChange
                        }
                      },
                      [
                        _c("div", { staticClass: "el-upload__text" }, [
                          _c("i", { staticClass: "el-icon-picture" })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "tick-box" }, [
                  _vm._v(_vm._s(_vm.message))
                ]),
                _vm.afterChat.reply_img.length > 0
                  ? _c(
                      "div",
                      { staticClass: "image-box" },
                      _vm._l(_vm.afterChat.reply_img, function(fit, key) {
                        return _c(
                          "span",
                          { key: key, staticClass: "image-span" },
                          [
                            fit.type !== "video/mp4"
                              ? _c("el-image", {
                                  staticClass: "image",
                                  attrs: {
                                    src: fit.url,
                                    "preview-src-list": [fit.url]
                                  }
                                })
                              : _vm._e(),
                            fit.type === "video/mp4"
                              ? _c("video", {
                                  staticClass: "image",
                                  attrs: { preload: "metadata", src: fit.url },
                                  on: {
                                    click: function($event) {
                                      return _vm.handlePreview(
                                        fit.url,
                                        fit.type
                                      )
                                    }
                                  }
                                })
                              : _vm._e(),
                            _c("i", {
                              staticClass: "el-icon-circle-close hIcon",
                              on: {
                                click: function($event) {
                                  return _vm.handleClickClose(key)
                                }
                              }
                            })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "视频预览", visible: _vm.videoVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.videoVisible = $event
            }
          }
        },
        [
          _c("iframe", {
            attrs: {
              width: "100%",
              height: "500px",
              src: _vm.previewUrl,
              frameborder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
              allowfullscreen: ""
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }