import request from '@/utils/request'; // 登录接口

export function login(data) {
  return request({
    url: '/site/login',
    method: 'post',
    data: data
  });
}
export function getInfo(data) {
  return request({
    url: '/base/common/get-user',
    method: 'post',
    data: data
  });
}
export function logout(baseURL) {
  return request({
    url: baseURL + '/site/login-out',
    method: 'post'
  });
} // 注册用户

export function registerUser(data) {
  return request({
    url: '/register',
    method: 'post',
    data: data
  });
}
export function getMenu(baseURL, data) {
  return request({
    url: baseURL + '/base/common/get-menu',
    method: 'post',
    data: data
  });
} // 客户,发货渠道列表

export function orderServicerList(baseURL, data) {
  return request({
    url: baseURL + '/order/order/get-servicer-list',
    method: 'get',
    params: data
  });
} // 登陆时用户头像

export function getUserAvatar(baseURL, data) {
  return request({
    url: baseURL + '/user/dx-user/info',
    method: 'get',
    params: data
  });
}