//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderSplit } from '@/api/commerce';
export default {
  data: function data() {
    return {
      labelBasis: [{
        label: '订单号:',
        value: 'order_no'
      }, {
        label: '第三方订单号:',
        value: 'thirdParty_order_on'
      }, {
        label: '订单名称:',
        value: 'order_name'
      }, {
        label: '客户:',
        value: 'service_name'
      }, {
        label: '店铺:',
        value: 'store_url'
      }, {
        label: '订单状态:',
        value: 'order_status_name'
      }, {
        label: '物流状态:',
        value: 'logistics_status_name'
      }, {
        label: '订单创建时间:',
        value: 'create_time'
      }, {
        label: '订单更新时间:',
        value: 'update_time'
      }],
      labelExpress: [{
        label: '收件人姓:',
        value: 'first_name'
      }, {
        label: '收件人名:',
        value: 'last_name'
      }, // {label:'收件人姓名:',value:'consignee'},
      {
        label: '收件国家:',
        value: 'country'
      }, {
        label: '收件人所在城市:',
        value: 'city'
      }, {
        label: '收件人所在省/州:',
        value: 'province'
      }, {
        label: '收件邮编:',
        value: 'zip'
      }, {
        label: '收件人地址:',
        value: 'address1'
      }, {
        label: '联系电话:',
        value: 'mobile'
      }, {
        label: '收件人邮箱:',
        value: 'email'
      }],
      labelShop: [{
        label: '商品编码',
        value: 'goods_id',
        type: 'order_goods'
      }, {
        label: '中文商品名称',
        value: 'goods_name'
      }, {
        label: '英文商品名称',
        value: 'english_title'
      }, {
        label: 'SKU编码',
        value: 'sku'
      }, {
        label: 'SKU名称',
        value: 'sku_name'
      }, {
        label: 'SKU数量',
        value: 'sku_num'
      } // { label: '售卖金额', value: 'sale_money' },
      // // {label:'物流费用:',value:'logistics_cost'},
      // { label: '服务费用', value: 'service_fee' }
      ],
      tabOrderList: [],
      platform_order: '0',
      orderForm: [],
      oreders: null
    };
  },
  created: function created() {
    this.orderForm = JSON.parse(localStorage.getItem('splitOrders'));
  },
  activated: function activated() {
    this.orderForm = JSON.parse(localStorage.getItem('splitOrders'));
  },
  methods: {
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    // 提交订单
    submitOrder: function submitOrder() {
      var _this = this;

      var data = this.orderForm;
      orderSplit(data).then(function (result) {
        if (result.code === 200) {
          localStorage.setItem('checkedOrders', JSON.stringify(result.data));

          _this.$store.dispatch('tagsView/delView', _this.$route);

          _this.$router.push({
            name: 'split-order-checked',
            query: {
              service_name: _this.$route.query.service_name,
              order_no: _this.$route.query.order_no
            }
          });
        }
      });
    },
    // 商品参数跳转
    order_goods: function order_goods(goods_id) {
      this.$router.push({
        name: 'commodity-detail',
        params: {
          id: goods_id
        },
        query: {
          type: 'view'
        }
      });
    }
  }
};