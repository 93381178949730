var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片上传",
            visible: _vm.dialogVisible,
            "append-to-body": "",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-upload",
            {
              ref: "myUpload",
              staticClass: "uploadBox",
              attrs: {
                action: "null",
                "list-type": "picture-card",
                multiple: true,
                "before-upload": _vm.handleBeforeUpload,
                "on-remove": _vm.handleRemove,
                "on-change": _vm.handleChange,
                "on-exceed": _vm.handleExceed,
                limit: _vm.limit,
                "auto-upload": false
              }
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.savePicLoading },
                  on: {
                    click: function($event) {
                      return _vm.handleClose(1)
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.savePicLoading },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }