//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'page404',
  computed: {
    message: function message() {
      return '站长说你不能进入这个页面。。。';
    }
  }
};