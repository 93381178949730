import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var commerceRouter = [{
  path: '/commerce',
  component: Layout,
  alwaysShow: true,
  name: 'commerce',
  redirect: 'noRedirect',
  meta: {
    title: '电商后台',
    icon: 'el-icon-s-tools'
  },
  children: [{
    path: 'businesschargeback',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/businesschargeback/index'));
      });
    },
    name: 'businesschargeback',
    meta: {
      title: '业务扣费',
      keepAlive: true
    }
  }, {
    path: 'fundsflow',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/fundsflow/index'));
      });
    },
    name: 'fundsflow',
    meta: {
      title: '资金流水',
      keepAlive: true
    }
  }, {
    path: 'orderSystem',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/orderSystem/index'));
      });
    },
    name: 'order-system',
    meta: {
      title: '订单系统',
      keepAlive: true
    }
  }, {
    path: 'orderdDetails/index/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/orderdDetails/index'));
      });
    },
    name: 'orderd-details',
    hidden: true,
    meta: {
      title: '订单详情'
    }
  }, {
    path: 'waybillSystem/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/waybillSystem/index'));
      });
    },
    name: 'waybill-system',
    meta: {
      title: '运单系统',
      keepAlive: true
    }
  }, {
    path: 'settlement/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/settlement/index'));
      });
    },
    name: 'settlement',
    meta: {
      title: '结算系统',
      keepAlive: true
    }
  }, {
    path: 'merge-detail/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/mergeDetail/index'));
      });
    },
    name: 'merge-detail',
    meta: {
      title: '订单合并详情'
    },
    hidden: true
  }, {
    // /:id
    path: 'split-detail/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/split-detail/index'));
      });
    },
    name: 'split-detail',
    meta: {
      title: '订单拆分详情'
    },
    hidden: true
  }, {
    path: 'split-orderprocess/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/split-orderprocess'));
      });
    },
    name: 'split-orderprocess',
    meta: {
      title: '订单拆分预览'
    },
    hidden: true
  }, {
    path: 'split-order-checked/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/split-order-checked'));
      });
    },
    name: 'split-order-checked',
    meta: {
      title: '订单拆分确认'
    },
    hidden: true
  }, {
    path: 'merge-checked/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/commerce/mergeChecked'));
      });
    },
    name: 'merge-checked',
    meta: {
      title: '订单合并确认'
    },
    hidden: true
  }]
}];
export default commerceRouter;