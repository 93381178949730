import "/var/lib/jenkins/workspace/dongketech-test/node_modules/core-js/modules/es.array.iterator.js";
import "/var/lib/jenkins/workspace/dongketech-test/node_modules/core-js/modules/es.promise.js";
import "/var/lib/jenkins/workspace/dongketech-test/node_modules/core-js/modules/es.object.assign.js";
import "/var/lib/jenkins/workspace/dongketech-test/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui'; // import('element-ui/lib/theme-chalk/index.css')

if (process.env.NODE_ENV === 'development') {
  require('element-ui/lib/theme-chalk/index.css');
} // import locale from 'element-ui/lib/locale/lang/en' // lang i18n


import '@/styles/index.scss'; // global css

import '@/directive'; // 节流指令

import App from './App';
import store from './store';
import router from './router';
import '@/icons'; // icon

import '@/permission'; // permission control
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明

import '@/directive'; // 指令

import * as filters from './filters'; // global filters
// register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});