import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var logisticsrulesRouter = [{
  path: '/shipping',
  component: Layout,
  alwaysShow: true,
  name: 'shipping',
  redirect: 'noRedirect',
  meta: {
    title: '物流规则配置',
    icon: 'el-icon-user-solid'
  },
  // hidden: true,
  children: [{
    path: 'shippings/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shipping/shippings/index'));
      });
    },
    name: 'shippings',
    meta: {
      title: '物流规则及价格管理',
      keepAlive: true
    }
  }, // shoprules
  {
    path: 'usermanage/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shipping/usermanage/index'));
      });
    },
    name: 'usermanage',
    meta: {
      title: '客户管理',
      keepAlive: true
    }
  }, {
    path: 'shoprules/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shipping/shoprules/index'));
      });
    },
    name: 'shoprules',
    meta: {
      title: '店铺规则配置',
      keepAlive: true
    }
  }]
}];
export default logisticsrulesRouter;