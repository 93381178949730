import _typeof from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getStore, relatedSave } from '@/api/commodity'; // import shopSelect from './shopSelect'

import shopSetting from './shopSetting';
export default {
  name: 'sku-setting',
  components: {
    // shopSelect,
    shopSetting: shopSetting
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '关联客户&供应商'
    },
    flag: {
      type: String,
      default: 'view'
    },
    formData: {
      type: [Object, Array],
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      form: {},
      activeName: '0',
      isDisabled: true,
      checked: true,
      boxshow: false,
      expandText: '展开',
      skulist: [],
      shoplist: [],
      goodId: '',
      skuId: '',
      selected: {},
      shopData: {
        third_good_id: '',
        third_sku_id: '',
        pool_id: '',
        good_id: '',
        sku_id: '',
        sale_price: '',
        sku_weight: '',
        logistics_id: '',
        chann_id: '',
        profit_margin: '',
        service_fee: '',
        store_id: '',
        providers_id: ''
      },
      shopVisible: false,
      loading: false,
      selShops: [],
      storeId: '',
      shopFlag: '',
      poolList: []
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    },
    services: function services() {
      return this.$store.getters.options.services;
    },
    supplier: function supplier() {
      return this.$store.getters.options.supplier;
    }
  },
  watch: {
    formData: {
      handler: function handler(list) {
        this.$set(this, 'skulist', list);
      },
      deep: true
    }
  },
  created: function created() {},
  methods: {
    // 弹窗打开事件
    handleOpenDialog: function handleOpenDialog() {
      this.activeName = '0';
      this.skuId = this.skulist[0].id;
      this.goodId = this.skulist[0].good_id;
      var services = this.skulist[0].services;
      this.fetchShopList(services);
    },
    // 关闭弹窗
    close: function close() {
      this.dialogVisible = false;
    },
    // 弹窗关闭事件
    // handleCloseDialog() {
    //   this.shoplist = []
    // },
    // SKUTabs点击事件
    handleClick: function handleClick() {
      this.skuId = this.skulist[this.activeName].id;
      var services = this.skulist[this.activeName].services;
      this.fetchShopList(services);
    },
    // 回显时获取店铺
    fetchShopList: function fetchShopList(services) {
      console.log('回显services', services);
      services.forEach(function (item) {
        console.log(item);
        var shopsId = item.shops.map(function (shop) {
          return shop.store_id;
        });
        console.log(shopsId);

        if (item.service_id) {
          getStore({
            id: item.service_id
          }).then(function (res) {
            if (res.status === 200) {
              var data = res.data;

              for (var key in data) {
                if (shopsId.length > 0) {
                  if (shopsId.includes(data[key].id)) {
                    data[key].checked = true;
                  }
                } else {
                  data[key].checked = false;
                }
              }

              item.nodata = false;
              item.shoplist = data;
            }
          }).catch(function (err) {
            console.log(err);
          });
        } else {
          item.shoplist = [];
        }
      });
    },
    // 新增客户
    addServer: function addServer(index) {
      this.skulist[index].services.push({
        shoplist: []
      });
    },
    // 新增供应商
    addSupplier: function addSupplier(index) {
      this.skulist[index].supplier.push({});
    },
    // 删除客户
    delServer: function delServer(index, key) {
      this.skulist[index].services.splice(key, 1);
    },
    // 删除供应商
    delSupplier: function delSupplier(index, key) {
      this.skulist[index].supplier.splice(key, 1);
    },
    // 选择客户时初始化店铺列表
    initShopList: function initShopList(item, index) {
      var _this = this;

      item.shops = [];

      if (item.service_id) {
        getStore({
          id: item.service_id
        }).then(function (res) {
          if (res.status === 200) {
            var data = res.data;

            if (data.length > 0) {
              _this.$set(item, 'nodata', false);

              item.shoplist = data.map(function (item) {
                item.checked = false;
                return item;
              });
            } else {
              _this.$set(item, 'shoplist', []);

              _this.$set(item, 'nodata', true);
            }
          }
        }).catch(function (err) {
          console.log(err);
        });
      } else {
        this.$set(item, 'nodata', false);
        this.$set(item, 'shoplist', []);
      }
    },
    addPoolList: function addPoolList(val) {
      var _this2 = this;

      val.forEach(function (i) {
        return _this2.poolList.push(i);
      });
      var list = Array.from(new Set(this.poolList));
      this.$set(this, 'poolList', list);
      console.log(this.poolList);
    },
    updatePoolList: function updatePoolList(val) {
      console.log(_typeof(val));
      var list = this.poolList.filter(function (item) {
        return item !== val;
      }); // val.forEach(i => this.poolList.push(i))
      // const list = Array.from(new Set(this.poolList))

      this.$set(this, 'poolList', list);
      console.log(this.poolList);
    },
    // 提交表单
    submit: function submit() {
      var _this3 = this;

      var skulist = JSON.stringify(this.skulist);
      var formatlist = JSON.parse(skulist);
      var formList = formatlist.map(function (list) {
        list.services.forEach(function (item) {
          delete item.shoplist;
          delete item.nodata;
        });
        return list;
      }); // console.log(JSON.stringify(formList))

      console.log(this.skulist);
      console.log(formList);
      this.loading = true;
      relatedSave(formList).then(function (res) {
        if (res.status === 200) {
          _this3.$emit('close');

          _this3.loading = false;

          _this3.$message.success('添加成功！');

          _this3.dialogVisible = false;
        }
      }).catch(function (err) {
        _this3.loading = false;
        console.log(err);
      });
    },
    // 选择店铺时
    checkboxChange: function checkboxChange(server, item) {
      if (item.checked) {
        console.log('新增');
        this.shopFlag = 'add';
        this.storeId = item.id;
        this.selShops = server.shops;
        this.selected = item;
        this.shopData = this.$options.data().shopData;
        this.shopData.good_id = this.goodId;
        this.shopData.sku_id = this.skuId;
        this.shopVisible = true;
      } else {
        // 从shops中移除掉店铺
        console.log('移除');
        var shopIndex = server.shops.findIndex(function (shop) {
          return shop.store_id === item.id;
        });
        server.shops.splice(shopIndex, 1);
      }

      console.log('serverShops', this.selShops);
    },
    // 回显店铺数据
    showCheckData: function showCheckData(server, item) {
      this.storeId = item.id;
      this.selShops = server.shops;
      this.selected = item;
      var data = server.shops.find(function (shop) {
        return shop.store_id === item.id;
      });

      if (data) {
        console.log('编辑');
        this.shopFlag = 'edit';
        this.shopData = Object.assign({}, data);
      } else {
        console.log('新增');
        this.shopFlag = 'add';
        this.shopData = this.$options.data().shopData;
        this.shopData.good_id = this.goodId;
        this.shopData.sku_id = this.skuId;
      }

      this.shopVisible = true;
      console.log('shopData', this.shopData);
      console.log('serverShops', this.selShops);
    },
    // 店铺数据提交回调
    setItem: function setItem(val) {
      console.log('pushData', val);

      if (val) {
        // 将新增的店铺添加进shops
        var data = Object.assign({}, val);
        this.selected.checked = true;
        console.log(this.selShops.some(function (e) {
          return e.store_id === val.store_id;
        }));

        if (this.selShops.some(function (e) {
          return e.store_id === data.store_id;
        })) {
          var shopIndex = this.selShops.findIndex(function (shop) {
            return shop.store_id === data.store_id;
          });
          this.selShops.splice(shopIndex, 1);
          this.selShops.push(data);
        } else {
          this.selShops.push(data);
        }
      } else {
        this.selected.checked = false;
      }

      console.log('serverShops', this.selShops);
    }
  }
};