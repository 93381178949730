import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import { blobUpload } from '@/utils/blob';
import { getToken } from '@/utils/auth';
import baseURL from '@/api/mixins';
export default {
  name: 'dialog-import',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '导入'
    },
    updateid: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 1
    },
    importProps: {
      type: Object,
      default: function _default() {}
    },
    importReturn: {
      // 导入返回Excel
      type: Boolean,
      default: true
    },
    importDeclare: {
      // 导入说明
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      xlsxUpLoadFileList: [],
      xlsxUpLoadList: [],
      loading: false
    };
  },
  mounted: function mounted() {},
  methods: {
    close: function close(type) {
      var _this = this;

      if (type === 1) {
        var data = [];
        this.xlsxUpLoadList.forEach(function (item) {
          data.push(_this.upLoadXlsx(item));
        });
        if (!data.length) return this.$message({
          type: 'warning',
          message: '请选择文件上传!'
        });
        this.loading = true;
        Promise.all(data).then(function (res) {
          if (_this.importReturn) {
            console.log('in here');
            res.forEach(function (item) {
              blobUpload(item, _this.importProps.name).then(function (res) {
                _this.$message.success('上传成功');

                _this.$emit('close', 1);

                _this.loading = false;
              });
            });
          } else {
            _this.$message.success(res[0].message);

            _this.$emit('close', 1);
          } // this.$emit('close', list)


          _this.loading = false;
        }).catch(function (err) {
          console.log(err);
          _this.loading = false;
        });
      } else {
        this.$emit('close');
      }

      this.xlsxUpLoadFileList = [];
      this.xlsxUpLoadList = [];
      this.$refs.myXlsx.clearFiles();
    },
    // 上传
    exceedClick: function exceedClick() {
      this.$message({
        message: '只能上传一个文件!',
        type: 'warning'
      });
    },
    httpRequest: function httpRequest() {},
    xlsxRemove: function xlsxRemove(file, fileList) {
      this.xlsxUpLoadList = fileList.map(function (item) {
        return item.raw;
      }); // this.xlsxUpLoadFileList = []
    },
    // 本地上传  数据更改
    xlsxUploads: function xlsxUploads(file) {
      this.xlsxUpLoadList.push(file);
    },
    upLoadXlsx: function upLoadXlsx(file) {
      var _this2 = this;

      var formData = new FormData();
      formData.append('id', file.uid);
      formData.append('name', file.name);
      formData.append('type', file.type);
      formData.append('lastModifiedDate', file.lastModifiedDate);
      formData.append('size', file.size);
      formData.append('excel', file);

      if (this.params && this.params.type) {
        formData.append('transport_id', this.params.transport_id);
        formData.append('type', this.params.type);
      }

      if (this.importProps.type === 'logistics') {
        formData.append('platform_id', this.updateid);
      } // const url = process.env.VUE_APP_BASE_API


      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          baseURL: baseURL + _this2.importProps.url,
          data: formData,
          responseType: _this2.importReturn ? 'blob' : null,
          headers: {
            'Content-Type': 'application/json',
            'token': getToken()
          },
          // transformRequest: [function(data, headers) {
          //   if (!headers.sign) {
          //     const new_data = JSON.stringify(data)
          //     let string = ''
          //     if (!new_data) {
          //       string = md5('key=f4d53fa55eab937660c088ade0ca6caa').toUpperCase()
          //     } else {
          //       string = md5('data=' + new_data.replace(/\s|\\/g, '') + '&key=f4d53fa55eab937660c088ade0ca6caa').toUpperCase()
          //     }
          //     headers.sign = string
          //   }
          //   return data
          // }],
          timeout: 300000
        }).then(function (response) {
          if (response.data.code && response.data.code !== 200) {
            _this2.$alert("<strong>".concat(response.data.message, "</strong>"), '导入错误', {
              dangerouslyUseHTMLString: true,
              type: 'warning'
            });

            _this2.loading = false;
            reject(response.data.message);
          } else {
            resolve(response.data);
            _this2.dialogImport = false;
          }
        }).catch(function (error) {
          _this2.$message({
            type: 'danger',
            message: '网络错误，请重新连接!'
          });

          reject(error);
        });
      });
    }
  }
};