import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { supplierDataList, supplierCooperate, supplierExport } from '@/api/information';
import { blobUpload } from '@/utils/blob';
export default {
  name: 'supplier',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    supplierAdd: function supplierAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/supplierAdd'));
      });
    },
    packageSearch: function packageSearch() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/packageSearch/index'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: 'id',
        value: 'id'
      }, {
        label: '供应商名称',
        value: 'supplier_name'
      }, {
        label: '电话号码',
        value: 'mobile'
      }, {
        label: '微信号',
        value: 'wechat_num'
      }, {
        label: '地址',
        value: 'address'
      }, {
        label: '开始合作时间',
        value: 'cooperate_time'
      }, {
        label: '主营类目',
        value: 'category'
      }, {
        label: '负责人',
        value: 'duty_name'
      }, {
        label: '当前合作状态',
        value: 'status'
      }, {
        label: '添加时间',
        value: 'create_time'
      }, {
        label: '更新时间',
        value: 'update_time'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      cooperation: [],
      create_start: [],
      update_start: [],
      loading: false,
      supplierVisible: false,
      downloadLoading: false,
      supplierTitle: '',
      supplierId: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        id: '',
        supplier_name: '',
        mobile: '',
        address: '',
        status: '',
        duty_name: '',
        category: '',
        wechat_num: ''
      },
      selectList: {
        typeList: [{
          value: '1',
          label: '正常'
        }, {
          value: '2',
          label: '暂停'
        }]
      }
    };
  },
  computed: {
    // 展开过滤列表
    filterList: function filterList() {
      return [{
        label: '供应商名称',
        inputType: 'input',
        name: 'supplier_name'
      }, {
        label: '电话号码',
        inputType: 'input',
        name: 'mobile'
      }, {
        label: '微信号',
        inputType: 'input',
        name: 'wechat_num'
      }, {
        label: '地址',
        inputType: 'input',
        name: 'address'
      }, {
        label: '开始合作时间',
        inputType: 'daterange',
        name: ['cooperate_start_time', 'cooperate_end_time']
      }, {
        label: '当前合作状态',
        inputType: 'select',
        name: 'status',
        children: this.selectList.typeList
      }, {
        label: '主营类目',
        inputType: 'input',
        name: 'category'
      }, {
        label: '负责人',
        inputType: 'input',
        name: 'duty_name'
      }, {
        label: '添加时间',
        inputType: 'daterange',
        name: ['create_start_time', 'create_end_time']
      }, {
        label: '更新时间',
        inputType: 'daterange',
        name: ['update_start_time', 'update_end_time']
      }];
    }
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      supplierDataList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            res.data.map(function (item) {
              item.status = item.status === '1' ? '正常' : '暂停';
            });
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total);
            _this.loading = false;
          }, 300);
        }
      });
    },
    // 新增供应商
    supplierClick: function supplierClick(type) {
      this.supplierVisible = true;

      if (type === 'add') {
        this.supplierTitle = '新增供应商';
      } else {
        this.supplierTitle = '修改供应商';
        this.supplierId = type;
      }
    },
    closeSupplier: function closeSupplier(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.supplierVisible = false;
    },
    operation: function operation(item, type) {
      var _this2 = this;

      var typeTitle;
      var typeHead;

      if (type === '2') {
        typeTitle = '确定是否停止和' + item.supplier_name + '的合作，停止后该供应商下所有商品&SKU无法正常下单！';
        typeHead = '停用合作';
      } else {
        typeTitle = '确定是否重新和' + item.supplier_name + '的合作，重新合作后该供应商下所有商品&SKU可以正常下单！';
        typeHead = '重新合作';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        supplierCooperate({
          id: item.id,
          status: type
        }).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.message);

            _this2.Inquire();
          }
        });
      }).catch(function () {
        _this2.$message.info('已取消设置');
      });
    },
    // 导出搜索结果
    handleDownload: function handleDownload() {
      var _this3 = this;

      var arr = Object.values(this.formInline).filter(function (item) {
        return item;
      });
      if (arr.length === 0) return this.$message({
        type: 'warning',
        message: '请选择搜索条件进行导出!'
      });
      this.downloadLoading = true;
      supplierExport(this.formInline).then(function (res) {
        blobUpload(res, 'supplier.xlsx').then(function (res) {
          console.log(res);
          _this3.downloadLoading = false;
        }).catch(function (err) {
          console.log(err);
          _this3.downloadLoading = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this3.downloadLoading = false;
      });
    },
    // 展开搜索
    handleFilter: function handleFilter(val) {
      var _this4 = this;

      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this4.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this4.formInline[filterItem] = item.value;
        } // 如果是日期


        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            _this4.formInline[item.name[0]] = item.value[0];
            _this4.formInline[item.name[1]] = item.value[1];
          } else {
            _this4.formInline[item.name[0]] = '';
            _this4.formInline[item.name[1]] = '';
          }
        }
      });
      this.Inquire();
    },
    // 重置
    resetFilter: function resetFilter() {
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 展开后二次搜索
    handleSecFilter: function handleSecFilter(val) {
      var _this5 = this;

      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this5.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this5.formInline[filterItem] = item.value;
        } // 如果是日期


        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            _this5.formInline[item.name[0]] = item.value[0];
            _this5.formInline[item.name[1]] = item.value[1];
          } else {
            _this5.formInline[item.name[0]] = '';
            _this5.formInline[item.name[1]] = '';
          }
        }
      });
      this.Inquire();
    }
  }
};