import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-integer.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var MIN_NUMBER = 0;
var MAX_NUMBER = 9999999;
export default {
  name: 'filterInput',
  props: {
    // 不限按钮显示
    showAll: {
      type: Boolean,
      default: false
    },
    // 筛选项标题
    label: {
      type: String,
      required: true
    },
    // 是否多选【仅在type为select时生效】
    multiple: {
      type: Boolean,
      default: false
    },

    /**
     * 筛选项类型（可根据实际情况扩展）
     * input：文本搜索框
     * radio： 单选框
     * checkbox： 多选框
     * select： 下拉框
     * date： 日期
     * daterange：日期区间
     * datetime： 时间日期
     * datetimerange： 时间日期区间
     * numberRange： 数字区间
     */
    inputType: {
      type: String,
      default: 'input',
      validator: function validator(value) {
        return ['input', 'radio', 'checkbox', 'select', 'date', 'daterange', 'datetime', 'datetimerange', 'numberRange'].includes(value);
      }
    },
    // 输入提示语
    placeholder: {
      type: String,
      default: ''
    },
    // 自定义筛选项列表 参考【{ label: '是', value: '1'}】
    children: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 是否需要支持v-model双向绑定
    binding: {
      type: Boolean,
      default: true
    },
    // 默认值
    value: {}
  },
  computed: {
    // 日期格式化
    inputFormat: function inputFormat() {
      if (!['date', 'datetime', 'daterange', 'datetimerange'].includes(this.inputType)) return '';
      return ['date', 'daterange'].includes(this.inputType) ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm:ss';
    },
    defaultDateTimeMerange: function defaultDateTimeMerange() {
      if (['datetimerange'].includes(this.inputType)) return ['00:00:00', '23:59:59'];
    }
  },
  watch: {
    filterValue: function filterValue(val, oldVal) {
      this.binding && this.$emit('input', val);
    }
  },
  data: function data() {
    return {
      // 筛选项对应的值
      filterValue: this.getDefaultValue(),
      form: {
        min: '',
        max: ''
      },
      // 数字区间校验
      rules: {
        min: [{
          validator: this.validateCom,
          trigger: 'blur'
        }, {
          validator: this.validateMin,
          trigger: 'blur'
        }],
        max: [{
          validator: this.validateCom,
          trigger: 'blur'
        }, {
          validator: this.validateMax,
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    // 清空
    clear: function clear() {
      if (this.multiple || this.inputType === 'checkbox') {
        this.filterValue = [];
      } else if (this.inputType === 'numberRange') {
        this.filterValue = {
          min: '',
          max: ''
        };
      } else {
        this.filterValue = '';
      }
    },
    // 初始化 filterValue
    getDefaultValue: function getDefaultValue() {
      if (![undefined, ''].includes(this.value)) return this.value;
      if (this.multiple || this.inputType === 'checkbox') return [];
      if (this.inputType === 'numberRange') return {
        min: '',
        max: ''
      };
      return '';
    },
    // 手动修改值
    setVal: function setVal(val) {
      this.filterValue = val;
    },
    // 区间校验
    validateCom: function validateCom(rule, value, callback) {
      if (this.filterValue[0] === '' && this.filterValue[1] === '') return callback;
      var one = Number(value);

      if (Number.isInteger(one)) {
        if (one < MIN_NUMBER) {
          return callback(new Error('输入值必须大于0'));
        } else if (one > MAX_NUMBER) {
          return callback(new Error('输入值必须小于9999999'));
        }

        return callback();
      }

      return callback(new Error('输入值必须为正整数'));
    },
    // 最小值校验
    validateMin: function validateMin(rule, value, callback) {
      var one = Number(value);
      var max = Number(this.filterValue.max);

      if (!max || one < max) {
        return callback();
      }

      return callback(new Error('输入值不得大于最大值'));
    },
    // 最大值校验
    validateMax: function validateMax(rule, value, callback) {
      var one = Number(value);
      var min = Number(this.filterValue.min);

      if (!min || one > min) {
        return callback();
      }

      return callback(new Error('输入值不得小于最小值'));
    },
    // 获取数值区间信息
    getFormDate: function getFormDate() {
      var _this = this;

      var ret = {};
      this.$refs.form.validate(function (valid) {
        ret.valid = valid;
        ret.form = _this.filterValue;
      });
      return ret;
    },
    // 监听日期输入框
    handleDatePickerChange: function handleDatePickerChange(val) {
      if (val == null) val = '';
    },
    // 重置表单校验
    resetForm: function resetForm() {
      this.$refs.form.resetFields();
    }
  }
};