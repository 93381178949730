import _slicedToArray from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import filterInput from './filterInput';
export default {
  name: 'index',
  components: {
    filterInput: filterInput
  },
  // inject: ['initList'],
  props: {
    /**
     * 筛选列表
     * label: String[必填] 标题
     * inputType: String[默认值input]
     * 筛选类型 input: 文本搜索框、radio: 单选框、checkbox: 多选框、select: 下拉框、date: 日期、daterange: 日期区间、datetime: 时间日期、datetimerange: 时间日期区间、numberRange: 数字区间
     * multiple: Boolean[默认值false]是否多选，仅对type为select时生效
     * children: Array 自定义筛选项的列表 参考[{ label: '是', value: '1'}]
     * placeholder: String 筛选项提示
     * name: String 筛选项对应键名 当inputType类型为日期区间或者时间区间时，可以填一个数组，分别放开始时间和结束时间的键名 => ['startTime', 'endTime']
     * defaultValue: String|Array 让筛选项默认显示，设置默认筛选的值，多选或者时间区间类型时传数组，单选或者非区间类型传字符串
     *  Example：
     * { label: '文字搜索筛选', inputType: 'input', name: 'input' },
     * { label: '单选筛选',inputType: 'radio',name: 'radio',children: [{ label:'选项1', value:'1'},{ label:'选项2', value:'0'}] },
     * { label: '多选筛选', inputType: 'checkbox', name: 'checkbox', children: [{ label:'选项1', value:'1'},{ label:'选项2', value:'0'}] },
     * { label: '下拉筛选', inputType: 'select', name: 'select', children: [{ label:'选项1', value:'1'},{ label:'选项2', value:'0'}], multiple: true },
     * { label: '日期时间筛选', inputType: 'datetime', name: 'datetime',},
     * { label: '日期筛选', inputType: 'date', name: 'date',},
     * { label: '日期区间', inputType: 'daterange', name: ['startTime','endTime']},
     * { label: '日期时间区间', inputType: 'datetimerange', name: ['startTime','endTime']},
     * { label: '数字区间', inputType: 'numberRange', name: ['startNum','endNum']},
     */
    filterList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },

    /**
     * 筛选的展现形式，目前有两种，一种是默认只显示一个展开过滤的按钮，点击通过弹窗显示筛选项【默认:default】
     * 另一种是默认显示两个筛选项，右边有一个展开按钮，点击显示全部筛选项【all】暂未完善
     */
    filterType: {
      type: String,
      default: 'default'
    },
    // 筛选项所占的份数（总数24，默认6），如果是弹窗筛选类型【filterType为default】，那么是设置筛选按钮所占的份数
    filterSpan: {
      type: Number,
      default: 6
    }
  },
  data: function data() {
    return {
      // 开启搜索弹窗
      popVisible: false,
      // 暂存筛选参数（防止页面请求时，参数合并造成数据重置失败）
      formData: {},
      // 条件筛选选中项
      chooseFilterList: [],
      // 展开搜索
      openFilter: false
    };
  },
  computed: {
    // 初始化筛选列表
    filterData: function filterData() {
      var _this = this;

      if (this.filterType === 'default' || this.openFilter === true) {
        if (!this.filterList.length) return [];
        var values = new Map();
        this.filterList.forEach(function (item) {
          item.defaultValue && values.set(item.name, item.defaultValue);
        });
        values.size && setTimeout(function () {
          return _this.setFilter(values);
        }, 0);
        return this.filterList.map(function (item, index) {
          return _objectSpread(_objectSpread({}, item), {}, {
            filterName: "filterItem".concat(index),
            binding: true
          });
        });
      } else {
        if (!this.filterList.length) return [];

        var _values = new Map();

        this.filterList.forEach(function (item) {
          item.defaultValue && _values.set(item.name, item.defaultValue);
        });
        _values.size && setTimeout(function () {
          return _this.setFilter(_values);
        }, 0);
        var sum = [];
        this.filterList.map(function (item, index) {
          if (index < 4) {
            sum.push(item);
          }
        });
        return sum.map(function (item, index) {
          return _objectSpread(_objectSpread({}, item), {}, {
            filterName: "filterItem".concat(index),
            binding: true
          });
        });
      }
    }
  },
  watch: {
    // 左右筛选双向绑定
    chooseFilterList: {
      deep: true,
      handler: function handler(val, oldVal) {
        var _this2 = this;

        //过滤出值改变了ref
        var refs = val.filter(function (item) {
          var contrast = oldVal.find(function (_e) {
            return _e.filterName === item.filterName;
          });
          return contrast === undefined || JSON.stringify(item.value) !== JSON.stringify(contrast.value);
        });
        refs.forEach(function (item) {
          var ref = _this2.$refs[item.filterName][0];
          ref.setVal(item.value);
        });
      }
    }
  },
  methods: {
    // 手动设置默认筛选项
    setFilter: function setFilter() {
      var _this3 = this;

      var map = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Map();
      if (!map.size) return;
      var formData = {};
      var chooseFilterList = [];
      map.forEach(function (value, key) {
        var filterItem = _this3.filterData.find(function (_) {
          return JSON.stringify(_.name) === JSON.stringify(key);
        }); // 检测传值正确性


        var isMultiple = ['datetimerange', 'daterange', 'numberRange', 'checkbox'].includes(filterItem.inputType) || filterItem.multiple;

        if (isMultiple && Object.prototype.toString.call(value) !== '[object Array]') {
          console.error("\u7B5B\u9009\u9879".concat(key, "\u9ED8\u8BA4\u503C\u5FC5\u987B\u4E3A\u6570\u7EC4\u7C7B\u578B"));
          return;
        }

        if (filterItem) {
          // 因为是预设值，所以日期和数字区间可以放在一块，把数字区间的预设值调整为数组
          if (['datetimerange', 'daterange', 'numberRange'].includes(filterItem.inputType) && Array.isArray(filterItem.name)) {
            var _filterItem$name = _slicedToArray(filterItem.name, 2),
                startName = _filterItem$name[0],
                endName = _filterItem$name[1];

            var _value = _slicedToArray(value, 2),
                startValue = _value[0],
                endValue = _value[1];

            formData[startName] = startValue;
            formData[endName] = endValue;
          } else {
            formData[filterItem.name] = value;
          }

          chooseFilterList.push(_objectSpread(_objectSpread({}, filterItem), {}, {
            inputType: ['checkbox', 'radio'].includes(filterItem.inputType) ? 'select' : filterItem.inputType,
            value: value,
            multiple: filterItem.inputType === 'checkbox' ? true : !!filterItem.multiple
          }));
        }
      });
      this.formData = formData;
      this.chooseFilterList = chooseFilterList;
    },
    // 条件筛选后双向绑定
    handlePopInputChange: function handlePopInputChange(val, data) {
      var _this4 = this;

      if (!this.chooseFilterList.length) return;
      this.chooseFilterList.forEach(function (item, index) {
        if (item.filterName === data.filterName) {
          var ref = _this4.$refs["chooseFilterItem".concat(index)][0];

          ref.setVal(val);
        }
      });
    },
    // 条件筛选后双向绑定default
    handleFilterInputChange: function handleFilterInputChange(val, data) {
      var _this5 = this;

      if (!this.filterData.length) return;
      this.filterData.forEach(function (item, index) {
        if (item.filterName === data.filterName) {
          var ref = _this5.$refs["filterItem".concat(index)][0];

          ref.setVal(val);
        }
      });
    },
    // 重置筛选
    resetFilter: function resetFilter() {
      var _this6 = this;

      var formData = {};
      this.filterData.forEach(function (item, index) {
        var ref = _this6.$refs["filterItem".concat(index)][0];

        ref.clear();

        if (['datetimerange', 'daterange', 'numberRange'].includes(ref.inputType) && Array.isArray(ref.name)) {
          var _ref$name = _slicedToArray(ref.name, 2),
              startName = _ref$name[0],
              endName = _ref$name[1];

          formData[startName] = '';
          formData[endName] = '';
        } else {
          formData[ref.name] = ref.multiple || ref.inputType === 'checkbox' ? [] : '';
        }
      });
      this.popVisible = false;
      this.$emit('resetFilter');
      this.chooseFilterList.length = 0; // this.initList({ ...formData })
    },
    // 条件筛选
    handleFilter: function handleFilter() {
      var _this7 = this;

      var formData = {};
      var chooseFilterList = [];
      var validate = true; // console.log('this.filterData--',this.filterData)

      this.filterData.forEach(function (item, index) {
        var ref = _this7.$refs["filterItem".concat(index)][0];

        if (ref.filterValue !== '') {
          // 筛选项日期区间处理
          if (['datetimerange', 'daterange'].includes(ref.inputType) && Array.isArray(ref.name)) {
            var _ref$name2 = _slicedToArray(ref.name, 2),
                startName = _ref$name2[0],
                endName = _ref$name2[1];

            var _ref$filterValue = _slicedToArray(ref.filterValue, 2),
                startTime = _ref$filterValue[0],
                endTime = _ref$filterValue[1];

            formData[startName] = startTime;
            formData[endName] = endTime;
          } else if (['numberRange'].includes(ref.inputType) && Array.isArray(ref.name)) {
            var _ref$getFormDate = ref.getFormDate(),
                valid = _ref$getFormDate.valid,
                form = _ref$getFormDate.form;

            var _ref$name3 = _slicedToArray(ref.name, 2),
                _startName = _ref$name3[0],
                _endName = _ref$name3[1];

            var min = form.min,
                max = form.max;
            formData[_startName] = min;
            formData[_endName] = max;
            validate = valid;
          } else {
            // console.log('ref.filterValue',ref.filterValue)
            formData[ref.name] = ref.filterValue;
          }

          if (_this7.filterType === 'default' && ref.filterValue !== '' && JSON.stringify(ref.filterValue) != '[]' && JSON.stringify(ref.filterValue) != "{'min': '', 'max': ''}") {
            chooseFilterList.push(_objectSpread(_objectSpread({}, item), {}, {
              inputType: ['checkbox', 'radio'].includes(item.inputType) ? 'select' : item.inputType,
              value: ref.filterValue,
              multiple: item.inputType === 'checkbox' ? true : !!item.multiple
            }));
          }

          if (_this7.filterType === 'all' && ref.filterValue !== '' && JSON.stringify(ref.filterValue) != '[]' && JSON.stringify(ref.filterValue) != "{'min': '', 'max': ''}") {
            chooseFilterList.push(_objectSpread(_objectSpread({}, item), {}, {
              inputType: ['checkbox', 'radio'].includes(item.inputType) ? 'select' : item.inputType,
              value: ref.filterValue,
              multiple: item.inputType === 'checkbox' ? true : !!item.multiple
            }));
          }
        }
      }); // console.log('formData',formData)
      // 数值区间校验

      if (!validate) return; //重置逻辑处理

      Object.keys(this.formData).forEach(function (item) {
        if (formData[item] === undefined) {
          formData[item] = '';
        }
      });
      this.formData = formData;
      this.popVisible = false;
      this.chooseFilterList = chooseFilterList; // this.initList({ ...chooseFilterList })
      // console.log('chooseFilterList',chooseFilterList)

      this.$emit('handleFilter', chooseFilterList);
    },
    // 展开过滤过的二次筛选
    handleSecFilter: function handleSecFilter() {
      var _this8 = this;

      var formData = {};
      var validate = true;
      this.chooseFilterList.forEach(function (item, index) {
        var ref = _this8.$refs["chooseFilterItem".concat(index)][0]; // 筛选项日期区间处理


        if (['datetimerange', 'daterange'].includes(ref.inputType) && Array.isArray(ref.name)) {
          var _ref$name4 = _slicedToArray(ref.name, 2),
              startName = _ref$name4[0],
              endName = _ref$name4[1];

          var _ref = ref.filterValue || [],
              _ref2 = _slicedToArray(_ref, 2),
              startTime = _ref2[0],
              endTime = _ref2[1];

          formData[startName] = startTime;
          formData[endName] = endTime;
        } else if (['numberRange'].includes(ref.inputType) && Array.isArray(ref.name)) {
          var _ref$getFormDate2 = ref.getFormDate(),
              valid = _ref$getFormDate2.valid,
              form = _ref$getFormDate2.form;

          var _ref$name5 = _slicedToArray(ref.name, 2),
              _startName2 = _ref$name5[0],
              _endName2 = _ref$name5[1];

          var min = form.min,
              max = form.max;
          formData[_startName2] = min;
          formData[_endName2] = max;
          validate = valid;
        } else {
          formData[ref.name] = ref.filterValue;
        }
      }); // 数值区间校验

      if (!validate) return; // 重置逻辑处理

      Object.keys(this.formData).forEach(function (item) {
        if (formData[item] === undefined) {
          formData[item] = '';
        }
      });
      this.formData = formData; // this.initList({ ...this.chooseFilterList })

      this.$emit('handleSecFilter', this.chooseFilterList);
    }
  }
};