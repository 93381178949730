import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var commodityRouter = [{
  path: '/supplychain',
  component: Layout,
  alwaysShow: true,
  name: 'supplychain',
  redirect: 'noRedirect',
  meta: {
    title: '供应链后台',
    icon: 'el-icon-s-operation'
  },
  children: [{
    path: 'commodity/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/supplychain/commodity/index'));
      });
    },
    name: 'commodity',
    meta: {
      title: '商品管理',
      keepAlive: true
    }
  }, {
    path: 'commodity-detail/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/supplychain/commodity-detail/index'));
      });
    },
    name: 'commodity-detail',
    meta: {
      title: '商品详情'
    },
    hidden: true
  }, {
    path: 'hosting/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/supplychain/commodity-hosting/index'));
      });
    },
    name: 'hosting',
    meta: {
      title: '商品托管池'
    }
  }, {
    path: 'filter/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/supplychain/commodity-filter/index'));
      });
    },
    name: 'filter',
    meta: {
      title: '商品报价'
    }
  }, {
    path: 'commodity-filter-detail/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/supplychain/commodity-filter/detail'));
      });
    },
    name: 'commodity-filter-detail',
    meta: {
      title: '报价详情'
    },
    hidden: true
  }]
}];
export default commodityRouter;