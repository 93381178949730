//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { setSettlementStatus, changeSupplierAmount } from '@/api/settlement';
export default {
  name: 'set-up-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '设置'
    },
    flag: {
      type: String,
      default: 'preview'
    },
    formData: {
      type: Object,
      default: function _default() {}
    },
    platform: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      setUpForm: {},
      sipplierForm: {
        money: ''
      },
      rules: {},
      stautsList: ['已结算', '已退款', '已处理', '已取消'],
      labelBasis: [{
        label: '结算单号:',
        value: 'settlement_no'
      }, {
        label: '第三方订单号:',
        value: 'thirdParty_order_no'
      }, {
        label: '客户名称:',
        value: 'service_name'
      }, {
        label: '客户店铺:',
        value: 'store_name'
      }, {
        label: '结算折扣率:',
        value: 'discount_rate'
      }, {
        label: '订单总金额:',
        value: 'total_amount'
      }, {
        label: '客户结算金额:',
        value: 'service_total_amount'
      }, {
        label: '商品金额:',
        value: 'product_total_amount'
      }, {
        label: '物流费用:',
        value: 'logistics_cost'
      } // { label: '币种:', value: 'currency' }
      ]
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  watch: {
    formData: {
      handler: function handler(data) {
        this.setUpForm = data;
      },
      deep: true
    }
  },
  methods: {
    setUpAdd: function setUpAdd() {
      var _this = this;

      if (this.$parent.setUpId === '0') {
        this.$refs.setUpForm.validate(function (valid) {
          if (valid) {
            setSettlementStatus({
              id: _this.setUpForm.id,
              status: _this.setUpForm.settlement_status,
              remarks: _this.setUpForm.remarks,
              plat_form: _this.platform
            }).then(function (res) {
              if (res.code === 200) {
                _this.$message.success('提交成功');

                _this.$emit('setUpAdd', 1);

                _this.dialogVisible = false;

                _this.$refs.setUpForm.resetFieleds();
              }
            }).catch(function (err) {
              console.log(err);
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$refs.sipplierForm.validate(function (valid) {
          if (valid) {
            changeSupplierAmount({
              id: _this.setUpForm.id,
              money: _this.sipplierForm.money,
              plat_form: _this.platform
            }).then(function (res) {
              if (res.code === 200) {
                _this.$message.success('提交成功');

                _this.$emit('setUpAdd', 1);

                _this.dialogVisible = false;
                _this.sipplierForm.money = '';
              }
            }).catch(function (err) {
              console.log(err);
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    close: function close() {
      this.$emit('setUpAdd', 0);
      this.dialogVisible = false;
    }
  }
};