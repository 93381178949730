var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-cell" },
    [
      _c(
        "div",
        { staticClass: "afterSaleTitle" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", icon: "el-icon-arrow-left" },
              on: { click: _vm.prev }
            },
            [_vm._v("返回")]
          ),
          _c("span", { staticClass: "title" }, [
            _vm._v("[ " + _vm._s(_vm.$route.query.service_name) + " ]")
          ]),
          _c("span", { staticClass: "orderId" }, [
            _vm._v(" " + _vm._s(_vm.$route.query.order_name) + " ")
          ]),
          _c(
            "el-button",
            {
              staticClass: "button2",
              attrs: {
                type: "primary",
                size: "small",
                round: "",
                loading: _vm.SubmitLoading
              },
              on: { click: _vm.submitReply }
            },
            [_vm._v("回复")]
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "after-sale-card" },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", [_vm._v("售后信息")])
          ]),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              staticClass: "infoForm",
              attrs: { "label-width": "105px", model: _vm.afterSaleInfo }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "售后类型:" } }, [
                        _c("span", { staticClass: "in_txt" }, [
                          _vm._v(
                            _vm._s(_vm.typeList[_vm.afterSaleInfo.after_type])
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "客户需求:" } }, [
                        _c("span", { staticClass: "in_txt" }, [
                          _vm._v(
                            _vm._s(
                              _vm.afterModel[_vm.afterSaleInfo.after_model]
                            )
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "物流状态:" } }, [
                        _vm.afterSaleInfo.shipping_json
                          ? _c("span", { staticClass: "in_txt" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.afterSaleInfo.shipping_json
                                    .logistics_status
                                )
                              )
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "in_txt" }, [_vm._v("--")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "创建时间:" } }, [
                        _c("span", { staticClass: "in_txt" }, [
                          _vm._v(_vm._s(_vm.afterSaleInfo.after_create_time))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品SKU:" } },
                        _vm._l(_vm.afterSaleInfo.product_json, function(
                          item,
                          k
                        ) {
                          return _c("span", { key: k, staticClass: "in_txt" }, [
                            _vm._v(_vm._s(item.sku_name) + " ")
                          ])
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "订单编号:" } }, [
                        _c("span", { staticClass: "in_txt" }, [
                          _vm._v(_vm._s(_vm.afterSaleInfo.order_no))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "第三方运单号:" } },
                        [
                          _vm.afterSaleInfo.shipping_json
                            ? _c("span", { staticClass: "in_txt" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.afterSaleInfo.shipping_json.logistics_no
                                  )
                                )
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "in_txt" }, [
                            _vm._v(_vm._s(_vm.afterSaleInfo.third_order_no))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card ml50 mr50 mt20 mb20" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "售后信息", name: "first" } },
                [
                  _c(
                    "el-card",
                    { staticClass: "after-sale-card" },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          readonly: "",
                          rows: 8,
                          placeholder: "请输入内容"
                        },
                        model: {
                          value: _vm.afterSaleInfo.info,
                          callback: function($$v) {
                            _vm.$set(_vm.afterSaleInfo, "info", $$v)
                          },
                          expression: "afterSaleInfo.info"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "反馈图片", name: "second" } },
                [
                  _c(
                    "el-card",
                    { staticClass: "after-sale-card" },
                    _vm._l(_vm.srcList, function(fit) {
                      return _c(
                        "div",
                        { key: fit, staticClass: "feedback_pic" },
                        [
                          _c(
                            "el-image",
                            {
                              staticClass: "sku_image",
                              staticStyle: {
                                width: "100px",
                                height: "100px",
                                float: "left"
                              },
                              attrs: {
                                src: fit,
                                fit: fit,
                                "preview-src-list": [fit]
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error"
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                    staticStyle: { "font-size": "30px" }
                                  })
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showTabs
        ? _c(
            "div",
            _vm._l(_vm.reply_data, function(item, kx) {
              return _c(
                "el-card",
                { key: kx, staticClass: "box-card  ml50 mr50 mt20 mb20" },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { type: "card" },
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: item.activeReplyName,
                        callback: function($$v) {
                          _vm.$set(item, "activeReplyName", $$v)
                        },
                        expression: "item.activeReplyName"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "回复内容", name: "first" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              readonly: "",
                              rows: 8,
                              placeholder: "无内容"
                            },
                            model: {
                              value: item.reply_info,
                              callback: function($$v) {
                                _vm.$set(item, "reply_info", $$v)
                              },
                              expression: "item.reply_info"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "回复图片", name: "second" } },
                        _vm._l(item.reply_img, function(fit) {
                          return _c(
                            "div",
                            { key: fit, staticClass: "feedback_pic" },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "sku_image",
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    float: "left"
                                  },
                                  attrs: {
                                    src: fit,
                                    fit: fit,
                                    "preview-src-list": [fit]
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error"
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                        staticStyle: { "font-size": "30px" }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("reply-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          flag: _vm.afterFlag,
          "form-data": _vm.formData
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          },
          "update:flag": function($event) {
            _vm.afterFlag = $event
          },
          handleUpdate: _vm.handleUpdate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }