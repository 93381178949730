import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { logisticsInfo } from '@/api/information';
export default {
  name: 'channel-price',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    }
  },
  data: function data() {
    return {
      labelList: [{
        label: '国家',
        value: 'country_name'
      }, {
        label: '国家英文缩写',
        value: 'country_code'
      }, {
        label: '渠道名称',
        value: 'channel_name'
      }, {
        label: '支持产品属性',
        value: 'product_type'
      }, {
        label: '参考时效(工作日)',
        value: 'aging'
      }, {
        label: '重量段(KG)',
        value: 'weight'
      }, {
        label: '运费RMB/KG',
        value: 'shpping_cost'
      }, {
        label: '挂号费RMB/KG',
        value: 'registration_fee'
      }, {
        label: '备注',
        value: 'remark'
      }, {
        label: '最近更新时间',
        value: 'update_time'
      }],
      formInline: {
        country_name: '',
        country_code: '',
        type: '',
        channel_name: ''
      },
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      loading: false,
      attrList: ['特货', '普货', '液体']
    };
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      this.formInline.id = this.$route.query.id;
      this.formInline.iDisplayLength = this.listQuery.limit;
      this.formInline.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      logisticsInfo(this.formInline).then(function (res) {
        if (res.code === 200) {
          _this.tableData = res.data;
          _this.listQuery.total = +res.total;
          _this.loading = false;
        }
      });
    }
  }
};