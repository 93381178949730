import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { baseSystemGetList, baseSystemGetMap, baseSystemRead } from '@/api/message';
export default {
  name: 'index',
  data: function data() {
    return {
      systemInformation: [],
      CustomerMessage: [],
      isSystem: true,
      type: 1,
      systemList: {},
      noData: require('./components/image/noData.png')
    };
  },
  created: function created() {
    this.initMessageList(this.type);
    this.init();
  },
  methods: {
    // 消息列表（1 为系统消息，2 为客户消息）
    initMessageList: function initMessageList(type) {
      var _this = this;

      baseSystemGetList({
        type: JSON.stringify(type)
      }).then(function (res) {
        if (res.code === 200) {
          if (type === 1) {
            var isReadList = [];
            _this.systemInformation = res.data;
            var arr = [];
            res.data.map(function (it) {
              arr.push(it.id);
            }); // 请求是否已读

            baseSystemRead({
              id: arr
            }).then(function (res) {
              if (res.code === 200) {
                isReadList.push(res.data);
              }
            }); // 遍历列表数据，将是否已读塞入列表

            _this.systemInformation.map(function (it, inx) {
              _this.$set(it, 'isRead', isReadList[inx]);
            });
          } else {
            var _isReadList = [];
            _this.CustomerMessage = res.data;
            var _arr = [];
            res.data.map(function (it) {
              _arr.push(it.id);
            }); // 请求是否已读

            baseSystemRead({
              id: _arr
            }).then(function (res) {
              if (res.code === 200) {
                _isReadList.push(res.data);
              }
            }); // 遍历列表数据，将是否已读塞入列表

            _this.CustomerMessage.map(function (it, inx) {
              _this.$set(it, 'isRead', _isReadList[inx]);
            });
          }
        }
      });
    },
    init: function init() {
      var _this2 = this;

      baseSystemGetMap().then(function (res) {
        if (res.code === 200) {
          _this2.systemList = res.data;
        }
      });
    },
    // 系统消息
    handleSystem: function handleSystem() {
      this.isSystem = true;
      this.type = 1;
      this.initMessageList(this.type);
    },
    // 客户消息
    handleCustomer: function handleCustomer() {
      this.isSystem = false;
      this.type = 2;
      this.initMessageList(this.type);
    },
    handleClickToDetail: function handleClickToDetail(val) {
      switch (val) {
        case 1:
          // 系统消息
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'system'
            },
            query: {
              type: 'system',
              tagTitle: '系统通知'
            }
          });
          break;

        case 2:
          // 报价信息
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 3:
          // 账单消息
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'bill'
            },
            query: {
              type: 'bill',
              tagTitle: '账单'
            }
          });
          break;

        case 4:
          // 取消托管
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'product'
            },
            query: {
              type: 'product',
              tagTitle: '商品变更'
            }
          });
          break;

        case 5:
          // 客户修改商品消息详情
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'product'
            },
            query: {
              type: 'product',
              tagTitle: '商品变更'
            }
          });
          break;

        case 6:
          // 拒绝修改商品价格
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 7:
          // 同意修改商品价格
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 8:
          // 报价采纳通知
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 9:
          // 售后消息详情3
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '售后'
            }
          });
          break;

        case 10:
          // 售后消息详情2
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '售后'
            }
          });
          break;

        case 11:
          // 退款审核通过
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '退款审核通过'
            }
          });
          break;

        case 12:
          // 退款驳回
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '退款驳回'
            }
          });
          break;

        case 13:
          // 退款审核
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '退款审核'
            }
          });
          break;

        case 14:
          // 售后消息详情
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '售后'
            }
          });
          break;

        case 15:
          // 订单异常消息详情2
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'order'
            },
            query: {
              type: 'order',
              tagTitle: '订单异常'
            }
          });
          break;

        case 16:
          // 订单异常消息详情1
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'order'
            },
            query: {
              type: 'order',
              tagTitle: '订单异常'
            }
          });
          break;

        case 17:
          // 订单关联消息详情
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'order'
            },
            query: {
              type: 'order',
              tagTitle: '订单变更'
            }
          });
          break;

        case 18:
          // 订单退款
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '订单退款'
            }
          });
          break;
      }
    },
    handleClickToLink: function handleClickToLink(val) {
      switch (val) {
        case 2:
          // 产品
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 3:
          // 订单
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'bill'
            },
            query: {
              type: 'bill',
              tagTitle: '账单'
            }
          });
          break;

        case 4:
          // 售后
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'product'
            },
            query: {
              type: 'product',
              tagTitle: '商品变更'
            }
          });
          break;

        case 5:
          // 报价
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'product'
            },
            query: {
              type: 'product',
              tagTitle: '商品变更'
            }
          });
          break;

        case 6:
          // 账单
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 7:
          // 退款审核
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 8:
          // 退款审核通过
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'quoted'
            },
            query: {
              type: 'quoted',
              tagTitle: '报价'
            }
          });
          break;

        case 9:
          // 退款审核驳回
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '售后'
            }
          });
          break;

        case 10:
          // 客户
          this.$router.push({
            name: 'message-list',
            params: {
              id: 'after'
            },
            query: {
              type: 'after',
              tagTitle: '售后'
            }
          });
          break;
      }
    }
  }
};