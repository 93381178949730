import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
import plugins from './plugins';
import toolbar from './toolbar';
import load from './dynamicLoadScript';
var tinymceCDN = 'https://cdn.jsdelivr.net/npm/tinymce-all-in-one-lin@4.9.5/tinymce.min.js';
export default {
  name: 'tinymce',
  props: {
    id: {
      type: String,
      default: function _default() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
      }
    },
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    },
    readonly: {
      type: Number,
      default: 0
    },
    upload: {
      type: Boolean,
      default: true
    },
    print: {
      type: Boolean,
      default: true
    },
    baseImg: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    baseImgType: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 0
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      valueLength: 0,
      languageTypeList: {
        'en': 'en',
        'zh': 'zh_CN',
        'es': 'es_MX',
        'ja': 'ja'
      }
    };
  },
  computed: {
    containerWidth: function containerWidth() {
      var width = this.width;

      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return "".concat(width, "px");
      }

      return width;
    }
  },
  watch: {
    value: function value(val) {
      var _this2 = this;

      val = val.replace(/(↵)+/g, '');
      val = val.replace(/\n+/g, '');

      if (val) {
        if (this.pageType === 'lazada') {
          val = val.replace(/<p>/g, '<li>').replace(/<\/p>/g, '</li>');
          this.$nextTick(function () {
            return window.tinymce.get(_this2.tinymceId).setContent(val || '');
          });
        }

        if (!this.hasChange && this.hasInit) {
          setTimeout(function () {
            _this2.$nextTick(function () {
              return window.tinymce.get(_this2.tinymceId).setContent(val || '');
            });
          }, 100);
        }
      } else {
        this.$nextTick(function () {
          return window.tinymce.get(_this2.tinymceId).setContent('');
        });
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  activated: function activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated: function deactivated() {
    this.destroyTinymce();
  },
  destroyed: function destroyed() {
    this.destroyTinymce();
  },
  methods: {
    escape2Html: function escape2Html(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };

      if (str) {
        str = str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
          return arrEntities[t];
        });
        str = str.replace(/<\/?[^>]*>/g, ''); // 去除HTML tag
        // str = str.replace(/[ | ]*\n/g, '\n') // 去除行尾空白
      }

      return str;
    },
    init: function init() {
      var _this3 = this;

      // dynamic load tinymce from cdn
      load(tinymceCDN, function (err) {
        if (err) {
          _this3.$message.error(err.message);

          return;
        }

        _this3.initTinymce();
      });
    },
    initTinymce: function initTinymce() {
      var _this4 = this;

      window.tinymce.init({
        selector: "#".concat(this.tinymceId),
        // language: this.languageTypeList['zh'],
        height: this.height,
        body_class: 'panel-body ',
        object_resizing: !false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        menubar: this.menubar,
        plugins: plugins,
        end_container_on_empty_block: true,
        powerpaste_word_import: 'clean',
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: 'square',
        advlist_number_styles: 'default',
        imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
        default_link_target: '_blank',
        link_title: false,
        nonbreaking_force_tab: true,
        // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        // branding: false,
        elementpath: false,
        // 左下角的当前标签路径
        statusbar: false,
        remove_linebreaks: false,
        force_br_newlines: true,
        force_p_newlines: false,
        // 清除换行符
        gecko_spellcheck: true,
        forced_root_block: '',
        // Needed for 3.x
        entity_encoding: 'raw',
        // 禁止转义
        init_instance_callback: function init_instance_callback(editor) {
          if (_this4.value) {
            editor.setContent(_this4.value);
          }

          _this4.hasInit = true;
          editor.on('NodeChange Change KeyUp SetContent', function () {
            _this4.hasChange = true;

            _this4.$emit('input', editor.getContent());
          });
        },
        setup: function setup(editor) {
          editor.on('FullscreenStateChanged', function (e) {
            _this4.fullscreen = e.state;
          });
          editor.on('keydown', function (e) {
            if (e.shiftKey && e.keyCode === 13) {
              window.tinymce.execCommand('mceInsertContent', false, '<br><br>');
              window.tinymce.dom.Event.cancel(e);
              return;
            }

            if (e.keyCode === 13 && !e.shiftKey) {
              window.tinymce.execCommand('mceInsertContent', false, '<br>');
              window.tinymce.dom.Event.cancel(e);

              _this4.$emit('reply');

              return;
            }
          });
        },
        readonly: this.readonly
      });
    },
    getContent: function getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    setContent: function setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    destroyTinymce: function destroyTinymce() {
      var tinymce = window.tinymce.get(this.tinymceId);

      if (this.fullscreen) {
        tinymce.execCommand('mceFullScreen');
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    execCommand: function execCommand(content) {
      window.tinymce.get(this.tinymceId).execCommand('mceInsertContent', false, content);
    },
    // 本地上传图片  添加到文本域
    imageSuccessCBK: function imageSuccessCBK(arr) {
      var _this = this;

      this.$emit('addPic', arr);
      arr.forEach(function (v) {
        window.tinymce.get(_this.tinymceId).insertContent("<img class=\"wscnph\" src=\"".concat(v, "\" >"));
      });
    },
    // 从图片库选择图片  添加到文本域
    storeImg: function storeImg(arr) {
      var _this = this;

      arr.forEach(function (v) {
        window.tinymce.get(_this.tinymceId).insertContent("<img class=\"wscnph\" src=\"".concat(v, "\" >"));
      });
    },
    handleReply: function handleReply() {
      this.$emit('reply');
    }
  }
};