var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        width: "600px",
        center: "",
        "append-to-body": "",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "shopForm",
          attrs: {
            model: _vm.shopForm,
            rules: _vm.shopRules,
            "label-width": "90px",
            size: "small"
          }
        },
        [
          _c(
            "el-row",
            { staticClass: "row-bg" },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "托管商品:", prop: "pool_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            "collapse-tags": "",
                            clearable: "",
                            placeholder: "请选择"
                          },
                          on: {
                            change: _vm.handleCheckedChange,
                            "remove-tag": _vm.handleRemoveTag
                          },
                          model: {
                            value: _vm.shopForm.pool_id,
                            callback: function($$v) {
                              _vm.$set(_vm.shopForm, "pool_id", $$v)
                            },
                            expression: "shopForm.pool_id"
                          }
                        },
                        _vm._l(_vm.custody, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.product_title,
                              value: item.id,
                              disabled:
                                _vm.poolList.includes(item.id) || item.disabled
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "售卖价格:", prop: "sale_price" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "售卖价格" },
                        model: {
                          value: _vm.shopForm.sale_price,
                          callback: function($$v) {
                            _vm.$set(_vm.shopForm, "sale_price", $$v)
                          },
                          expression: "shopForm.sale_price"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "售卖重量:", prop: "sku_weight" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "售卖重量" },
                        model: {
                          value: _vm.shopForm.sku_weight,
                          callback: function($$v) {
                            _vm.$set(_vm.shopForm, "sku_weight", $$v)
                          },
                          expression: "shopForm.sku_weight"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流商:", prop: "logistics_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "物流商" },
                          model: {
                            value: _vm.shopForm.logistics_id,
                            callback: function($$v) {
                              _vm.$set(_vm.shopForm, "logistics_id", $$v)
                            },
                            expression: "shopForm.logistics_id"
                          }
                        },
                        _vm._l(_vm.providers, function(provider, pindex) {
                          return _c("el-option", {
                            key: pindex,
                            attrs: {
                              label: provider.logistics_name,
                              value: provider.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货渠道:", prop: "chann_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.channelDisable,
                            placeholder: "发货渠道"
                          },
                          model: {
                            value: _vm.shopForm.chann_id,
                            callback: function($$v) {
                              _vm.$set(_vm.shopForm, "chann_id", $$v)
                            },
                            expression: "shopForm.chann_id"
                          }
                        },
                        _vm._l(_vm.channelList, function(chann, cindex) {
                          return _c("el-option", {
                            key: cindex,
                            attrs: {
                              label: chann.channel_name,
                              value: chann.id
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务费:", prop: "service_fee" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "服务费" },
                        model: {
                          value: _vm.shopForm.service_fee,
                          callback: function($$v) {
                            _vm.$set(_vm.shopForm, "service_fee", $$v)
                          },
                          expression: "shopForm.service_fee"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save("shopForm")
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }