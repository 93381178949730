var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderSystem" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.resetClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.tabList, function(item, key) {
              return _c("el-tab-pane", {
                key: key,
                attrs: { label: item, name: String(key) }
              })
            }),
            1
          ),
          _c(
            "el-form",
            {
              ref: "formInline",
              attrs: {
                inline: true,
                model: _vm.formInline,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称:", prop: "id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        multiple: "",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formInline.customer_name,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "customer_name", $$v)
                        },
                        expression: "formInline.customer_name"
                      }
                    },
                    _vm._l(_vm.serviceList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: {
                          label: item.service_name,
                          value: String(item.id)
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称:", prop: "product_title" } },
                [
                  _c("el-input", {
                    staticClass: "w-200",
                    attrs: {
                      size: "small",
                      clearable: "",
                      placeholder: "请输入商品名称"
                    },
                    model: {
                      value: _vm.formInline.product_title,
                      callback: function($$v) {
                        _vm.$set(_vm.formInline, "product_title", $$v)
                      },
                      expression: "formInline.product_title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "托管状态:", prop: "manage_status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "托管状态"
                      },
                      model: {
                        value: _vm.formInline.manage_status,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "manage_status", $$v)
                        },
                        expression: "formInline.manage_status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "正常", value: "1" } }),
                      _c("el-option", {
                        attrs: { label: "取消托管", value: "2" }
                      }),
                      _c("el-option", {
                        attrs: { label: "未匹配", value: "3" }
                      }),
                      _c("el-option", {
                        attrs: { label: "未匹配-有订单", value: "4" }
                      }),
                      _c("el-option", {
                        attrs: { label: "正常-价格改动", value: "5" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺链接:", prop: "store_url" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "w-200",
                      attrs: {
                        size: "small",
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.formInline.store_url,
                        callback: function($$v) {
                          _vm.$set(_vm.formInline, "store_url", $$v)
                        },
                        expression: "formInline.store_url"
                      }
                    },
                    _vm._l(_vm.storeList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item, value: String(item) }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        icon: "el-icon-search"
                      },
                      on: { click: _vm.search }
                    },
                    [_vm._v("筛 选")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.resetClick }
                    },
                    [_vm._v("重 置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            [
              _vm._l(_vm.labelList, function(item, idx) {
                return [
                  item.show === undefined || item.show.includes(_vm.activeName)
                    ? _c("el-table-column", {
                        key: idx,
                        attrs: {
                          width: item.width,
                          label: item.label,
                          prop: item.value,
                          align: item.align || "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  item.type === undefined
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row[item.value]))
                                      ])
                                    : _vm._e(),
                                  item.type === "image"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top",
                                                width: 300,
                                                "open-delay": 300,
                                                trigger: "click"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass: "product-img",
                                                  attrs: {
                                                    slot: "reference",
                                                    src: _vm._f("ArrToStr")(
                                                      scope.row.img_url
                                                    ),
                                                    alt: "加载失败..."
                                                  },
                                                  slot: "reference"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "error-icon el-icon-picture-outline"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("el-image", {
                                                staticStyle: {
                                                  height: "300px",
                                                  width: "300px"
                                                },
                                                attrs: {
                                                  src: _vm._f("ArrToStr")(
                                                    scope.row.img_url
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.type === "link_sku_img"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top",
                                                width: 300,
                                                "open-delay": 300,
                                                trigger: "click"
                                              }
                                            },
                                            [
                                              _c(
                                                "el-image",
                                                {
                                                  staticClass: "product-img",
                                                  attrs: {
                                                    slot: "reference",
                                                    src: scope.row.sku_url,
                                                    alt: "加载失败..."
                                                  },
                                                  slot: "reference"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "image-slot",
                                                      attrs: { slot: "error" },
                                                      slot: "error"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "error-icon el-icon-picture-outline"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("el-image", {
                                                staticStyle: {
                                                  height: "300px",
                                                  width: "300px"
                                                },
                                                attrs: {
                                                  src: scope.row.sku_url
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.type === "hosting_status"
                                    ? _c("div", [
                                        scope.row.manage_status === "正常"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#2ba45c"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.manage_status
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.manage_status === "取消托管"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.manage_status)
                                              )
                                            ])
                                          : _vm._e(),
                                        scope.row.manage_status === "未匹配"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#f60602"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.manage_status
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.manage_status ===
                                        "未匹配-有订单"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#ce3131"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.manage_status
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        scope.row.manage_status ===
                                        "正常-价格改动"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#f69330"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.manage_status
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  item.type === "operation"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-link",
                                            {
                                              staticStyle: { display: "block" },
                                              attrs: {
                                                type: "primary",
                                                underline: false
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.actions(scope.row)
                                                }
                                              }
                                            },
                                            [_vm._v("关联")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _c("pagination", {
            attrs: {
              total: _vm.listQuery.total,
              page: _vm.listQuery.page,
              limit: _vm.listQuery.limit
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery, "page", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery, "limit", $event)
              },
              pagination: _vm.Inquire
            }
          })
        ],
        1
      ),
      _c("link-commodity-dialog", {
        attrs: {
          visible: _vm.dialogVisible,
          flag: _vm.hostFlag,
          "form-data": _vm.formData
        },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          },
          "update:flag": function($event) {
            _vm.hostFlag = $event
          },
          close: _vm.Inquire
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }