var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticClass: "box-card mt20" }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [
        _vm.selFileCount > 0
          ? _c(
              "div",
              { staticClass: "selected-tip" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "light",
                      content: "全选",
                      placement: "top"
                    }
                  },
                  [
                    _c("el-checkbox", {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll"
                      }
                    })
                  ],
                  1
                ),
                _c("span", { staticClass: "tip-text" }, [
                  _vm._v(_vm._s("选择了" + _vm.selFileCount + "个媒体文件"))
                ]),
                _c(
                  "a",
                  {
                    staticClass: "delete-media ml20",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.deleteMedia($event)
                      }
                    }
                  },
                  [_vm._v("删除文件")]
                )
              ],
              1
            )
          : _vm._e()
      ]
    ),
    _c(
      "div",
      { staticClass: "bg-white" },
      [
        _c("el-scrollbar", { attrs: { "wrap-class": "scrollbar-wrapper" } }, [
          _c(
            "div",
            {
              staticClass: "grid-content upload-photos",
              on: { dragenter: _vm.dragEnter, drop: _vm.drop }
            },
            [
              _c(
                "ul",
                { staticClass: "el-upload-list el-upload-list--picture-card" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { tag: "span" },
                      model: {
                        value: _vm.fileList,
                        callback: function($$v) {
                          _vm.fileList = $$v
                        },
                        expression: "fileList"
                      }
                    },
                    _vm._l(_vm.fileList, function(file, index) {
                      return _c(
                        "li",
                        {
                          key: index,
                          staticClass: "el-upload-list__item",
                          class: [
                            file.showProgress ? "is-ready" : "is-success"
                          ],
                          attrs: { tabindex: index },
                          on: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "delete",
                                  [8, 46],
                                  $event.key,
                                  ["Backspace", "Delete", "Del"]
                                )
                              ) {
                                return null
                              }
                              return _vm.deleteImg(index)
                            }
                          }
                        },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: file.url }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "el-upload-list__item-status-label"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "el-icon-upload-success el-icon-check"
                              })
                            ]
                          ),
                          file.showProgress
                            ? _c(
                                "div",
                                { staticClass: "upload-progress" },
                                [
                                  _c("el-progress", {
                                    attrs: {
                                      type: "circle",
                                      percentage: file.percent
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("i", { staticClass: "el-icon-close" }),
                          _c("i", { staticClass: "el-icon-close-tip" }, [
                            _vm._v("按 delete 键可删除")
                          ]),
                          _c(
                            "span",
                            { staticClass: "upload-checkbtn" },
                            [
                              _c("el-checkbox", {
                                on: {
                                  change: function($event) {
                                    return _vm.checkedImg(file)
                                  }
                                },
                                model: {
                                  value: file.checked,
                                  callback: function($$v) {
                                    _vm.$set(file, "checked", $$v)
                                  },
                                  expression: "file.checked"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-preview",
                                  on: {
                                    click: function($event) {
                                      return _vm.previewImg(file)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteImg(index)
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "uploading",
                      class: [_vm.isDrag ? "dragging" : ""],
                      on: {
                        dragleave: function($event) {
                          $event.preventDefault()
                          return _vm.dragLeave($event)
                        }
                      }
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-photos",
                          attrs: {
                            accept: "image/png, image/jpeg",
                            drag: "",
                            multiple: "",
                            action: "",
                            "show-file-list": false,
                            "http-request": _vm.upload,
                            "before-upload": _vm.handleBeforeUpload,
                            "on-change": _vm.handleChange
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _c("em", [_vm._v("点击上传")]),
                            _c("p", [_vm._v("或将文件拖到此处")])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.dialogVisible },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible = $event
              }
            }
          },
          [
            _c(
              "el-image",
              {
                staticStyle: { width: "100%", height: "600px" },
                attrs: { src: _vm.dialogImageUrl, fit: "scale-down" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "image-slot",
                    attrs: { slot: "placeholder" },
                    slot: "placeholder"
                  },
                  [
                    _vm._v(" 加载中"),
                    _c("span", { staticClass: "dot" }, [_vm._v("...")])
                  ]
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }