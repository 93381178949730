import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { servicerDataList, servicerExport, storeAssociation } from '@/api/information';
import { blobUpload } from '@/utils/blob';
export default {
  name: 'service',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    serviceAdd: function serviceAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/serviceAdd'));
      });
    },
    serviceShow: function serviceShow() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/serviceShow'));
      });
    },
    rechargeAdd: function rechargeAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/rechargeAdd'));
      });
    },
    // ManagedPool: () => import('./component/ManagedPool')
    packageSearch: function packageSearch() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/packageSearch/index'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [// { label: 'id', value: 'id', width: '100' },
      {
        label: '客户名称',
        value: 'service_name'
      }, {
        label: '账户余额',
        value: 'balance',
        width: '120'
      }, {
        label: '预警额度',
        value: 'warning_amounts'
      }, {
        label: '物流标准方案',
        value: 'program'
      }, {
        label: '客户经理',
        value: 'account_manager'
      }, {
        label: '规则名称',
        value: 'rule_name'
      }, {
        label: '累计充值',
        value: 'recharge_amount',
        width: '100',
        type: 'recharge'
      }, {
        label: '电话号码',
        value: 'mobile'
      }, {
        label: '国家',
        value: 'country',
        width: '100'
      }, {
        label: '邮箱',
        value: 'email'
      }, {
        label: '推荐人',
        value: 'reference',
        width: '100'
      }, // { label: '店铺+负责人', value: 'duty_name', type: 'duty_name' },
      {
        label: '当前折扣率',
        value: 'discount_rate',
        width: '120',
        type: 'discount'
      }, {
        label: '活跃度',
        value: 'activation',
        width: '120'
      }, {
        label: '添加时间',
        value: 'create_time'
      }, {
        label: '更新时间',
        value: 'update_time'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      loading: false,
      serviceShowVisible: false,
      serviceVisible: false,
      rechargeVisible: false,
      storeSetVisible: false,
      downloadLoading: false,
      serviceId: '',
      serviceTitle: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      storeId: '',
      rechargeId: '',
      storeList: [],
      formInline: {
        id: '',
        service_name: '',
        mobile: '',
        country: '',
        email: '',
        activation: '',
        reference: ''
      },
      customerData: {
        user: '',
        balance: '',
        amount: ''
      },
      selectList: {
        typeList: [{
          value: '1',
          label: 'Active'
        }, {
          value: '2',
          label: 'Communication'
        }, {
          value: '3',
          label: 'Low'
        }, {
          value: '4',
          label: 'Disappear'
        }]
      },
      storeRelatedList: {}
    };
  },
  computed: {
    // 展开过滤列表
    filterList: function filterList() {
      return [{
        label: '客户名称',
        inputType: 'input',
        name: 'service_name'
      }, {
        label: '电话号码',
        inputType: 'input',
        name: 'mobile'
      }, {
        label: '国家',
        inputType: 'input',
        name: 'country'
      }, {
        label: '邮箱',
        inputType: 'input',
        name: 'email'
      }, {
        label: '活跃度',
        inputType: 'select',
        name: 'activation',
        children: this.selectList.typeList
      }, {
        label: '推荐人',
        inputType: 'input',
        name: 'reference'
      }, {
        label: '添加时间',
        inputType: 'daterange',
        name: ['create_start_time', 'create_end_time']
      }, {
        label: '更新时间',
        inputType: 'daterange',
        name: ['update_start_time', 'update_end_time']
      }];
    }
  },
  created: function created() {
    var _this = this;

    this.Inquire();
    storeAssociation().then(function (res) {
      if (res.code === 200) {
        _this.storeRelatedList = res.data.list;
      }
    });
  },
  mounted: function mounted() {// this.$nextTick(function() {
    //   this.tableHeight = window.innerHeight - this.$refs.tableData.$el.offsetTop - 150
    //   // 监听窗口大小变化
    //   const self = this
    //   window.onresize = function() {
    //     self.tableHeight = window.innerHeight - self.$refs.tableData.$el.offsetTop - 150
    //   }
    // })
  },
  methods: {
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      servicerDataList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            // 转换状态活跃度
            res.data.list.map(function (item) {
              switch (item.activation) {
                case '1':
                  item.activation = 'Active';
                  break;

                case '2':
                  item.activation = 'Communication';
                  break;

                case '3':
                  item.activation = 'Low';
                  break;

                case '4':
                  item.activation = 'Disappear';
                  break;
              }
            });
            _this2.tableData = res.data.list;
            _this2.customerData = res.data.total;
            _this2.listQuery.total = parseInt(res.total.totalCount);
            _this2.loading = false;
          }, 300);
        }
      });
    },
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_start = [];
      this.update_start = [];
      this.Inquire();
    },
    // 新增客户
    serviceClick: function serviceClick(type) {
      this.serviceVisible = true;

      if (type === 'add') {
        this.serviceTitle = '新增客户';
      } else {
        this.serviceTitle = '修改客户';
        this.serviceId = type;
      }
    },
    // . 查看充值记录
    showLog: function showLog(type) {
      this.serviceShowVisible = true;
      this.serviceId = type;
    },
    closeShowLog: function closeShowLog(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.serviceShowVisible = false;
    },
    closeDialog: function closeDialog(type) {
      this.serviceShowVisible = type;
    },
    closeService: function closeService(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.serviceVisible = false;
    },
    // 充值
    recharge: function recharge(id) {
      this.rechargeVisible = true;
      this.rechargeId = id;
    },
    closeRecharge: function closeRecharge(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.rechargeVisible = false;
    },
    // 配置店铺
    storeClick: function storeClick(item) {
      this.storeSetVisible = true;
      this.storeList = item.store_list;
      this.storeId = item.id;
    },

    /* closeStoreSet(type) {
      if (type !== 1) {
        this.Inquire()
      }
      this.storeSetVisible = false
    },*/
    // 导出搜索结果
    handleDownload: function handleDownload() {
      var _this3 = this;

      var arr = Object.values(this.formInline).filter(function (item) {
        return item;
      });
      if (arr.length === 0) return this.$message({
        type: 'warning',
        message: '请选择搜索条件进行导出!'
      });
      this.downloadLoading = true;
      servicerExport(this.formInline).then(function (res) {
        blobUpload(res, 'service.xlsx').then(function (res) {
          console.log(res);
          _this3.downloadLoading = false;
        }).catch(function (err) {
          console.log(err);
          _this3.downloadLoading = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this3.downloadLoading = false;
      });
    },
    // 展开搜索
    handleFilter: function handleFilter(val) {
      var _this4 = this;

      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this4.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this4.formInline[filterItem] = item.value;
        } // 如果是日期


        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            _this4.formInline[item.name[0]] = item.value[0];
            _this4.formInline[item.name[1]] = item.value[1];
          } else {
            _this4.formInline[item.name[0]] = '';
            _this4.formInline[item.name[1]] = '';
          }
        }
      });
      this.Inquire();
    },
    // 重置
    resetFilter: function resetFilter() {
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    }
    /* // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    }*/

  }
};