import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { registerUser } from '@/api/user';
export default {
  name: 'registered',
  data: function data() {
    var _this = this;

    var validatePass = function validatePass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (_this.registeredForm.repassword !== '') {
          _this.$refs.registeredForm.validateField('repassword');
        }

        callback();
      }
    };

    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.registeredForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      activeName: '1',
      registeredForm: {
        repassword: '',
        password: '',
        phone: '',
        company_name: '',
        username: ''
      },
      loginRules: {
        phone: [{
          required: true,
          message: '请输入11位手机号码',
          trigger: 'blur'
        }, {
          pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
          message: '请输入合法手机号',
          trigger: 'blur'
        }],
        password: [{
          validator: validatePass,
          trigger: 'blur'
        }],
        repassword: [{
          validator: validatePass2,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名称未填写',
          trigger: 'blur'
        }],
        company_name: [{
          required: true,
          message: '公司名称未填写',
          trigger: 'blur'
        }]
      },
      fbAli: '',
      type: {
        key1: '',
        key2: ''
      },
      loading: false
    };
  },
  created: function created() {
    if (Object.keys(this.$route.query).length !== 0) {
      this.fbAli = Object.values(this.$route.query)[0];
      this.type.key1 = this.fbAli.split('-')[0].slice(1, this.fbAli.length);
      this.type.key2 = this.fbAli.split('-')[1];
    }
  },
  watch: {
    $route: function $route() {
      if (Object.keys(this.$route.query).length !== 0) {
        this.fbAli = Object.values(this.$route.query)[0];
        this.type.key1 = this.fbAli.split('-')[0].slice(1, this.fbAli.length);
        this.type.key2 = this.fbAli.split('-')[1];
      }
    }
  },
  methods: {
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.registeredForm.validate(function (valid) {
        var successloading = '';

        if (valid) {
          _this2.loading = true;

          if (Object.keys(_this2.$route.query).length !== 0) {
            _this2.registeredForm.type = _this2.type;
          }

          console.log(_this2.registeredForm);
          registerUser(_this2.registeredForm).then(function (res) {
            if (Object.keys(_this2.$route.query).length !== 0) {
              successloading = _this2.$loading({
                lock: true,
                text: '正在为您准备...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
            }

            if (res.code == 200) {
              _this2.loading = false;

              if (Object.keys(_this2.$route.query).length !== 0) {
                setTimeout(function () {
                  successloading.close();
                  _this2.registeredForm = _this2.$options.data().registeredForm;

                  _this2.$router.push({
                    query: {}
                  });

                  _this2.$message.success(res.message);
                }, 3000);
              } else {
                _this2.registeredForm = _this2.$options.data().registeredForm;

                _this2.$router.push({
                  query: {}
                });

                _this2.$message.success(res.message);
              }
            } else {
              _this2.loading = false;

              if (Object.keys(_this2.$route.query).length !== 0) {
                successloading.close();
              }

              _this2.$message.error(res.message);
            }
          }).catch(function (err) {
            _this2.loading = false;

            if (Object.keys(_this2.$route.query).length !== 0) {
              successloading.close();
            }
          }).finally(function () {
            _this2.loading = false;

            if (Object.keys(_this2.$route.query).length !== 0) {
              setTimeout(function () {
                successloading.close();
              }, 3000);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 返回登录
    loginRouter: function loginRouter() {
      this.$router.push({
        path: '/login'
      });
    }
  }
};