import _defineProperty from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getwarehoust_list as _getwarehoust_list, getopt_inventory as _getopt_inventory, addopt_inventory, getstockList as _getstockList, taskstock } from "@/api/system";
export default {
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("@/components/Pagination"));
      });
    }
  },
  data: function data() {
    var _ref;

    return _ref = {
      loading: false,
      formLabelWidth: "120px",
      formpandian: {
        type: "pandian",
        current_qty: "",
        inventory_id: ""
      },
      formruku: {
        type: "ruku",
        current_qty: "",
        inventory_id: "",
        mark: ""
      },
      formchuku: {
        type: "chuku",
        current_qty: "",
        inventory_id: "",
        mark: ""
      },
      dialogpandian: false,
      dialogruku: false,
      dialogchuku: false,
      dialogedit: false,
      data: "库存明细",
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      addflag: false,
      selectData: "",
      warehoustlist: [],
      optinventorydata: []
    }, _defineProperty(_ref, "formLabelWidth", "120px"), _defineProperty(_ref, "dialogFormVisible", false), _defineProperty(_ref, "rules", {
      level: [{
        required: true,
        message: "请选择消耗优先级",
        trigger: "blur"
      }],
      warehouse_id: [{
        required: true,
        message: "请选择仓库",
        trigger: "blur"
      }],
      current_qty: [{
        required: true,
        message: "请输入库存数量",
        trigger: "blur"
      }]
    }), _defineProperty(_ref, "form", {
      sku: "",
      sku_code: "",
      sku_name: "",
      ven_product_name: "",
      inventory_id: "",
      store_url: "",
      customer_name: "",
      product_title: "",
      warehouse_id: ""
    }), _defineProperty(_ref, "addform", {
      type: 2,
      inventory_id: "",
      warehouse_id: "",
      warehouse_name: "",
      sku_code: "",
      sku_name: "",
      level: "",
      current_qty: "",
      products: [{
        sku_code: "",
        sku_name: "",
        ven_product_name: "",
        img: "",
        product_id: "",
        store_url: "",
        customer_name: "name"
      }]
    }), _defineProperty(_ref, "rows", []), _defineProperty(_ref, "tableData", []), _ref;
  },
  mounted: function mounted() {
    // let aaa='1212'
    // console.log('qwqwqwqwqw',typeof(aaa))
    this.getwarehoust_list();
    this.getstockList();
  },
  update: function update() {
    console.log("updateupdateupdate-------------------------"); // let rows=this.rows
    //     if (rows) {
    //       console.log('rows----------',rows)
    //       rows.forEach(row => {
    //         this.$refs.multipleTable.toggleRowSelection(row);
    //       });
    //     } else {
    //       this.$refs.multipleTable.clearSelection();
    //     }
  },
  methods: {
    toggleSelection: function toggleSelection(rows) {
      var _this = this;

      if (rows) {
        console.log("rows----------", rows);
        rows.forEach(function (row) {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    opendialogFormVisible: function opendialogFormVisible() {
      this.dialogFormVisible = true;
      this.optinventorydata = [];
      this.form = {
        sku: "",
        product_title: ""
      }, this.addform = {
        type: 2,
        inventory_id: "",
        warehouse_id: "",
        warehouse_name: "",
        sku_code: "",
        sku_name: "",
        level: "",
        products: []
      };
    },
    // 多选
    handleSelectionChange: function handleSelectionChange(val) {
      this.addform.products = val;
    },
    // 库存列表接口
    // getstockList() {
    //   this.loading = true;
    //   getstockList(this.listQuery).then((res) => {
    //     this.tableData = res.data;
    //     this.loading = false;
    //   });
    // },
    // 查询
    getstockList: function getstockList() {
      var _this2 = this;

      this.loading = true;
      var params = this.form;
      params.iDisplayLength = this.listQuery.limit, params.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;

      _getstockList(params).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            item.status = item.status === "1" ? "进行中" : "已完成";
          });
          _this2.loading = false;
          _this2.listQuery.total = parseInt(res.total);
          _this2.tableData = res.data;
        }
      });
    },
    // 操作
    editok: function editok(i) {
      var _this3 = this;

      if (i == "pandian") {
        this.dialogpandian = false;
        taskstock(this.formpandian).then(function (res) {
          _this3.getstockList();
        });
      } else if (i == "ruku") {
        this.dialogruku = false;
        taskstock(this.formruku).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success("操作成功");
          } else {
            _this3.$message(res.message);
          }

          _this3.getstockList();
        });
      } else if (i == "chuku") {
        this.dialogchuku = false;
        taskstock(this.formchuku).then(function (res) {
          _this3.getstockList();
        });
      } // this.dialogpandian=false

    },
    edit: function edit(i, row) {
      if (i == "pandian") {
        this.dialogpandian = true;
        this.formpandian.inventory_id = row.inventory_id;
      } else if (i == "ruku") {
        this.dialogruku = true;
        this.formruku.inventory_id = row.inventory_id;
      } else if (i == "chuku") {
        this.dialogchuku = true;
        this.formchuku.inventory_id = row.inventory_id;
      } else if (i == "edit") {
        this.dialogedit = true;
        this.optinventorydata = row.products;
        this.addform = row;
        var id = this.addform.warehouse_id;
        this.addform.warehouse_id = this.addform.warehouse_name;
        this.addform.warehouse_name = id;
        this.$nextTick(function () {
          var _this4 = this;

          var rows = [];

          for (var m = 0; m < this.optinventorydata.length; m++) {
            for (var n = 0; n < this.optinventorydata.length; n++) {
              if (this.optinventorydata[m].id === this.optinventorydata[n].id) {
                rows.push(this.optinventorydata[n]);
              }
            }
          }

          rows.forEach(function (row) {
            _this4.$refs.multipleTable.toggleRowSelection(row);
          });
        }); // this.formchuku

        console.log("编辑", this.optinventorydata, this.addform, row);
      }
    },
    // onSubmit() {
    //   console.log("---", this.form);
    // },
    getwarehoust_list: function getwarehoust_list() {
      var _this5 = this;

      _getwarehoust_list(this.listQuery).then(function (res) {
        console.log(res);
        _this5.warehoustlist = res.data.data; // this.loading=false
      });
    },
    getopt_inventory: function getopt_inventory() {
      var _this6 = this;

      this.loading = true;

      _getopt_inventory({
        type: 1,
        sku_name: this.form.product_title,
        sku_codes: this.form.sku
      }).then(function (res) {
        _this6.optinventorydata = res.data.data.data;
        _this6.rows = _this6.optinventorydata.filter(function (item) {
          return item.is_bind == 1;
        }); // this.$refs.multipleTable.toggleRowSelection(this.rows,true)

        _this6.$nextTick(function () {
          var _this7 = this;

          var rows = [];

          for (var m = 0; m < this.rows.length; m++) {
            for (var n = 0; n < this.optinventorydata.length; n++) {
              if (this.rows[m].id === this.optinventorydata[n].id) {
                rows.push(this.optinventorydata[n]);
              }
            }
          }

          rows.forEach(function (row) {
            _this7.$refs.multipleTable.toggleRowSelection(row);
          });
        });

        _this6.loading = false;
        _this6.addflag = true;
        _this6.addform.inventory_id = res.data.data.inventory_id;
      });
    },
    addstock: function addstock(formName) {
      var _this8 = this;

      this.$refs.addform.validate(function (valid) {
        if (valid) {
          if (_this8.addform.products.length == 0) {
            console.log("请选择商品");

            _this8.$message("请选择已关联商品");

            return;
          } // alert('submit!');


          var warehouse_id = _this8.addform.warehouse_id;

          var warehouse_name = _this8.warehoustlist.filter(function (item) {
            return item.id == warehouse_id;
          });

          _this8.addform.warehouse_name = warehouse_name[0].name || _this8.addform.warehouse_name;
          _this8.addform.warehouse_id = warehouse_id; // this.addform.products = this.optinventorydata;

          _this8.dialogFormVisible = false;
          addopt_inventory(_this8.addform).then(function (res) {
            _this8.$message.success("添加成功");

            _this8.getstockList();
          });
          _this8.dialogFormVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editstock: function editstock(formName) {
      var _this9 = this;

      this.$refs.addform.validate(function (valid) {
        if (valid) {
          // alert('submit!');
          // console.log("selectData", this.selectData);
          var warehouse_id = _this9.addform.warehouse_id;

          if (typeof warehouse_id == "string") {
            warehouse_id = _this9.addform.warehouse_name;
            _this9.addform.warehouse_name = _this9.addform.warehouse_id;
            _this9.addform.warehouse_id = warehouse_id;
          }

          var warehouse_name = _this9.warehoustlist.filter(function (item) {
            return item.id == warehouse_id;
          });

          _this9.addform.warehouse_name = warehouse_name[0].name;
          _this9.addform.warehouse_id = warehouse_id;
          _this9.addform.type = "2"; // this.addform.products = this.optinventorydata;

          _this9.dialogFormVisible = false;
          addopt_inventory(_this9.addform).then(function (res) {
            _this9.$message.success("编辑成功");

            _this9.getstockList();
          });
          _this9.dialogedit = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    look: function look() {
      this.getstockList();
    },
    onSubmit: function onSubmit() {
      if (!this.form.product_title && !this.form.sku) {
        this.$message("请输入");
        return;
      }

      this.getopt_inventory();
    },
    reset: function reset() {
      this.form = {
        inventory_id: "",
        sku_code: "",
        skuname: "",
        create_time: "",
        store_url: "",
        customer_name: "",
        warehouse_name: ""
      };
      this.getstockList();
    }
  }
};