//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { storeSave, storeDataList, storeEdit } from '@/api/information';
export default {
  name: 'store-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      storeForm: {
        api_key: '',
        password: '',
        platform: '1',
        platform_name: '',
        shared_secret: '',
        store_name: '',
        store_url: '',
        charge_people: '',
        location_id: '',
        api_version: ''
      },
      rules: {
        store_name: [{
          required: true,
          message: '请填写店铺代号',
          trigger: 'blur'
        }],
        platform_name: [{
          required: true,
          message: '请填写平台账号',
          trigger: 'blur'
        }],
        store_url: [{
          required: true,
          message: '请填写店铺域名',
          trigger: 'blur'
        }],
        api_key: [{
          required: true,
          message: '请填写api_key',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请填写password',
          trigger: 'blur'
        }],
        shared_secret: [{
          required: true,
          message: '请填写shared_secret',
          trigger: 'blur'
        }],
        location_id: [{
          required: true,
          message: '请填写location_id',
          trigger: 'blur'
        }],
        api_version: [{
          required: true,
          message: '请填写api_version',
          trigger: 'blur'
        }],
        charge_people: [{
          required: true,
          message: '请填写负责人',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      if (val) {
        if (this.$parent.storeTitle == '修改店铺') {
          storeDataList({
            id: this.$parent.storeId
          }).then(function (res) {
            if (res.code == 200) {
              _this.storeForm = {
                store_name: res.data[0].store_name,
                platform_name: res.data[0].platform_name,
                store_url: res.data[0].store_url,
                api_key: res.data[0].api_key,
                password: res.data[0].password,
                shared_secret: res.data[0].shared_secret,
                charge_people: res.data[0].charge_people,
                api_version: res.data[0].api_version,
                location_id: res.data[0].location_id,
                platform: '1'
              };
              console.log(_this.storeForm);
            }
          });
        }
      } else {
        this.$refs.storeForm.resetFields();
      }
    }
  },
  methods: {
    storeAdd: function storeAdd(type) {
      var _this2 = this;

      if (type == 1) {
        this.$refs.storeForm.validate(function (valid) {
          if (valid) {
            var params = JSON.parse(JSON.stringify(_this2.storeForm));

            if (_this2.$parent.storeTitle == '新增店铺') {
              storeSave(params).then(function (res) {
                if (res.code == 200) {
                  _this2.$emit('storeAdd');

                  _this2.$message.success(res.message);
                }
              });
            } else {
              params.id = _this2.$parent.storeId;
              storeEdit(params).then(function (res) {
                if (res.code == 200) {
                  _this2.$emit('storeAdd');

                  _this2.$message.success(res.message);
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('storeAdd', 1);
      }
    }
  }
};