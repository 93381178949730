import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Message } from 'element-ui';
export function blobUpload(data, name) {
  return new Promise(function (resolve, reject) {
    if (data.type === 'application/json' || data.type === 'text/html') {
      var reader = new FileReader();

      reader.onload = function (e) {
        var result = JSON.parse(e.target.result);
        Message.success("".concat(result.message, "! "));
        reject(result);
      };

      reader.readAsText(data);
    } else {
      var blob = new Blob([data]); // 处理文档流

      var fileName = name;
      var elink = document.createElement('a');
      elink.download = fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象

      document.body.removeChild(elink);
      resolve();
    }
  });
}