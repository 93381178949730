import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { afterSaleReplay } from '@/api/after';
export default {
  name: 'reply-dialog',
  components: {
    ShopWindow: function ShopWindow() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./shop-window'));
      });
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '售后回复'
    }
  },
  data: function data() {
    return {
      formRule: {
        reply_info: [{
          required: true,
          message: '请输入回复内容',
          trigger: 'blur'
        }]
      },
      activeName: 'first',
      SubmitLoading: false,
      imgList: [],
      formData: {
        reply_img: [],
        reply_info: ''
      }
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  methods: {
    reset: function reset() {
      this.dialogVisible = false;
      this.formData = this.$options.data().formData;
    },
    handleClick: function handleClick(tabs, event) {},
    updateImgList: function updateImgList(list) {
      this.imgList = list;
      this.formData.reply_img = this.imgList;
    },
    deleteImg: function deleteImg() {},
    submitReply: function submitReply() {
      var _this = this;

      if ((this.formData.reply_info === '' || this.formData.reply_info === null || this.formData.reply_info === undefined) & this.formData.reply_img.length === 0) {
        this.$message.warning('回复内容不能为空！');
      } else {
        var imgs = [];
        this.formData.a_id = parseInt(this.$route.query.id);
        this.formData.reply_img.map(function (item) {
          imgs.push(item.url);
        });
        this.formData.reply_img = imgs;
        this.SubmitLoading = true;
        afterSaleReplay(this.formData).then(function (res) {
          console.log(res);

          if (res.code === 200) {
            _this.$emit('handleUpdate');

            _this.$message({
              message: res.message,
              type: 'success'
            });

            _this.reset();
          }
        }).catch(function (err) {
          console.log(err);
        }).finally(function () {
          _this.SubmitLoading = false;
        });
      }
    }
  }
};