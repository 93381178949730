import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.values.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { settlementList, settlementBillList, settlementById, exportSettlement } from '@/api/settlement';
import setUp from './component/setUp';
import { blobUpload } from '@/utils/blob';
import { getSession } from '@/utils/session';
export default {
  name: 'settlement',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    DialogImport: function DialogImport() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/dialogImport'));
      });
    },
    // packageSearch: () => import('@/components/packageSearch/index'),
    setUp: setUp
  },

  /* computed: {
    // 展开过滤列表
    filterList() {
      return [
        { label: '结算状态', inputType: 'select', name: 'settlement_status', children: this.selectList.stautsList, multiple: true },
        { label: '客户', inputType: 'select', name: 'service_id', children: this.selectList.serviceList, multiple: true  },
        { label: '结算单号', inputType: 'input', name: 'settlement_no' },
        { label: '第三方订单号', inputType: 'input', name: 'third_order_on' },
        { label: '创建时间', inputType: 'daterange', name: ['create_start_time','create_end_time']},
        { label: '更新时间', inputType: 'daterange', name: ['update_start_time','update_end_time']}
      ]
    },
    SettlementFilterList() {
      return [
        { label: '客户', inputType: 'select', name: 'service_id', children: this.selectList.serviceList, multiple: true  },
        { label: '结算单号', inputType: 'input', name: 'settlement_no' },
        { label: '第三方订单号', inputType: 'input', name: 'third_order_on' },
        { label: '创建时间', inputType: 'daterange', name: ['create_start_time','create_end_time']},
        { label: '更新时间', inputType: 'daterange', name: ['update_start_time','update_end_time']}
      ]
    }
  },*/
  filters: {
    status: function status(val) {
      var status = {
        '1': '已结算',
        '2': '已退款',
        '3': '已处理',
        '4': '已取消'
      };
      return status[val];
    }
  },
  data: function data() {
    return {
      rules: {},
      loading: false,
      downloadLoading: false,
      setupVisible: false,
      setUpId: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      labelList: [{
        label: '结算单号',
        value: 'settlement_no',
        show: '01,11,02,12'
      }, {
        label: '订单号',
        value: 'order_no',
        type: 'order',
        show: '01,11,02,12'
      }, {
        label: '第三方订单号',
        value: 'thirdParty_order_no',
        show: '01,11,02,12'
      }, {
        label: '客户',
        value: 'service_name',
        show: '01,11,02,12'
      }, {
        label: '客户店铺',
        value: 'store_url',
        show: '01,11,02,12'
      }, {
        label: '订单总金额',
        value: 'total_amount',
        show: '01,11,02,12'
      }, // { label: '折扣率', value: 'discount_rate', show: '01,02' },
      // { label: '客户结算金额', value: 'service_total_amount', show: '01,11,02,12' },
      {
        label: '供应商结算金额',
        value: 'supplier_total_amount',
        show: '11,12'
      }, {
        label: '商品金额',
        value: 'product_total_amount',
        show: '01,02'
      }, {
        label: '物流成本',
        value: 'logistics_cost',
        show: '11,12'
      }, {
        label: '订单利润',
        value: 'order_profit',
        show: '11,12'
      }, {
        label: '物流费用',
        value: 'logistics_cost',
        show: '01,02'
      }, {
        label: '服务费费用',
        value: 'service_fee',
        show: '01,02'
      }, // { label: '币种', value: 'currency', show: '0', width: '100' },
      {
        label: '创建时间',
        value: 'create_time',
        show: '01,11,02,12'
      }, {
        label: '更新时间',
        value: 'update_time',
        show: '01,11,02,12'
      }, {
        label: '结算状态',
        value: 'settlement_status',
        show: '01,11,02,12',
        type: 'status',
        width: '80'
      }, {
        label: '备注',
        value: 'remarks',
        show: '01,11,02,12',
        width: '80'
      }, {
        label: '操作',
        type: 'operation',
        width: '80'
      }],
      importVisible: false,
      importProps: {
        index: process.env.VUE_APP_DOWNLOAD_URL + '/importSettlement.xlsx',
        url: '/settlement/settlement/import-order',
        name: 'importSettlement.xlsx'
      },
      updateTitle: '批量设置结算单',
      formInline: {
        settlement_status: [],
        service_id: [],
        settlement_no: '',
        third_order_on: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      selectList: {
        stautsList: ['已结算', '已退款', '已处理', '已取消'],

        /* stautsList: [
          {
            value: '1',
            label: '已结算'
          },
          {
            value: '2',
            label: '已退款'
          },
          {
            value: '3',
            label: '已处理'
          },
          {
            value: '4',
            label: '已取消'
          }
        ],*/
        serviceList: []
      },
      status: {
        '1': '已结算',
        '2': '已退款',
        '3': '已处理',
        '4': '已取消'
      },
      tableData: [],
      tabList: ['对账列表', '结算列表'],
      activeName: '01',
      setUpData: {},
      create_at: [],
      update_at: [],
      settlement_status: [],
      service_id: [],
      channelList: [],
      platforms: [],
      platform: '1',
      tabOneActive: '01',
      tabTwoActive: '02',
      formInlineOneOne: {
        settlement_status: [],
        service_id: [],
        settlement_no: '',
        third_order_on: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      formInlineOneTwo: {
        settlement_status: [],
        service_id: [],
        settlement_no: '',
        third_order_on: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      formInlineTwoOne: {
        settlement_status: [],
        service_id: [],
        settlement_no: '',
        third_order_on: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      formInlineTwoTwo: {
        settlement_status: [],
        service_id: [],
        settlement_no: '',
        third_order_on: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      }
    };
  },
  watch: {
    create_at: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.create_start_time = this.create_at[0];
          this.formInline.create_end_time = this.create_at[1];
        } else {
          this.formInline.create_start_time = '';
          this.formInline.create_end_time = '';
        }
      },
      immediate: true
    },
    update_at: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.update_start_time = this.update_at[0];
          this.formInline.update_end_time = this.update_at[1];
        } else {
          this.formInline.update_start_time = '';
          this.formInline.update_end_time = '';
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // this.selectList.serviceList = getSession('serviceList')
    // this.channelList = getSession('channelList')
    this.platforms = getSession('platform');
    this.selectList.serviceList = getSession('serviceList');
    this.Inquire();
  },
  methods: {
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();

      switch (this.activeName) {
        case '01':
          this.formInlineOneOne = this.formInline;
          break;

        case '11':
          this.formInlineOneTwo = this.formInline;
          break;

        case '02':
          this.formInlineTwoOne = this.formInline;
          break;

        case '12':
          this.formInlineTwoTwo = this.formInline;
      }
    },
    // 重置
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_at = [];
      this.update_at = [];
      this.settlement_status = [];
      this.service_id = [];
      this.Inquire();

      switch (this.activeName) {
        case '01':
          this.formInlineOneOne = this.formInline;
          break;

        case '11':
          this.formInlineOneTwo = this.formInline;
          break;

        case '02':
          this.formInlineTwoOne = this.formInline;
          break;

        case '12':
          this.formInlineTwoTwo = this.formInline;
      }
    },
    handlePlatformClick: function handlePlatformClick(tab) {
      if (tab.paneName === '1') {
        this.activeName = this.tabOneActive;
        this.handleClick();
      } else {
        this.activeName = this.tabTwoActive;
        this.handleClick();
      }
    },
    handleClick: function handleClick() {
      if (this.platform === '1') {
        switch (this.activeName) {
          case '01':
            this.formInline = this.formInlineOneOne;
            break;

          case '11':
            this.formInline = this.formInlineOneTwo;
            break;
        }
      } else {
        switch (this.activeName) {
          case '02':
            this.formInline = this.formInlineTwoOne;
            break;

          case '12':
            this.formInline = this.formInlineTwoTwo;
            break;
        }
      }

      this.Inquire();

      if (this.activeName.slice(1, 2) === '1') {
        this.tabOneActive = this.activeName;
      } else {
        this.tabTwoActive = this.activeName;
      }
    },
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;

      var formData = _objectSpread({}, this.formInline);

      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      formData.settlement_status = this.formInline.settlement_status ? this.formInline.settlement_status.join(',') : this.formInline.settlement_status;
      formData.service_id = this.formInline.service_id ? this.formInline.service_id.join(',') : this.formInline.service_id;
      formData.plat_form = this.platform;

      if (this.activeName === '01' || this.activeName === '02') {
        // 对账列表API
        settlementList(formData).then(function (res) {
          if (res.code === 200) {
            setTimeout(function () {
              _this.loading = false;
              _this.tableData = res.data;
              _this.listQuery.total = +res.total;
            }, 300);
          }
        }).catch(function (err) {
          _this.loading = false;
          console.log(err);
        });
      } else {
        // 结算列表API
        settlementBillList(formData).then(function (res) {
          if (res.code === 200) {
            setTimeout(function () {
              _this.loading = false;
              _this.tableData = res.data;
              _this.listQuery.total = +res.total;
            }, 300);
          }
        }).catch(function (err) {
          _this.loading = false;
          console.log(err);
        });
      }
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      console.log(val);
      this.selectTabData = val;
    },
    // 打开上传弹窗
    openImport: function openImport() {
      this.importVisible = true;
    },
    // 关闭上传弹窗
    closeImport: function closeImport(type) {
      this.importVisible = false;

      if (type === 1) {
        this.Inquire();
      }
    },
    // 设置
    setUpClick: function setUpClick(id, type) {
      var _this2 = this;

      settlementById({
        id: id,
        plat_form: this.platform
      }).then(function (res) {
        _this2.setUpData = res.data;
        _this2.setUpId = type;
        _this2.setupVisible = true;
      }).catch(function (err) {
        console.log(err);
      });
    },
    closeSetUp: function closeSetUp(type) {
      if (type === 1) {
        this.Inquire();
      }

      this.setupVisible = false;
    },
    // 下单详情
    orderClick: function orderClick(item) {
      this.$router.push({
        name: 'orderd-details',
        query: {
          order_no: item.order_no,
          service_name: item.service_name,
          id: item.orders_sublist_id,
          plat_form: this.activeName
        },
        params: {
          id: item.orders_sublist_id
        }
      });
    },
    // 导出搜索结果
    handleDownload: function handleDownload() {
      var _this3 = this;

      var params = _objectSpread(_objectSpread({}, this.formInline), {}, {
        type: this.activeName === '0' ? '1' : '2'
      });

      params.settlement_status = this.settlement_status.join(',');
      params.service_id = this.service_id.join(',');
      params.plat_form = this.platform;
      var arr = Object.values(params).filter(function (item) {
        return item;
      });
      if (arr.length === 0) return this.$message({
        type: 'warning',
        message: '请选择搜索条件进行导出!'
      });
      this.downloadLoading = true;
      exportSettlement(params).then(function (res) {
        blobUpload(res, "".concat(_this3.tabList[_this3.activeName], ".xlsx")).then(function (res) {
          console.log(res);
          _this3.downloadLoading = false;
        }).catch(function (err) {
          console.log(err);
          _this3.downloadLoading = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this3.downloadLoading = false;
      });
    }
    /* // 展开搜索
    handleFilter(val) {
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
      switch (this.activeName) {
        case '01':
          this.formInlineOneOne = this.formInline
          break
        case '11':
          this.formInlineOneTwo = this.formInline
          break
        case '02':
          this.formInlineTwoOne = this.formInline
          break
        case '12':
          this.formInlineTwoTwo = this.formInline
      }
    },
    // 重置
    resetFilter() {
      this.formInline = this.$options.data().formInline
      this.Inquire()
      switch (this.activeName) {
        case '01':
          this.formInlineOneOne = this.formInline
          break
        case '11':
          this.formInlineOneTwo = this.formInline
          break
        case '02':
          this.formInlineTwoOne = this.formInline
          break
        case '12':
          this.formInlineTwoTwo = this.formInline
      }
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
      switch (this.activeName) {
        case '01':
          this.formInlineOneOne = this.formInline
          break
        case '11':
          this.formInlineOneTwo = this.formInline
          break
        case '02':
          this.formInlineTwoOne = this.formInline
          break
        case '12':
          this.formInlineTwoTwo = this.formInline
      }
    }*/

  }
};