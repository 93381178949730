//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { relatedSave } from '@/api/commodity';
import SelectCommodityDialog from './selectCommodityDialog';
export default {
  name: 'link-commodity-dialog',
  components: {
    SelectCommodityDialog: SelectCommodityDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '关联商品'
    },
    flag: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      linkForm: {
        sku_name: '',
        sale_price: '',
        service_fee: '',
        new_sale_price: '',
        new_service_fee: '',
        url: ''
      },
      rules: {
        sku_name: [{
          required: true,
          message: '请选择商品SKU',
          trigger: 'blur'
        }]
      },
      productList: [],
      skuList: [],
      skuData: {},
      listLoading: false,
      submitLoading: false,
      selectCommodity: false,
      selectPro: '',
      customerName: ''
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    },
    hostFlag: {
      get: function get() {
        return this.flag;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:flag', newVal);
      }
    }
  },
  watch: {
    formData: {
      handler: function handler(data) {
        this.linkForm = data;
        this.customerName = data.customer_name;
      },
      deep: true
    }
  },
  methods: {
    selectProduct: function selectProduct() {
      this.selectCommodity = true;
    },
    handleClosed: function handleClosed() {
      // this.linkForm = {}
      this.$refs['linkForm'].resetFields();
      this.linkForm.url = '';
      this.dialogVisible = false;
    },
    receiveData: function receiveData(data) {
      this.selectPro = data.sale_price;
      this.linkForm = data;
      this.hostFlag = false;
      this.$refs['linkForm'].clearValidate();
    },
    submit: function submit(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // console.log(this.hostFlag)
          var formQuery = Object.assign({}, _this.linkForm);
          var cname = _this.customerName; // . 判断输入价格是否与原价格相同，发送改价通知

          if (_this.selectPro !== formQuery.sale_price) {
            _this.$confirm("\u786E\u8BA4\u5BF9\u5F53\u524D\u5546\u54C1\u8FDB\u884C\u4EF7\u683C\u8C03\u6574\u5417\uFF1F\u786E\u5B9A\u5C06\u5BF9\u5BA2\u6237\uFF1A".concat(cname, " \u53D1\u9001\u53D8\u52A8\u901A\u77E5\u4E14\u53D8\u66F4\u540E\u5173\u4E8E\u6B64\u5546\u54C1\u7684\u540E\u7EED\u8BA2\u5355\u60A8\u5C06\u63A5\u6536\u4E0D\u5230\uFF0C\u9700\u5BA2\u6237\u540C\u610F\u540E\u624D\u53EF\u63A5\u6536\u5230\u8BA2\u5355\u4FE1\u606F\uFF0C\u82E5\u5BA2\u6237\u62D2\u7EDD\u5C06\u4E8E\u60A8\u5F53\u524D\u5546\u54C1\u53D6\u6D88\u5173\u8054\u82E5\u9700\u7EE7\u7EED\u63A5\u53D7\u8BA2\u5355\u9700\u91CD\u65B0\u5173\u8054\u5BA2\u6237\u5546\u54C1"), '温馨提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(function () {
              formQuery.sku_id = _this.hostFlag ? _this.formData.sku_id : _this.linkForm.id;
              formQuery.good_id = _this.linkForm.good_id;
              formQuery.sku = _this.linkForm.sku;
              formQuery.sku_name = _this.linkForm.sku_name;
              formQuery.product_attribute = _this.linkForm.product_attribute;
              formQuery.id = _this.formData.id;
              formQuery.s_id = _this.formData.s_id;
              formQuery.customer_name = _this.formData.customer_name;
              formQuery.store_url = _this.formData.store_url;
              formQuery.product_id = _this.formData.product_id;
              formQuery.shopify_sku_id = _this.formData.shopify_sku_id;
              _this.submitLoading = true;
              relatedSave([formQuery]).then(function (res) {
                if (res.status === 200) {
                  _this.$emit('close');

                  _this.linkForm = {};

                  _this.$message.success('关联成功！');

                  _this.dialogVisible = false;
                }

                _this.submitLoading = false;
              }).catch(function (err) {
                _this.submitLoading = false;
                console.log(err);
              });
              console.log('确定'); // goodsOpertation({ id: cid, type: 'del' }).then(res => {
              //   console.log(res.data)
              //   if (res.status === 200) {
              //     this.$message.success('删除成功！')
              //     this.Inquire()
              //   }
              // }).catch(err => {
              //   console.log(err)
              // })
            }).catch(function () {});
          } else {
            formQuery.sku_id = _this.hostFlag ? _this.formData.sku_id : _this.linkForm.id;
            formQuery.good_id = _this.linkForm.good_id;
            formQuery.sku = _this.linkForm.sku;
            formQuery.sku_name = _this.linkForm.sku_name;
            formQuery.product_attribute = _this.linkForm.product_attribute;
            formQuery.id = _this.formData.id;
            formQuery.s_id = _this.formData.s_id;
            formQuery.customer_name = _this.formData.customer_name;
            formQuery.store_url = _this.formData.store_url;
            formQuery.product_id = _this.formData.product_id;
            formQuery.shopify_sku_id = _this.formData.shopify_sku_id;
            _this.submitLoading = true;
            relatedSave([formQuery]).then(function (res) {
              if (res.status === 200) {
                _this.$emit('close');

                _this.linkForm = {};

                _this.$message.success('关联成功！');

                _this.dialogVisible = false;
              }

              _this.submitLoading = false;
            }).catch(function (err) {
              _this.submitLoading = false;
              console.log(err);
            });
          } // console.log(formQuery.sale_price)
          // console.log(this.selectPro)
          // return false

        } else {
          return false;
        }
      });
    }
  }
};