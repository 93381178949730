import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getOrganizationalStructure, getAdminAssignment, assignmentSave } from '@/api/admin';
export default {
  name: 'data-authority',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      saveLoading: false,
      dialogTitle: '',
      treeList: [],
      list: [],
      defaultProps: {
        children: 'son',
        label: 'name'
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val, oldVal) {
      var _this = this;

      // 打开  关闭
      if (val) {
        getAdminAssignment({
          id: this.id
        }).then(function (res) {
          var checkedKeys = res.data.user.split(',').map(String);

          var list = _this.list.filter(function (item) {
            return checkedKeys.includes(item.user_id);
          });

          _this.$refs.tree.setCheckedNodes(list);
        });
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;

    setTimeout(function () {
      _this2.getList();
    }, 3000);
  },
  methods: {
    getList: function getList() {
      // getOrganizationalStructure().then(res =>{
      //   this.treeList = res.data
      //   fn(this.treeList,this.list)
      // })
      function fn(treeList, data) {
        treeList.forEach(function (item) {
          data.push(item);

          if (item.son != undefined) {
            fn(item.son, data);
          }
        });
      }
    },
    close: function close(type) {
      var _this3 = this;

      if (type != 1) return this.$emit('close');
      var user_id = this.$refs.tree.getCheckedNodes().map(function (item) {
        return item.user_id;
      }).join(',');
      this.saveLoading = true;
      assignmentSave({
        id: this.id,
        user_id: user_id
      }).then(function (res) {
        _this3.$emit('close', 1);

        _this3.saveLoading = false;

        _this3.$message({
          type: 'success',
          message: res.message
        });
      });
    }
  }
};