var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "message-center-box" }, [
    _c("div", { staticClass: "message-main" }, [
      _c(
        "div",
        { staticClass: "message-title" },
        [
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: {
                    type: "primary",
                    plain: _vm.isSystem ? false : true
                  },
                  on: { click: _vm.handleSystem }
                },
                [_vm._v(" 系统消息 "), _c("span", { staticClass: "origin" })]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: {
                    type: "primary",
                    plain: _vm.isSystem ? true : false
                  },
                  on: { click: _vm.handleCustomer }
                },
                [_vm._v(" 客户消息 "), _c("span", { staticClass: "origin" })]
              )
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { type: "primary" }
            },
            [_vm._v("全部已读")]
          )
        ],
        1
      ),
      _vm.isSystem
        ? _c(
            "div",
            { staticClass: "message-container" },
            [
              _vm._l(_vm.systemInformation, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "message-box" },
                  [
                    item.isRead
                      ? _c("span", { staticClass: "origin" })
                      : _vm._e(),
                    _c("div", { staticClass: "article-box" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleClickToDetail(item.type)
                            }
                          }
                        },
                        [
                          _c("h4", [_vm._v(_vm._s(_vm.systemList[item.type]))]),
                          _c("p", [
                            _vm._v(_vm._s(item.msg_json.msg) + " "),
                            item.type !== 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleClickToLink(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "查看" + _vm._s(_vm.systemList[item.type])
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c("div", [_c("p", [_vm._v(_vm._s(item.create_time))])])
                    ]),
                    _c("el-divider")
                  ],
                  1
                )
              }),
              _vm.systemInformation.length === 0
                ? _c("div", { staticClass: "no-data-message" }, [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "100px" },
                          attrs: { src: _vm.noData }
                        })
                      ],
                      1
                    ),
                    _c("span", [_vm._v("No news notification")])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      !_vm.isSystem
        ? _c(
            "div",
            { staticClass: "message-container" },
            [
              _vm._l(_vm.CustomerMessage, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "message-box" },
                  [
                    item.isRead
                      ? _c("span", { staticClass: "origin" })
                      : _vm._e(),
                    _c("div", { staticClass: "article-box" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleClickToDetail(item.type)
                            }
                          }
                        },
                        [
                          _c("h4", [_vm._v(_vm._s(_vm.systemList[item.type]))]),
                          _c("p", [
                            _vm._v(_vm._s(item.msg_json.msg) + " "),
                            _c("span", { staticClass: "btn" }, [
                              _vm._v("查看" + _vm._s(_vm.systemList[item.type]))
                            ])
                          ])
                        ]
                      ),
                      _c("div", [_c("p", [_vm._v(_vm._s(item.create_time))])])
                    ]),
                    _c("el-divider")
                  ],
                  1
                )
              }),
              _vm.CustomerMessage.length === 0
                ? _c("div", { staticClass: "no-data-message" }, [
                    _c(
                      "div",
                      [
                        _c("el-image", {
                          staticStyle: { width: "100px" },
                          attrs: { src: _vm.noData }
                        })
                      ],
                      1
                    ),
                    _c("span", [_vm._v("No news notification")])
                  ])
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }