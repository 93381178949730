import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getSession } from '@/utils/session';
import { quotedList, quotedOption } from '@/api/commodity';
import Pagination from '@/components/Pagination';
export default {
  name: 'index',
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      formInline: {
        customer_id: '',
        status: ''
      },
      create_at: [],
      update_at: [],
      serviceList: [],
      tableData: [],
      labelList: [{
        label: '商品图片',
        value: 'img_url',
        type: 'image',
        width: '150',
        show: '0,1'
      }, {
        label: '客户名称',
        value: 'customer_id',
        show: '0,1'
      }, {
        label: '创建时间',
        value: 'create_time'
      }, {
        label: '状态',
        value: 'status',
        type: 'status',
        show: '0'
      }, {
        label: '报价时间',
        value: 'push_time'
      }, {
        label: '操作',
        type: 'operation',
        width: '120'
      }],
      loading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: []
    };
  },
  created: function created() {
    this.init();
    this.Inquire();
  },
  methods: {
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_at = [];
      this.update_at = [];
      this.Inquire();
    },
    // 查询
    init: function init() {
      var _this = this;

      this.serviceList = getSession('serviceList');
      quotedOption().then(function (res) {
        if (res.code === 200) {
          _this.statusList = res.data;
        }
      });
    },
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;
      this.formInline.create_at_start = this.create_at[0] || '';
      this.formInline.create_at_end = this.create_at[1] || '';
      this.formInline.update_at_start = this.update_at[0] || '';
      this.formInline.update_at_end = this.update_at[1] || '';

      var formData = _objectSpread({}, this.formInline);

      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      quotedList(formData).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data;
          _this2.listQuery.total = +res.total;
          _this2.loading = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    getValueOfLabel: function getValueOfLabel(num, sum) {
      var obj = sum.find(function (it) {
        return it.value === num;
      });

      if (obj) {
        return obj.label;
      } else {
        return num;
      }
    },
    handleUpdate: function handleUpdate(id) {
      this.$router.push({
        name: 'commodity-filter-detail',
        params: {
          id: 'edit'
        },
        query: {
          id: id
        }
      });
    }
  }
};