import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 获取售后列表

export function afterSaleDataList(data) {
  return request({
    url: '/after/after-sales/index',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取售后状态

export function afterSaleStatus() {
  return request({
    url: '/after/after-sales/status',
    method: 'get',
    baseURL: baseURL
  });
} // 获取售后操作

export function afterSaleOpertation() {
  return request({
    url: '/after/after-sales/opertation',
    method: 'get',
    baseURL: baseURL
  });
} // 获取售后类型

export function afterSaleAfterModel() {
  return request({
    url: '/after/after-sales/after-model',
    method: 'get',
    baseURL: baseURL
  });
} // 获取某个订单详细信息

export function afterSaleDetails(data) {
  return request({
    url: '/after/after-sales/details',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 发送回复请求

export function afterSaleReplay(data) {
  return request({
    url: '/after/after-sales/reply',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取售后类型

export function afterSaleType() {
  return request({
    url: '/after/after-sales/type',
    method: 'get',
    baseURL: baseURL
  });
} // 退款

export function afterSaleRefund(data) {
  return request({
    url: '/after/after-sales/order-refund',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 补发

export function afterSaleReissue(data) {
  return request({
    url: '/after/after-sales/order-reissue',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 完成售后

export function afterSaleUpdateStatus(data) {
  return request({
    url: '/after/after-sales/update-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}