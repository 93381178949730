var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function($event) {
                _vm.dialogFormVisible = true
              }
            }
          },
          [_vm._v("添加仓库")]
        ),
        _c(
          "el-dialog",
          {
            attrs: { title: "添加仓库", visible: _vm.dialogFormVisible },
            on: {
              "update:visible": function($event) {
                _vm.dialogFormVisible = $event
              }
            }
          },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.form } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "仓库名称",
                      "label-width": _vm.formLabelWidth
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.form.name,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.dialogFormVisible = false
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.addWarehouse }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: { title: "编辑仓库", visible: _vm.editdialogFormVisible },
            on: {
              "update:visible": function($event) {
                _vm.editdialogFormVisible = $event
              }
            }
          },
          [
            _c(
              "el-form",
              { attrs: { model: _vm.editform } },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "仓库名称",
                      "label-width": _vm.formLabelWidth
                    }
                  },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.editform.name,
                        callback: function($$v) {
                          _vm.$set(_vm.editform, "name", $$v)
                        },
                        expression: "editform.name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer"
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.editdialogFormVisible = false
                      }
                    }
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.editWarehouse }
                  },
                  [_vm._v("确 定")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.warehoustlist,
              "highlight-current-row": "",
              fit: "",
              border: "",
              stripe: "",
              "header-cell-style": { background: "#e9f1ff", color: "#606266" }
            }
          },
          [
            _c("el-table-column", { attrs: { prop: "name", label: "名称" } }),
            _c("el-table-column", {
              attrs: { prop: "create_time", label: "创建时间" }
            }),
            _c("el-table-column", {
              attrs: { prop: "update_time", label: "更新时间" }
            }),
            _c("el-table-column", {
              attrs: { label: "操作" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.edit(scope.row)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }