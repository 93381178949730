import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var systemRouter = [{
  path: '/system',
  component: Layout,
  alwaysShow: true,
  name: 'system',
  redirect: 'noRedirect',
  meta: {
    title: '系统管理',
    icon: 'theme'
  },
  children: [{
    path: 'schedule/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/schedule/index'));
      });
    },
    name: 'schedule',
    meta: {
      title: '进度列表',
      keepAlive: true
    }
  }, {
    path: 'export/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/export/index'));
      });
    },
    name: 'export',
    meta: {
      title: '订单导出列表',
      keepAlive: true
    }
  }, {
    path: 'task/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/task/index'));
      });
    },
    name: 'task',
    meta: {
      title: '任务设置',
      keepAlive: true
    }
  }]
}];
export default systemRouter;