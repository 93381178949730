import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderLogistics, orderLogisticsWay, orderLogisticsExport, orderStatus, uploadTrackingOnline, trackingArrived } from '@/api/commerce';
import { blobUpload } from '@/utils/blob';
import { getSession } from '@/utils/session';
export default {
  name: 'waybill-system',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    logistics: function logistics() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/logistics'));
      });
    },
    DialogImport: function DialogImport() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/dialogImport'));
      });
    } // packageSearch: () => import('@/components/packageSearch/index')

  },

  /*  computed: {
    // 展开过滤列表
    filterList() {
      return [
        { label: '物流状态', inputType: 'select', name: 'logistics_status', children: this.selectList.logisticsStatusList, multiple: true },
        { label: '客户', inputType: 'select', name: 'service_id', children: this.selectList.serviceList, multiple: true },
        { label: '物流公司', inputType: 'input', name: 'logistics_company' },
        { label: '物流单号', inputType: 'input', name: 'logistics_no' },
        { label: '第三方订单号', inputType: 'input', name: 'thirdParty_order_on' },
        { label: '收件人姓名', inputType: 'input', name: 'consignee' },
        { label: '创建时间', inputType: 'daterange', name: ['create_start_time','create_end_time']},
        { label: '更新时间', inputType: 'daterange', name: ['update_start_time','update_end_time']}
      ]
    }
  },*/
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '第三方订单号',
        value: 'thirdParty_order_on',
        type: 'order'
      }, {
        label: '客户',
        value: 'service_name'
      }, {
        label: '物流单号',
        value: 'logistics_no'
      }, {
        label: '物流跟踪号',
        value: 'logistics_track_num'
      }, {
        label: '物流公司',
        value: 'logistics_company'
      }, {
        label: '收货国家',
        value: 'country'
      }, {
        label: '收货地址',
        value: 'address1'
      }, {
        label: '收件人姓名',
        value: 'consignee'
      }, {
        label: '创建时间',
        value: 'create_time'
      }, {
        label: '更新时间',
        value: 'update_time'
      }, {
        label: '物流状态',
        value: 'logistics_status_name',
        width: '100'
      }, {
        label: '运单标签',
        value: 'order_label',
        width: '100',
        type: 'order_label'
      }, {
        label: '操作',
        type: 'operation',
        width: '80'
      }],
      tableData: [],
      dataList: [],
      loading: false,
      supplierVisible: false,
      downloadLoading: false,
      logisticsVisible: false,
      importVisible: false,
      logisticsUpdateVisible: false,
      importProps: {
        index: process.env.VUE_APP_DOWNLOAD_URL + '/uploadTrackNumber.xls',
        url: '/order/order-logistics/upload-tracking-number',
        name: 'importOrder.xlsx'
      },
      updateTitle: '批量导入追踪号',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        logistics_status: [],
        service_id: [],
        logistics_company: '',
        logistics_no: '',
        thirdParty_order_on: '',
        consignee: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      selectList: {
        logisticsStatusList: [],
        serviceList: [],
        logisticsCompany: []
      },
      create_start: [],
      update_start: [],
      channelList: [],
      platforms: [],
      platform: '1',
      formInlineOneOne: {
        logistics_status: [],
        service_id: [],
        logistics_company: '',
        logistics_no: '',
        thirdParty_order_on: '',
        consignee: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      formInlineOneTwo: {
        logistics_status: [],
        service_id: [],
        logistics_company: '',
        logistics_no: '',
        thirdParty_order_on: '',
        consignee: '',
        create_start_time: '',
        create_end_time: '',
        update_start_time: '',
        update_end_time: ''
      },
      selectTabData: [],
      logisticsUpdateForm: {
        updateTableData: []
      },
      logisticsUpdateRules: {},
      updateLoading: false
    };
  },
  watch: {
    create_start: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.create_start_time = this.create_start[0];
          this.formInline.create_end_time = this.create_start[1];
        } else {
          this.formInline.create_start_time = '';
          this.formInline.create_end_time = '';
        }
      },
      immediate: true
    },
    update_start: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.update_start_time = this.update_start[0];
          this.formInline.update_end_time = this.update_start[1];
        } else {
          this.formInline.update_start_time = '';
          this.formInline.update_end_time = '';
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.Inquire();
    this.selectList.serviceList = getSession('serviceList');
    this.channelList = getSession('channelList');
    this.platforms = getSession('platform');
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      orderStatus({
        plat_form: this.platform
      }).then(function (res) {
        if (res.code === 200) {
          _this.selectList.logisticsStatusList = res.data.logisticsStatus;
          _this.selectList.logisticsCompany = res.data.logistics_company; // 展开过滤转换

          /* for( let i in res.data.logisticsStatus) {
            let o = {}
            o.value = i
            o.label = res.data.logisticsStatus[i]
            this.selectList.logisticsStatusList.push(o)
          }*/
        }
      });
    },
    handlePlatformClick: function handlePlatformClick() {
      if (this.platform === '1') {
        this.init();
        this.formInline = this.formInlineOneOne;
      } else {
        this.init();
        this.formInline = this.formInlineOneTwo;
      }

      this.Inquire();
    },
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();

      if (this.platform === '1') {
        this.formInlineOneOne = this.formInline;
      } else {
        this.formInlineOneTwo = this.formInline;
      }
    },
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_start = [];
      this.update_start = [];
      this.Inquire();

      if (this.platform === '1') {
        this.formInlineOneOne = this.formInline;
      } else {
        this.formInlineOneTwo = this.formInline;
      }
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      formData.logistics_status = this.formInline.logistics_status.join(',');
      formData.service_id = this.formInline.service_id.join(',');
      formData.plat_form = this.platform;
      orderLogistics(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this2.tableData = res.data;
            _this2.listQuery.total = parseInt(res.total);
            _this2.loading = false;
          }, 300);
        }
      });
    },
    lookClick: function lookClick(row) {
      var _this3 = this;

      if (row.logistics_no === '') {
        this.$message.error('logistics_no 不能为空');
        return;
      }

      if (row.logistics_company === '') {
        this.$message.error('logistics_no 不能为空');
        return;
      }

      orderLogisticsWay({
        logistics_no: row.logistics_no,
        logistics_company: row.logistics_company,
        plat_form: this.platform
      }).then(function (res) {
        if (res.code === 200) {
          if (res.data.OrderTrackingDetails !== undefined) {
            _this3.dataList = res.data.OrderTrackingDetails;
            _this3.logisticsVisible = true;
          } else {
            _this3.$message.error(res.message);
          }
        }
      });
    },
    closeLogistics: function closeLogistics() {
      this.logisticsVisible = false;
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectTabData = val;
    },
    // 下单详情
    orderClick: function orderClick(item) {
      this.$router.push({
        name: 'orderd-details',
        query: {
          order_no: item.order_no,
          service_name: item.service_name,
          id: item.orders_sublist_id,
          plat_form: this.platform
        },
        params: {
          id: item.orders_sublist_id
        }
      });
    },
    // 导入追踪号
    // 打开上传弹窗
    openImport: function openImport() {
      this.importVisible = true;
    },
    // 关闭上传弹窗
    closeImport: function closeImport(type) {
      this.importVisible = false;

      if (type === 1) {
        this.Inquire();
      }
    },
    handleDownload: function handleDownload() {
      var _this4 = this;

      var params = JSON.parse(JSON.stringify(this.formInline));
      params.logistics_status = this.formInline.logistics_status.join(',');
      params.service_id = this.formInline.service_id.join(',');
      params.plat_form = this.platform;
      var arr = Object.values(params).filter(function (item) {
        return item;
      });
      if (arr.length === 0) return this.$message({
        type: 'warning',
        message: '请选择搜索条件进行导出!'
      });
      this.downloadLoading = true;
      orderLogisticsExport(params).then(function (res) {
        blobUpload(res, 'orderLogistics.xlsx').then(function (res) {
          console.log(res);
          _this4.downloadLoading = false;
        }).catch(function (err) {
          console.log(err);
          _this4.downloadLoading = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this4.downloadLoading = false;
      });
    },
    // 更新
    logisticsUpdate: function logisticsUpdate() {
      var _this5 = this;

      if (this.selectTabData.length === 0) {
        this.$message.warning('请至少选择一条数据！');
      } else if (this.selectTabData.length > 10) {
        this.$message.warning('最多选择10条数据！');
      } else {
        this.logisticsUpdateForm.updateTableData = JSON.parse(JSON.stringify(this.selectTabData));
        this.logisticsUpdateForm.updateTableData.map(function (it) {
          _this5.$set(it, 'tracking_company', it.logistics_company);
        });
        this.logisticsUpdateVisible = true;
      }
    },
    handleLogisticsUpdateClose: function handleLogisticsUpdateClose() {
      this.logisticsUpdateVisible = false;
      this.logisticsUpdateForm.updateTableData = [];
    },
    handleLogisticsUpdate: function handleLogisticsUpdate() {
      var _this6 = this;

      this.$refs['logisticsUpdateForm'].validate(function (valid) {
        if (valid) {
          _this6.updateLoading = true;
          var arr = [];

          _this6.logisticsUpdateForm.updateTableData.map(function (it) {
            var obj = {
              order_no: it.order_no,
              third_order_no: it.thirdParty_order_on,
              tracking_company: it.tracking_company,
              foreign_tracking_company: it.foreign_tracking_company,
              logistics_no: it.logistics_no,
              tracking_number: it.logistics_track_num,
              tracking_url: it.tracking_url
            };
            arr.push(obj);
          });

          var formData = {
            plat_form: _this6.platform,
            list: arr
          };
          uploadTrackingOnline(formData).then(function (res) {
            if (res.code === 200) {
              _this6.updateLoading = false;

              _this6.$message.success(res.message);

              _this6.handleLogisticsUpdateClose();

              _this6.Inquire();
            } else {
              _this6.updateLoading = false;
            }
          }).catch(function (e) {
            console.log(e);
            _this6.updateLoading = false;
          }).finally(function () {
            _this6.updateLoading = false;
          });
        } else {
          _this6.$message.warning('请完善必填项！');
        }
      });
    },
    arrived: function arrived() {
      var _this7 = this;

      if (this.selectTabData.length === 0) {
        this.$message.warning('请至少选择一条数据！');
      } else if (this.selectTabData.length > 10) {
        this.$message.warning('最多选择10条数据！');
      } else {
        var isAllow = true;
        var data_id = this.selectTabData.map(function (e) {
          if (!e.logistics_no) {
            isAllow = false;
          }

          return e.id;
        });

        if (!isAllow) {
          this.$message.warning('运单号为空不允许设置已送达');
          return;
        }

        trackingArrived({
          id: data_id.join(','),
          plat_form: this.platform
        }).then(function (res) {
          if (res.code === 200) {
            _this7.$message.success(res.message);

            _this7.Inquire();
          } else {
            _this7.$message.warning(res.message);
          }
        });
      }
    }
    /* // 展开搜索
    handleFilter(val) {
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    },
    // 重置
    resetFilter() {
      this.formInline = this.$options.data().formInline
      this.Inquire()
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    }*/

  }
};