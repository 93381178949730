var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增公司",
            visible: _vm.visible,
            width: "600px",
            "before-close": _vm.companyAdd,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "companyForm",
              attrs: {
                model: _vm.companyForm,
                size: "small",
                "label-width": "150px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称:", prop: "company_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "公司名称" },
                    model: {
                      value: _vm.companyForm.company_name,
                      callback: function($$v) {
                        _vm.$set(_vm.companyForm, "company_name", $$v)
                      },
                      expression: "companyForm.company_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "对接人:", prop: "docking_people" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "对接人" },
                    model: {
                      value: _vm.companyForm.docking_people,
                      callback: function($$v) {
                        _vm.$set(_vm.companyForm, "docking_people", $$v)
                      },
                      expression: "companyForm.docking_people"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.companyAdd } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.companyAdd(1)
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }