import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import { login as _login, logout as _logout, getMenu, orderServicerList, getUserAvatar } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import router, { routesMap, constantRoutes, resetRouter } from '@/router';
import { getSession, setSession } from '@/utils/session';

var Base64 = require('js-base64').Base64;

export function hasRouter(route) {
  return routesMap[route.component] !== undefined ? routesMap[route.component] : '';
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */

export function filterAsyncRoutes(serverMap) {
  var res = []; // console.log(...routesMap['information'])

  serverMap.forEach(function (route) {
    if (routesMap[route.component]) {
      var tmp = _objectSpread(_objectSpread({}, routesMap[route.component]), {}, {
        children: route.next_menu
      });

      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children);
      }

      res.push(tmp);
    }
  });
  return res;
}
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  user: {},
  routes: [],
  addRoutes: [],
  routesMap: getSession('routes')
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
    state.introduction = introduction;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_USER: function SET_USER(state, user) {
    state.user = user;
  },
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  },
  SET_ROUTES_MAP: function SET_ROUTES_MAP(state, list) {
    state.routesMap = list;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    // const { username, password } = userInfo
    // const LoginForm = {}
    return new Promise(function (resolve, reject) {
      _login({
        LoginForm: userInfo,
        id: 1
      }).then(function (response) {
        if (response) {
          var data = response;
          var user = JSON.parse(Base64.decode(data.params));
          var token = user.token;
          setSession('user', user);
          commit('SET_USER', user); // commit('SET_NAME', user.username)

          commit('SET_TOKEN', user.token);
          setToken(token);
          resolve();
          getUserAvatar(user.url).then(function (res) {
            if (res.code === 200) {
              setSession('avatar', res.data.image);
              setSession('username', res.data.username);
            }
          });
        } else {
          reject();
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取用户信息
  getInfo: function getInfo(_ref2) {
    var _this = this;

    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      var user = getSession('user');
      commit('SET_NAME', user.username);
      var routes = getSession('routes');

      if (routes) {
        commit('SET_ROUTES_MAP', routes);
        resolve(routes);
      } else {
        getMenu(user.url, {
          id: user.id,
          cid: user.company_id
        }).then(function (res) {
          if (res.code === 200) {
            var maps = res.data.next_menu.filter(function (i) {
              return i.name !== '首页';
            });
            var list = maps.map(function (item) {
              item.component = item.url.split('/')[0];

              if (item.next_menu.length > 0) {
                item.next_menu.map(function (v) {
                  if (v.next_menu.length > 0) {
                    v.next_menu.map(function (h) {
                      h.component = h.url.split('/')[1];
                      return h;
                    });
                  }

                  v.component = v.url.split('/')[1];
                  return v;
                });
              }

              return item;
            });
            commit('SET_ROUTES_MAP', list);
            setSession('routes', list);
            resolve(list);
          } else {
            reject(new Error('获取菜单报错'));
          }
        });
      }

      var serviceList = [];
      var platform = [];
      var channelList = [];
      orderServicerList(user.url).then(function (res) {
        if (res.code === 200) {
          _this.serviceloading = false;
          /* res.data.servicer_list.map(item => {
            let o = {}
            o.value = item.id
            o.label = item.service_name
            serviceList.push(o)
          })*/

          serviceList = res.data.servicer_list;
          res.data.platform.map(function (item) {
            var o = {};
            o.value = item;

            if (item === '1') {
              o.label = 'Shopify';
            } else {
              o.label = 'Woo Commerce';
            }

            platform.push(o);
          });
          channelList = res.data.channel;
          setSession('serviceList', serviceList);
          setSession('platform', platform);
          setSession('channelList', channelList);
        }
      });
    });
  },
  generateRoutes: function generateRoutes(_ref3, routesMap) {
    var state = _ref3.state,
        commit = _ref3.commit;
    return new Promise(function (resolve) {
      var routes = routesMap || state.routesMap;
      var accessedRoutes = filterAsyncRoutes(routes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state,
        dispatch = _ref4.dispatch;
    return new Promise(function (resolve, reject) {
      _logout(getSession('user').url, state.token).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_NAME', '');
        commit('SET_ROLES', []);
        removeToken();
        resetRouter();
        sessionStorage.clear();
        localStorage.clear(); // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485

        dispatch('tagsView/delAllViews', null, {
          root: true
        });
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },
  // dynamically modify permissions
  changeRoles: function changeRoles(_ref6, role) {
    var commit = _ref6.commit,
        dispatch = _ref6.dispatch;
    return new Promise(function (resolve) {
      var token = role + '-token';
      commit('SET_TOKEN', token);
      setToken(token);

      var _dispatch = dispatch('getInfo'),
          roles = _dispatch.roles;

      resetRouter(); // generate accessible routes map based on roles

      var accessRoutes = dispatch('permission/generateRoutes', roles, {
        root: true
      }); // dynamically add accessible routes

      router.addRoutes(accessRoutes); // reset visited views and cached views

      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};