import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getMergedOrder, previewMergedorder } from '@/api/commerce';
export default {
  data: function data() {
    return {
      labelBasis: [{
        label: '订单号:',
        value: 'order_no'
      }, {
        label: '第三方订单号:',
        value: 'thirdParty_order_on'
      }, {
        label: '订单名称:',
        value: 'order_name'
      }, {
        label: '客户:',
        value: 'service_name'
      }, {
        label: '店铺:',
        value: 'store_url'
      }],
      labelExpress: [{
        label: '收件人姓:',
        value: 'first_name'
      }, {
        label: '收件人名:',
        value: 'last_name'
      }, {
        label: '收件国家:',
        value: 'country'
      }, {
        label: '收件人所在城市:',
        value: 'city'
      }, {
        label: '收件人所在省/州:',
        value: 'province'
      }, {
        label: '收件邮编:',
        value: 'zip'
      }, {
        label: '收件人地址:',
        value: 'address1'
      }, {
        label: '联系电话:',
        value: 'mobile'
      }, {
        label: '收件人邮箱:',
        value: 'email'
      }],
      labelShop: [{
        label: '商品编码',
        value: 'goods_id',
        type: 'order_goods'
      }, {
        label: '中文商品名称',
        value: 'goods_name'
      }, {
        label: '英文商品名称',
        value: 'english_title'
      }, {
        label: 'SKU编码',
        value: 'sku'
      }, {
        label: 'SKU名称',
        value: 'sku_name'
      }, {
        label: 'SKU数量',
        value: 'sku_num'
      }],
      orderForm: {},
      expressInfo: {
        'first_name': '',
        'last_name': '',
        'country': '',
        'city': '',
        'province': '',
        'zip': '',
        'address1': '',
        'mobile': '',
        'email': ''
      }
    };
  },
  computed: {
    newOrderNo: function newOrderNo() {
      return this.$route.query.order_no;
    }
  },
  created: function created() {
    this.init();
  },
  activated: function activated() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      var ids = JSON.parse(this.$route.query.ids).join(',');
      getMergedOrder({
        orders_sublist_id: ids
      }).then(function (result) {
        if (result.code === 200) {
          _this.orderForm = result.data;
          console.log(_this.orderForm); // 若两个订单快递信息相同，则初始化快递信息

          for (var key in _this.expressInfo) {
            if (_this.orderForm[key][0] === _this.orderForm[key][1]) {
              _this.expressInfo[key] = _this.orderForm[key][0];
            }
          }
        } else {
          console.log('err');
        }
      });
    },
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    submitOrder: function submitOrder() {
      var _this2 = this;

      var formData = {};
      var obj = {
        'customer_orders_sublist_id': '',
        'thirdParty_order_on': '',
        'order_name': '',
        'service_id': '',
        'rate': '',
        'country': '',
        'order_no': '',
        'service_name': '',
        'store_url': '',
        'order_status_name': '',
        'logistics_status_name': '',
        'currency': '',
        'order_status_client': '',
        'orders_id': '',
        'customer_id': ''
      };

      for (var key in obj) {
        if (key === 'customer_orders_sublist_id') {
          formData[key] = this.orderForm[key];
        } else if (key === 'thirdParty_order_on' || key === 'order_name') {
          formData[key] = this.orderForm[key].join(',');
        } else {
          formData[key] = this.orderForm[key][0];
        }
      }

      formData.order_no = this.newOrderNo;

      for (var _key in this.expressInfo) {
        formData[_key] = this.expressInfo[_key];
      }

      previewMergedorder(formData).then(function (result) {
        if (result.code === 200) {
          localStorage.setItem('mergedOrders', JSON.stringify(result.data));

          _this2.$store.dispatch('tagsView/delView', _this2.$route);

          _this2.$router.push({
            name: 'merge-checked',
            query: {
              order_no: result.data.order_no,
              service_name: _this2.$route.query.service_name
            }
          });
        }
      });
    }
  }
};