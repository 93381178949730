var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commodity-warp" },
    [
      _c("div", { staticClass: "commodity-info" }, [
        _c(
          "div",
          { staticClass: "box-card" },
          [
            _c(
              "el-form",
              {
                ref: "goodsForm",
                staticClass: "commodity-form",
                attrs: {
                  model: _vm.form,
                  rules: _vm.goodsRules,
                  "label-width": "120px",
                  size: "small"
                }
              },
              [
                _c(
                  "el-card",
                  { staticClass: "box-card mt10" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [
                        _c("span", { staticClass: "card-title" }, [
                          _vm._v("基础信息")
                        ])
                      ]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "row-bg", attrs: { gutter: 30 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { md: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品中文名称:",
                                  prop: "chinese_title"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "100",
                                    clearable: "",
                                    placeholder: "请输入商品中文名称"
                                  },
                                  model: {
                                    value: _vm.form.chinese_title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "chinese_title", $$v)
                                    },
                                    expression: "form.chinese_title"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { md: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品英文名称:",
                                  prop: "english_title"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "100",
                                    clearable: "",
                                    placeholder: "请输入商品英文名称"
                                  },
                                  model: {
                                    value: _vm.form.english_title,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "english_title", $$v)
                                    },
                                    expression: "form.english_title"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { md: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品描述:",
                                  prop: "product_descript"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    clearable: "",
                                    autosize: { minRows: 5 },
                                    maxlength: "500",
                                    "show-word-limit": "",
                                    placeholder: "请输入商品描述"
                                  },
                                  model: {
                                    value: _vm.form.product_descript,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "product_descript",
                                        $$v
                                      )
                                    },
                                    expression: "form.product_descript"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { md: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品内容:",
                                  prop: "product_content"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    clearable: "",
                                    autosize: { minRows: 5 },
                                    maxlength: "500",
                                    "show-word-limit": "",
                                    placeholder: "请输入商品内容"
                                  },
                                  model: {
                                    value: _vm.form.product_content,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "product_content", $$v)
                                    },
                                    expression: "form.product_content"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { md: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "商品属性:",
                                  prop: "product_attribute"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择商品属性"
                                    },
                                    on: { change: _vm.clearProviders },
                                    model: {
                                      value: _vm.form.product_attribute,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "product_attribute",
                                          $$v
                                        )
                                      },
                                      expression: "form.product_attribute"
                                    }
                                  },
                                  _vm._l(_vm.product_attribute, function(
                                    item,
                                    key
                                  ) {
                                    return _c("el-option", {
                                      key: key,
                                      attrs: { label: item, value: String(key) }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { md: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "采购链接:",
                                  prop: "product_pur_url"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入商品采购链接"
                                  },
                                  model: {
                                    value: _vm.form.product_pur_url,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "product_pur_url", $$v)
                                    },
                                    expression: "form.product_pur_url"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("shop-window", {
                  attrs: { "img-list": _vm.imgList },
                  on: { update: _vm.updateImgList, delete: _vm.deleteImg }
                }),
                _c(
                  "el-card",
                  { staticClass: "box-card mt20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [
                        _c("span", { staticClass: "card-title" }, [
                          _vm._v("商品SKU")
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "f-r",
                            attrs: {
                              type: "primary",
                              icon: "el-icon-plus",
                              size: "mini"
                            },
                            on: {
                              click: function($event) {
                                return _vm.addSkuData()
                              }
                            }
                          },
                          [_vm._v("新增SKU ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.form.sub_sku,
                          border: "",
                          stripe: "",
                          "header-cell-style": {
                            background: "#e9f1ff",
                            color: "#606266"
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "50", label: "序号" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "SKU编码",
                            prop: "sku",
                            width: "140"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flexbox justify-center align-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(_vm._s(scope.column.label))
                                      ])
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." + scope.$index + ".sku",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写SKU编码",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "SKU编码"
                                        },
                                        model: {
                                          value: scope.row.sku,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "sku", $$v)
                                          },
                                          expression: "scope.row.sku"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "SKU别名", prop: "sku_name" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flexbox justify-center align-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(_vm._s(scope.column.label))
                                      ])
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sku_name",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请填写SKU别名",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "SKU别名"
                                        },
                                        model: {
                                          value: scope.row.sku_name,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "sku_name", $$v)
                                          },
                                          expression: "scope.row.sku_name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "SKU特征", prop: "sku_feature" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flexbox justify-center align-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(_vm._s(scope.column.label))
                                      ])
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sku_feature",
                                        rules: [
                                          {
                                            required: true,
                                            message: "请输入SKU特征",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "SKU特征"
                                        },
                                        model: {
                                          value: scope.row.sku_feature,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sku_feature",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sku_feature"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "采购链接", prop: "sku_pur_url" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sku_pur_url"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "SKU采购链接"
                                        },
                                        model: {
                                          value: scope.row.sku_pur_url,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sku_pur_url",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sku_pur_url"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "体积", prop: "sku_volume" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flexbox justify-center align-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(_vm._s(scope.column.label))
                                      ]),
                                      _c("el-input", {
                                        staticClass: "w-80 ml5",
                                        attrs: {
                                          size: "mini",
                                          placeholder: "体积"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.attHeaderChange(
                                              "sku_volume"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.attr.sku_volume,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.attr,
                                              "sku_volume",
                                              $$v
                                            )
                                          },
                                          expression: "attr.sku_volume"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sku_volume",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "体积"
                                        },
                                        model: {
                                          value: scope.row.sku_volume,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sku_volume",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sku_volume"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "重量(G)", prop: "sku_weight" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flexbox justify-center align-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(_vm._s(scope.column.label))
                                      ]),
                                      _c("el-input", {
                                        staticClass: "w-80 ml5",
                                        attrs: {
                                          size: "mini",
                                          placeholder: "重量"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.attHeaderChange(
                                              "sku_weight"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.attr.sku_weight,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.attr,
                                              "sku_weight",
                                              $$v
                                            )
                                          },
                                          expression: "attr.sku_weight"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sku_weight",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "重量(G)"
                                        },
                                        model: {
                                          value: scope.row.sku_weight,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sku_weight",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sku_weight"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "尺寸", prop: "sku_size" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flexbox justify-center align-center"
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(_vm._s(scope.column.label))
                                      ]),
                                      _c("el-input", {
                                        staticClass: "w-80 ml5",
                                        attrs: {
                                          size: "mini",
                                          placeholder: "尺寸"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.attHeaderChange(
                                              "sku_size"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.attr.sku_size,
                                          callback: function($$v) {
                                            _vm.$set(_vm.attr, "sku_size", $$v)
                                          },
                                          expression: "attr.sku_size"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sku_size",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          size: "mini",
                                          clearable: "",
                                          placeholder: "尺寸(长宽高)"
                                        },
                                        model: {
                                          value: scope.row.sku_size,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "sku_size", $$v)
                                          },
                                          expression: "scope.row.sku_size"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "属性图", width: "200" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(_vm._s(scope.column.label))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "sku_image",
                                      attrs: {
                                        src: _vm._f("ArrToStr")(scope.row.url)
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.openPrint(
                                            scope.$index,
                                            scope.row,
                                            "edit",
                                            true
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "error-icon el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作", width: "80px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.delSkuData(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-card",
                  { staticClass: "box-card mt20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [
                        _c("span", { staticClass: "card-title" }, [
                          _vm._v("SKU设置")
                        ])
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.form.sub_sku,
                          border: "",
                          stripe: "",
                          "header-cell-style": {
                            background: "#e9f1ff",
                            color: "#606266"
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "50", label: "序号" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "SKU编码",
                            prop: "sku",
                            width: "140"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.sku))])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "SKU别名", prop: "sku_name" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.sku_name))
                                  ])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "属性图", width: "150" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.column.label))
                                  ])
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "sku_image",
                                      attrs: {
                                        src: _vm._f("ArrToStr")(scope.row.url)
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.openPrint(
                                            scope.$index,
                                            scope.row,
                                            "view",
                                            false
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "error-icon el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "售卖报价",
                            prop: "sale_price",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c("el-input", {
                                    staticClass: "w-80 ml5",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "售卖报价"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.attHeaderChange("sale_price")
                                      }
                                    },
                                    model: {
                                      value: _vm.attr.sale_price,
                                      callback: function($$v) {
                                        _vm.$set(_vm.attr, "sale_price", $$v)
                                      },
                                      expression: "attr.sale_price"
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".sale_price",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "售卖报价"
                                        },
                                        model: {
                                          value: scope.row.sale_price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sale_price",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sale_price"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "售卖库存",
                            prop: "sale_inventory",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c("el-input", {
                                    staticClass: "w-80 ml5",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "售卖库存"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.attHeaderChange(
                                          "sale_inventory"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.attr.sale_inventory,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.attr,
                                          "sale_inventory",
                                          $$v
                                        )
                                      },
                                      expression: "attr.sale_inventory"
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: { "label-width": "0" }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "售卖库存数"
                                        },
                                        model: {
                                          value: scope.row.sale_inventory,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sale_inventory",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sale_inventory"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "采购报价", prop: "pur_price" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c("el-input", {
                                    staticClass: "w-80 ml5",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "采购报价"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.attHeaderChange("pur_price")
                                      }
                                    },
                                    model: {
                                      value: _vm.attr.pur_price,
                                      callback: function($$v) {
                                        _vm.$set(_vm.attr, "pur_price", $$v)
                                      },
                                      expression: "attr.pur_price"
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: { "label-width": "0" }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "采购报价"
                                        },
                                        model: {
                                          value: scope.row.pur_price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "pur_price",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.pur_price"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "采购库存", prop: "pur_inventory" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c("el-input", {
                                    staticClass: "w-80 ml5",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "采购库存"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.attHeaderChange(
                                          "pur_inventory"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.attr.pur_inventory,
                                      callback: function($$v) {
                                        _vm.$set(_vm.attr, "pur_inventory", $$v)
                                      },
                                      expression: "attr.pur_inventory"
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: { "label-width": "0" }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "采购库存数"
                                        },
                                        model: {
                                          value: scope.row.pur_inventory,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "pur_inventory",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.pur_inventory"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "物流商",
                            prop: "providers_id",
                            width: "140"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "物流商",
                                        filterable: "",
                                        clearable: "",
                                        size: "mini"
                                      },
                                      on: { change: _vm.setTheProviders },
                                      model: {
                                        value: _vm.attr.providers_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.attr,
                                            "providers_id",
                                            $$v
                                          )
                                        },
                                        expression: "attr.providers_id"
                                      }
                                    },
                                    _vm._l(_vm.providers, function(
                                      provider,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: provider.logistics_name,
                                          value: provider.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: { "label-width": "0" }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "物流商",
                                            filterable: "",
                                            clearable: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.initChannel(
                                                scope.row.providers_id,
                                                scope.$index
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.providers_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "providers_id",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.providers_id"
                                          }
                                        },
                                        _vm._l(_vm.providers, function(
                                          provider,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: provider.logistics_name,
                                              value: provider.id
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "发货渠道",
                            prop: "chann_id",
                            width: "180"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: !_vm.attr.providers_id,
                                        placeholder: "发货渠道",
                                        filterable: "",
                                        clearable: "",
                                        size: "mini"
                                      },
                                      on: { change: _vm.setTheChannel },
                                      model: {
                                        value: _vm.attr.chann_id,
                                        callback: function($$v) {
                                          _vm.$set(_vm.attr, "chann_id", $$v)
                                        },
                                        expression: "attr.chann_id"
                                      }
                                    },
                                    _vm._l(_vm.channelList, function(
                                      channel,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: channel.channel_name,
                                          value: channel.id,
                                          disabled: channel.disabled
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: { "label-width": "0" }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            disabled: !scope.row.providers_id,
                                            placeholder: "发货渠道",
                                            filterable: "",
                                            clearable: ""
                                          },
                                          model: {
                                            value: scope.row.chann_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "chann_id",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.chann_id"
                                          }
                                        },
                                        _vm._l(scope.row.channelList, function(
                                          channel,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: channel.channel_name,
                                              value: channel.id,
                                              disabled: channel.disabled
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "服务费用",
                            prop: "service_fee",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c("el-input", {
                                    staticClass: "w-80 ml5",
                                    attrs: {
                                      size: "mini",
                                      placeholder: "服务费用"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.attHeaderChange(
                                          "service_fee"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.attr.service_fee,
                                      callback: function($$v) {
                                        _vm.$set(_vm.attr, "service_fee", $$v)
                                      },
                                      expression: "attr.service_fee"
                                    }
                                  })
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." +
                                          scope.$index +
                                          ".service_fee",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "服务费用"
                                        },
                                        model: {
                                          value: scope.row.service_fee,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "service_fee",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.service_fee"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "attr", prop: "attr", width: "140" },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function(scope) {
                                return [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("*")]
                                    ),
                                    _vm._v(_vm._s(scope.column.label))
                                  ]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "attr",
                                        filterable: "",
                                        clearable: "",
                                        size: "mini"
                                      },
                                      model: {
                                        value: _vm.attr.attr,
                                        callback: function($$v) {
                                          _vm.$set(_vm.attr, "attr", $$v)
                                        },
                                        expression: "attr.attr"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "normal", value: "2" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "special", value: "3" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "liquid", value: "1" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "powder", value: "4" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "sub_sku." + scope.$index + ".attr",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "attr",
                                            filterable: "",
                                            clearable: ""
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.initChannel(
                                                scope.row.attr,
                                                scope.$index
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.attr,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "attr", $$v)
                                            },
                                            expression: "scope.row.attr"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "normal",
                                              value: "2"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "special",
                                              value: "3"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "liquid",
                                              value: "1"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "powder",
                                              value: "4"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作", width: "80px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger", size: "small" },
                                      on: {
                                        click: function($event) {
                                          return _vm.clearSkuData(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("清空")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-card",
                  { staticClass: "box-card mt20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [
                        _c("span", { staticClass: "card-title" }, [
                          _vm._v("商品关联")
                        ])
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "multipleTable",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.form.provider_sku_map,
                          border: "",
                          stripe: "",
                          "header-cell-style": {
                            background: "#e9f1ff",
                            color: "#606266"
                          }
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: { type: "index", width: "50", label: "序号" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "客户名称",
                            prop: "customer_name",
                            width: "140"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "店铺链接", prop: "store_url" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "关联商品名称",
                            prop: "product_title",
                            width: "140"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "关联商品图片",
                            prop: "sku_url",
                            width: "140"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-image",
                                    {
                                      staticClass: "sku_image",
                                      attrs: { src: scope.row.sku_url[0] },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.openPrint(
                                            scope.$index,
                                            scope.row,
                                            "view",
                                            false
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "image-slot",
                                          attrs: { slot: "error" },
                                          slot: "error"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "error-icon el-icon-picture-outline"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "SKU编码", prop: "sku" }
                        }),
                        _c("el-table-column", {
                          attrs: { label: "SKU别名", prop: "sku_name" }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "售卖报价",
                            prop: "sale_price",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "provider_sku_map." +
                                          scope.$index +
                                          ".sale_price",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "售卖报价"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.handleEdit(scope.row)
                                          }
                                        },
                                        model: {
                                          value: scope.row.sale_price,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "sale_price",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.sale_price"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "服务费用",
                            prop: "service_fee",
                            width: "120"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "mb0",
                                      attrs: {
                                        "label-width": "0",
                                        prop:
                                          "provider_sku_map." +
                                          scope.$index +
                                          ".service_fee",
                                        rules: [
                                          {
                                            required: true,
                                            message: "不能为空",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "p5_input",
                                        attrs: {
                                          clearable: "",
                                          size: "mini",
                                          placeholder: "售卖报价"
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.handleEdit(scope.row)
                                          }
                                        },
                                        model: {
                                          value: scope.row.service_fee,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "service_fee",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.service_fee"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "关联状态",
                            prop: "status",
                            width: "140"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.status))])
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: "操作", width: "120px" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: scope.row.edit
                                          ? "primary"
                                          : "info",
                                        size: "small",
                                        disabled: !scope.row.edit
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleSetGoodSkuNotice(
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("发送通知")]
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.pageLoading,
              expression: "pageLoading"
            }
          ],
          staticClass: "footBtn"
        },
        [
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "info" },
                  on: { click: _vm.handleCancel }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "throttle",
                      rawName: "v-throttle",
                      value: [
                        function() {
                          return _vm.submit("goodsForm")
                        }
                      ],
                      expression: "[() => submit('goodsForm')]"
                    }
                  ],
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.submitLoading
                  }
                },
                [_vm._v("保存内容 ")]
              )
            ],
            1
          )
        ]
      ),
      _c("upload-print", {
        attrs: { visible: _vm.uploadVisible },
        on: {
          "update:visible": function($event) {
            _vm.uploadVisible = $event
          },
          close: _vm.closeUpload
        }
      }),
      _c("edit-pictures", {
        attrs: {
          visible: _vm.editVisible,
          "show-close": _vm.showClose,
          "form-data": _vm.imgList,
          checked: _vm.skuImg,
          flag: _vm.editFlag
        },
        on: {
          "update:visible": function($event) {
            _vm.editVisible = $event
          },
          select: _vm.selectedImg,
          update: _vm.updateImgList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }