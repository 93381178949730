import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { uploadImage } from '@/api/commodity';
import { getUserInfo, setUserInfo, setUserPassword, setUserEnterprise, deleteUserEnterprise } from '@/api/personalCenter';
import { setSession } from '@/utils/session';
export default {
  name: 'personal-center',
  data: function data() {
    return {
      showInformation: false,
      showPassword: false,
      informationForm: {
        image: '',
        username: '',
        another_name: '',
        email: '',
        phone: '',
        business_image: '',
        enterprise: []
      },
      informationFormRules: {
        image: [{
          required: true,
          message: '请上传头像',
          trigger: 'blur'
        }],
        another_name: [{
          required: true,
          message: '请填写名称',
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '请填写姓名',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请填写邮箱',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请填写手机号',
          trigger: 'blur'
        }],
        business_image: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'blur'
        }]
      },
      passwordForm: {
        old_password: '******',
        password: '',
        password_confirmation: ''
      },
      passwordFormRules: {
        old_password: [{
          required: true,
          message: '请填写原密码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请填写新密码',
          trigger: 'blur'
        }],
        password_confirmation: [{
          required: true,
          validator: this.passValidator,
          trigger: 'blur'
        }]
      },
      avatar: '',
      username: ''
    };
  },
  watch: {
    avatar: function avatar(val, oVal) {
      if (oVal !== '' && val !== '' && oVal !== val) {
        this.$router.go(0);
      }
    },
    username: function username(val, oVal) {
      if (oVal !== '' && val !== '' && oVal !== val) {
        this.$router.go(0);
      }
    }
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      getUserInfo().then(function (res) {
        if (res.code === 200) {
          _this.informationForm = res.data;
          _this.avatar = res.data.image;
          _this.username = res.data.username;
          setSession('avatar', _this.avatar);
          setSession('username', _this.username);
        }
      });
    },
    passValidator: function passValidator(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码以确认新密码！'));
      } else if (value !== this.passwordForm.password) {
        callback(new Error('两次输入密码不一致！'));
      } else {
        callback();
      }
    },
    // 头像上传
    upload: function upload(fileObj) {
      var _this2 = this;

      var file = {
        showProgress: true,
        url: '',
        percent: 0
      };
      var formData = new FormData();
      formData.append('file', fileObj.file);
      uploadImage(formData, function (progress) {
        file.percent = Math.round(progress.loaded / progress.total * 100);
      }).then(function (res) {
        if (res.code === 200) {
          var data = JSON.parse(JSON.stringify(res.data));
          file.url = data['data-service-file'];
          file.showProgress = false;
          _this2.informationForm.image = data['data-service-file'];
          _this2.showImg = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 头像改变钩子
    handleChange: function handleChange(file, fileList) {// console.log('change', file)
    },
    // 头像上传后
    handleBeforeUpload: function handleBeforeUpload(file) {
      var _this3 = this;

      var isSize = new Promise(function (resolve, reject) {
        var isLt2M = file.size / 1024 / 1024 < 2;
        isLt2M ? resolve() : reject(new Error('Error'));
      }).then(function () {
        return file;
      }, function () {
        _this3.$message.error('The uploaded image size exceeds 2M！');

        return Promise.reject(new Error('Error'));
      });
      return isSize;
    },
    // 营业执照上传
    businessUpload: function businessUpload(fileObj) {
      var _this4 = this;

      var file = {
        showProgress: true,
        url: '',
        percent: 0
      };
      var formData = new FormData();
      formData.append('file', fileObj.file);
      uploadImage(formData, function (progress) {
        file.percent = Math.round(progress.loaded / progress.total * 100);
      }).then(function (res) {
        if (res.code === 200) {
          var data = JSON.parse(JSON.stringify(res.data));
          file.url = data['data-service-file'];
          file.showProgress = false;
          _this4.informationForm.business_image = data['data-service-file'];
          _this4.showImg = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 营业执照改变钩子
    businessChange: function businessChange(file, fileList) {// console.log('change', file)
    },
    // 营业执照上传后
    businessBeforeUpload: function businessBeforeUpload(file) {
      var _this5 = this;

      var isSize = new Promise(function (resolve, reject) {
        var isLt2M = file.size / 1024 / 1024 < 2;
        isLt2M ? resolve() : reject(new Error('Error'));
      }).then(function () {
        return file;
      }, function () {
        _this5.$message.error('The uploaded image size exceeds 2M！');

        return Promise.reject(new Error('Error'));
      });
      return isSize;
    },
    // 企业logo上传
    enterpriseUpload: function enterpriseUpload(fileObj, row) {
      var _this6 = this;

      var file = {
        showProgress: true,
        url: '',
        percent: 0
      };
      var formData = new FormData();
      formData.append('file', fileObj.file);
      uploadImage(formData, function (progress) {
        file.percent = Math.round(progress.loaded / progress.total * 100);
      }).then(function (res) {
        if (res.code === 200) {
          var data = JSON.parse(JSON.stringify(res.data));
          file.url = data['data-service-file'];
          file.showProgress = false;
          row.enterprise_logo = data['data-service-file'];
          _this6.showImg = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 企业logo改变钩子
    enterpriseBeforeUpload: function enterpriseBeforeUpload(file, fileList) {// console.log('change', file)
    },
    // 企业logo上传后
    enterpriseChange: function enterpriseChange(file) {
      var _this7 = this;

      var isSize = new Promise(function (resolve, reject) {
        var isLt2M = file.size / 1024 / 1024 < 2;
        isLt2M ? resolve() : reject(new Error('Error'));
      }).then(function () {
        return file;
      }, function () {
        _this7.$message.error('The uploaded image size exceeds 2M！');

        return Promise.reject(new Error('Error'));
      });
      return isSize;
    },
    // 新增
    handleAdd: function handleAdd() {
      this.informationForm.enterprise.push({
        enterprise_logo: '',
        enterprise_info: '',
        id: '',
        changeBilling: true,
        isEdit: false
      });
    },
    // 修改
    handleEdit: function handleEdit(row) {
      this.$set(row, 'isEdit', true);
      this.$set(row, 'changeBilling', true);
    },
    // 保存
    handleSubmit: function handleSubmit(row) {
      var _this8 = this;

      var fromData = {
        id: row.id,
        enterprise_logo: row.enterprise_logo,
        enterprise_info: row.enterprise_info
      };
      setUserEnterprise(fromData).then(function (res) {
        if (res.code === 200) {
          row.changeBilling = false;

          _this8.$message.success(res.message);
        } else {
          _this8.$message.error(res.message);
        }
      });
    },
    // 删除
    handleDelete: function handleDelete(scope) {
      var _this9 = this;

      if (scope.row.id !== '') {
        var id = scope.row.id;
        deleteUserEnterprise({
          id: id
        }).then(function (res) {
          if (res.code === 200) {
            _this9.$message.success(res.message);
          } else {
            _this9.$message.error(res.message);
          }
        });
      } else {
        this.informationForm.enterprise.splice(scope.$index, 1);
      }
    },
    // 基本信息保存
    submitInformation: function submitInformation() {
      var _this10 = this;

      this.$refs['basicInformation'].validate(function (valid) {
        if (valid) {
          setUserInfo(_this10.informationForm).then(function (res) {
            if (res.code === 200) {
              _this10.showInformation = false;

              _this10.$message.success(res.message);

              _this10.init();
            } else {
              _this10.$message.error(res.message);
            }
          });
        }
      });
    },
    // 基本信息编辑
    editInformation: function editInformation() {
      this.showInformation = true;
    },
    // 修改密码编辑
    editPassword: function editPassword() {
      this.passwordForm = this.$options.data().passwordForm;
      this.passwordForm.old_password = '';
      this.showPassword = true;
    },
    // 修改密码保存
    submitPassword: function submitPassword() {
      var _this11 = this;

      this.$refs['changePassword'].validate(function (valid) {
        if (valid) {
          setUserPassword(_this11.passwordForm).then(function (res) {
            if (res.code === 200) {
              _this11.showPassword = false;

              _this11.$message.success(res.message);

              _this11.passwordForm.old_password = '******';
            } else {
              _this11.$message.error(res.message);
            }
          });
        }
      });
    }
  }
};