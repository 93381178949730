//
//
//
//
//
//
//
//
//
//
//
//
//
// import { orderLogisticsWay } from '@/api/commerce'
export default {
  name: 'logistics',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      dataList: []
    };
  },
  watch: {
    formData: function formData(val) {
      if (val) {
        this.dataList = val;
      }
    }
  },
  methods: {
    logisticsText: function logisticsText() {
      this.$emit('logisticsText');
    }
  }
};