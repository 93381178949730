import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { exportDataList } from '@/api/system';
export default {
  // name:"runTaskList",
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    }
  },
  data: function data() {
    return {
      tableColumn: [{
        label: 'id',
        value: 'id',
        width: '150px'
      }, {
        label: '创建人',
        value: 'user'
      }, {
        label: '文件名',
        value: 'file_name'
      }, {
        label: '创建时间',
        value: 'create_time'
      }, {
        label: '下载链接',
        value: 'download_url'
      }],
      tableData: [],
      tableDataLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 1
      },
      timeId: '',
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.Inquire();
    this.timeId = setInterval(function () {
      setTimeout(_this.Inquire(), 0);
    }, 5000);
  },
  destroyed: function destroyed() {
    window.clearTimeout(this.timeId);
  },
  methods: {
    goDownload: function goDownload(val) {
      window.open(val);
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      var params = {
        iDisplayLength: this.listQuery.limit,
        iDisplayStart: (this.listQuery.page - 1) * this.listQuery.limit
      };
      exportDataList(params).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            item.status = item.status === '1' ? '进行中' : '已完成';
          });
          _this2.tableDataLoading = false;
          _this2.listQuery.total = parseInt(res.total);
          _this2.tableData = res.data;
        }
      });
    }
  }
};