import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { userGetList, baseCommonOperating, baseCommonOption } from '@/api/account';
export default {
  name: 'account-set',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    reviewAdd: function reviewAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/reviewAdd'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '手机号',
        value: 'phone'
      }, {
        label: '姓名',
        value: 'username'
      }, {
        label: '审核人员',
        value: 'audit'
      }, {
        label: '角色',
        value: 'user_role'
      }, {
        label: '权限',
        value: 'ruleName',
        width: '600'
      }, {
        label: '注册时间',
        value: 'create_at'
      }, {
        label: '状态',
        value: 'status',
        type: 'status'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      loading: false,
      reviewVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        phone: '',
        audit: '',
        user_role: '',
        status: ''
      },
      selectList: {},
      typeList: ['待审核', '正常', '停用'],
      dataList: {},
      reviewTitle: '',
      create_start: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.Inquire();
    baseCommonOption().then(function (res) {
      if (res.code == 200) {
        _this.selectList = res.data;
      }
    });
  },
  methods: {
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;

      if (this.create_start) {
        formData.create_at_start = this.create_start[0] || '';
        formData.create_at_end = this.create_start[1] || '';
      } else {
        formData.create_at_start = '';
        formData.create_at_end = '';
      }

      userGetList(formData).then(function (res) {
        if (res.code == 200) {
          setTimeout(function () {
            res.data.map(function (item) {
              if (item.user_role) {
                item.user_role = item.user_role.map(function (items) {
                  return items.role_name;
                }).join(',');
              }
            });
            _this2.tableData = res.data;
            _this2.loading = false;
            _this2.listQuery.total = +res.iTotalRecords;
          }, 300);
        }
      });
    },
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_start = [];
      this.Inquire();
    },
    // 审核 => 修改
    reviewClick: function reviewClick(list, type) {
      this.reviewVisible = true;
      this.dataList = list;

      if (type == 'edit') {
        this.reviewTitle = '修改用户账号';
      } else {
        this.reviewTitle = '审核用户账号';
      }
    },
    closeAccount: function closeAccount(type) {
      if (type != 1) {
        this.Inquire();
      }

      this.reviewVisible = false;
    },
    operation: function operation(id, phone, type) {
      var _this3 = this;

      var typeTitle;
      var typeHead;

      if (type == 'disable ') {
        typeTitle = '确定是否停用该用户:' + phone + ',停用后账号无法登录使用！';
        typeHead = '停用用户账户';
      } else {
        typeTitle = '确定是否激活该用户账号:' + phone + ',激活后用户账号可以重新登录使用！';
        typeHead = '激活用户账户';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        baseCommonOperating({
          id: [id],
          type: type
        }).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success(res.message);

            _this3.Inquire();
          }
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消设置'
        });
      });
    }
  }
};