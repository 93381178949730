import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.values.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Pagination from '@/components/Pagination'
// import commodityAdd from './components/commodityAdd'
// import commodityDialog from './components/commodityDialog'
// import skuSetting from './components/skuSetting'
// import dialogImport from '@/components/dialogImport'
import serviceProvider from './components/serviceProvider';
import { relatedEdit, goodsList, goodsEdit, goodsOpertation, getAllSku, goodsExport, goodsOptions, allUserList } from '@/api/commodity';
import { blobUpload } from '@/utils/blob';
import { getSession } from '@/utils/session';
export default {
  name: 'commodity',
  components: {
    // commodityAdd,
    // commodityDialog,
    // skuSetting,
    serviceProvider: serviceProvider,
    dialogImport: function dialogImport() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/dialogImport'));
      });
    },
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    } // packageSearch: () => import('@/components/packageSearch/index')

  },
  filters: {
    ArrToStr: function ArrToStr(value) {
      if (!value) return '';

      if (Array.isArray(value)) {
        return value[0].toString();
      }

      return '';
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === 'commodity-detail') {
      next(function (vm) {
        vm.Inquire();
      });
    }

    next();
  },
  data: function data() {
    return {
      rules: {},
      loading: false,
      downloadLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      tableHeight: '500',
      activeName: '0',
      formInline: {
        id: '',
        chinese_title: '',
        english_title: '',
        product_attribute: '',
        product_pur_url: '',
        create_user: '',
        update_user: '',
        create_time_start: '',
        create_time_end: '',
        update_time_start: '',
        update_time_end: '',
        sku_name: '',
        sku_id: ''
      },
      create_at: [],
      update_at: [],
      labelList: [{
        label: '商品编码',
        value: 'id',
        show: '0,1',
        type: 'id',
        width: '80'
      }, {
        label: '商品中文名称',
        value: 'chinese_title',
        show: '0,1'
      }, {
        label: '商品英文名称',
        value: 'english_title',
        show: '0,1'
      }, // { label: '商品名称', value: 'product_title', show: '0,1' },
      {
        label: '商品属性',
        value: 'product_attribute',
        show: '0,1',
        width: '80'
      }, {
        label: '采购链接',
        value: 'product_pur_url',
        show: '0',
        type: 'link'
      }, {
        label: '商品图片',
        value: 'img_url',
        show: '0,1',
        type: 'image'
      }, {
        label: 'SKU数量',
        value: 'sku_qty',
        show: '0',
        width: '80'
      }, {
        label: 'SKU供货信息',
        type: 'sku_info'
      }, {
        label: 'SKU售卖信息',
        type: 'sku_sell'
      }, {
        label: '创建人',
        value: 'create_user',
        show: '0',
        width: '80'
      }, {
        label: '创建时间',
        value: 'create_at',
        show: '0'
      }, {
        label: '更新人',
        value: 'update_user',
        show: '0',
        width: '80'
      }, {
        label: '更新时间',
        value: 'update_at',
        show: '0,1'
      } // { label: '操作', type: 'operation', fixed: 'right' }
      ],
      AddCommodityDialog: false,
      commodityDialog: false,
      skuDialog: false,
      serviceDialog: false,
      importVisible: false,
      importProps: {
        index: process.env.VUE_APP_DOWNLOAD_URL + '/newGoodUpload.xlsx',
        url: '/goods/goods/import-goods',
        name: 'goodUpload.xlsx'
      },
      commodityTitle: '新增商品',
      skuTitle: 'SKU设置',
      serviceTitle: '关联客户&供应商',
      selectList: [],
      tableData: [],
      // tabList: ['商品列表', '商品设置'],
      tabList: ['商品列表'],
      commodityFlag: '',
      skuFlag: '',
      serviceFlag: '',
      commodityData: {},
      skuData: [],
      serviceData: [],
      userList: [],
      multipleSelection: []
    };
  },

  /* computed: {
    // 展开过滤列表
    filterList() {
      return this.activeName === '0' ? [
        { label: '商品编码', inputType: 'input', name: 'id' },
        { label: '商品中文名称', inputType: 'input', name: 'chinese_title' },
        { label: '商品英文名称', inputType: 'input', name: 'english_title' },
        { label: '采购链接', inputType: 'input', name: 'product_pur_url' },
        { label: '商品属性', inputType: 'select', name: 'product_attribute', children: this.selectList },
        { label: '创建人', inputType: 'select', name: 'create_user', children: this.userList },
        { label: '创建时间', inputType: 'daterange', name: ['create_time_start','create_time_end']},
        { label: '更新时间', inputType: 'daterange', name: ['update_time_start','update_time_end']}
      ] : [
        { label: '商品编码', inputType: 'input', name: 'id' },
        { label: '商品中文名称', inputType: 'input', name: 'chinese_title' },
        { label: '商品英文名称', inputType: 'input', name: 'english_title' },
        { label: '采购链接', inputType: 'input', name: 'product_pur_url' },
        { label: '商品属性', inputType: 'select', name: 'product_attribute', children: this.selectList },
        { label: '更新人', inputType: 'select', name: 'update_user', children: this.userList },
        { label: '更新时间', inputType: 'daterange', name: ['update_time_start','update_time_end']}
      ]
    }
  },*/
  watch: {
    create_at: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.create_time_start = this.create_at[0];
          this.formInline.create_time_end = this.create_at[1];
        } else {
          this.formInline.create_time_start = '';
          this.formInline.create_time_end = '';
        }
      },
      immediate: true
    },
    update_at: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.update_time_start = this.update_at[0];
          this.formInline.update_time_end = this.update_at[1];
        } else {
          this.formInline.update_time_start = '';
          this.formInline.update_time_end = '';
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;

      goodsOptions().then(function (res) {
        if (res.status === 200) {
          _this.$store.commit('goods/SET_OPTIONS', res.data);

          _this.selectList = res.data.product_attribute; // 展开过滤需要转换

          /* for (let i in res.data.product_attribute) {
            let o = {}
            o.value = i
            o.label = res.data.product_attribute[i]
            this.selectList.push(o)
          }*/
        }
      }).catch(function (err) {
        console.log(err);
      });
      allUserList({
        company_id: getSession('user').company_id
      }).then(function (res) {
        if (res.code === 200) {
          _this.userList = res.data; // 展开过滤需要转换

          /* for (let i in res.data) {
            let o = {}
            o.value = i
            o.label = res.data[i]
            this.userList.push(o)
          }*/
        }
      }).catch(function (err) {
        console.log(err);
      });
      this.Inquire();
    },
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 重置
    resetClick: function resetClick() {
      this.create_at = [];
      this.update_at = [];
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 查询
    Inquire: function Inquire() {
      var _this2 = this;

      this.loading = true;

      var formData = _objectSpread({}, this.formInline);

      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      goodsList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this2.loading = false;
            _this2.tableData = res.data;
            _this2.listQuery.total = +res.iTotalDisplayRecords;
          }, 300);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 新增商品弹窗
    addGoods: function addGoods() {
      // this.commodityTitle = '新增商品'
      // this.AddCommodityDialog = true
      this.$router.push({
        name: 'commodity-detail',
        params: {
          id: 'add'
        },
        query: {
          type: 'add',
          tagTitle: '新增商品'
        }
      });
    },
    openImport: function openImport() {
      this.importVisible = true;
    },
    // 关闭上传弹窗
    closeImport: function closeImport(type) {
      this.importVisible = false;

      if (type === 1) {
        this.Inquire();
      }
    },
    toLink: function toLink(row) {
      this.$router.push({
        name: 'commodity-detail',
        params: {
          id: row.id
        },
        query: {
          type: 'view'
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 操作弹窗
    operation: function operation(id, type) {
      this.commodityFlag = type;

      switch (type) {
        case 'preview':
          this.commodityTitle = '查看商品';
          this.fetchGoodsById(id);
          break;

        case 'edit':
          this.commodityTitle = '修改商品';
          this.fetchGoodsById(id);
          break;

        case 'remove':
          this.goodsRemove(id);
          break;
      }
    },
    fetchGoodsById: function fetchGoodsById(id) {
      var _this3 = this;

      goodsEdit({
        id: id
      }).then(function (res) {
        // console.log(res.data)
        if (res.status === 200) {
          _this3.commodityDialog = true;
          _this3.commodityData = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    fetchSkuById: function fetchSkuById(id) {
      var _this4 = this;

      getAllSku({
        id: id
      }).then(function (res) {
        if (res.status === 200) {
          _this4.skuDialog = true;
          _this4.skuData = res.data;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    goodsRemove: function goodsRemove(id) {
      var _this5 = this;

      var count = this.multipleSelection.length;
      var cid = this.multipleSelection.map(function (i) {
        return i.id;
      });

      if (count <= 0) {
        this.$message.warning('请选择商品后再试！');
        return;
      }

      this.$confirm("\u786E\u8BA4\u662F\u5426\u5220\u9664".concat(count, "\u4E2A\u5546\u54C1\uFF1F\u5220\u9664\u540E\u8BE5\u5546\u54C1\u65E0\u6CD5\u6062\u590D\uFF0C\u8BF7\u5C0F\u5FC3\u64CD\u4F5C\uFF01"), '删除产品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        goodsOpertation({
          id: cid,
          type: 'del'
        }).then(function (res) {
          console.log(res.data);

          if (res.status === 200) {
            _this5.$message.success('删除成功！');

            _this5.Inquire();
          }
        }).catch(function (err) {
          console.log(err);
        });
      }).catch(function () {});
    },
    actions: function actions(id, type) {
      var _this6 = this;

      this.skuFlag = type;

      switch (type) {
        case 'view':
          this.skuTitle = '查看';
          this.fetchSkuById(id);
          break;

        case 'setting':
          this.skuTitle = 'SKU设置';
          this.fetchSkuById(id);
          break;

        case 'associate':
          // this.$router.push({ name: 'hosting', params: { id: id }})
          this.serviceTitle = '关联客户&供应商';
          relatedEdit({
            id: id
          }).then(function (res) {
            if (res.status === 200) {
              _this6.serviceData = res.data.map(function (i) {
                i.services.forEach(function (s) {
                  s.shoplist = [];
                  s.nodata = false;
                }); // if (i.supplier.length <= 0) {
                //   i.supplier.push({})
                // }

                return i;
              });
              _this6.serviceDialog = true;
            }
          });
          break;

        default:
          break;
      }
    },
    // 导出搜索结果
    handleDownload: function handleDownload() {
      var _this7 = this;

      var arr = Object.values(this.formInline).filter(function (item) {
        return item;
      });
      if (arr.length === 0) return this.$message({
        type: 'warning',
        message: '请选择搜索条件进行导出!'
      });
      this.downloadLoading = true;
      goodsExport(this.formInline).then(function (res) {
        blobUpload(res, 'commodity.xlsx').then(function (res) {
          console.log(res);
          _this7.downloadLoading = false;
        }).catch(function (err) {
          console.log(err);
          _this7.downloadLoading = false;
        });
      }).catch(function (err) {
        console.log(err);
        _this7.downloadLoading = false;
      });
    }
    /* // 展开搜索
    handleFilter(val) {
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    },
    // 重置
    resetFilter() {
      this.formInline = this.$options.data().formInline
      this.Inquire()
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.formInline).find( e => e === item.name)
        if (item.name === filterItem) {
          this.formInline[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.formInline[item.name[0]] = item.value[0]
            this.formInline[item.name[1]] = item.value[1]
          } else {
            this.formInline[item.name[0]] = ''
            this.formInline[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    }*/

  }
};