var state = {
  ordesList: [],
  orderData: [],
  disableShopNameList: [true, true, true, true, true, true, true, true],
  delRoutes: [],
  otherTags: {}
};
var mutations = {
  SET_ORDES_LIST: function SET_ORDES_LIST(state, data) {
    state.ordesList = data;
  },
  SET_ORDER_DATA: function SET_ORDER_DATA(state, data) {
    state.orderData = data;
  },
  SET_DISABLE_SHOPNAME_LIST: function SET_DISABLE_SHOPNAME_LIST(state, data) {
    state.disableShopNameList = data;
  },
  SET_DEL_ROUTES: function SET_DEL_ROUTES(state, data) {
    state.delRoutes = data;
  },
  SET_OTHER_TAGS: function SET_OTHER_TAGS(state, data) {
    state.otherTags = data;
  }
};
var actions = {
  // 关闭tags
  closeTags: function closeTags(_ref, val) {
    var commit = _ref.commit,
        state = _ref.state,
        dispatch = _ref.dispatch;
    console.log('state', state.delRoutes);

    if (state.delRoutes) {
      // 关闭上一个页面
      dispatch('tagsView/delView', state.delRoutes, {
        root: true
      }).then(function (_ref2) {
        var visitedViews = _ref2.visitedViews;

        // 如果为true关闭当前页
        if (val.flag) {
          dispatch('tagsView/delView', val.data, {
            root: true
          });
        } else {
          commit('SET_DEL_ROUTES', val.data);
        }
      });
    } else {
      commit('SET_DEL_ROUTES', val.data);
    }
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};