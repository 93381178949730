var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "tinymce-container",
        class: { fullscreen: _vm.fullscreen },
        style: { width: _vm.containerWidth }
      },
      [
        _c(
          "el-button",
          {
            staticClass: "reply",
            attrs: { type: "text" },
            on: { click: _vm.handleReply }
          },
          [_vm._v("回复")]
        ),
        _c("textarea", {
          staticClass: "tinymce-textarea",
          attrs: { id: _vm.tinymceId }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }