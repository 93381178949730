import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { orderBillDataList, orderBillFinish } from '@/api/bill';
import { historyRoute } from '@/mixins/del';
export default {
  name: 'bill-manage',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    } // packageSearch: () => import('@/components/packageSearch/index')

  },
  mixins: [historyRoute],
  data: function data() {
    return {
      // 过滤条件
      billForm: {
        bill_status: '',
        create_start_time: '',
        create_end_time: '',
        fulfill_start_time: '',
        fulfill_end_time: ''
      },
      create_at: [],
      update_at: [],
      // 筛选条件-状态
      billStatusList: [{
        value: 1,
        label: '未结算'
      }, {
        value: 2,
        label: '已结算'
      }],
      // 列表数据
      tableData: [],
      // 列表表头
      labelList: [{
        label: '账单编号',
        value: 'bill_no',
        type: 'order'
      }, {
        label: '账单名称',
        value: 'bill_name'
      }, {
        label: '订单数量',
        value: 'order_num'
      }, {
        label: '创建时间',
        value: 'create_time'
      }, {
        label: '完成时间',
        value: 'fulfill_time'
      }, {
        label: '状态',
        type: 'status',
        value: 'bill_status',
        width: '150'
      }, {
        label: '操作',
        type: 'operation',
        width: '100'
      }],
      loading: false,
      // 分页
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      }
    };
  },

  /* computed: {
    // 展开过滤列表
  filterList() {
    return [
      { label: '状态', inputType: 'select', name: 'bill_status', children: this.billStatusList },
      { label: '账单创建时间', inputType: 'datetimerange', name: ['create_start_time','create_end_time']},
      { label: '账单完成时间', inputType: 'datetimerange', name: ['fulfill_start_time','fulfill_end_time']}
    ]
  }
  },*/
  watch: {
    create_at: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.billForm.create_start_time = this.create_at[0];
          this.billForm.create_end_time = this.create_at[1];
        } else {
          this.billForm.create_start_time = '';
          this.billForm.create_end_time = '';
        }
      },
      immediate: true
    },
    update_at: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.billForm.fulfill_start_time = this.update_at[0];
          this.billForm.fulfill_end_time = this.update_at[1];
        } else {
          this.billForm.fulfill_start_time = '';
          this.billForm.fulfill_end_time = '';
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    // 筛选
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 重置
    resetClick: function resetClick() {
      this.billForm = this.$options.data().billForm;
      this.create_at = [];
      this.update_at = [];
      this.Inquire();
    },
    // 搜索
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;

      var formData = _objectSpread({}, this.billForm);

      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      orderBillDataList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total);
            _this.loading = false;
          }, 300);
        }
      }).catch(function (e) {
        console.log(e);
      });
    },
    // 创建账单
    createBill: function createBill() {
      this.$router.push({
        name: 'create-bill',
        params: {
          id: 'add'
        },
        query: {
          type: 'add',
          tagTitle: '创建账单'
        }
      });
    },
    // 完成账单结算
    completeBill: function completeBill() {
      var _this2 = this;

      var ids = this.selectTabData.map(function (it) {
        return it.id;
      });

      if (this.selectTabData.length > 0) {
        orderBillFinish({
          id: ids
        }).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success('账单结算成功！');

            _this2.Inquire();
          } else {
            _this2.$message.error(res.message);
          }
        });
      } else {
        this.$message.warning('请选择需要完成的账单！');
      }
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectTabData = val;
    },
    // 查看
    lookClick: function lookClick(row) {
      this.$router.push({
        name: 'bill-detail',
        params: {
          id: 'detail'
        },
        query: {
          orderData: row
        }
      });
    },
    getValueOfLabel: function getValueOfLabel(num, sum) {
      var arr = sum.filter(function (e) {
        return e.value === num;
      });

      if (arr.length > 0) {
        return arr[0].label;
      } else {
        return num;
      }
    }
    /* // 展开搜索
    handleFilter(val) {
      this.listQuery.page = 1
      this.listQuery.limit = 10
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.billForm).find( e => e === item.name)
        if (item.name === filterItem) {
          this.billForm[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.billForm[item.name[0]] = item.value[0]
            this.billForm[item.name[1]] = item.value[1]
          } else {
            this.billForm[item.name[0]] = ''
            this.billForm[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    },
    // 重置
    resetFilter() {
      this.billForm = this.$options.data().billForm
      this.Inquire()
    },
    // 展开后二次搜索
    handleSecFilter(val) {
      val.forEach(item => {
        // 如果是下拉框或输入框
        let filterItem = Object.keys(this.billForm).find( e => e === item.name)
        if (item.name === filterItem) {
          this.billForm[filterItem] = item.value
        }
        // 如果是日期
        if (Array.isArray(item.name)) {
          if (item.name.length > 0 && item.value !== null) {
            this.billForm[item.name[0]] = item.value[0]
            this.billForm[item.name[1]] = item.value[1]
          } else {
            this.billForm[item.name[0]] = ''
            this.billForm[item.name[1]] = ''
          }
        }
      })
      this.Inquire()
    }*/

  }
};