import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 供应商列表

export function supplierDataList(data) {
  return request({
    url: '/supplier/supplier/index',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 供应商新增

export function supplierSave(data) {
  return request({
    url: '/supplier/supplier/supplier-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取供应商修改数据

export function supplierById(data) {
  return request({
    url: '/supplier/supplier/supplier-by-id',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 供应商修改提交

export function supplierEdit(data) {
  return request({
    url: '/supplier/supplier/supplier-edit',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 供应商合作状态

export function supplierCooperate(data) {
  return request({
    url: '/supplier/supplier/cooperate-change',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 客户列表

export function servicerDataList(data) {
  return request({
    url: '/servicer/servicer/index',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 客户新增

export function servicerSave(data) {
  return request({
    url: '/servicer/servicer/servicer-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取客户修改数据

export function servicerById(data) {
  return request({
    url: '/servicer/servicer/servicer-by-id',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} //. 获取客户充值记录

export function servicerGetRecharge(data) {
  return request({
    url: '/servicer/servicer/recharge-log',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 客户修改提交

export function servicerEdit(data) {
  return request({
    url: '/servicer/servicer/servicer-edit',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 客户配置店铺提交

export function servicerStoreAdd(data) {
  return request({
    url: '/servicer/servicer/servicer-store-add',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 客户配置店铺回显

export function servicerServiceid(data) {
  return request({
    url: '/servicer/servicer/store-by-serviceid',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 客户配置店铺回显

export function servicerDelete(data) {
  return request({
    url: '/servicer/servicer/store-delete',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 客户充值金额

export function servicerBalance(data) {
  return request({
    url: '/servicer/servicer/recharge-balance',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流商列表

export function logisticsDataList(data) {
  return request({
    url: '/logistics/logistics/index',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流商新增

export function logisticsSave(data) {
  return request({
    url: '/logistics/logistics/logistics-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取物流商修改数据

export function logisticsById(data) {
  return request({
    url: '/logistics/logistics/logistics-by-id',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流商修改提交

export function logisticsEdit(data) {
  return request({
    url: '/logistics/logistics/logistics-edit',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流商状态

export function logisticsCooperate(data) {
  return request({
    url: '/logistics/logistics/cooperate-change',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 配置物流发货渠道

export function logisticsDelivery(data) {
  return request({
    url: '/logistics/logistics/delivery-channel',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 查看物流商发货渠道

export function logisticsGetChannel(data) {
  return request({
    url: '/logistics/logistics/get-delivery-channel',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流商配置发货渠道删除

export function logisticsDeleteChannel(data) {
  return request({
    url: '/logistics/logistics/delete-delivery-channel',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流商配置店铺

export function logisticsGetStoreList(data) {
  return request({
    url: '/logistics/logistics/get-store-list',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流商配置国家删除

export function logisticsDeleteCountryfee(data) {
  return request({
    url: '/logistics/logistics/delete-countryfee',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 供应商导出

export function supplierExport(data) {
  return request({
    url: '/supplier/supplier/export-search',
    method: 'post',
    data: data,
    responseType: 'blob',
    baseURL: baseURL
  });
} // 客户导出

export function servicerExport(data) {
  return request({
    url: '/servicer/servicer/export-service',
    method: 'post',
    data: data,
    responseType: 'blob',
    baseURL: baseURL
  });
} // 物流商导出

export function logisticsExport(data) {
  return request({
    url: '/logistics/logistics/export-logistics',
    method: 'post',
    data: data,
    responseType: 'blob',
    baseURL: baseURL
  });
} // 店铺列表

export function storeDataList(data) {
  return request({
    url: '/store/store/index',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 店铺新增

export function storeSave(data) {
  return request({
    url: '/store/store/store-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 店铺修改

export function storeEdit(data) {
  return request({
    url: '/store/store/store-edit',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 店铺删除

export function storedelete(data) {
  return request({
    url: '/store/store/del-store',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流渠道信息

export function logisticsChannelInfo(data) {
  return request({
    url: '/logistics/logistics/get-logistics-info',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取可以关联的店铺列表

export function storeAssociation(data) {
  return request({
    url: '/store/store/not-association-store',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function storeAssociationAdd(data) {
  return request({
    url: '/store/store/association-store',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取物流渠道

export function logisticsChannel(data) {
  return request({
    url: '/logistics/logistics/get-channel ',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流渠道获取国家

export function channelCountryInfo(data) {
  return request({
    url: '/logistics/logistics/get-country-info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流渠道获取国家

export function getWeightByCountry(data) {
  return request({
    url: '/logistics/logistics/get-weight',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 根据国家获取价格

export function getCalculatePriceByCountry(data) {
  return request({
    url: '/logistics/logistics/calculate-price',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 托管池

export function getGoodsDeposite(data) {
  return request({
    url: '/servicer/servicer/goods-deposite',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流平台查询

export function platformList(data) {
  return request({
    url: '/logistics/logistics/platform-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 物流平台新增

export function logisticsPlatformSave(data) {
  return request({
    url: '/logistics/logistics/save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 物流平台新增

export function logisticsPlatformstatus(data) {
  return request({
    url: '/logistics/logistics/update-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取平台
// export function logisticsPlatform(data) {
//   return request({
//     url: '/logistics/logistics/platform',
//     method: 'get',
//     params: data,
//     baseURL
//   })
// }
// 配置渠道启用

export function logisticsChangeStatus(data) {
  return request({
    url: '/logistics/logistics/change-status',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 配置渠道备注

export function logisticsRemark(data) {
  return request({
    url: '/logistics/logistics/remark',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 修改物流信息

export function logisticsAddConfig(data) {
  return request({
    url: '/logistics/logistics/add-config-logistics ',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 修改物流信息回显

export function logisticsEditConfig(data) {
  return request({
    url: '/logistics/logistics/edit-config-logistics',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改物流信息合作状态

export function logisticsCooperateStatu(data) {
  return request({
    url: '/logistics/logistics/change-cooperate-status',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改渠道类型

export function logisticsChangeType(data) {
  return request({
    url: '/logistics/logistics/change-channel-type',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 已激活渠道价格

export function logisticsInfo(data) {
  return request({
    url: '/logistics/logistics/check-logistics-info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 规则列表

export function rulesList(data) {
  return request({
    url: '/logistics/logistics/rules-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 规则渠道

export function rulesChann(data) {
  return request({
    url: '/logistics/logistics/chann',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 保存规则列表

export function rulesSave(data) {
  return request({
    url: '/logistics/logistics/save-rules',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 保存规则列表

export function ruleEdit(data) {
  return request({
    url: '/logistics/logistics/edit-rule',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 删除规则列表

export function ruleDelete(data) {
  return request({
    url: '/logistics/logistics/dele-rule',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 店铺规则列表

export function storeConfigRule(data) {
  return request({
    url: '/logistics/logistics/store-config-rule',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 规则列表

export function ruleOption(data) {
  return request({
    url: '/logistics/logistics/option',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 店铺规则保存

export function ruleConfigSave(data) {
  return request({
    url: '/logistics/logistics/rule-config-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 店铺规则保存

export function ruleConfigEdit(data) {
  return request({
    url: '/logistics/logistics/edit',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 店铺规则保存

export function ruleChannelTitle(data) {
  return request({
    url: '/logistics/logistics/get-platform-channel-title',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 店铺状态改变

export function storeType(data) {
  return request({
    url: '/logistics/logistics/store-type-edit',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} //物流规则列表

export function shippingrulerist(data) {
  return request({
    url: '/quoted/quoted/shipping-rule-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function getuser(data) {
  return request({
    url: '/servicer/servicer/get-user',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}