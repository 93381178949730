import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getAllRulesCheck, roleUpdate, permissionSave, baseCommonGetCheck } from '@/api/admin';
import { login } from '@/api/user';
export default {
  name: 'btn-tree',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      menuTree: [],
      openNode: [],
      saveLoading: false,
      defaultProps: {
        children: 'next_menu',
        label: 'name'
      }
    };
  },
  computed: {},
  watch: {
    dialogVisible: function dialogVisible(val, oldVal) {
      var _this = this;

      // 打开  关闭
      if (val) {
        roleUpdate({
          id: this.id
        }).then(function (res) {
          _this.$refs.tree.setCheckedKeys(res.data.role.split(','));
        });
      } else {
        this.$refs.tree.setCheckedKeys([]);
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {// this.getData()
  },
  methods: {
    getData: function getData() {
      var _this2 = this;

      getAllRulesCheck().then(function (res) {
        _this2.menuTree = res.data;

        _this2.$set(_this2, 'openNode', [String(res.data[0].menu_id)]);
      });
    },
    close: function close(type) {
      var _this3 = this;

      if (type != 1) return this.$emit('close');
      var role = this.$refs.tree.getCheckedKeys();
      var params = {
        role: role.join(','),
        id: this.id,
        type: 2,
        c_type: 1
      };
      this.saveLoading = true;
      permissionSave(params).then(function (res) {
        _this3.$emit('close', 1);

        _this3.saveLoading = false;

        _this3.$message({
          type: 'success',
          message: res.message
        });

        baseCommonGetCheck().then(function (res) {
          sessionStorage.setItem('commonCheck', JSON.stringify(res.data));
        });
      });
    },
    renderContent: function renderContent(h, _ref) {
      var node = _ref.node,
          data = _ref.data,
          store = _ref.store;

      if (data.status == 0) {
        return h("span", {
          "style": 'flex: 1; display: flex; align-items: center; justify-content: space-between; font-size: 14px; padding-right: 8px;'
        }, [h("span", [h("span", [node.label])]), h("span", [h("el-tag", {
          "attrs": {
            "type": 'warning'
          }
        }, ["\u7981\u7528"])])]);
      } else if (data.status == 1) {
        return h("span", {
          "style": 'flex: 1; display: flex; align-items: center; justify-content: space-between; font-size: 14px; padding-right: 8px;'
        }, [h("span", [h("span", [node.label])]), h("span", [h("el-tag", {
          "attrs": {
            "type": 'success'
          }
        }, ["\u6B63\u5E38"])])]);
      }
    }
  }
};