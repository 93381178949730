import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getdetaillist as _getdetaillist, taskstock, getwarehoust_list as _getwarehoust_list } from "@/api/system";
export default {
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    }
  },
  data: function data() {
    return {
      loading: false,
      warehoustlist: [],
      formLabelWidth: "120px",
      formpandian: {
        type: "pandian",
        current_qty: "",
        inventory_id: ""
      },
      formruku: {
        type: "pandian",
        current_qty: "",
        inventory_id: "",
        mark: ""
      },
      formchuku: {
        type: "pandian",
        current_qty: "",
        inventory_id: "",
        mark: ""
      },
      dialogpandian: false,
      dialogruku: false,
      dialogchuku: false,
      form: {
        sku_code: "",
        sku_name: "",
        inventory_id: "",
        store_url: "",
        oper_user: "",
        warehouse_name: "",
        product_title: "",
        oper: "",
        create_time: "",
        ven_product_name: ''
      },
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: []
    };
  },
  mounted: function mounted() {
    this.getdetaillist();
    this.getwarehoust_list();
  },
  methods: {
    getwarehoust_list: function getwarehoust_list() {
      var _this = this;

      _getwarehoust_list(this.listQuery).then(function (res) {
        console.log(res);
        _this.warehoustlist = res.data.data; // this.loading=false
      });
    },
    // 库存列表接口
    // getdetaillist() {
    //   this.loading = true;
    //   getdetaillist(this.listQuery).then((res) => {
    //     this.tableData = res.data;
    //     this.loading = false;
    //   });
    // },
    // 查询
    getdetaillist: function getdetaillist() {
      var _this2 = this;

      this.loading = true;
      var params = this.form;
      params.iDisplayLength = this.listQuery.limit, params.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;

      _getdetaillist(params).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            item.status = item.status === '1' ? '进行中' : '已完成';
          });
          _this2.loading = false;
          _this2.listQuery.total = parseInt(res.total);
          _this2.tableData = res.data;
        }
      });
    },
    // 操作
    editok: function editok(i) {
      var _this3 = this;

      if (i == "pandian") {
        this.dialogpandian = false;
        taskstock(this.formpandian).then(function (res) {
          _this3.getdetaillist();
        });
      } else if (i == "ruku") {
        this.dialogruku = false;
        taskstock(this.formruku).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success("操作成功");
          } else {
            _this3.$message(res.message);
          }

          _this3.getdetaillist();
        });
      } else if (i == "chuku") {
        this.dialogchuku = false;
        taskstock(this.formchuku).then(function (res) {
          _this3.getdetaillist();
        });
      } // this.dialogpandian=false

    },
    edit: function edit(i, row) {
      if (i == "pandian") {
        this.dialogpandian = true;
        this.formpandian.inventory_id = row.inventory_id;
      } else if (i == "ruku") {
        this.dialogruku = true;
        this.formruku.inventory_id = row.inventory_id;
      } else if (i == "chuku") {
        this.dialogchuku = true;
        this.formchuku.inventory_id = row.inventory_id;
      }
    },
    onSubmit: function onSubmit() {
      console.log("---", this.form);
    },
    look: function look() {
      this.getdetaillist();
    },
    reset: function reset() {
      this.form = {
        inventory_id: "",
        sku_code: "",
        skuname: "",
        create_time: "",
        store_url: "",
        oper_user: "",
        warehouse_name: "",
        ven_product_name: ""
      };
      this.getdetaillist();
    }
  }
};