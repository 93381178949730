var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderdDetails" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            { staticClass: "orderTitle" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", icon: "el-icon-arrow-left" },
                  on: { click: _vm.prev }
                },
                [_vm._v("返回")]
              ),
              _c("span", { staticClass: "title" }, [
                _vm._v("[ " + _vm._s(_vm.$route.query.service_name) + " ]")
              ]),
              _c("span", { staticClass: "orderId" }, [
                _vm._v(_vm._s(_vm.$route.query.order_no))
              ]),
              _vm.canRecover
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.recoverOperation()
                        }
                      }
                    },
                    [_vm._v("复原订单")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.orderForm,
                inline: "",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-card",
                { staticClass: "box-card mt5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix step-jump",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("基础信息")])]
                  ),
                  _vm._l(_vm.labelBasis, function(val, idx) {
                    return _c(
                      "el-form-item",
                      {
                        key: idx,
                        attrs: { label: val.label, prop: val.value }
                      },
                      [
                        _c("div", { staticClass: "w-300" }, [
                          _vm._v(_vm._s(_vm.orderForm[val.value]))
                        ])
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "el-card",
                { staticClass: "box-card mt5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix step-jump",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("快递信息")])]
                  ),
                  _vm._l(_vm.labelExpress, function(val, idx) {
                    return _c(
                      "el-form-item",
                      {
                        key: idx,
                        attrs: { label: val.label, prop: val.value }
                      },
                      [
                        _c("div", { staticClass: "w-300" }, [
                          _vm._v(_vm._s(_vm.orderForm[val.value]))
                        ])
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "el-card",
                { staticClass: "box-card mt5" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix step-jump",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("商品售卖信息")])]
                  ),
                  _vm._l(_vm.labelCommodity, function(val, idx) {
                    return _c(
                      "el-form-item",
                      {
                        key: idx,
                        attrs: { label: val.label, prop: val.value }
                      },
                      [
                        _c("div", { staticClass: "w-300" }, [
                          _vm._v(_vm._s(_vm.orderForm[val.value]))
                        ])
                      ]
                    )
                  }),
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.orderForm.goods_info,
                        border: "",
                        "header-cell-style": {
                          background: "#e9f1ff",
                          color: "#606266"
                        }
                      }
                    },
                    _vm._l(_vm.labelShop, function(item, key) {
                      return _c("el-table-column", {
                        key: key,
                        attrs: { label: item.label, prop: item.value },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  item.type === undefined
                                    ? _c(
                                        "span",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              scope.row[item.value]
                                            )
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row[item.value]))]
                                      )
                                    : _vm._e(),
                                  item.type === "order_goods"
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "c_blue cursor_p",
                                          on: {
                                            click: function($event) {
                                              return _vm.order_goods(
                                                scope.row.goods_id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.goods_id))]
                                      )
                                    : _vm._e(),
                                  item.type === "goods_type"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.goods_type === 1
                                              ? "正常"
                                              : "退款"
                                          )
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }