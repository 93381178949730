import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Sortable from 'sortablejs';
import { rulesChann, rulesSave } from '@/api/information';
import { getChanCountry } from '@/api/commodity';
export default {
  name: 'rules-config',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '新增规则'
    },
    flag: {
      type: String,
      default: 'create'
    },
    formData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      showVisible: false,
      form: {
        rule_name: '',
        usd_exchange: '',
        rate: '',
        info: []
      },
      rules: {
        rule_name: [{
          required: true,
          message: '规则名称不能为空',
          trigger: 'bulr'
        }],
        info: [{
          required: true,
          message: '请选择物流及渠道',
          trigger: 'bulr'
        }]
      },
      countryies: [],
      channelList: [],
      selCountry: [],
      countryDialog: false,
      popVisible: false,
      ruleIndex: '',
      props: {
        multiple: true,
        label: 'title',
        value: 'id'
      },
      selChannel: [],
      checkedKeys: [],
      expandedKeys: [],
      submitLoading: false,
      selectTabData: [],
      selCountryTable: []
    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  watch: {
    formData: {
      handler: function handler(data) {
        this.initData(data);
      },
      deep: true
    },
    flag: {
      handler: function handler(val) {
        if (val === 'create') {
          this.form = this.$options.data().form;
          this.checkedKeys = [];
          this.expandedKeys = [];
        }
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.sortable1) {
      this.sortable1.destroy();
    }
  },
  created: function created() {},
  methods: {
    handleOpen: function handleOpen() {
      this.rowDrop();
      this.fetchChann();
    },
    rowDrop: function rowDrop() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.countryForm.clearValidate();

        var xTable = _this.$refs.xTable;
        _this.sortable1 = Sortable.create(xTable.$el.querySelector('.el-table__body-wrapper tbody'), {
          animation: 150,
          // handle: '.drag-btn'
          onEnd: function onEnd(_ref) {
            var newIndex = _ref.newIndex,
                oldIndex = _ref.oldIndex;
            var data = _this.form.info;
            data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]); // 数据处理

            var sortList = data.map(function (item, index) {
              item.sort = 100 - index;
              return item;
            });

            _this.$nextTick(function () {
              _this.$set(_this.form, 'info', sortList);
            });
          }
        });
      });
    },
    initData: function initData(data) {
      this.checkedKeys = this.formData.info.map(function (i) {
        return i.channel_name;
      });
      this.expandedKeys = Array.from(new Set(this.formData.info.map(function (i) {
        return i.logistics_name;
      })));
      data.info.forEach(function (item) {
        if (item.country) {
          item.countryList = item.country.split(',');
        }
      });
      this.form = JSON.parse(JSON.stringify(data));
    },
    fetchChann: function fetchChann() {
      var _this2 = this;

      rulesChann().then(function (res) {
        if (res.code === 200) {
          _this2.channelList = res.data.map(function (item) {
            var obj = {};
            obj.title = item.platform_title;
            obj.id = item.id;

            if (item.info) {
              obj.children = item.info.map(function (i) {
                return {
                  title: i.channel_name,
                  country: i.country,
                  id: i.id,
                  logistics_id: item.id,
                  logistics_name: item.platform_title
                };
              });
            }

            return obj;
          });
          console.log('channelList', _this2.channelList);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleCheckChange: function handleCheckChange(data, checked) {
      // console.log(data, checked)
      // console.log(checked.checkedNodes)
      var checkArr = checked.checkedNodes;
      console.log('checkArr', checkArr);
      checkArr.forEach(function (item, index) {
        if (Object.hasOwnProperty.call(item, 'children')) {
          checkArr.splice(index, 1);
        }
      });
      this.selChannel = checkArr;
    },
    fillingUSD: function fillingUSD(val) {
      this.form.info.forEach(function (i) {
        i.usd_exchange = val;
      });
    },
    fillingRate: function fillingRate(val) {
      this.form.info.forEach(function (i) {
        i.rate = val;
      });
    },
    confirmChannel: function confirmChannel() {
      var _this3 = this;

      // console.log(this.selChannel)
      this.form.info = this.selChannel.map(function (item, index) {
        return {
          channel_id: item.id,
          channel_name: item.title,
          country: item.country.toString(),
          countryList: item.country.slice(0),
          logistics_id: item.logistics_id,
          logistics_name: item.logistics_name,
          sort: 100 - index,
          usd_exchange: _this3.form.usd_exchange,
          rate: _this3.form.rate
        };
      });
      console.log(this.form.info);
      this.popVisible = false;
    },
    deleteChannel: function deleteChannel(index) {
      this.form.info.splice(index, 1);
    },
    countryConfig: function countryConfig(row, index) {
      var _this4 = this;

      this.ruleIndex = index;
      getChanCountry({
        id: row.channel_id
      }).then(function (res) {
        if (res.code === 200) {
          _this4.selCountryTable = res.data;
        }
      });
      this.countryDialog = true;
    },
    handleClose: function handleClose() {
      this.selCountryTable = this.$options.data().selCountryTable;
      this.countryDialog = false;
    },
    submitCountry: function submitCountry() {
      var obj = {};
      this.selectTabData.map(function (it) {
        obj[it.selCountry] = it.heaven;
      }); // const country = this.selCountry.toString()
      // this.form.info[this.ruleIndex].country = country

      this.form.info[this.ruleIndex].heaven = obj;
      this.handleClose();
    },
    saveCountry: function saveCountry(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.form.info.forEach(function (i) {
            if (i.country) {
              _this5.hasCountry = false;
            } else {
              _this5.hasCountry = true;
            }
          });

          if (!_this5.hasCountry) {
            _this5.submitLoading = true;
            rulesSave(_this5.form).then(function (res) {
              if (res.code === 200) {
                _this5.submitLoading = false;
                _this5.dialogVisible = false;

                _this5.$emit('close');

                _this5.$message.success('保存成功');
              }
            }).catch(function (err) {
              _this5.submitLoading = false;
              console.log(err);
            });
          } else {
            _this5.$message({
              showClose: true,
              message: '渠道中有包含国家为空，请确认后再保存',
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 选择框
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectTabData = val;
    }
  }
};