var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c(
      "div",
      { staticClass: "card-top" },
      [
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h3", [_vm._v("总订单")]),
            _c("p", [_vm._v(_vm._s(_vm.dashboardForm.all_orders))])
          ])
        ]),
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h3", [_vm._v("今日订单")]),
            _c("p", [_vm._v(_vm._s(_vm.dashboardForm.today_orders))])
          ])
        ]),
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h3", [_vm._v("今日成交额")]),
            _c("p", [_vm._v(_vm._s(_vm.dashboardForm.today_turnover))])
          ])
        ]),
        _c("el-card", { staticClass: "box-card" }, [
          _c("div", { staticClass: "top-box" }, [
            _c("h3", [
              _vm._v("物流信息 "),
              _c("span", [_vm._v("待发货")]),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
              _c("span", [_vm._v("待收货")]),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
              _c("span", [_vm._v("已收货")])
            ]),
            _c("p", [
              _c("span", { staticClass: "deliver" }, [
                _vm._v(_vm._s(_vm.dashboardForm.all_wait_for_send))
              ]),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
              _c("span", { staticClass: "receive" }, [
                _vm._v(_vm._s(_vm.dashboardForm.all_wait_for_receiving))
              ]),
              _c("span", { staticClass: "line" }, [_vm._v("|")]),
              _c("span", { staticClass: "received" }, [
                _vm._v(_vm._s(_vm.dashboardForm.all_delivered))
              ])
            ])
          ])
        ])
      ],
      1
    ),
    _c("div", { staticClass: "box-main" }, [
      _c(
        "div",
        { staticClass: "main-left" },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "title-box" }, [
              _c("h3", [
                _c("span", { staticClass: "radius-shadow" }, [
                  _c("span", { staticClass: "radius" })
                ]),
                _vm._v(" 销量趋势 ")
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "400px" },
                attrs: { id: "myChart" }
              })
            ])
          ]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c("div", { staticClass: "title-box" }, [
                  _c("h3", [
                    _c("span", { staticClass: "radius-shadow" }, [
                      _c("span", { staticClass: "radius" })
                    ]),
                    _vm._v(" 售后信息 ")
                  ]),
                  _c("div", { staticClass: "after-sale" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "h4",
                          {
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "after-index",
                                  query: { status: _vm.pending.add.status }
                                })
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "mr10" }, [
                              _vm._v("待处理:")
                            ]),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.pending.add.total)
                              }
                            }),
                            _c("i", { staticClass: "el-icon-arrow-right" })
                          ]
                        ),
                        _vm._l(_vm.pending.add.afterOrder, function(
                          item,
                          index
                        ) {
                          return _c(
                            "p",
                            {
                              key: index,
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "after-sale-checked",
                                    query: {
                                      id: item.id,
                                      order_name: item.order_name,
                                      service_name: item.service_name
                                    }
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(index + 1 + ":"))]),
                              _c(
                                "span",
                                { staticStyle: { color: "#409EFF" } },
                                [_vm._v(_vm._s(item.order_no))]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "h4",
                          {
                            on: {
                              click: function($event) {
                                return _vm.$router.push({
                                  name: "after-index",
                                  query: {
                                    status: _vm.pending.processing.status
                                  }
                                })
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "mr10" }, [
                              _vm._v("处理中:")
                            ]),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.pending.processing.total)
                              }
                            }),
                            _c("i", { staticClass: "el-icon-arrow-right" })
                          ]
                        ),
                        _vm._l(_vm.pending.processing.afterOrder, function(
                          item,
                          index
                        ) {
                          return _c(
                            "p",
                            {
                              key: index,
                              on: {
                                click: function($event) {
                                  return _vm.$router.push({
                                    name: "after-sale-checked",
                                    query: {
                                      id: item.id,
                                      order_name: item.order_name,
                                      service_name: item.service_name
                                    }
                                  })
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(index + 1 + ":"))]),
                              _c(
                                "span",
                                { staticStyle: { color: "#409EFF" } },
                                [_vm._v(_vm._s(item.order_no))]
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ])
              ]),
              _c("el-card", { staticClass: "box-card" }, [
                _c("div", { staticClass: "title-box" }, [
                  _c("h3", [
                    _c("span", { staticClass: "radius-shadow" }, [
                      _c("span", { staticClass: "radius" })
                    ]),
                    _vm._v(" 邀请码 ")
                  ]),
                  _c("p", { staticClass: "invitation-code" }, [
                    _vm._v(_vm._s(_vm.dashboardForm.invitation_code))
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("el-card", { staticClass: "box-card" }, [
            _c(
              "div",
              { staticClass: "title-box" },
              [
                _c(
                  "h3",
                  { staticClass: "title" },
                  [
                    _c("span", [
                      _c("span", { staticClass: "radius-shadow" }, [
                        _c("span", { staticClass: "radius" })
                      ]),
                      _vm._v(" 未关联异常托管产品 ")
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "more",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "hosting",
                              query: { status: "dashboard" }
                            })
                          }
                        }
                      },
                      [
                        _vm._v("更多"),
                        _c("i", { staticClass: "el-icon-arrow-right" })
                      ]
                    )
                  ],
                  1
                ),
                _vm._l(_vm.dashboardForm.exception_product, function(item) {
                  return _c("div", { staticClass: "abnormal" }, [
                    _c("img", {
                      attrs: {
                        src: item.img_url,
                        width: "100px",
                        height: "100px"
                      }
                    }),
                    _c(
                      "div",
                      [
                        _c("h3", [_vm._v(_vm._s(item.customer_name))]),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: item.product_title,
                              placement: "top"
                            }
                          },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm._f("ellipsis")(item.product_title))
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                })
              ],
              2
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }