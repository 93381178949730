import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getSystemRoleList, roleUpdateStatus, getRoleOption } from '@/api/admin';
export default {
  name: 'charactar',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    Tree: function Tree() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./components/tree'));
      });
    },
    BtnTree: function BtnTree() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./components/btnTree'));
      });
    },
    DataAuthority: function DataAuthority() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./components/dataAuthority'));
      });
    },
    Assignment: function Assignment() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./components/assignment'));
      });
    },
    MdfCharactar: function MdfCharactar() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./components/mdfCharactar'));
      });
    }
  },
  data: function data() {
    return {
      formData: {
        role_name: '',
        is_open: ''
      },
      roleData: {},
      roleType: '',
      rowId: '',
      isOpenOptions: [],
      tableData: [],
      tableDataLoading: false,
      multipleSelection: [],
      mdfVisible: false,
      authorityVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      treeVisible: false,
      btnTreeVisible: false,
      assignmentVisible: false
    };
  },
  created: function created() {
    var _this = this;

    this.getData();
    getRoleOption().then(function (res) {
      _this.isOpenOptions = res.data.is_open;
    });
  },
  methods: {
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.getData(1);
    },
    getData: function getData(type) {
      var _this2 = this;

      if (type == 1) this.listQuery.page = 1;
      this.formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      this.formData.iDisplayLength = this.listQuery.limit;
      this.tableDataLoading = true;
      getSystemRoleList(this.formData).then(function (res) {
        if (res.code == 200) {
          setTimeout(function () {
            _this2.tableData = res.data;
            _this2.tableDataLoading = false;
            _this2.listQuery.total = Number(res.total);
          }, 300);
        }
      });
    },
    // 新增角色
    addRole: function addRole() {
      this.roleType = 'add';
      this.mdfVisible = true;
    },
    // 修改角色
    mdfRole: function mdfRole(scope) {
      this.roleType = 'mdf';
      this.roleData = scope.row;
      this.mdfVisible = true;
    },
    // 关闭角色弹窗
    closeRole: function closeRole(type) {
      this.mdfVisible = false;
      if (type == 1) this.getData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    mdf: function mdf(item) {
      console.log(item);
      this.mdfVisible = true;
    },
    save: function save(val) {
      console.log(val);
      this.mdfVisible = false;
    },
    // 修改角色状态
    updateStatus: function updateStatus(title, type) {
      var _this3 = this;

      if (this.multipleSelection.length == 0) return this.$message({
        type: 'warning',
        message: '请先选择角色!'
      });
      this.$confirm('您确定' + title + this.multipleSelection.length + '个角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params = {
          id: _this3.multipleSelection.map(function (item) {
            return item.id;
          }).join(','),
          is_open: type
        };
        roleUpdateStatus(params).then(function (res) {
          _this3.getData();

          _this3.$message({
            type: 'success',
            message: '成功!'
          });
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 删除角色
    delUser: function delUser(scope) {
      var _this4 = this;

      this.$confirm('您确定删除角色吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var params = {
          id: scope.row.id,
          is_open: 3
        };
        roleUpdateStatus(params).then(function (res) {
          _this4.getData();

          _this4.$message({
            type: 'success',
            message: '成功!'
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 打开  角色菜单管理
    openTree: function openTree(scope) {
      this.rowId = scope.row.id;
      this.treeVisible = true;
    },
    // 关闭  角色菜单管理
    closeTree: function closeTree(type) {
      this.treeVisible = false;
      if (type == 1) this.getData();
    },
    // 打开  角色单点权限管理
    openBtnTree: function openBtnTree(scope) {
      this.rowId = scope.row.id;
      this.btnTreeVisible = true;
    },
    // 关闭  角色单点权限管理
    closeBtnTree: function closeBtnTree(type) {
      this.btnTreeVisible = false;
      if (type == 1) this.getData();
    },
    // 打开 分配人员
    openAssignment: function openAssignment(scope) {
      this.rowId = scope.row.id;
      this.assignmentVisible = true;
    },
    // 关闭 分配人员
    closeAssignment: function closeAssignment(type) {
      this.assignmentVisible = false;
      if (type == 1) this.getData();
    },
    // 打开  数据权限
    openAuthority: function openAuthority(scope) {
      this.rowId = scope.row.id;
      this.authorityVisible = true;
    },
    // 关闭  数据权限
    closeAuthority: function closeAuthority(type) {
      this.authorityVisible = false;
    },
    // 重置
    resetClick: function resetClick() {
      this.formData = this.$options.data().formData;
      this.getData();
    }
  }
};