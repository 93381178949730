var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流进度",
            visible: _vm.visible,
            width: "700px",
            "before-close": _vm.logisticsText,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _vm.dataList
            ? _c(
                "el-timeline",
                _vm._l(_vm.dataList, function(item, key) {
                  return _c(
                    "el-timeline-item",
                    {
                      key: key,
                      attrs: {
                        color: "#0bbd87",
                        timestamp: item.ProcessDate,
                        placement: "top"
                      }
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(item.TrackingStatus))]),
                      _c("p", [_vm._v(_vm._s(item.ProcessLocation))]),
                      _c("p", [_vm._v(_vm._s(item.ProcessContent))])
                    ]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }