import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { logisticsDataList, logisticsCooperateStatu } from '@/api/information';
export default {
  name: 'logistics',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    logisticsAdd: function logisticsAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/logisticsAdd'));
      });
    },
    DialogImport: function DialogImport() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/dialogImport'));
      });
    },
    packageSearch: function packageSearch() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/packageSearch/index'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '物流商名称',
        value: 'logistics_name',
        type: 'logistics_name'
      }, {
        label: '英文代码',
        value: 'english_title'
      }, {
        label: '启用发货渠道',
        value: 'channel',
        type: 'channel'
      }, {
        label: '价格更新时间',
        value: 'update_at'
      }, {
        label: '当前状态',
        value: 'status_name'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      loading: false,
      logisticsVisible: false,
      shippVisible: false,
      downloadLoading: false,
      logisticsTitle: '',
      logisticsId: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        logistics_name: ''
      },
      importVisible: false,
      importProps: {
        type: 'logistics',
        url: '/logistics/logistics/uploadexcel' // name: 'importOrder.xlsx'

      },
      Updatetitle: '',
      UpdateId: '',
      shippId: ''
    };
  },
  computed: {
    // 展开过滤列表
    filterList: function filterList() {
      return [{
        label: '物流商名称',
        inputType: 'input',
        name: 'logistics_name'
      }];
    }
  },
  created: function created() {
    this.Inquire();
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === 'channel-conifg') {
      next(function (vm) {
        vm.Inquire();
      });
    }

    next();
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      logisticsDataList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            res.data.map(function (item) {
              item.status = item.status === '1' ? '启用' : '未启用';
            });
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total);
            _this.loading = false;
          }, 300);
        }
      });
    },
    // 打开上传弹窗更新价格
    Updateprice: function Updateprice(row) {
      this.importVisible = true;
      this.Updatetitle = row.logistics_name;
      this.UpdateId = row.id;
    },
    // 关闭上传弹窗更新价格
    closeImport: function closeImport(type) {
      this.importVisible = false;

      if (type === 1) {
        this.Inquire();
      }
    },
    // 新增物流商
    logisticsClick: function logisticsClick(row) {
      this.logisticsVisible = true;
      this.logisticsTitle = row.logistics_name;
      this.logisticsId = row.id;
    },
    closelogistics: function closelogistics(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.logisticsVisible = false;
    },
    // 配置发货渠道
    shipping: function shipping(row) {
      this.$router.push({
        name: 'channel-conifg',
        params: {
          id: row.id
        },
        query: {
          logistics_name: row.logistics_name,
          platform_id: row.id
        }
      });
    },
    // 合作
    operation: function operation(item, type) {
      var _this2 = this;

      var typeTitle;
      var typeHead;

      if (type === '2') {
        typeTitle = '确定是否和' + item.logistics_name + '停止合作，停止后该供应商下的所有商品&SKU将无法正常下单！';
        typeHead = '停用合作';
      } else {
        typeTitle = '确定是否和' + item.logistics_name + '重新合作，重新合作该供应商下的所有商品&SKU将可以正常下单！';
        typeHead = '启用';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        logisticsCooperateStatu({
          id: item.id,
          status: type
        }).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.message);

            _this2.Inquire();
          }
        });
      }).catch(function () {
        _this2.$message.info('已取消设置');
      });
    },
    toLink: function toLink(row) {
      this.$router.push({
        name: 'channel-price',
        params: {
          id: row.id
        },
        query: {
          logistics_name: row.logistics_name,
          id: row.id
        }
      });
    },
    // 展开搜索
    handleFilter: function handleFilter(val) {
      var _this3 = this;

      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this3.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this3.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    },
    // 重置
    resetFilter: function resetFilter() {
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 展开后二次搜索
    handleSecFilter: function handleSecFilter(val) {
      var _this4 = this;

      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this4.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this4.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    }
  }
};