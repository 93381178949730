//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { companySave } from '@/api/account';
export default {
  name: 'company-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      companyForm: {
        company_name: '',
        docking_people: ''
      },
      rules: {
        company_name: [{
          required: true,
          message: '请填写公司名称',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      if (val) {} else {
        this.$refs.companyForm.resetFields();
      }
    }
  },
  methods: {
    companyAdd: function companyAdd(type) {
      var _this = this;

      if (type == 1) {
        this.$refs.companyForm.validate(function (valid) {
          if (valid) {
            companySave(_this.companyForm).then(function (res) {
              if (res.code == 200) {
                _this.$message.success(res.message);

                _this.$emit('companyAdd');
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('companyAdd', 1);
      }
    }
  }
};