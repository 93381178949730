import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.values.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { companyList, companyOpertation } from '@/api/account';
export default {
  name: 'company',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    companyAdd: function companyAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/companyAdd'));
      });
    },
    adminAdd: function adminAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/adminAdd'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '公司id',
        value: 'id'
      }, {
        label: '公司名称',
        value: 'company_name'
      }, {
        label: '注册时间',
        value: 'create_time'
      }, {
        label: '状态',
        value: 'status'
      }, {
        label: '分配管理员',
        value: 'admin_all'
      }, {
        label: '对接人',
        value: 'docking_people'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      loading: false,
      companyVisible: false,
      adminVisible: false,
      adminList: {},
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {},
      create_start: [],
      selectList: {
        typeList: ['未激活', '已激活']
      }
    };
  },
  mounted: function mounted() {
    this.Inquire();
  },
  methods: {
    // 搜索
    search: function search() {
      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      this.Inquire();
    },
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;

      if (this.create_start) {
        formData.create_time_start = this.create_start[0] || '';
        formData.create_time_end = this.create_start[1] || '';
      } else {
        formData.create_time_start = '';
        formData.create_time_end = '';
      }

      companyList(formData).then(function (res) {
        if (res.code == 200) {
          setTimeout(function () {
            res.data.map(function (item) {
              if (item.admin_all != null) {
                item.admin_id = Object.keys(item.admin_all);
                item.admin_all = Object.values(item.admin_all).join(',');
              }
            });
            _this.tableData = res.data;
            _this.loading = false;
            _this.listQuery.total = parseInt(res.iTotalRecords);
          }, 300);
        }
      });
    },
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_start = [];
      this.Inquire();
    },
    // 新增公司
    addClick: function addClick() {
      this.companyVisible = true;
    },
    closeCompany: function closeCompany(type) {
      if (type != 1) {
        this.Inquire();
      }

      this.companyVisible = false;
    },
    // 分配管理员
    adminAdd: function adminAdd(list) {
      this.adminVisible = true;
      this.adminList = list;
    },
    closeAdmin: function closeAdmin(type) {
      if (type != 1) {
        this.Inquire();
      }

      this.adminVisible = false;
    },
    operation: function operation(id, name, type) {
      var _this2 = this;

      var typeTitle;
      var typeHead;

      if (type == 'disable') {
        typeTitle = '确定是否停用该公司:' + name + ',停用后该公司下所有账户无法登录使用！';
        typeHead = '停用公司';
      } else {
        typeTitle = '确定是否激活该公司:' + name + ',激活后该公司下所有正常账户可以重新登录使用！';
        typeHead = '激活公司';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        companyOpertation({
          id: [id],
          type: type
        }).then(function (res) {
          if (res.code == 200) {
            _this2.$message.success(res.message);

            _this2.Inquire();
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消设置'
        });
      });
    }
  }
};