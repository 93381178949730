import "core-js/modules/es.function.name.js";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  user: function user(state) {
    return state.user.user;
  },
  options: function options(state) {
    return state.goods.options;
  },
  permission_routes: function permission_routes(state) {
    return state.user.routes;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  routesMap: function routesMap(state) {
    return state.user.routesMap;
  },
  ordesList: function ordesList(state) {
    return state.bill.ordesList;
  },
  orderData: function orderData(state) {
    return state.bill.orderData;
  },
  disableShopNameList: function disableShopNameList(state) {
    return state.bill.disableShopNameList;
  },
  delRoutes: function delRoutes(state) {
    return state.bill.delRoutes;
  },
  otherTags: function otherTags(state) {
    return state.bill.otherTags;
  }
};
export default getters;