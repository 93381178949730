import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var personal = [{
  path: '/personal',
  component: Layout,
  alwaysShow: true,
  name: 'personal',
  redirect: 'noRedirect',
  meta: {
    title: '个人中心',
    icon: 'el-icon-user-solid'
  },
  hidden: true,
  children: [{
    path: 'personal/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/personal-center/personal-center'));
      });
    },
    name: 'personal-index',
    meta: {
      title: '个人设置',
      keepAlive: true
    }
  }]
}];
export default personal;