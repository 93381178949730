import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var billRouter = [{
  path: '/bill',
  component: Layout,
  alwaysShow: true,
  name: 'bill',
  redirect: 'noRedirect',
  meta: {
    title: '账单管理',
    icon: 'el-icon-menu'
  },
  children: [{
    path: 'billIndex/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/billIndex/index'));
      });
    },
    name: 'bill-index',
    meta: {
      title: '账单管理',
      keepalive: true
    }
  }, {
    path: 'createBill/index/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/createBill/index'));
      });
    },
    name: 'create-bill',
    hidden: true,
    meta: {
      title: '创建账单'
    }
  }, {
    path: 'generateBill/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/generateBill/index'));
      });
    },
    name: 'generate-bill',
    hidden: true,
    meta: {
      title: '生成账单'
    }
  }, {
    path: 'billDetail/index/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/billDetail/index'));
      });
    },
    name: 'bill-detail',
    hidden: true,
    meta: {
      title: '账单详情'
    }
  }, {
    path: 'previewBill/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/previewBill/index'));
      });
    },
    name: 'preview-bill',
    hidden: true,
    meta: {
      title: '查看账单'
    }
  }]
}];
export default billRouter;