var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: "分配管理员",
            "before-close": _vm.adminAdd,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "formData",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "120px",
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户人员:", prop: "admin_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w-300",
                          attrs: {
                            filterable: "",
                            multiple: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.formData.admin_id,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "admin_id", $$v)
                            },
                            expression: "formData.admin_id"
                          }
                        },
                        _vm._l(_vm.userOptions, function(item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: {
                              label: item.username,
                              value: String(item.id)
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "small" },
                  on: { click: _vm.adminAdd }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.adminAdd(1)
                    }
                  }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }