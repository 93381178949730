var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loginContent" }, [
    _c("div", { staticClass: "loginImage" }, [
      _c(
        "div",
        { staticClass: "loginBox" },
        [
          _c("p", { staticClass: "loginTitle" }, [
            _vm._v("欢迎使用FbAli跨境平台系统")
          ]),
          _c(
            "el-card",
            { staticClass: "boxCard" },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "手机号登录", name: "1" } },
                    [
                      _c(
                        "div",
                        { staticClass: "boxForm" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "LoginForm",
                              staticClass: "LoginForm",
                              attrs: {
                                model: _vm.LoginForm,
                                rules: _vm.loginRules,
                                "status-icon": ""
                              }
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "phone" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "username",
                                      autocomplete: "off",
                                      "prefix-icon": "el-icon-mobile",
                                      clearable: "",
                                      placeholder: "请输入手机名"
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.LoginForm.phone,
                                      callback: function($$v) {
                                        _vm.$set(_vm.LoginForm, "phone", $$v)
                                      },
                                      expression: "LoginForm.phone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "password" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "password",
                                      autocomplete: "off",
                                      "prefix-icon": "el-icon-lock",
                                      clearable: "",
                                      "show-password": "",
                                      placeholder: "请输入密码"
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.LoginForm.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.LoginForm, "password", $$v)
                                      },
                                      expression: "LoginForm.password"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        loading: _vm.loading,
                                        type: "primary"
                                      },
                                      on: { click: _vm.handleLogin }
                                    },
                                    [_vm._v("登 录")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "p",
                {
                  staticClass: "prompt c_blue cursor_p",
                  on: { click: _vm.registered }
                },
                [_vm._v("注册用户")]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "record" }, [
      _c("p", { staticClass: "recordTitle" }, [
        _c(
          "a",
          { attrs: { href: "https://beian.miit.gov.cn", target: "_blank" } },
          [_vm._v("粤ICP备2020104876号-1")]
        ),
        _c("span", { staticStyle: { "margin-left": "10px" } }, [
          _vm._v("©2020深圳东克科技有限公司")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }