import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import RulesConfing from './components/rules-confing';
import { rulesList, ruleEdit, ruleDelete } from '@/api/information';
export default {
  name: 'channel-rules',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    packageSearch: function packageSearch() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/packageSearch/index'));
      });
    },
    RulesConfing: RulesConfing
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '规则名称',
        value: 'rule_name'
      }, // { label: '优先级顺序', value: 'mobile' },
      {
        label: '包含渠道',
        type: 'channel'
      }, {
        label: '包含国家',
        type: 'country'
      }, {
        label: '美元汇率',
        value: 'usd_exchange'
      }, {
        label: '利润率',
        value: 'rate'
      }, {
        label: '创建人',
        value: 'create_user'
      }, {
        label: '创建时间',
        value: 'create_at'
      }, {
        label: '更新时间',
        value: 'update_at'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      update_start: [],
      create_start: [],
      loading: false,
      dialogVisible: false,
      downloadLoading: false,
      logisticsTitle: '',
      logisticsId: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        rule_name: '',
        channel_name: '',
        create_user: ''
      },
      shippId: '',
      ruleTitle: '新增规则',
      ruleFlag: 'create',
      rulesData: {}
    };
  },
  computed: {
    // 展开过滤列表
    filterList: function filterList() {
      return [{
        label: '规则名称',
        inputType: 'input',
        name: 'rule_name'
      }, {
        label: '包含渠道',
        inputType: 'input',
        name: 'channel_name'
      }, {
        label: '创建人',
        inputType: 'input',
        name: 'create_user'
      }];
    }
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      rulesList(formData).then(function (res) {
        if (res.code === 200) {
          setTimeout(function () {
            res.data.map(function (item) {
              item.status = item.status === '1' ? '正常' : '暂停';
            });
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total.totalCount);
            _this.loading = false;
          }, 300);
        }
      });
    },
    // 新增规则
    rulesConfig: function rulesConfig() {
      this.ruleTitle = '新增规则';
      this.ruleFlag = 'create';
      this.dialogVisible = true;
    },
    closelogistics: function closelogistics(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.logisticsVisible = false;
    },
    // 配置发货渠道
    shipping: function shipping(id) {
      // this.shippId = id
      // this.shippVisible = true
      this.$router.push({
        name: 'channel-conifg',
        params: {
          id: id
        }
      });
    },
    closeShipp: function closeShipp(type) {
      if (type !== 1) {
        this.Inquire();
      }

      this.shippVisible = false;
    },
    // 操作
    operation: function operation(type, item) {
      var _this2 = this;

      if (type === 1) {
        this.ruleTitle = '编辑规则';
        this.ruleFlag = 'edit';
        ruleEdit({
          id: item.id
        }).then(function (res) {
          // console.log(res.data)
          if (res.code === 200) {
            _this2.rulesData = res.data;
          }
        }).catch(function (err) {
          console.log(err);
        });
        this.dialogVisible = true;
      } else {
        this.$confirm('您确定删除改规则吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          ruleDelete({
            id: [item.id]
          }).then(function (res) {
            console.log(res.data);

            if (res.code === 200) {
              _this2.$message.success('删除成功！');

              _this2.Inquire();
            }
          }).catch(function (err) {
            console.log(err);
          });
        }).catch(function () {});
      }
    },
    // 展开搜索
    handleFilter: function handleFilter(val) {
      var _this3 = this;

      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this3.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this3.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    },
    // 重置
    resetFilter: function resetFilter() {
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 展开后二次搜索
    handleSecFilter: function handleSecFilter(val) {
      var _this4 = this;

      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this4.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this4.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    }
  }
};