import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 公司列表

export function companyList(data) {
  return request({
    url: '/user/company/company-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 新增公司

export function companySave(data) {
  return request({
    url: '/user/company/save-data',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 公司状态

export function companyOpertation(data) {
  return request({
    url: '/user/company/opertation',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 账号

export function userGetList(data) {
  return request({
    url: '/user/dx-user/user-list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 审核账户

export function companyEheckAudits(data) {
  return request({
    url: '/base/common/check-audits',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取账号基础信息

export function baseCommonOption(data) {
  return request({
    url: '/base/common/option',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 账户列表状态修改

export function baseCommonOperating(data) {
  return request({
    url: '/base/common/operating-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 账户修改查询

export function baseCommonEdit(data) {
  return request({
    url: '/base/common/edit',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 分配管理员获取人员

export function companyEdit(data) {
  return request({
    url: '/user/company/edit',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 分配管理员保存

export function companyAdmin(data) {
  return request({
    url: '/user/company/assign-admin',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
}