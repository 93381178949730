var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "channel-conifg" },
    [
      _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("span", { staticClass: "card-title" }, [
                _vm._v(_vm._s("渠道配置 - " + _vm.$route.query.logistics_name))
              ]),
              _c(
                "el-button",
                {
                  staticClass: "f-r mr20",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.Updateprice }
                },
                [_vm._v("更新价格表")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "highlight-current-row": "",
                fit: "",
                border: "",
                stripe: "",
                "header-cell-style": { background: "#e9f1ff", color: "#606266" }
              }
            },
            _vm._l(_vm.labelList, function(item, idx) {
              return _c("el-table-column", {
                key: idx,
                attrs: {
                  label: item.label,
                  prop: item.value,
                  width: item.width
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          item.type == undefined
                            ? _c("span", [
                                _vm._v(_vm._s(scope.row[item.value]))
                              ])
                            : _vm._e(),
                          item.type == "operation"
                            ? _c(
                                "span",
                                [
                                  scope.row.status == "启用"
                                    ? _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "throttle",
                                              rawName: "v-throttle",
                                              value: [
                                                function() {
                                                  return _vm.operation(
                                                    scope.row,
                                                    "1"
                                                  )
                                                }
                                              ],
                                              expression:
                                                "[() => operation(scope.row,'1')]"
                                            }
                                          ],
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.status))]
                                      )
                                    : _vm._e(),
                                  scope.row.status == "禁用"
                                    ? _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "throttle",
                                              rawName: "v-throttle",
                                              value: [
                                                function() {
                                                  return _vm.operation(
                                                    scope.row,
                                                    "2"
                                                  )
                                                }
                                              ],
                                              expression:
                                                "[() => operation(scope.row,'2')]"
                                            }
                                          ],
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          }
                                        },
                                        [_vm._v(_vm._s(scope.row.status))]
                                      )
                                    : _vm._e(),
                                  scope.row.status == "禁用"
                                    ? _c(
                                        "el-link",
                                        {
                                          directives: [
                                            {
                                              name: "throttle",
                                              rawName: "v-throttle",
                                              value: [
                                                function() {
                                                  return _vm.channelPrice(
                                                    scope.row
                                                  )
                                                }
                                              ],
                                              expression:
                                                "[() => channelPrice(scope.row)]"
                                            }
                                          ],
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            type: "primary",
                                            underline: false
                                          }
                                        },
                                        [_vm._v("查看价格表")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          item.type == "product_type"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      on: {
                                        change: function($event) {
                                          return _vm.typeClick(scope.row)
                                        }
                                      },
                                      model: {
                                        value: scope.row.product_type,
                                        callback: function($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "product_type",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.product_type"
                                      }
                                    },
                                    _vm._l(_vm.attrList, function(val, key) {
                                      return _c("el-option", {
                                        key: key,
                                        attrs: {
                                          label: val,
                                          value: String(key + 1)
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          item.type == "remark"
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        width: "450",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: "8",
                                          placeholder: "请输入内容"
                                        },
                                        on: {
                                          blur: function($event) {
                                            return _vm.commentBlur(scope)
                                          }
                                        },
                                        model: {
                                          value: scope.row.remark,
                                          callback: function($$v) {
                                            _vm.$set(scope.row, "remark", $$v)
                                          },
                                          expression: "scope.row.remark"
                                        }
                                      }),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "text"
                                          },
                                          slot: "reference"
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    ],
                                    1
                                  ),
                                  scope.row.remark != null
                                    ? _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "450",
                                            trigger: "hover"
                                          }
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(scope.row.remark))
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "webkitBox",
                                              attrs: { slot: "reference" },
                                              slot: "reference"
                                            },
                                            [_vm._v(_vm._s(scope.row.remark))]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            1
          )
        ],
        1
      ),
      _c("dialog-import", {
        attrs: {
          updateid: _vm.$route.query.platform_id,
          visible: _vm.importVisible,
          "import-props": _vm.importProps,
          "import-return": false,
          title: _vm.$route.query.logistics_name
        },
        on: { close: _vm.closeImport }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }