import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var informationRouter = [{
  path: '/information',
  component: Layout,
  alwaysShow: true,
  name: 'information',
  redirect: 'noRedirect',
  meta: {
    title: '信息管理后台',
    icon: 'el-icon-s-comment'
  },
  children: [{
    path: 'supplier/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/supplier/index'));
      });
    },
    name: 'supplier',
    meta: {
      title: '供应商管理',
      keepAlive: true
    }
  }, {
    path: 'service/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/service/index'));
      });
    },
    name: 'service',
    meta: {
      title: '客户管理',
      keepAlive: true
    }
  }, {
    path: 'logistics/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/logistics/index'));
      });
    },
    name: 'logistics',
    meta: {
      title: '物流商管理',
      keepAlive: true
    }
  }, {
    path: 'channel-rules',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/channel-rules/index'));
      });
    },
    name: 'channel-rules',
    meta: {
      title: '渠道优先级及价格管理'
    }
  }, {
    path: 'client-rules',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/client-rules/index'));
      });
    },
    name: 'client-rules',
    meta: {
      title: '客户店铺规则配置管理'
    }
  }, {
    path: 'channel-conifg/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/channel-conifg/index'));
      });
    },
    name: 'channel-conifg',
    meta: {
      title: '发货渠道配置'
    },
    hidden: true
  }, {
    path: 'channel-price/:id',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/channel-price/index'));
      });
    },
    name: 'channel-price',
    meta: {
      title: '渠道价格'
    },
    hidden: true
  }, {
    path: 'logistics-channel',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/logistics-channel/index'));
      });
    },
    name: 'logistics-channel',
    meta: {
      title: '渠道信息',
      keepAlive: true
    }
  }, {
    path: 'logistics-platform',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/logistics-platform/index'));
      });
    },
    name: 'logistics-platform',
    meta: {
      title: '物流平台',
      keepAlive: true
    }
  }, // {
  //   path: 'YunExpressChannel/index',
  //   component: () => import('@/views/information/YunExpressChannel/index'),
  //   name: 'yun-express-channel',
  //   meta: {
  //     title: '云途渠道信息',
  //     keepAlive: true
  //   }
  // },
  // {
  //   path: 'PXChannel/index',
  //   component: () => import('@/views/information/PXChannel/index'),
  //   name: 'px-channel',
  //   meta: {
  //     title: '4PX渠道信息',
  //     keepAlive: true
  //   }
  // },
  {
    path: 'store/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/information/store/index'));
      });
    },
    name: 'store',
    meta: {
      title: '店铺管理',
      keepAlive: true
    }
  }]
}];
export default informationRouter;