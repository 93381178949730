import { render, staticRenderFns } from "./personal-center.vue?vue&type=template&id=fecb496a&scoped=true&"
import script from "./personal-center.vue?vue&type=script&lang=js&"
export * from "./personal-center.vue?vue&type=script&lang=js&"
import style0 from "./personal-center.vue?vue&type=style&index=0&id=fecb496a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fecb496a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dongketech-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fecb496a')) {
      api.createRecord('fecb496a', component.options)
    } else {
      api.reload('fecb496a', component.options)
    }
    module.hot.accept("./personal-center.vue?vue&type=template&id=fecb496a&scoped=true&", function () {
      api.rerender('fecb496a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/personal-center/personal-center.vue"
export default component.exports