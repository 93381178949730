import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { storeDataList, storedelete } from '@/api/information';
export default {
  name: 'store',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    storeAdd: function storeAdd() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('./component/storeAdd'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '店铺代号',
        value: 'store_name',
        width: '150'
      }, {
        label: '平台',
        value: 'platform',
        width: '120'
      }, {
        label: '平台账号',
        value: 'platform_name'
      }, {
        label: '店铺域名',
        value: 'store_url'
      }, {
        label: 'api_key',
        value: 'api_key'
      }, {
        label: 'password',
        value: 'password'
      }, {
        label: 'shared_secret',
        value: 'shared_secret'
      }, {
        label: 'api_version',
        value: 'api_version'
      }, {
        label: 'location_id',
        value: 'location_id'
      }, {
        label: '负责人',
        value: 'charge_people',
        width: '120'
      }, {
        label: '创建人',
        value: 'creator',
        width: '120'
      }, {
        label: '创建时间',
        value: 'create_time',
        width: '150'
      }, {
        label: '更新人',
        value: 'update_user',
        width: '120'
      }, {
        label: '更新时间',
        value: 'update_time',
        width: '150'
      }, {
        label: '操作',
        type: 'operation',
        width: '120'
      }],
      tabList: ['shopify'],
      tableData: [],
      create_start: [],
      loading: false,
      storeVisible: false,
      storeTitle: '',
      storeId: '',
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        store_name: '',
        platform_name: '',
        store_url: '',
        creator: '',
        create_start_time: '',
        create_end_time: ''
      },
      selectList: {},
      Selected: []
    };
  },
  watch: {
    create_start: {
      handler: function handler(val) {
        if (val !== null && val.length > 0) {
          this.formInline.create_start_time = this.create_start[0];
          this.formInline.create_end_time = this.create_start[1];
        } else {
          this.formInline.create_start_time = '';
          this.formInline.create_end_time = '';
        }
      },
      immediate: true
    }
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      storeDataList(formData).then(function (res) {
        if (res.code == 200) {
          setTimeout(function () {
            _this.tableData = res.data;
            _this.listQuery.total = parseInt(res.total);
            _this.loading = false;
          }, 300);
        }
      });
    },
    // Tabs点击
    handleClick: function handleClick() {},
    resetClick: function resetClick() {
      this.formInline = this.$options.data().formInline;
      this.create_start = [];
      this.Inquire();
    },
    // 多选
    handleSelectionChange: function handleSelectionChange(val) {
      this.Selected = val;
    },
    // 删除
    delClick: function delClick() {
      var _this2 = this;

      if (this.Selected.length == 0) return this.$message({
        type: 'warning',
        message: '请至少选择单条数据进行操作'
      });
      this.$confirm('您确定删除选中' + this.Selected.length + '条数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        storedelete({
          ids: _this2.Selected.map(function (item) {
            return item.id;
          }).join(',')
        }).then(function (res) {
          if (res.code == 200) {
            _this2.$message({
              type: 'success',
              message: res.message
            });

            _this2.Inquire();
          } else {
            _this2.$notify({
              title: '提示',
              message: res.message,
              dangerouslyUseHTMLString: true,
              type: 'error'
            });

            _this2.Inquire();
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 新增店铺
    storeClick: function storeClick(type) {
      this.storeVisible = true;

      if (type == 'add') {
        this.storeTitle = '新增店铺';
      } else {
        this.storeTitle = '修改店铺';
        this.storeId = type;
      }
    },
    closestore: function closestore(type) {
      if (type != 1) {
        this.Inquire();
      }

      this.storeVisible = false;
    }
  }
};