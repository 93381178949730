import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
export var historyRoute = {
  methods: {
    delHistoryRoute: function delHistoryRoute(list) {
      var _this = this;

      var delRoutes = this.$store.getters.delRoutes;
      list.map(function (view) {
        _this.$store.dispatch('tagsView/delView', view).then(function (_ref) {
          var visitedViews = _ref.visitedViews;

          if (view.path === _this.$route.path) {
            var latestView = visitedViews.slice(-1)[0];

            if (latestView) {
              _this.$router.push(latestView.fullPath);
            } else {
              // now the default is to redirect to the home page if there is no tags-view,
              // you can adjust it according to your needs.
              if (view.name === 'Dashboard') {
                // to reload home page
                _this.$router.replace({
                  path: '/redirect' + view.fullPath
                });
              } else {
                _this.$router.push('/');
              }
            }
          }
        });
      });
    }
  }
};