var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "sku-setting-dialog",
      attrs: { visible: _vm.dialogVisible, title: _vm.title, width: "700px" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        open: _vm.handleOpenDialog
      }
    },
    [
      _c(
        "div",
        { staticClass: "sku-pre-box" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.skulist, function(sku, i) {
              return _c(
                "el-tab-pane",
                { key: i, attrs: { label: "SKU" + (i + 1), name: "" + i } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: sku,
                        "label-width": "80px",
                        size: "small"
                      }
                    },
                    [
                      _c("el-form-item", { attrs: { label: "SKU别名" } }, [
                        _c("div", { staticClass: "w-300" }, [
                          _vm._v(_vm._s(sku.sku_name))
                        ])
                      ]),
                      _c("el-divider", [_vm._v("关联客户")]),
                      _vm._l(sku.services, function(server, j) {
                        return _c(
                          "el-card",
                          { key: j + 1, staticClass: "mt20" },
                          [
                            _c(
                              "div",
                              { staticClass: "action-header clearfix" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      float: "left",
                                      padding: "3px 0",
                                      "font-size": "14px",
                                      color: "#999"
                                    }
                                  },
                                  [_vm._v("客户" + _vm._s(j + 1))]
                                ),
                                j != 0
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                          "font-size": "13px"
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delServer(i, j)
                                          }
                                        }
                                      },
                                      [_vm._v("删除此客户")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  {
                                    staticClass: "row-bg mt10",
                                    attrs: { type: "flex" }
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 22 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "客户" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择客户",
                                                  filterable: "",
                                                  clearable: ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.initShopList(
                                                      server,
                                                      j
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: server.service_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      server,
                                                      "service_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "server.service_id"
                                                }
                                              },
                                              _vm._l(_vm.services, function(
                                                service,
                                                index
                                              ) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: service.service_name,
                                                    value: service.id
                                                  }
                                                })
                                              }),
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "配置店铺" } },
                                          [
                                            server.nodata
                                              ? _c(
                                                  "div",
                                                  { staticClass: "tip" },
                                                  [_vm._v("暂无店铺数据")]
                                                )
                                              : server.shoplist.length > 0
                                              ? _c("div", [
                                                  _c(
                                                    "ul",
                                                    {
                                                      staticClass: "check-list"
                                                    },
                                                    _vm._l(
                                                      server.shoplist,
                                                      function(item, skey) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: skey,
                                                            class: [
                                                              "check-item",
                                                              item.checked
                                                                ? "checked"
                                                                : ""
                                                            ]
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.checked,
                                                                  expression:
                                                                    "item.checked"
                                                                }
                                                              ],
                                                              attrs: {
                                                                type: "checkbox"
                                                              },
                                                              domProps: {
                                                                checked: Array.isArray(
                                                                  item.checked
                                                                )
                                                                  ? _vm._i(
                                                                      item.checked,
                                                                      null
                                                                    ) > -1
                                                                  : item.checked
                                                              },
                                                              on: {
                                                                change: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        item.checked,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c = $$el.checked
                                                                        ? true
                                                                        : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v = null,
                                                                        $$i = _vm._i(
                                                                          $$a,
                                                                          $$v
                                                                        )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            item,
                                                                            "checked",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            item,
                                                                            "checked",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        item,
                                                                        "checked",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.checkboxChange(
                                                                      server,
                                                                      item
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            }),
                                                            item.checked
                                                              ? _c(
                                                                  "el-tooltip",
                                                                  {
                                                                    staticClass:
                                                                      "item",
                                                                    attrs: {
                                                                      effect:
                                                                        "dark",
                                                                      content:
                                                                        "点击查看",
                                                                      placement:
                                                                        "top"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticStyle: {
                                                                          cursor:
                                                                            "pointer"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.showCheckData(
                                                                              server,
                                                                              item
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.store_name
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "label",
                                                                  {
                                                                    staticStyle: {
                                                                      cursor:
                                                                        "pointer"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.showCheckData(
                                                                          server,
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.store_name
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                ])
                                              : _c(
                                                  "div",
                                                  { staticClass: "tip" },
                                                  [
                                                    _vm._v(
                                                      "选择客户店铺并设置对应配置信息"
                                                    )
                                                  ]
                                                )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "add-btn mt20",
                          attrs: { size: "mini", icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              return _vm.addServer(i)
                            }
                          }
                        },
                        [_vm._v("新增客户")]
                      ),
                      _c("el-divider", [_vm._v("关联供应商")]),
                      _vm._l(sku.supplier, function(sup, k) {
                        return _c("el-card", { key: k, staticClass: "mt20" }, [
                          _c(
                            "div",
                            { staticClass: "action-header clearfix" },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    float: "left",
                                    padding: "3px 0",
                                    "font-size": "14px",
                                    color: "#999"
                                  }
                                },
                                [_vm._v("供应商" + _vm._s(k + 1))]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                    "font-size": "13px"
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.delSupplier(i, k)
                                    }
                                  }
                                },
                                [_vm._v("删除此供应商")]
                              ),
                              _c(
                                "el-row",
                                {
                                  staticClass: "row-bg mt10",
                                  attrs: { type: "flex" }
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 22 } },
                                    [
                                      _c(
                                        "el-form",
                                        {
                                          attrs: {
                                            model: sup,
                                            "label-width": "80px",
                                            size: "small"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "供应商" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticStyle: {
                                                    width: "100%"
                                                  },
                                                  attrs: {
                                                    placeholder: "请选择供应商",
                                                    filterable: ""
                                                  },
                                                  model: {
                                                    value: sup.supplier_id,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        sup,
                                                        "supplier_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "sup.supplier_id"
                                                  }
                                                },
                                                _vm._l(_vm.supplier, function(
                                                  suppl,
                                                  index
                                                ) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label:
                                                        suppl.supplier_name,
                                                      value: suppl.id
                                                    }
                                                  })
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "采购价格" } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入采购价格"
                                                },
                                                model: {
                                                  value: sup.pur_price,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      sup,
                                                      "pur_price",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "sup.pur_price"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "add-btn mt20",
                          attrs: { size: "mini", icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              return _vm.addSupplier(i)
                            }
                          }
                        },
                        [_vm._v("新增供应商")]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "mini", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submit()
                }
              }
            },
            [_vm._v("确认")]
          )
        ],
        1
      ),
      _c("shop-setting", {
        attrs: {
          visible: _vm.shopVisible,
          "form-data": _vm.shopData,
          flag: _vm.shopFlag,
          "store-id": _vm.storeId,
          "pool-list": _vm.poolList
        },
        on: {
          "update:visible": function($event) {
            _vm.shopVisible = $event
          },
          close: _vm.setItem,
          "add-pool": _vm.addPoolList,
          "update-pool": _vm.updatePoolList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }