import request from '@/utils/request'; // import baseURL from './mixins'

import { getSession } from '@/utils/session';
var baseURL = '';
getSession('user') ? baseURL = getSession('user').url : baseURL = ''; // 菜单管理 获取列表

export function menuGetList(data) {
  return request({
    url: 'system/menu/list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}
export function commonGetAll(data) {
  return request({
    url: 'base/common/get-all',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 菜单管理 获取下拉选项

export function menuGetOption(data) {
  return request({
    url: 'system/menu/get-option',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 菜单管理 更改菜单状态

export function menuUpdateStatus(data) {
  return request({
    url: 'system/menu/update-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 菜单管理 ID获取修改信息

export function menuGetUpdate(data) {
  return request({
    url: 'system/menu/update',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 菜单管理 新增、修改菜单信息

export function menuStore(data) {
  return request({
    url: 'system/menu/store',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取用户通过角色获取的菜单和增减的菜单

export function getUserRules(data) {
  return request({
    url: 'system/admin/get-user-rules',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} //  保存用户通过角色获取的菜单和增减的菜单

export function saveUserRules(data) {
  return request({
    url: 'system/admin/save-user-rules',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取角色列表

export function getSystemRoleList(data) {
  return request({
    url: 'system/role/list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 更改角色状态

export function roleUpdateStatus(data) {
  return request({
    url: 'system/role/update-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 更改角色状态

export function roleSave(data) {
  return request({
    url: 'system/role/save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 角色状态 获取搜索下拉选项

export function getRoleOption(data) {
  return request({
    url: 'system/role/get-option',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 角色修改时获取信息

export function roleUpdate(data) {
  return request({
    url: 'system/admin/permission',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 更改角色菜单权限  / 单点权限

export function permissionSave(data) {
  return request({
    url: 'system/admin/permission-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 角色修改 获取角色分配人员

export function getAdminAssignment(data) {
  return request({
    url: 'system/admin/assignment',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 更改角色  分配人员

export function assignmentSave(data) {
  return request({
    url: 'system/admin/assignment-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取用户列表信息

export function getAdminList(data) {
  return request({
    url: 'system/admin/list',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改用户状态

export function updateStatus(data) {
  return request({
    url: 'system/admin/update-status',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取所有角色

export function getAllRole(data) {
  return request({
    url: 'base/common/get-all-role',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取用户信息

export function getUserInfo(data) {
  return request({
    url: 'system/admin/get-user-info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改用户信息

export function adminStore(data) {
  return request({
    url: 'system/admin/store',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取组织架构数据

export function getOrganizationalStructure(data) {
  return request({
    url: 'base/common/get-organizational-structure',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取用户组织架构数据

export function getSystemAdminData(data) {
  return request({
    url: 'system/admin/data',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改用户组织架构

export function mdfSystemAdminData(data) {
  return request({
    url: 'system/admin/data-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取用户单点权限

export function baseCommonGetCheck(data) {
  return request({
    url: 'base/common/get-check',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取用户 信息

export function baseCommonIndexInfo(data) {
  return request({
    url: 'base/common/index-info',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取所有菜单

export function getAllRules(data) {
  return request({
    url: 'base/common/get-all-rules',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取所有菜单

export function getAllVueAndApiRules(data) {
  return request({
    url: 'base/common/get-all',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取 角色数据前线

export function permissionUid(data) {
  return request({
    url: 'system/admin/permission-uid',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改 角色数据前线

export function permissionUidSave(data) {
  return request({
    url: 'system/admin/permission-uid-save',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取 用户数据权限

export function getUserUids(data) {
  return request({
    url: 'system/admin/get-user-uids',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 修改 用户 自定义 数据权限

export function saveUserUids(data) {
  return request({
    url: 'system/admin/save-user-uids',
    method: 'post',
    data: data,
    baseURL: baseURL
  });
} // 获取所有单点菜单

export function getAllRulesCheck(data) {
  return request({
    url: 'base/common/get-all-rules-check',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
} // 获取所有单点菜单

export function getDashboardStatistics(data) {
  return request({
    url: '/order/order/statistics',
    method: 'get',
    params: data,
    baseURL: baseURL
  });
}