import _defineProperty from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getwarehoust_list as _getwarehoust_list, addWarehouse as _addWarehouse } from '@/api/system';
export default {
  data: function data() {
    var _ref;

    return _ref = {
      loading: false,
      warehoustlist: [],
      dialogFormVisible: false,
      editdialogFormVisible: false,
      form: {
        name: ''
      },
      editform: {
        name: ''
      },
      formLabelWidth: '120px'
    }, _defineProperty(_ref, "form", {
      sku: "",
      skuname: ""
    }), _defineProperty(_ref, "listQuery", {
      page: 1,
      limit: 10
    }), _defineProperty(_ref, "tableData", [{
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄"
    }, {
      date: "2016-05-04",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1517 弄"
    }, {
      date: "2016-05-01",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1519 弄"
    }, {
      date: "2016-05-03",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1516 弄"
    }]), _ref;
  },
  mounted: function mounted() {
    this.getwarehoust_list();
  },
  methods: {
    edit: function edit(i) {
      this.editdialogFormVisible = true;
      this.editform.name = i.name;
      this.editform.id = i.id;
    },
    // 库存列表接口
    getwarehoust_list: function getwarehoust_list() {
      var _this = this;

      this.loading = true;

      _getwarehoust_list(this.listQuery).then(function (res) {
        console.log(res);
        _this.warehoustlist = res.data.data;
        _this.loading = false;
      });
    },
    editWarehouse: function editWarehouse() {
      var _this2 = this;

      this.loading = true;
      this.editdialogFormVisible = false;

      _addWarehouse(this.editform).then(function (res) {
        _this2.getwarehoust_list();
      });
    },
    addWarehouse: function addWarehouse() {
      var _this3 = this;

      this.loading = true;
      this.dialogFormVisible = false;

      _addWarehouse({
        name: this.form.name
      }).then(function (res) {
        _this3.getwarehoust_list();
      });
    },
    onSubmit: function onSubmit() {
      console.log("---", this.form);
    }
  }
};