import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { uploadImage } from '@/api/commodity';
export default {
  name: 'edit-pictures',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '选择SKU图片'
    },
    flag: {
      type: String,
      default: 'view'
    },
    checked: {
      type: String,
      default: ''
    },
    formData: {
      type: [Object, Array],
      default: function _default() {}
    },
    showClose: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      imgList: [],
      fileList: [],
      uploadVisible: false,
      checkedImg: '' // 已选中图片

    };
  },
  computed: {
    dialogVisible: {
      get: function get() {
        return this.visible;
      },
      set: function set(newVal, oldVal) {
        this.$emit('update:visible', newVal);
      }
    }
  },
  watch: {
    formData: {
      handler: function handler(newVal) {
        if (Array.isArray(newVal)) {
          this.$set(this, 'imgList', Array.from(newVal));
        }
      },
      deep: true
    },
    checked: function checked(newVal) {
      this.checkedImg = newVal;
    }
  },
  created: function created() {},
  methods: {
    selectImg: function selectImg(file) {
      this.checkedImg = file.url;
    },
    handleChange: function handleChange(file, fileList) {
      console.log('change', file);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var _this = this;

      // const is1M = file.size / 1024 / 1024 < 1 // 限制小于1M
      var isSize = new Promise(function (resolve, reject) {
        var isLt2M = file.size / 1024 / 1024 < 2;
        isLt2M ? resolve() : reject(new Error('Error'));
      }).then(function () {
        return file;
      }, function () {
        _this.$message.error('上传的图片大小超出2M！');

        return Promise.reject(new Error('Error'));
      });
      return isSize;
    },
    upload: function upload(fileObj) {
      var file = {
        showProgress: true,
        url: '',
        percent: 0
      };
      this.imgList.push(file);
      var formData = new FormData();
      formData.append('file', fileObj.file);
      uploadImage(formData, function (progress) {
        file.percent = Math.round(progress.loaded / progress.total * 100);
      }).then(function (res) {
        if (res.code === 200) {
          file.url = res.data['data-service-file'];
          file.showProgress = false;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    close: function close() {
      this.dialogVisible = false;
    },
    submit: function submit() {
      this.$emit('select', this.checkedImg);
      this.$emit('update', this.imgList);
      this.close();
    }
  }
};