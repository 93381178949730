import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import draggable from 'vuedraggable';
import { uploadImage } from '@/api/commodity';
export default {
  name: 'shop-window',
  components: {
    draggable: draggable
  },
  props: {
    imgList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isShow: true,
      showFlag: false,
      percent: 0,
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: '',
      imgWidth: '1920',
      imgHeight: '1080',
      isDrag: false,
      checkAll: false,
      checkedList: [],
      isIndeterminate: true
    };
  },
  computed: {
    selFileCount: function selFileCount() {
      return this.fileList.filter(function (file) {
        return file.checked;
      }).length;
    }
  },
  watch: {
    imgList: {
      handler: function handler(newVal) {
        this.$set(this, 'fileList', newVal);
      },
      deep: true
    }
  },
  created: function created() {},
  methods: {
    dragEnter: function dragEnter() {
      console.log('dragenter'); // this.isDrag = true
    },
    drop: function drop() {
      this.isDrag = false;
    },
    dragLeave: function dragLeave() {
      console.log('dragleave'); // this.isDrag = false
    },
    handleChange: function handleChange(file, fileList) {// console.log('change', file)
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var _this = this;

      // const is1M = file.size / 1024 / 1024 < 1 // 限制小于1M
      var isSize = new Promise(function (resolve, reject) {
        var isLt2M = file.size / 1024 / 1024 < 2;
        isLt2M ? resolve() : reject(new Error('Error'));
      }).then(function () {
        return file;
      }, function () {
        _this.$message.error('上传的图片大小超出2M！');

        return Promise.reject(new Error('Error'));
      });
      return isSize;
    },
    upload: function upload(fileObj) {
      var _this2 = this;

      var file = {
        showProgress: true,
        url: '',
        percent: 0
      };
      this.fileList.push(file);
      var formData = new FormData();
      formData.append('file', fileObj.file);
      uploadImage(formData, function (progress) {
        file.percent = Math.round(progress.loaded / progress.total * 100);
      }).then(function (res) {
        if (res.code === 200) {
          file.url = res.data['data-service-file'];
          file.showProgress = false;

          _this2.$emit('update', _this2.fileList);
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    previewImg: function previewImg(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    deleteImg: function deleteImg(index) {
      var _this3 = this;

      this.$confirm("\u6B64\u64CD\u4F5C\u5C06\u5220\u9664\u8BE5\u6587\u4EF6, \u662F\u5426\u7EE7\u7EED?", '删除图片', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.$emit('delete', _this3.fileList[index].url);

        _this3.fileList.splice(index, 1);

        _this3.$emit('update', _this3.fileList);
      }).catch(function () {});
    },
    deleteMedia: function deleteMedia() {
      var _this4 = this;

      this.$confirm("\u6B64\u64CD\u4F5C\u5C06\u5220\u9664".concat(this.selFileCount, "\u4E2A\u6587\u4EF6, \u662F\u5426\u7EE7\u7EED?"), '删除图片', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log('del');

        _this4.fileList.forEach(function (file, index) {
          if (file.checked) {
            _this4.$emit('delete', _this4.fileList[index].url);
          }
        });

        var ddata = _this4.fileList.filter(function (file) {
          return file.checked === false;
        });

        _this4.$emit('update', ddata);
      }).catch(function () {});
    },
    handleCheckAllChange: function handleCheckAllChange() {
      if (this.isIndeterminate) {
        this.fileList.forEach(function (file) {
          file.checked = true;
        });
        this.isIndeterminate = false;
      } else {
        this.fileList.forEach(function (file) {
          file.checked = false;
        });
        this.isIndeterminate = true;
      }
    },
    checkedImg: function checkedImg(file) {
      // if (!this.checkedList.includes(file.url)) {
      //   this.checkedList.push(file.url)
      // } else {
      //   const index = this.checkedList.findIndex(url => url === file.url)
      //   this.checkedList.splice(index, 1)
      // }
      var checkedCount = this.fileList.filter(function (file) {
        return file.checked;
      }).length;
      this.checkAll = checkedCount === this.fileList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fileList.length;
    }
  }
};