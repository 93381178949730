var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop-form" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            model: _vm.formData,
            inline: "",
            "label-position": "right",
            rules: _vm.formDataRules
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-search" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "店铺名称", prop: "shopName" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "shop_calc",
                              attrs: {
                                size: "small",
                                clearable: "",
                                filterable: "",
                                disabled: !(_vm.index === -1),
                                "default-first-option": "",
                                placeholder: "请选择"
                              },
                              on: {
                                change: _vm.handleChooseShop,
                                clear: _vm.handleClearShop
                              },
                              model: {
                                value: _vm.formData.shopName,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "shopName", $$v)
                                },
                                expression: "formData.shopName"
                              }
                            },
                            _vm._l(_vm.shopNameList, function(item, i) {
                              return _c("el-option", {
                                key: item.store_url,
                                attrs: {
                                  label: item.store_name,
                                  value: item.store_url,
                                  disabled: !_vm.disableShopNameList[i]
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "时间筛选", prop: "timeScreening" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "date_calc",
                            attrs: {
                              size: "small",
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              disabled: _vm.formData.disabled
                            },
                            on: { change: _vm.handleChooseData },
                            model: {
                              value: _vm.formData.timeScreening,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "timeScreening", $$v)
                              },
                              expression: "formData.timeScreening"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 9 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "区间筛选", prop: "sectionScreening" }
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c("el-input", {
                                    staticClass: "sec_calc",
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      clearable: "",
                                      disabled: _vm.formData.disabled
                                    },
                                    on: { change: _vm.handleChooseStart },
                                    model: {
                                      value: _vm.formData.order_start_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "order_start_name",
                                          $$v
                                        )
                                      },
                                      expression: "formData.order_start_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 2 } }, [
                                _vm._v(" - ")
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 11 } },
                                [
                                  _c("el-input", {
                                    staticClass: "sec_calc",
                                    attrs: {
                                      type: "text",
                                      size: "small",
                                      clearable: "",
                                      disabled: _vm.formData.disabled
                                    },
                                    on: { change: _vm.handleChooseEnd },
                                    model: {
                                      value: _vm.formData.order_end_name,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "order_end_name",
                                          $$v
                                        )
                                      },
                                      expression: "formData.order_end_name"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-button", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isDeleteShow,
                    expression: "isDeleteShow"
                  }
                ],
                staticClass: "delete-error",
                attrs: { type: "text", size: "small", icon: "el-icon-error" },
                on: { click: _vm.handleDelete }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-data p-r" },
            [
              _vm._l(_vm.tags, function(tag, inx) {
                return _c(
                  "el-tag",
                  {
                    key: inx,
                    attrs: { closable: "", effect: "plain", type: "info" },
                    on: {
                      close: function() {
                        _vm.handleWTagClose(tag, inx)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tag.order_name) + " ")]
                )
              }),
              _vm.moreShow
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.loadMore }
                    },
                    [_vm._v("Load more")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addingGoods }
                },
                [_vm._v("Add order")]
              )
            ],
            2
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "75%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dialog-box" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.dialogForm,
                    "label-width": "100px",
                    inline: ""
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单名称:", prop: "orderName" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "vw",
                          attrs: {
                            size: "small",
                            clearable: "",
                            filterable: "",
                            remote: "",
                            "default-first-option": "",
                            placeholder: "请选择",
                            "remote-method": _vm.remoteMethod
                          },
                          on: { change: _vm.handleChooseOrder },
                          model: {
                            value: _vm.dialogForm.orderName,
                            callback: function($$v) {
                              _vm.$set(_vm.dialogForm, "orderName", $$v)
                            },
                            expression: "dialogForm.orderName"
                          }
                        },
                        [
                          _vm._l(_vm.dialogList, function(item, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: {
                                label: item.order_name,
                                value: item.orders_sublist_id,
                                disabled: _vm.disableDialog(
                                  item.orders_sublist_id
                                )
                              }
                            })
                          }),
                          _vm.dialogMoreShow
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "padding-left": "20px" },
                                  attrs: { type: "text", size: "small" },
                                  on: { click: _vm.dialogLoadMore }
                                },
                                [_vm._v("Load more")]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "dialog-data" },
                    _vm._l(_vm.dialogTags, function(dTag, inx) {
                      return _c(
                        "el-tag",
                        {
                          key: inx,
                          attrs: {
                            closable: "",
                            effect: "plain",
                            type: "info"
                          },
                          on: {
                            close: function() {
                              _vm.handleDTagClose(dTag, inx)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(dTag.order_name) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.determine } },
                [_vm._v("Determine")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }