//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { logisticsAddConfig, logisticsEditConfig } from '@/api/information';
export default {
  name: 'logistics-add',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      logisticsForm: {
        client_code: '',
        secret_key: ''
      },
      rules: {
        client_code: [{
          required: true,
          message: '请填写客户代码',
          trigger: 'blur'
        }],
        secret_key: [{
          required: true,
          message: '请填写密钥',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    visible: function visible(val) {
      var _this = this;

      if (val) {
        console.log(this.$parent.logisticsId);
        logisticsEditConfig({
          id: this.$parent.logisticsId
        }).then(function (res) {
          if (res.code === 200) {
            if (res.data.length !== 0) {
              _this.logisticsForm = res.data;
            }
          }
        });
      } else {
        this.$refs.logisticsForm.resetFields();
      }
    }
  },
  methods: {
    logisticsAdd: function logisticsAdd(type) {
      var _this2 = this;

      if (type === 1) {
        this.$refs.logisticsForm.validate(function (valid) {
          if (valid) {
            _this2.logisticsForm.id = _this2.$parent.logisticsId;
            logisticsAddConfig(_this2.logisticsForm).then(function (res) {
              if (res.code === 200) {
                _this2.$emit('logisticsAdd');

                _this2.$message.success(res.message);
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      } else {
        this.$emit('logisticsAdd', 1);
      }
    }
  }
};