import _toConsumableArray from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.of.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { getStoreOrder } from '@/api/bill';
import { baseload } from '@/mixins/baseloading';
export default {
  name: 'shop-form',
  mixins: [baseload],
  props: {
    shopFrom: {
      type: Object,
      default: function _default() {}
    },
    shopNameList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    index: {
      type: Number,
      require: true
    },
    isEdit: {
      type: Boolean,
      require: true
    },
    isDeleteShow: {
      type: Boolean,
      require: true
    }
  },
  data: function data() {
    var validateTag = function validateTag(rule, value, callback) {
      if (!value) {
        return callback(new Error('订单号不能为空！'));
      }
    };

    return {
      formData: {
        shopName: '',
        timeScreening: [],
        order_start_name: '',
        order_end_name: '',
        disabled: true,
        delete_orders_sublist_ids: '',
        add_orders_sublist_ids: ''
      },

      /**
       * 外面选中的数组
       */
      tags: [],
      dialogVisible: false,
      // 弹框表单
      dialogForm: {
        orderName: ''
      },
      tagsList: [],
      dialogList: [],
      dialogTags: [],
      pageSize: 100,
      storeOrder: {
        store_url: '',
        create_start_time: '',
        create_end_time: '',
        order_start_name: '',
        order_end_name: '',
        is_all: 0,
        iDisplayLength: 100,
        iDisplayStart: 0,
        delete_orders_sublist_ids: '',
        add_orders_sublist_ids: ''
      },
      disableDialogList: [],
      formDataRules: {
        shopName: [{
          required: this.index === -1,
          message: '请选择店铺名称',
          trigger: 'blur'
        }],
        timeScreening: [{
          required: false,
          message: '请选择时间区间',
          trigger: 'blur'
        }],
        tag: [{
          required: false,
          validator: validateTag,
          trigger: 'blur'
        }]
      },
      ordersSublistIds: [],
      removeArrays: [],
      // 标记选择后重新加载店铺
      isLonginTags: -1,
      timeOut: 2000,
      addArr: [],
      deleteArr: [],
      moreShow: false,
      dialogMoreShow: false,
      isClickDelete: false,
      moreList: []
    };
  },
  computed: _objectSpread({}, mapGetters({
    disableShopNameList: 'disableShopNameList'
  })),
  created: function created() {
    var _this = this;

    this.mixinloading('数据加载中...');
    console.log('子组件created中shopFrom', JSON.parse(JSON.stringify(this.shopFrom)));

    if (Object.keys(this.shopFrom).length > 3) {
      var ordersSublistIds = [];
      this.formData = JSON.parse(JSON.stringify(this.shopFrom));

      if (this.isEdit) {
        this.$set(this.storeOrder, 'iDisplayLength', this.formData.orders_sublist_ids ? this.formData.orders_sublist_ids.length : 10);
      }

      this.ordersSublistIds = JSON.parse(JSON.stringify(this.formData.orders_sublist_ids));
      this.$set(this.storeOrder, 'store_url', this.formData.store_url);
      this.$set(this.formData, 'shopName', this.formData.store_url);

      if (this.formData.create_start_time) {
        this.$set(this.formData, 'timeScreening', Array.of(this.formData.create_start_time, this.formData.create_end_time));
        this.$set(this.storeOrder, 'create_start_time', this.formData.create_start_time);
        this.$set(this.storeOrder, 'create_end_time', this.formData.create_end_time);
        this.$set(this.storeOrder, 'order_start_name', this.formData.order_start_name);
        this.$set(this.storeOrder, 'order_end_name', this.formData.order_end_name);
      }

      this.$set(this.storeOrder, 'iDisplayLength', JSON.parse(JSON.stringify(this.formData.orders_sublist_ids)).length);
      this.handleGetStoreOrder();
      setTimeout(function (e) {
        _this.ordersSublistIds.map(function (item) {
          // 是编辑的时候为true
          ordersSublistIds.push({
            order_name: _this.getLabelByValue(item),
            orders_sublist_id: item
          });

          _this.disableDialogList.push(true); // this.handleChooseOrder(item)

        });

        setTimeout(function (e) {
          var otherTags = _this.$store.getters.otherTags;
          var arr = _this.dialogTags;

          if (Object.keys(otherTags).length > 0) {
            arr = [].concat(_toConsumableArray(_this.dialogTags), _toConsumableArray(otherTags[_this.formData.store_url]));
          }

          console.log('created中otherTags', arr);
          _this.tags = JSON.parse(JSON.stringify(arr));
          _this.dialogTags = JSON.parse(JSON.stringify(arr));
        }, 500); // console.log(this.tags)

        _this.mixinclose();
      }, this.timeOut);
    } else {
      this.mixinclose();
    }
  },
  methods: {
    generateTagsList: function generateTagsList(dialogList) {
      var _this2 = this;

      dialogList.map(function (item) {
        _this2.dialogTags.push(item);
      });
    },
    // 获取店铺订单
    handleGetStoreOrder: function handleGetStoreOrder() {
      var _this3 = this;

      getStoreOrder(this.storeOrder).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (it, inx) {
            if (it.order_name === null) {
              it.order_name = '#' + inx;
            }
          });
          _this3.tagsList = JSON.parse(JSON.stringify(res.data)); // const data = res.data
          // console.log('请求反回店铺信息：', data)
          // this.tagsList.push(...data)

          Array.from(new Set(_this3.tagsList));

          if (_this3.storeOrder.store_url === '') {
            // console.log("store_url等于空")
            _this3.tagsList.map(function (e) {
              return _this3.disableDialogList.push(true);
            });

            if (!_this3.formData) {
              _this3.generateTagsList(_this3.tagsList);
            }
          } else {
            _this3.generateTagsList(_this3.tagsList);

            _this3.tags = JSON.parse(JSON.stringify(_this3.dialogTags));

            _this3.$set(_this3.formData, 'orders_sublist_ids', _this3.tags.map(function (item) {
              return item.orders_sublist_id;
            }));

            _this3.$emit('storeUrl', _this3.formData, _this3.index);

            _this3.tagsList.map(function (it) {
              // this.canSelected(it.order_name)
              // 是编辑的时候为true
              if (!_this3.isEdit) {
                _this3.disableDialogList.push(false);
              } else {
                _this3.disableDialogList.push(true);
              }
            });
          }

          if (Number(res.total) > 99) {
            _this3.moreShow = true;
          }

          var num = _this3.moreList.length + _this3.tags.length - _this3.addArr.length;

          if (Number(res.total) === num) {
            _this3.moreShow = false;
          }
        }
      });
    },
    // 添加商品
    addingGoods: function addingGoods() {
      this.dialogVisible = true;
      var param = JSON.parse(JSON.stringify(this.storeOrder));
      this.$set(param, 'is_all', 1);
      this.$set(param, 'iDisplayLength', this.pageSize);
      this.$set(param, 'iDisplayStart', 0);
      this.getOrder(param);
    },
    getOrder: function getOrder(param) {
      var _this4 = this;

      getStoreOrder(param).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (it, inx) {
            if (it.order_name === null) {
              it.order_name = '#' + inx;
            }
          });

          if (!_this4.isClickDelete) {
            _this4.dialogList = JSON.parse(JSON.stringify(res.data));

            _this4.dialogTags.map(function (item) {
              _this4.dialogList.map(function (it, index) {
                if (item.orders_sublist_id + '' !== it.orders_sublist_id) {
                  _this4.$set(_this4.disableDialogList, index, !_this4.disableDialogList[index]);
                }
              });
            });
          } else {
            _this4.dialogList = [].concat(_toConsumableArray(_this4.dialogList), _toConsumableArray(res.data));
            res.data.map(function (it) {
              _this4.disableDialogList.push(true);
            });
          }

          if (Number(res.total) > 99) {
            _this4.dialogMoreShow = true;
          }

          console.log('res.total', res.total);
          console.log('this.dialogList.length', _this4.dialogList.length);

          if (Number(res.total) === _this4.dialogList.length) {
            _this4.dialogMoreShow = false;
          }
        }
      });
    },
    // 关闭弹框
    handleClose: function handleClose(done) {
      var _this5 = this;

      this.$confirm('确认关闭？').then(function (_) {
        // 确认关闭需要还原内外选中的数据，保持一致
        _this5.dialogTags = JSON.parse(JSON.stringify(_this5.tags));
        _this5.removeArrays = [];

        _this5.removeArrays.map(function (item) {
          _this5.canSelected(item.order_name);
        });

        done();
      }).catch(function (_) {});
    },
    // 确认添加订单
    determine: function determine() {
      this.dialogVisible = false;
      this.tags = JSON.parse(JSON.stringify(this.dialogTags));
      this.$set(this.formData, 'orders_sublist_ids', this.tags.map(function (item) {
        return item.orders_sublist_id;
      }));
      this.$emit('storeUrl', this.formData, this.index);
      this.dialogList = [];
    },
    // 内层关闭标签
    handleDTagClose: function handleDTagClose(dTag, index) {
      console.log(dTag, index);
      this.dialogTags.splice(index, 1); // 保存内部门被移除的数据

      this.removeArrays.push(dTag);
      this.$set(this.disableDialogList, index, !this.disableDialogList[index]);
      this.otherTagsDel(dTag);
    },
    // 外层关闭标签
    handleWTagClose: function handleWTagClose(dTag, index) {
      this.dialogTags.splice(index, 1); // 保存删除标签

      this.deleteArr.push(dTag.orders_sublist_id);
      this.moreList.push(dTag);
      this.tags = JSON.parse(JSON.stringify(this.dialogTags));
      this.$set(this.disableDialogList, index, !this.disableDialogList[index]);
      this.$set(this.formData, 'orders_sublist_ids', this.tags.map(function (item) {
        return item.orders_sublist_id;
      }));
      this.$emit('storeUrl', this.formData, this.index);
    },
    // 选中店铺订单
    handleChooseShop: function handleChooseShop(val) {
      var _this6 = this;

      // this.mixinloading('数据加载中...')
      this.formData.disabled = false;

      try {
        if (this.index > -1) {
          var list = this.$store.getters.disableShopNameList; // 禁用相同的店铺名称

          this.shopNameList.map(function (item, index) {
            if (item.store_url === val) {
              _this6.$set(list, index, false);
            }
          });
        } // console.log('选中名称', val)


        this.handleClearShop();
        this.handleClearDate();
        this.$set(this.storeOrder, 'store_url', val);
        this.handleGetStoreOrder();
        var num;
        var arr = this.shopNameList.filter(function (it) {
          return it.store_url === val;
        });

        if (arr.length > 0) {
          num = arr[0].store_name;
        }

        this.$set(this.formData, 'store_url', val);
        this.$set(this.formData, 'ltem', num); // console.log("选中店铺订单", JSON.parse(JSON.stringify(this.formData)));

        this.$emit('storeUrl', this.formData, this.index);
      } catch (e) {
        console.error(e); // this.mixinclose()
      }
    },
    // 清空店铺
    handleClearShop: function handleClearShop() {
      // console.log('清空店铺')
      this.tags = [];
      this.dialogTags = [];
      this.disableDialogList = [];
    },
    // 清空清空日期、区间
    handleClearDate: function handleClearDate() {
      // console.log('清空日期')
      this.formData.timeScreening = [];
      this.formData.order_start_name = '';
      this.formData.order_end_name = '';
    },
    handleClearShopName: function handleClearShopName() {
      this.formData.shopName = '';
      this.dialogList = [];
      this.tagsList = [];
      this.dialogTags = [];
      this.tags = [];
      this.moreShow = false;
      this.formData = {
        shopName: '',
        timeScreening: [],
        order_start_name: '',
        order_end_name: '',
        disabled: true,
        isAdd: false,
        delete_orders_sublist_ids: '',
        add_orders_sublist_ids: ''
      };
      this.storeOrder = {
        store_url: '',
        create_start_time: '',
        create_end_time: '',
        order_start_name: '',
        order_end_name: '',
        is_all: 0,
        iDisplayLength: 100,
        iDisplayStart: 0,
        delete_orders_sublist_ids: '',
        add_orders_sublist_ids: ''
      };
    },
    // 选中日期
    handleChooseData: function handleChooseData(val) {
      var _this7 = this;

      // console.log('日期筛选',val)
      this.storeOrder.iDisplayStart = 0;
      this.formData.delete_orders_sublist_ids = this.deleteArr.toString();
      this.storeOrder.delete_orders_sublist_ids = this.formData.delete_orders_sublist_ids;
      this.mixinloading('数据加载中...');

      try {
        this.handleClearShop();
        this.isLonginTags = 0;

        if (val === null || val === '' || val === undefined) {
          this.storeOrder.create_start_time = '';
          this.storeOrder.create_end_time = '';
          this.handleGetStoreOrder();
          this.$set(this.formData, 'create_start_time', '');
          this.$set(this.formData, 'create_end_time', '');
          this.$emit('storeUrl', this.formData, this.index);
        } else {
          this.storeOrder.create_start_time = val[0];
          this.storeOrder.create_end_time = val[1];
          this.handleGetStoreOrder();
          this.$set(this.formData, 'create_start_time', val[0]);
          this.$set(this.formData, 'create_end_time', val[1]);
          this.$emit('storeUrl', this.formData, this.index);
        }

        setTimeout(function () {
          _this7.mixinclose();
        }, this.timeOut);
      } catch (e) {
        console.error(e);
        this.mixinclose();
      }
    },
    // 选中区间
    handleChooseStart: function handleChooseStart(val) {
      var _this8 = this;

      this.storeOrder.iDisplayStart = 0;
      this.formData.delete_orders_sublist_ids = this.deleteArr.toString();
      this.storeOrder.delete_orders_sublist_ids = this.formData.delete_orders_sublist_ids;
      this.mixinloading('数据加载中...');

      try {
        this.isLonginTags = 0;
        this.handleClearShop();
        this.storeOrder.order_start_name = val;
        this.handleGetStoreOrder();
        this.$emit('storeUrl', this.formData, this.index);
        setTimeout(function () {
          _this8.mixinclose();
        }, this.timeOut);
      } catch (e) {
        console.error(e);
        this.mixinclose();
      }
    },
    handleChooseEnd: function handleChooseEnd(val) {
      var _this9 = this;

      this.storeOrder.iDisplayStart = 0;
      this.formData.delete_orders_sublist_ids = this.deleteArr.toString();
      this.storeOrder.delete_orders_sublist_ids = this.formData.delete_orders_sublist_ids;
      this.mixinloading('数据加载中...');

      try {
        this.isLonginTags = 0;
        this.handleClearShop();
        this.storeOrder.order_end_name = val;
        this.handleGetStoreOrder();
        this.$emit('storeUrl', this.formData, this.index);
        setTimeout(function () {
          _this9.mixinclose();
        }, this.timeOut);
      } catch (e) {
        console.error(e);
        this.mixinclose();
      }
    },
    // 选中订单
    handleChooseOrder: function handleChooseOrder(val) {
      console.log('val', val); // 找到选中值对应的对象

      var selectVal = this.dialogList.filter(function (item) {
        return val === item.orders_sublist_id;
      });
      this.tagsList.push(selectVal[0]);
      console.log(this.tagsList);
      this.canSelected(val);
      this.addArr.push(val);
      this.formData.add_orders_sublist_ids = this.addArr.toString(); // this.dialogTags.push({ order_name: this.getLabelByValue(val), orders_sublist_id: val })

      this.dialogTags.push(selectVal[0]);
      this.otherTagsAdd(val, selectVal);
      console.log('this.dialogTags', this.dialogTags);
    },
    canSelected: function canSelected(val) {
      var _this10 = this;

      this.dialogList.map(function (it, index) {
        if (val + '' === it.orders_sublist_id) {
          _this10.$set(_this10.disableDialogList, index, !_this10.disableDialogList[index]);
        }
      });
    },
    // 根据value 获取label
    getLabelByValue: function getLabelByValue(value) {
      var name = '';

      if (this.index === -1) {
        this.tagsList.map(function (item) {
          if (typeof value === 'string') {
            if (item.orders_sublist_id === value + '') {
              name = item.order_name;
            }
          } else {
            if (item.orders_sublist_id === value.orders_sublist_id) {
              name = item.order_name;
            }
          }
        });
      } else {
        this.dialogList.map(function (item) {
          if (typeof value === 'string') {
            if (item.orders_sublist_id === value + '') {
              name = item.order_name;
            }
          } else {
            if (item.orders_sublist_id === value.orders_sublist_id) {
              name = item.order_name;
            }
          }
        });
      }

      return name;
    },
    // 删除店铺
    handleDelete: function handleDelete() {
      this.$emit('handleDelete');
    },
    // 加载更多
    loadMore: function loadMore() {
      this.$set(this.storeOrder, 'is_all', 0);
      this.storeOrder.iDisplayStart += this.pageSize;
      this.$set(this.storeOrder, 'iDisplayLength', this.pageSize);
      this.handleGetStoreOrder();
    },
    // 下拉框加载更多
    dialogLoadMore: function dialogLoadMore() {
      var param = JSON.parse(JSON.stringify(this.storeOrder));
      this.isClickDelete = true;
      param.iDisplayStart += this.pageSize;
      this.$set(param, 'is_all', 1);
      this.$set(param, 'iDisplayLength', this.pageSize);
      this.getOrder(param);
    },
    // 添加弹框中选中的店铺
    otherTagsAdd: function otherTagsAdd(val, selectVal) {
      // 弹框上面选中的tags保存下来  此时可能选中的不是根据外层时间区间带出来的店铺
      var otherTags = this.$store.getters.otherTags;
      console.log(otherTags);

      if (Object.keys(otherTags).length > 0) {
        var arr = otherTags[this.formData.store_url];
        var filter = arr.filter(function (e) {
          return val.orders_sublist_id === e.orders_sublist_id;
        });

        if (filter.length === 0) {
          if (selectVal.length > 0) {
            arr.push(selectVal[0]);
          } else {
            console.log('么有匹配到值');
          }
        }

        console.log('vuex中选择的其他数据不为空', otherTags, this.formData.store_url);
        this.$store.commit('bill/SET_OTHER_TAGS', otherTags);
      } else {
        var data = {};
        var _arr = [];

        if (selectVal.length > 0) {
          _arr.push(selectVal[0]);
        } else {
          console.log('么有匹配到值');
        }

        data[this.formData.store_url] = _arr;
        console.log('vuex中选择的其他数据为空', data, this.formData.store_url);
        this.$store.commit('bill/SET_OTHER_TAGS', data);
      }
    },
    otherTagsDel: function otherTagsDel(val) {
      // 内层移除的时候处理vuex中非筛选时间新增的数据
      var otherTags = this.$store.getters.otherTags;
      console.log(otherTags);

      if (Object.keys(otherTags).length > 0) {
        var arr = otherTags[this.formData.store_url];
        var ind = -1;

        for (var i = 0; i < arr.length; i++) {
          if (arr[i].orders_sublist_id === val.orders_sublist_id) {
            ind = i;
            break;
          }
        }

        if (ind !== -1) {
          arr.splice(ind, 1);
        }

        otherTags[this.formData.store_url] = arr;
        console.log('vuex中删除的其他数据不为空', otherTags, this.formData.store_url);
        this.$store.commit('bill/SET_OTHER_TAGS', otherTags);
      }
    },
    remoteMethod: function remoteMethod(query) {
      var _this11 = this;

      var param = JSON.parse(JSON.stringify(this.storeOrder));
      this.$set(param, 'is_all', 1);
      this.$set(param, 'iDisplayLength', this.pageSize);
      this.$set(param, 'iDisplayStart', 0);
      this.$set(param, 'order_name', query);
      getStoreOrder(param).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (it, inx) {
            if (it.order_name === null) {
              it.order_name = '#' + inx;
            }
          });
          _this11.dialogList = JSON.parse(JSON.stringify(res.data));
        }
      });
    },
    disableDialog: function disableDialog(num) {
      var disable = false;
      this.dialogTags.map(function (it) {
        if (it.orders_sublist_id === num) {
          disable = true;
        }
      });
      return disable;
    }
  }
};