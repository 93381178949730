var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$parent.supplierTitle,
            visible: _vm.visible,
            width: "600px",
            "before-close": _vm.supplierAdd,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "supplierForm",
              attrs: {
                model: _vm.supplierForm,
                size: "small",
                "label-width": "150px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "供应商名称:", prop: "supplier_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "供应商名称" },
                    model: {
                      value: _vm.supplierForm.supplier_name,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "supplier_name", $$v)
                      },
                      expression: "supplierForm.supplier_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话号码:", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "电话号码" },
                    model: {
                      value: _vm.supplierForm.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "mobile", $$v)
                      },
                      expression: "supplierForm.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址信息:", prop: "address" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "地址" },
                    model: {
                      value: _vm.supplierForm.address,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "address", $$v)
                      },
                      expression: "supplierForm.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "负责人:", prop: "duty_name" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "负责人" },
                    model: {
                      value: _vm.supplierForm.duty_name,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "duty_name", $$v)
                      },
                      expression: "supplierForm.duty_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "微信号:", prop: "wechat_num" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: { size: "small", placeholder: "联系的微信号码" },
                    model: {
                      value: _vm.supplierForm.wechat_num,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "wechat_num", $$v)
                      },
                      expression: "supplierForm.wechat_num"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开始合作时间:", prop: "cooperate_time" } },
                [
                  _c("el-date-picker", {
                    staticClass: "w-300",
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      placeholder: "请选择日期",
                      size: "small",
                      type: "date"
                    },
                    model: {
                      value: _vm.supplierForm.cooperate_time,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "cooperate_time", $$v)
                      },
                      expression: "supplierForm.cooperate_time"
                    }
                  })
                ],
                1
              ),
              _c("el-divider", [_vm._v("经营信息")]),
              _c(
                "el-form-item",
                { attrs: { label: "主营类目:", prop: "category" } },
                [
                  _c("el-input", {
                    staticClass: "w-300",
                    attrs: {
                      type: "textarea",
                      rows: 4,
                      placeholder: "可输入多个主营类目,一行一个"
                    },
                    model: {
                      value: _vm.supplierForm.category,
                      callback: function($$v) {
                        _vm.$set(_vm.supplierForm, "category", $$v)
                      },
                      expression: "supplierForm.category"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.supplierAdd } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "throttle",
                      rawName: "v-throttle",
                      value: [
                        function() {
                          return _vm.supplierAdd(1)
                        }
                      ],
                      expression: "[() => supplierAdd(1)]"
                    }
                  ],
                  attrs: { size: "small", type: "primary" }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }