import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { afterSaleDetails, afterSaleType } from '@/api/after';
import { ReplyDialog } from './components';
export default {
  name: 'after-sale-edit',
  components: {
    ReplyDialog: ReplyDialog
  },
  data: function data() {
    return {
      activeName: 'first',
      activeReplyName: 'first',
      formData: {
        after_type: '',
        reply_img: [],
        reply_info: '',
        a_id: null
      },
      loading: false,
      replay: '',
      typeList: {},
      srcList: [],
      replayImageUrl: '',
      SubmitLoading: false,
      imageVisible: false,
      opertationloading: false,
      dialogVisible: false,
      afterFlag: false,
      afterModel: {
        '1': '补发',
        '2': '退款',
        '3': '退货/退款',
        '4': '其他'
      },
      imgList: [],
      formRule: {
        reply_info: [{
          required: true,
          message: '请输入回复内容',
          trigger: 'blur'
        }]
      },
      reply_data: {},
      afterSaleInfo: {
        customer_name: '',
        after_type: '',
        after_model: '',
        after_create_time: '',
        product_json: '',
        id: '',
        order_no: '',
        third_order_no: '',
        info: ''
      },
      showTabs: true,
      myRoute: {}
    };
  },
  created: function created() {
    this.myRoute = this.$route;
  },
  mounted: function mounted() {
    this.loading = true;
    this.getType();
    this.getDetail();
  },
  methods: {
    getType: function getType() {
      var _this = this;

      afterSaleType().then(function (res) {
        _this.typeList = res.data;
      });
    },
    // 获取详细信息
    getDetail: function getDetail() {
      var _this2 = this;

      afterSaleDetails({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.code === 200) {
          _this2.afterSaleInfo = res.data;
          _this2.loading = false;
          _this2.srcList = res.data.img;
          _this2.reply_data = res.data.reply;

          _this2.reply_data.map(function (it) {
            if (it.reply_img !== '') {
              _this2.$set(it, 'activeReplyName', 'second');
            } else {
              _this2.$set(it, 'activeReplyName', 'first');
            }
          });

          _this2.$nextTick(function () {
            _this2.showTabs = true;
          });
        }
      }).catch(function (err) {
        console.log(err);
      }).finally(function () {});
    },
    handleClick: function handleClick(tab, event) {},
    // . 回复提交
    submitReply: function submitReply() {
      var _this3 = this;

      this.dialogVisible = true;
      var data = parseInt(this.$route.query.id);
      afterSaleDetails({
        id: data
      }).then(function (res) {
        if (res.code === 200) {
          console.log(res);
          _this3.afterSaleInfo = res.data;
          _this3.loading = false;
        }
      });
    },
    // . 上一页
    prev: function prev() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'after-index'
      });
    },
    // 强制刷新Tabs
    handleUpdate: function handleUpdate() {
      this.showTabs = false;
      this.getDetail();
    }
  }
};