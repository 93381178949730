import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.set.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { quotedEdit, quotedDraft, goodsOptions, quotedSend, quotedChangePrice } from '@/api/commodity';
import RulesConfig from './components/rules-confing';
import SelectCommodityDialog from './components/selectCommodityDialog';
export default {
  name: 'detail',
  components: {
    RulesConfig: RulesConfig,
    SelectCommodityDialog: SelectCommodityDialog
  },
  data: function data() {
    return {
      loading: false,
      isTotal: false,
      formData: {
        product_title: '',
        url: '',
        img: ''
      },
      channel_rules: '',
      formDataRules: {},
      dialogVisible: false,
      selectCommodity: false,
      rulesData: {},
      quotedId: '',
      serialNumber: 0,
      selectList: [],
      imageList: [],
      platform_index_id: '',
      labelListOne: [{
        label: 'Serial',
        value: 'serial',
        width: '80'
      }, {
        label: 'Picture',
        value: 'img',
        type: 'img',
        width: '120'
      }],
      labelListTwo: [{
        label: 'Size',
        value: 'size',
        type: 'input'
      }, {
        label: 'Weight(G)',
        value: 'weight',
        type: 'input'
      }, {
        label: 'Product_price',
        value: 'product_price',
        type: 'change'
      }, {
        label: 'Service_price',
        value: 'service_price',
        type: 'change'
      }],
      optionList: [],
      countryLabelList: [],
      dayList: [],
      heaven: [],
      map_sku_id: 0
    };
  },
  created: function created() {
    var id = this.$route.query.id;
    this.init(id);
  },
  methods: {
    handleCalculateTotal: function handleCalculateTotal(val) {
      var _this = this;

      this.loading = true;
      val.product_price === null ? 0 : val.product_price;
      val.service_price === null ? 0 : val.service_price;
      var tem = 0;

      if (val.result !== null) {
        val.result.map(function (it) {
          it.product_price = val.product_price;
          it.service_price = val.service_price;
          var logistics_fee = val[it.country_code].logistics_fee === undefined ? 0 : val[it.country_code].logistics_fee;

          if (it.logistics_fee !== null) {
            tem = Number(val.product_price) + Number(val.service_price) + Number(logistics_fee);
          } else {
            tem = Number(val.product_price) + Number(val.service_price);
          }

          _this.$set(val[it.country_code], 'total', tem);
        });
      }

      var formData = {
        id: val.id,
        img: val.img,
        map_sku_id: val.map_sku_id,
        option: val.option,
        product_id: val.product_id,
        product_price: val.product_price,
        quoted_id: val.quoted_id,
        result: val.result,
        service_price: val.service_price,
        size: val.size,
        sku_id: val.sku_id,
        weight: val.weight
      };
      quotedChangePrice(formData).then(function (res) {
        if (res.code === 200) {
          _this.$message.success(res.message);

          _this.loading = false;
        }
      });
    },
    handleCalculateFeeTotal: function handleCalculateFeeTotal(sum, val) {
      var _this2 = this;

      this.loading = true;
      sum.product_price === null ? 0 : sum.product_price;
      sum.service_price === null ? 0 : sum.service_price;
      val.logistics_fee === null ? 0 : val.logistics_fee;
      var total = Number(sum.product_price) + Number(sum.service_price) + Number(val.logistics_fee);
      this.$set(val, 'total', total);
      var formData = {
        id: sum.id,
        img: sum.img,
        map_sku_id: sum.map_sku_id,
        option: sum.option,
        product_id: sum.product_id,
        product_price: sum.product_price,
        quoted_id: sum.quoted_id,
        result: sum.result,
        service_price: sum.service_price,
        size: sum.size,
        sku_id: sum.sku_id,
        weight: sum.weight
      };
      quotedChangePrice(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.message);

          _this2.loading = false;
        }
      });
    },
    handleToSubmit: function handleToSubmit(val) {
      var _this3 = this;

      this.loading = true;
      var formData = {
        id: val.id,
        img: val.img,
        map_sku_id: val.map_sku_id,
        option: val.option,
        product_id: val.product_id,
        product_price: val.product_price,
        quoted_id: val.quoted_id,
        result: val.result,
        service_price: val.service_price,
        size: val.size,
        sku_id: val.sku_id,
        weight: val.weight
      };
      quotedChangePrice(formData).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success(res.message);

          _this3.loading = false;
        }
      });
    },
    init: function init(id) {
      var _this4 = this;

      this.loading = true;
      this.imageList = [];
      quotedEdit({
        id: id
      }).then(function (res) {
        if (res.code === 200) {
          _this4.formData = res.data;
          _this4.platform_index_id = res.data.platform_index_id;
          _this4.channel_rules = res.data.channel_rules === null ? res.data.channel_rules : res.data.channel_rules.toString();
          var country = res.data.country.split(',');
          var labelList = [];
          var optionList = [];

          _this4.formData.sub_data.map(function (it, inx) {
            _this4.$set(it, 'serial', inx + 1);

            _this4.imageList.push(it.img);

            country.map(function (e) {
              var sum = {
                label: e,
                value: e,
                type: 'dynamic',
                width: '220'
              };
              labelList.push(sum);
            });
          });

          if (_this4.formData.sub_data[0].option) {
            for (var key in _this4.formData.sub_data[0].option) {
              var _labelList = {
                label: key,
                value: key
              };
              optionList.push(_labelList);
            }
          }

          var newobj = {};
          var arr = labelList.reduce(function (preVal, curVal) {
            newobj[curVal.label] ? '' : newobj[curVal.label] = preVal.push(curVal);
            return preVal;
          }, []);
          arr.map(function (it) {
            _this4.dayList.push('0-0');
          });
          var newOp = {};
          var newOptionList = optionList.reduce(function (preVal, curVal) {
            newOp[curVal.label] ? '' : newOp[curVal.label] = preVal.push(curVal);
            return preVal;
          }, []);
          _this4.optionList = newOptionList;
          _this4.countryLabelList = arr;
          var heaven = [];

          _this4.formData.sub_data.map(function (item) {
            if (item.result !== null) {
              item.result.map(function (it, inx) {
                if (item.result[inx].country_code === arr[inx].label) {
                  _this4.$set(item, arr[inx].label, item.result[inx]);

                  if (it.heaven) {
                    heaven.push(it.heaven);
                  }
                }
              });
            } else {
              arr.map(function (it, inx) {
                var obj = {
                  country_code: arr[inx].label,
                  id: null,
                  logistics_fee: null,
                  product_price: null,
                  service_price: null,
                  total: null
                };

                _this4.$set(item, arr[inx].label, obj);
              });
            }

            if (item.option) {
              for (var _key in item.option) {
                _this4.$set(item, _key, item.option[_key]);
              }
            }
          });

          if (heaven.length > 0) {
            var heavenArr = Array.from(new Set(heaven));
            heavenArr.map(function (it) {
              if (it === '') {
                return 0;
              }
            });
            _this4.dayList = heavenArr;
          }

          _this4.loading = false;
        }
      });
      goodsOptions().then(function (res) {
        if (res.status === 200) {
          _this4.selectList = res.data.product_attribute;
        }
      });
    },
    logisticsSettings: function logisticsSettings() {
      var flag = [];
      this.formData.sub_data.map(function (it) {
        if (it.map_sku_id === null) {
          flag.push(true);
        } else {
          flag.push(false);
        }
      });
      var num = flag.find(function (it) {
        return it === false;
      });

      if (num !== false) {
        this.$message.warning('商品选择为必填项，请至少选择一条商品再进行物流配置！');
      } else {
        this.dialogVisible = true;
        this.quotedId = this.$route.query.id;
      }
    },
    handleSaveDraft: function handleSaveDraft() {
      var _this5 = this;

      quotedDraft({
        id: this.$route.query.id
      }).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success(res.message);
        } else {
          _this5.$message.error(res.message);
        }
      });
    },
    MakeAQuotation: function MakeAQuotation() {
      var _this6 = this;

      var num = [];
      var sum = [];
      var arr = [];
      var name = [];
      var temporary = [];
      var country = [];
      this.formData.sub_data.some(function (it) {
        if (it.size === null || it.weight === null) {
          num.push(it);
        } else {
          arr.push(it);
        }
      });
      num.map(function (it) {
        sum.push(it.serial);
      });
      arr.map(function (it) {
        if (it.result !== null) {
          it.result.map(function (i) {
            if (i.logistics_fee === null) {
              temporary.push(i.country_code);
              name.push(it.serial);
            }
          });
        } else {
          name.push(it.serial);
          temporary = _this6.formData.country.split(',');
        }
      });
      country = Array.from(new Set(temporary));
      this.formData.heaven = this.heaven;

      if (sum.length > 0) {
        this.$confirm("\u68C0\u6D4B\u5230\u4EA7\u54C1\uFF1A".concat(sum, " \u5185\u5305\u542B\u7684\u4FE1\u606F\u4E0D\u5B8C\u5584\uFF0C\u5EFA\u8BAE\u60A8\u5C06\u5176\u5B8C\u5584"), '提示', {
          confirmButtonText: '提交',
          cancelButtonText: '前往完善',
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          type: 'warning'
        }).then(function () {
          if (country.length > 0) {
            _this6.$confirm("\u68C0\u6D4B\u5230\u60A8\u7684\u914D\u7F6E\u5546\u54C1\uFF1A".concat(name, " \u89C4\u5219\u5185\u672A\u5305\u542B\uFF1A").concat(country, " \u7684\u6E20\u9053\u4FE1\u606F"), '提示', {
              confirmButtonText: '提交',
              cancelButtonText: '更新规则',
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              type: 'warning'
            }).then(function () {
              quotedSend({
                id: _this6.$route.query.id
              }).then(function (res) {
                if (res.code === 200) {
                  _this6.$message.success(res.message);

                  _this6.handleCancel();
                } else {
                  _this6.$message.error(res.message);
                }
              });
            }).catch(function () {
              _this6.logisticsSettings();
            });
          } else {
            quotedSend({
              id: _this6.$route.query.id
            }).then(function (res) {
              if (res.code === 200) {
                _this6.$message.success(res.message);

                _this6.handleCancel();
              } else {
                _this6.$message.error(res.message);
              }
            });
          }
        }).catch(function () {// this.logisticsSettings()
        });
      } else {
        if (country.length > 0) {
          this.$confirm("\u68C0\u6D4B\u5230\u60A8\u7684\u914D\u7F6E\u5546\u54C1\uFF1A".concat(name, " \u89C4\u5219\u5185\u672A\u5305\u542B\uFF1A").concat(country, " \u7684\u6E20\u9053\u4FE1\u606F"), '提示', {
            confirmButtonText: '提交',
            cancelButtonText: '更新规则',
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: 'warning'
          }).then(function () {
            quotedSend({
              id: _this6.$route.query.id
            }).then(function (res) {
              if (res.code === 200) {
                _this6.$message.success(res.message);

                _this6.handleCancel();
              } else {
                _this6.$message.error(res.message);
              }
            });
          }).catch(function () {
            _this6.logisticsSettings();
          });
        } else {
          quotedSend({
            id: this.$route.query.id
          }).then(function (res) {
            if (res.code === 200) {
              _this6.$message.success(res.message);

              _this6.handleCancel();
            } else {
              _this6.$message.error(res.message);
            }
          });
        }
      }
    },
    handleCancel: function handleCancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.push({
        name: 'filter'
      });
    },
    handleClick: function handleClick(row, index) {
      this.$set(this.formData.sub_data[index], 'disabled', true);
      this.serialNumber = row.id;
      this.selectCommodity = true;
      this.map_sku_id = Number(row.map_sku_id);
    },
    receiveData: function receiveData(data) {
      var _this7 = this;

      this.formData.sub_data.map(function (it) {
        if (it.id === _this7.serialNumber) {
          it.size = data.sku_size;
          it.weight = data.sku_weight;

          _this7.$set(it, 'good_id', data.good_id);

          _this7.$set(it, 'map_sku_id', data.id);

          _this7.$set(it, 'product_price', data.sale_price);

          _this7.$set(it, 'service_price', data.service_fee);

          _this7.$set(it, 'product_attribute', data.product_attribute);
        }
      });
    },
    handleDayList: function handleDayList(val) {
      var _this8 = this;

      var arr = this.formData.country.split(',');

      if (val) {
        val.map(function (it) {
          arr.map(function (i, inx) {
            if (i === it.country) {
              _this8.$set(_this8.dayList, inx, it.heaven);

              _this8.heaven.push(it);
            }
          });
        });
      }
    }
  }
};