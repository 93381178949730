import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { logisticsGetChannel, logisticsChangeStatus, logisticsRemark, logisticsChangeType } from '@/api/information';
export default {
  name: 'channel-conifg',
  components: {
    DialogImport: function DialogImport() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/dialogImport'));
      });
    }
  },
  data: function data() {
    return {
      labelList: [{
        label: '渠道名称',
        value: 'channel_name',
        width: '230'
      }, {
        label: '国家',
        value: 'countryList'
      }, {
        label: '渠道支持商品类型',
        value: 'product_type',
        width: '200',
        type: 'product_type'
      }, {
        label: '参考时效',
        value: 'aging',
        width: '120'
      }, {
        label: '备注',
        value: 'remark',
        width: '250',
        type: 'remark'
      }, {
        label: '价格更新时间',
        value: 'update_time',
        width: '200'
      }, {
        label: '操作',
        type: 'operation',
        width: '150'
      }],
      attrList: ['特货', '普货', '液体'],
      tableData: [],
      loading: '',
      importVisible: false,
      importProps: {
        type: 'logistics',
        url: '/logistics/logistics/uploadexcel'
      }
    };
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    operation: function operation(item, type) {
      var _this = this;

      var typeTitle;
      var typeHead;

      if (type === '2') {
        typeTitle = '确定是否停用' + item.channel_name + '该渠道！';
        typeHead = '停用合作';
      } else {
        typeTitle = '确定是否启用' + item.channel_name + '该渠道！';
        typeHead = '启用';
      }

      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        logisticsChangeStatus({
          cid: item.cid,
          status: type
        }).then(function (res) {
          if (res.code === 200) {
            _this.$message.success('操作成功');

            _this.Inquire();
          }
        });
      }).catch(function () {
        _this.$message.info('已取消设置');
      });
    },
    channelPrice: function channelPrice(row) {
      this.$router.push({
        name: 'logistics-channel',
        params: {
          platform_id: row.logistics_platform_id,
          cid: row.cid,
          type: 'edit'
        }
      });
    },
    Inquire: function Inquire() {
      var _this2 = this;

      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      logisticsGetChannel({
        logistics_providers_id: this.$route.params.id
      }).then(function (res) {
        var channel = res.data;

        if (channel.length > 0) {
          channel.forEach(function (chan) {
            chan.countryList = chan.country.map(function (item) {
              return item.country;
            }).toString();
          });
          _this2.tableData = channel;
        }

        loading.close();
      }).catch(function (err) {
        console.log(err);
        loading.close();
      });
    },
    // 修改备注
    commentBlur: function commentBlur(scope) {
      var params = {
        cid: scope.row.cid,
        remark: scope.row.remark
      };
      logisticsRemark(params).then(function (res) {});
    },
    typeClick: function typeClick(row) {
      var _this3 = this;

      logisticsChangeType({
        type: row.product_type,
        cid: row.cid
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success('操作成功');
        }
      });
    },
    // 打开上传弹窗更新价格
    Updateprice: function Updateprice() {
      this.importVisible = true;
    },
    // 关闭上传弹窗更新价格
    closeImport: function closeImport(type) {
      this.importVisible = false;

      if (type === 1) {
        this.Inquire();
      }
    }
  }
};