import _typeof from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        time = parseInt(time);
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    return value.toString().padStart(2, '0');
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function param2Obj(url) {
  var search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ');

  if (!search) {
    return {};
  }

  var obj = {};
  var searchArr = search.split('&');
  searchArr.forEach(function (v) {
    var index = v.indexOf('=');

    if (index !== -1) {
      var name = v.substring(0, index);
      var val = v.substring(index + 1, v.length);
      obj[name] = val;
    }
  });
  return obj;
}
/**
 * @param {Array} arr
 * @returns {Array}
 */

export function parseMap(arr) {
  var routesMap = {};

  function flat(arr) {
    return arr.reduce(function (pre, cur) {
      if (cur.name) {
        routesMap[cur.name] = cur;
      }

      return pre.concat(Array.isArray(cur.children) ? flat(cur.children) : cur);
    }, []);
  }

  flat(arr);
  console.log('123', routesMap);
  return routesMap;
}
/**
 * 生成随机或者指定位数的英文数字组合
 * @param {boolean} randomFlag	是否是随机生成位数
 * @param {number} min 			生成随机位数的最小数
 * @param {number} max			生成随机位数的最大数
 * @return {string}				返回生成的英文数字组合
*/

export function randomSrt(randomFlag, min, max) {
  var str = '';
  var range = min; // 默认赋值为第二个参数，如果是随机产生位数会通过下面的if改变。

  var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']; // 随机产生

  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min;
  }

  for (var i = 0; i < range; i++) {
    var index = Math.round(Math.random() * (arr.length - 1));
    str += arr[index];
  }

  return str;
}
/**
 * 生成随机或者指定位数的英文数字组合
 * @param {boolean} randomFlag	是否是随机生成位数
 * @param {number} min 			生成随机位数的最小数
 * @param {number} max			生成随机位数的最大数
 * @return {string}				返回生成的英文数字组合
*/

export function clearObj(obj, exclude) {
  for (var _i = 0, _Object$keys = Object.keys(obj); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];

    if (!exclude.includes(key)) {
      obj[key] = '';
    }
  }
}