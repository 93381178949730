import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { platformList, logisticsPlatformSave, logisticsPlatformstatus } from '@/api/information';
export default {
  name: 'logistics-platform',
  components: {
    Pagination: function Pagination() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/Pagination'));
      });
    },
    packageSearch: function packageSearch() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/components/packageSearch/index'));
      });
    }
  },
  data: function data() {
    return {
      // 列表表头
      labelList: [{
        label: '平台名称',
        value: 'platform_title'
      }, {
        label: '状态',
        value: 'status',
        type: 'status'
      }, {
        label: '创建时间',
        value: 'create_at'
      }, {
        label: '操作',
        type: 'operation'
      }],
      tableData: [],
      dialogVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        total: 0
      },
      formInline: {
        platform_title: ''
      },
      platform: {
        platform_title: ''
      },
      platform_rules: {
        platform_title: [{
          required: true,
          message: '请输入平台名称',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    // 展开过滤列表
    filterList: function filterList() {
      return [{
        label: '平台名称',
        inputType: 'input',
        name: 'platform_title'
      }];
    }
  },
  created: function created() {
    this.Inquire();
  },
  methods: {
    // 查询
    Inquire: function Inquire() {
      var _this = this;

      this.loading = true;
      var formData = JSON.parse(JSON.stringify(this.formInline));
      formData.iDisplayLength = this.listQuery.limit;
      formData.iDisplayStart = (this.listQuery.page - 1) * this.listQuery.limit;
      platformList(formData).then(function (res) {
        if (res.code === 200) {
          res.data.map(function (item) {
            item.status = item.status === '1' ? '启用' : '禁用';
          });
          _this.tableData = res.data;
          _this.listQuery.total = parseInt(res.total);
          _this.loading = false;
        }
      });
    },
    // 新增平台
    addPlatform: function addPlatform() {
      this.dialogVisible = true;
    },
    // 确认新增
    platformClick: function platformClick() {
      var _this2 = this;

      //  this.$refs.platform.resetFields()
      this.$refs.platform.validate(function (valid) {
        if (valid) {
          logisticsPlatformSave(_this2.platform).then(function (res) {
            if (res.code === 200) {
              _this2.$message.success(res.message);

              _this2.dialogVisible = false;

              _this2.Inquire();
            }
          });
        }
      });
    },
    // 禁用 启用
    operationClick: function operationClick(row) {
      var _this3 = this;

      var typeTitle;
      var typeHead;
      var status;

      if (row.status === '启用') {
        typeTitle = '确定是否禁用' + row.platform_title + '物流平台?';
        typeHead = '禁用';
        status = 2;
      } else {
        typeTitle = '确定是否启用' + row.platform_title + '物流平台?';
        typeHead = '启用';
        status = 1;
      }

      console.log(status);
      this.$confirm(typeTitle, typeHead, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        logisticsPlatformstatus({
          id: [row.id],
          status: status
        }).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.message);

            _this3.Inquire();
          }
        });
      }).catch(function () {
        _this3.$message.info('已取消设置');
      });
    },
    // 展开搜索
    handleFilter: function handleFilter(val) {
      var _this4 = this;

      this.listQuery.page = 1;
      this.listQuery.limit = 10;
      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this4.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this4.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    },
    // 重置
    resetFilter: function resetFilter() {
      this.formInline = this.$options.data().formInline;
      this.Inquire();
    },
    // 展开后二次搜索
    handleSecFilter: function handleSecFilter(val) {
      var _this5 = this;

      val.forEach(function (item) {
        // 如果是下拉框或输入框
        var filterItem = Object.keys(_this5.formInline).find(function (e) {
          return e === item.name;
        });

        if (item.name === filterItem) {
          _this5.formInline[filterItem] = item.value;
        }
      });
      this.Inquire();
    }
  }
};