var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "500px",
        "before-close": _vm.close,
        top: "25vh",
        "modal-append-to-body": false,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c(
            "el-upload",
            {
              ref: "myXlsx",
              staticStyle: { width: "50%", "margin-left": "60px" },
              attrs: {
                action: "#",
                "http-request": _vm.httpRequest,
                "file-list": _vm.xlsxUpLoadFileList,
                "before-upload": _vm.xlsxUploads,
                multiple: true,
                "on-remove": _vm.xlsxRemove,
                limit: _vm.limit,
                "on-exceed": _vm.exceedClick,
                accept: ".xlsx,.xls,.csv"
              }
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("选取文件")
              ])
            ],
            1
          ),
          _vm.importProps.index
            ? _c(
                "div",
                { staticClass: "f-r mt10" },
                [
                  _c(
                    "el-link",
                    { attrs: { type: "primary", href: _vm.importProps.index } },
                    [_vm._v("下载模板")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.importDeclare
        ? _c(
            "div",
            { staticClass: "t-c mt10" },
            [
              _c("label", { staticClass: "mr10 d-lb" }, [
                _vm._v(_vm._s(_vm.importProps.declareLabel))
              ]),
              _c("el-image", {
                staticClass: "d-lb",
                staticStyle: { width: "80px", height: "100px" },
                attrs: {
                  src: _vm.importProps.declareUrl,
                  "preview-src-list": [_vm.importProps.declareUrl]
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "throttle",
                  rawName: "v-throttle",
                  value: [
                    function() {
                      return _vm.close(1)
                    }
                  ],
                  expression: "[() => close(1)]"
                }
              ],
              attrs: { type: "primary", loading: _vm.loading, size: "small" }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }