import _interopRequireWildcard from "/var/lib/jenkins/workspace/dongketech-test/node_modules/@babel/runtime/helpers/esm/interopRequireWildcard";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';
var afterRouter = [{
  path: '/after',
  component: Layout,
  alwaysShow: true,
  name: 'after',
  redirect: 'noRedirect',
  meta: {
    title: '售后管理',
    icon: 'el-icon-menu'
  },
  children: [{
    path: 'afterIndex/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/after/afterIndex/index'));
      });
    },
    name: 'after-index',
    meta: {
      title: '售后列表',
      keepAlive: true
    }
  }, {
    path: 'after-sale-edit/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/after/after-sale-edit/index'));
      });
    },
    name: 'after-sale-edit',
    meta: {
      title: '售后回复',
      keepAlive: true
    },
    hidden: true
  }, {
    path: 'after-sale-checked/index',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/after/after-sale-checked/index'));
      });
    },
    name: 'after-sale-checked',
    meta: {
      title: '售后查看',
      keepAlive: true
    },
    hidden: true
  }]
}];
export default afterRouter;